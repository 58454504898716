import React from 'react'
import { CAREPLAN_VALUE_TYPES } from '../../../../constants/carePlanValueTypes'
import CarePlanItem from '../CarePlanItem'

export default class RespiratoryRateRangeItem extends CarePlanItem {
  constructor (props) {
    super(props)
    this.type = 'rr_range'
  }

  rangeRespiratoryRateMinInputElement () {
    return this.getInputElement(
      5, 19, 1,
      CAREPLAN_VALUE_TYPES.RESPIRATORY_RATE_MIN.get(val => val),
      CAREPLAN_VALUE_TYPES.RESPIRATORY_RATE_MIN.set(val => val)
    )
  }

  rangeRespiratoryRateMaxInputElement () {
    return this.getInputElement(
      20, 40, 1,
      CAREPLAN_VALUE_TYPES.RESPIRATORY_RATE_MAX.get(val => val),
      CAREPLAN_VALUE_TYPES.RESPIRATORY_RATE_MAX.set(val => val)
    )
  }

  renderGoal () {
    return <div>Keep resting respiratory rate in range</div>
  }

  renderClinicianTrigger () {
    return <div>If <b>resting respiratory rate</b> is below {this.rangeRespiratoryRateMinInputElement()} breaths/min or above {this.rangeRespiratoryRateMaxInputElement()} breaths/min</div>
  }

  renderCondensed () {
    return (
      <div>Resp. rate &lt; {this.getValueElement(CAREPLAN_VALUE_TYPES.RESPIRATORY_RATE_MIN.get(val => val))} or
        &gt; {this.getValueElement(CAREPLAN_VALUE_TYPES.RESPIRATORY_RATE_MAX.get(val => val))} br/min
      </div>
    )
  }
}
