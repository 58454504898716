import _ from 'lodash'
import moment from 'moment'
import classnames from 'classnames'

import {
  getEmptyChart,
  getBaseChartOptions,
  addPausePlotBands,
  getBaseSeriesOptions,
  getPointDateString
} from './baseChartUtils'
import { getXAxisForRange, getMarkerRadius } from './chartUtils'
import { isAlertOpen } from '../../constants/constants'

const basePulseOxChartOptions = {
  chart: { className: 'pulse_ox-chart' },

  legend: {
    symbolRadius: 0,
    itemMarginTop: 12
  },

  tooltip: {
    headerFormat: null,
    pointFormatter: function format () {
      return `
        <strong>${this.name}</strong><br/>
        ${this.description}
      `
    },
    useHTML: true,
    padding: 12
  },

  plotOptions: {
    line: {
      marker: { enabled: true }
    }
  },

  yAxis: [{
    title: { text: '' },
    labels: { format: '{value}' }
  }],

  series: []
}

function getPulseOxAlertsSeries (memberObj, options, handleAlertClick) {
  if (!(memberObj.alerts && memberObj.alerts.length)) return options
  const pulseOxAlerts = _.filter(memberObj.alerts, a => a.metric_type === 'pulse_ox')
  if (!pulseOxAlerts.length) return options

  const alertsData = pulseOxAlerts.map((alertObj) => {
    /*
     * Get the Y-value of the marker
     */
    let pointY = 0
    const pulseOxMeasurement = memberObj.pulse_ox.data.find(
      d => d.timestamp === alertObj.measurement_timestamp
    )

    // If there is a corresponding measurement, display alert marker above the higher submetric
    if (pulseOxMeasurement) {
      pointY = _.max([pulseOxMeasurement.oxygen_saturation, pulseOxMeasurement.heart_rate]) * 1.07
    } else pointY = memberObj.pulse_ox.data[0].oxygen_saturation * 1.07
    // Otherwise just use the first oxygen_saturation value we have

    /*
     * Get marker styling
     */
    const marker = {}
    // Support pulse_ox_tracking even though no customer has asked for that yet
    if (alertObj.type === 'pulse_ox_tracking') {
      marker.symbol = 'circle'
    }

    const isOpen = isAlertOpen(alertObj)
    const classNames = classnames({
      'is-open': isOpen,
      'is-closed': !isOpen,
      'is-tracking': alertObj.type === 'pulse_ox_tracking'
    })

    /*
     * Define Highcharts point
     */
    const point = {
      name: alertObj.provider_content,
      x: moment(alertObj.measurement_timestamp),
      y: pointY,
      marker,

      className: classNames,
      events: {
        click: () => {
          if (handleAlertClick) {
            handleAlertClick(alertObj)
          }
        }
      }
    }

    return point
  })

  const alertsSeries = _.extend({
    name: 'SpO₂ alerts',

    type: 'scatter',
    className: 'series-pulseOxAlerts',
    lineWidth: 0,
    marker: {
      symbol: 'triangle-down',
      radius: 7,
      lineWidth: 0
    },

    tooltip: {
      useHTML: true,
      headerFormat: '<strong>SpO₂ alert</strong>',
      pointFormat: '<div class="pulse_ox-alert">{point.name}</div>'
    },

    data: alertsData,

    zIndex: 2 // so the alerts are on top of the data
  }, getBaseSeriesOptions())

  const newOptions = _.cloneDeep(options)
  newOptions.series.push(alertsSeries)
  return newOptions
}

function getPulseOxSeries (options, memberObj, dashboardViewStore, pulseOxType, markerSymbol) {
  const newOptions = _.cloneDeep(options)

  // Set marker radius
  _.set(newOptions, ['plotOptions', 'line', 'marker', 'radius'], getMarkerRadius(dashboardViewStore))

  const pulseOxMeasurements = _.reverse(
    _.cloneDeep(memberObj.pulse_ox.data)).map((m) => {
    const unit = pulseOxType === 'heart_rate' ? ' beats/min' : '%'

    const point = {
      x: +moment(m.timestamp),
      y: m[pulseOxType],
      name: getPointDateString(moment(m.timestamp), true),
      description: `${m[pulseOxType]}${unit}`
    }
    return point
  })

  const pulseOxMeasurementsSeries = _.extend({
    name: pulseOxType === 'heart_rate' ? 'Heart rate' : 'SpO₂',
    data: pulseOxMeasurements,
    zIndex: 1,

    marker: {
      symbol: markerSymbol
    },

    type: 'line',
    className: 'series-oxygenSaturationMeasurements'
  }, getBaseSeriesOptions())

  newOptions.series.push(pulseOxMeasurementsSeries)
  return newOptions
}

function getOxygenSaturationSeries (options, memberObj, dashboardViewStore) {
  let newOptions = _.cloneDeep(options)

  newOptions = getPulseOxSeries(newOptions, memberObj, dashboardViewStore, 'oxygen_saturation', 'square')

  return newOptions
}

function getHeartRateSeries (options, memberObj, dashboardViewStore) {
  let newOptions = _.cloneDeep(options)

  newOptions = getPulseOxSeries(newOptions, memberObj, dashboardViewStore, 'heart_rate', 'circle')

  return newOptions
}

function getFullChartOptions (memberObj, dashboardViewStore, showMemberAlerts,
  handleAlertClick) {
  const xAxisOptions = { xAxis: getXAxisForRange(memberObj, dashboardViewStore) }

  let options = _.merge(
    getBaseChartOptions(),
    basePulseOxChartOptions,
    xAxisOptions
  )

  options = getOxygenSaturationSeries(options, memberObj, dashboardViewStore)
  options = getHeartRateSeries(options, memberObj, dashboardViewStore)

  if (showMemberAlerts) {
    options = getPulseOxAlertsSeries(memberObj, options, handleAlertClick)
  }

  options = addPausePlotBands(memberObj.user.pauses, options, true)

  return options
}

export default function getPulseOxChartOptions ({
  memberObj, dashboardViewStore,
  showMemberAlerts, handleAlertClick
}) {
  if (!memberObj.pulse_ox) return getEmptyChart()

  return getFullChartOptions(memberObj, dashboardViewStore, showMemberAlerts,
    handleAlertClick)
}
