import _ from 'lodash'
import {
  MEMBERS_URL_FILTERS,
  MembersListFilterUtils
} from '../utils/baseMembersListUtils'
import {
  getInitialState,
  filterBySearchQuery,
  filterByFilters,
  filterByCareManager,
  filterByLCU
} from '../utils/baseListUtils'
import { createSlice } from '@reduxjs/toolkit'

import { productConfig } from '../config/config-service'
import { getMemberSorts } from '../constants/memberSorts'
import { getFilterGroups, PAGE_SIZE } from '../constants/memberFilterGroups'

/* ******************************************************************************
 *
 * Data & interface state for members list page
 *
 * ******************************************************************************/
const config = productConfig().members

const initialState = {
  ...getInitialState({
    sortBy: config.refineSidebar.defaultFilterState.sortBy,
    filters: config.refineSidebar.defaultFilterState.filters
  }),
  clinicianId: null, // added for purpose of starred members filter
  allItems: [],
  processedItems: [],
  searchQuery: '',
  searchQueryReset: false,
  urlFilters: null,
  expandedColumn: null,
  careManagerId: 'DEFAULT_OPTION',
  selectedLCU: 'All'
}

const membersViewSlice = createSlice({
  name: 'membersView',
  initialState,
  reducers: {
    setClinicianId: (state, action) => {
      state.clinicianId = action.payload
    },
    populateMembersFromCache: (state, action) => {
      state.allItems = _.values(action.payload.entities)
      state.page = 1
      getCurrentPage(processList(state))
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload
      state.searchQueryReset = false
      state.page = 1
      getCurrentPage(processList(state))
    },

    setSort: (state, action) => {
      state.sortBy = action.payload
      state.page = 1
      getCurrentPage(processList(state))
    },

    setCareManagerId: (state, action) => {
      state.careManagerId = action.payload
      getCurrentPage(processList(state))
    },

    setSelectedLCU: (state, action) => {
      state.selectedLCU = action.payload
      getCurrentPage(processList(state))
    },

    setFilter: (state, action) => {
      if (state.filters.includes(action.payload)) {
        state.filters = _.without(state.filters, action.payload)
      } else {
        state.filters = _.concat(state.filters, action.payload)
      }
      getCurrentPage(processList(state))
    },

    setPage: (state, action) => {
      const maxPage = Math.ceil(state.processedItems.length / MEMBERS_LIST.PAGE_SIZE)

      if (action.payload < 1) state.page = 1
      else if (action.payload > maxPage) state.page = maxPage
      else state.page = action.payload
      getCurrentPage(processList(state))
    },

    pagePrev: (state, _action) => {
      state.page = Math.max(1, state.page - 1)
      getCurrentPage(processList(state))
    },

    pageNext: (state, _action) => {
      const maxPage = Math.ceil(state.processedItems.length / MEMBERS_LIST.PAGE_SIZE)
      state.page = Math.min(maxPage, state.page + 1)
      getCurrentPage(processList(state))
    },

    setUrlFilters: (state, action) => {
      const applyFilter = (nFilter) => {
        if (state.filters.includes(nFilter)) state.filters = _.without(state.filters, nFilter)
        else state.filters = state.filters.concat(nFilter)
      }

      const query = Object.fromEntries(new URLSearchParams(action.payload).entries())
      state.page = 1

      Object.keys(query).forEach(qfilter => {
        const filter = `FILTER/${qfilter.toUpperCase()}`
        applyFilter(filter)
      })

      state.urlFilters = _.pick(query, _.values(MEMBERS_URL_FILTERS))
    },

    resetView: (state) => {
      state.sortBy = initialState.sortBy
      state.filters = initialState.filters
      state.processedItems = initialState.processedItems
      state.searchQuery = initialState.searchQuery
      state.urlFilters = initialState.urlFilters
      state.expandedColumn = initialState.expandedColumn
      state.careManagerId = initialState.careManagerId
      state.selectedLCU = initialState.selectedLCU
      state.searchQueryReset = true
      state.page = 1
      getCurrentPage(processList(state))
    },

    setLocationKey: (state, action) => {
      state.locationKey = action.payload
    },

    changeMemberViewStarStatus: (state, action) => {
      // Placeholder star is used while we are waiting for server response
      const placeholderStars = action.payload.starred
        ? [{ clinician_id: action.payload.clinicianId, timestamp: new Date().getTime() }]
        : [];

      [state.currentPage, state.processedItems, state.allItems].forEach((lst) => {
        /* eslint-disable no-param-reassign */
        lst.forEach((userInfo) => {
          if (userInfo.user.id === action.payload.memberId) {
            userInfo.user.stars = placeholderStars
          }
        })
      })
      processList(state)
    }
  }
})

/*
 * Helpers
 */

function processList (state) {
  let processedItems = state.allItems
  const filterCounts = state.filterCounts ?? {}

  // Filter by URL query
  if (_.size(state.urlFilters)) {
    processedItems = processedItems.filter(obj =>
      _.toPairs(state.urlFilters).reduce((isAllowed, urlFilter) => (
        isAllowed && MembersListFilterUtils[urlFilter[0]](obj, urlFilter[1])
      ), true)
    )
  }

  // Filter by search query
  processedItems = filterBySearchQuery(processedItems, state.searchQuery)

  // Sort by selected sort order
  const selectedSort = _.find(MEMBERS_LIST.SORTS, { id: state.sortBy })
  processedItems = processedItems.sort(selectedSort.compare)

  // Get filter counts
  _.each(_.values(MEMBERS_LIST.FILTER_GROUPS), (filterGroup) => {
    _.each(filterGroup.filters, (f) => {
      filterCounts[f.id] = filterByFilters({
        currentList: processedItems,
        selectedFilters: [f.id],
        allFilters: MEMBERS_LIST.FILTER_GROUPS,
        clinicianId: state.clinicianId
      }).length
    })
  })

  // Filter by selected filters
  if (state.filters?.length) {
    processedItems = filterByFilters({
      currentList: processedItems,
      selectedFilters: state.filters,
      allFilters: MEMBERS_LIST.FILTER_GROUPS,
      clinicianId: state.clinicianId
    })
  }

  // Filter by the care manager selected
  processedItems = filterByCareManager({
    currentList: processedItems,
    careManagerId: state.careManagerId
  })

  // Filter by the selected LCU
  processedItems = filterByLCU({
    currentList: processedItems,
    selectedLCU: state.selectedLCU
  })

  state.processedItems = processedItems
  state.filterCounts = filterCounts
  return state
}

function getCurrentPage (state) {
  const pageStart = (state.page - 1) * MEMBERS_LIST.PAGE_SIZE
  const pageEnd = state.page * MEMBERS_LIST.PAGE_SIZE
  state.currentPage = state.processedItems.slice(pageStart, pageEnd)
  return state
}

const MEMBERS_LIST = {
  PAGE_SIZE,
  SORTS: getMemberSorts(),
  FILTER_GROUPS: getFilterGroups()
}

export const {
  populateMembersFromCache,
  setSearchQuery,
  setSort,
  setFilter,
  pagePrev,
  pageNext,
  setPage,
  setUrlFilters,
  resetView,
  setLocationKey,
  changeMemberViewStarStatus,
  setClinicianId,
  setSelectedLCU
} = membersViewSlice.actions
export { MEMBERS_LIST, membersViewSlice }
