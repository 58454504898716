
import React, { useState } from 'react'
import moment from 'moment'
import { momentCalendarDateConfig } from '../../../utils/baseStringUtils'
import EmptyCell from './EmptyCell'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

export default function BloodOxygenCell (props) {
  const [memberObj] = useState(props.memberObj)

  if (!memberObj.user.has_pulseox || !memberObj.pulse_ox) {
    return <EmptyCell isBloodOxygen />
  }

  const lastPulseOxMeasurement = moment(memberObj.pulse_ox.latest_measurement.date)

  const latestCol = (
    <Box>
      <div className='member-row__latest'> {`${memberObj.pulse_ox.latest_measurement.oxygen_saturation.toLocaleString()}%`} </div>
      <div className='member-row__latest-timestamp'> {lastPulseOxMeasurement.calendar(null, momentCalendarDateConfig)} </div>
    </Box>
  )

  return (
    <Grid container>
      <Grid item xs={12}> {latestCol} </Grid>
    </Grid>
  )
}
