export const allAccessRoutes = {
  loginPage: '/login',
  forgotPasswordPage: '/forgot',
  resetPasswordPage: '/reset',
  activateAccountPage: '/activate',
  phoneLoginPage: '/phone',
  signupPage: '/signup'
}

const authorizedRoutes = {
  welcomePage: '/welcome',
  logoutPage: '/logout',
  alertsPage: '/alerts',
  memberDashboardPage: '/memberDashboard',
  accountPage: '/account',
  helpPage: '/help',
  profilePage: '/profile',
  chatPage: '/chat',
  membersPage: '/members',
  careplanPage: '/careplan',
  analyticsPage: '/analytics'
}

export const isProfilePage = (pathname) => {
  return pathname.includes(authorizedRoutes.profilePage)
}

export const isAllAccessRoute = (pathname) => {
  return Object.values(allAccessRoutes).includes(pathname)
}

export const ejentaRoutes = {
  ...allAccessRoutes,
  ...authorizedRoutes
}
