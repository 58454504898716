import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import restApi from '../../../../api'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Alert, Button } from '@mui/material'
import { membersViewSlice, MEMBERS_LIST } from '../../../../store/membersView'
import {
  ContentMain,
  ContentMainIntro,
  ContentMainIntroHeader,
  ContentMainBody
} from '../../../layout/ContentMain'
import LoadingIndicator from '../../../elements/LoadingIndicator'
import Pagination from '../../../elements/Pagination'
import MemberHeaderRow from './MemberHeaderRow'
import MemberRow from './MemberRow'
import AddMemberModal from './AddMemberModal'
import RefineMembersSidebar from './RefineMembersSidebar'

import { useLocation } from 'react-router'

/* *****************************************************************************
 * Members page (clinician-only)
 * *****************************************************************************/

function getPropsFromStore (state) {
  return {
    memberSummariesApi: state.api.memberSummaries,
    membersViewStore: state.membersView,
    pageStart: (state.membersView.page - 1) * MEMBERS_LIST.PAGE_SIZE,
    pageEnd: state.membersView.page * MEMBERS_LIST.PAGE_SIZE
  }
}

function getPropsFromActions (dispatch) {
  return {
    storeActions: bindActionCreators(membersViewSlice.actions, dispatch),
    apiActions: bindActionCreators(restApi.actions, dispatch)
  }
}

function ClinicianMembersPage (props) {
  const location = useLocation()
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false)
  const [locationKey, setLocationKey] = useState(null)

  // ComponentDidMount
  useEffect(() => {
    if (location) props.storeActions.setUrlFilters(location.search)
    props.apiActions.memberSummaries()
  }, [])

  useEffect(() => {
    setLocationKey(location.key)

    if (props.membersViewStore.locationKey !== locationKey) {
      props.storeActions.setLocationKey(locationKey)
      props.apiActions.memberSummaries()
    }
  }, [location])

  // Resets the search query, sorts, and filters
  const handleResetMembersList = () => {
    props.storeActions.resetView()

    if (!_.isEmpty(location.search)) {
      props.storeActions.setUrlFilters(location.search)
    }
  }

  /*
   * Interaction handling
   */
  const handleOpenAddMemberModal = () => {
    setIsAddMemberModalOpen(true)
  }

  const handleCloseAddMemberModal = () => {
    setIsAddMemberModalOpen(false)
  }

  const onSetPage = (value) => {
    props.storeActions.setPage(value)
  }

  /*
   * Rendering
   */
  const renderHeader = () => {
    return (
      <ContentMainIntroHeader title='Members'>
        <Button variant='contained' size='small' data-testid='clinician-members__add-member-button' onClick={handleOpenAddMemberModal}>
          Add member
        </Button>
      </ContentMainIntroHeader>
    )
  }

  const renderIntro = () => {
    if (!props.membersViewStore.currentPage?.length) return null

    return (
      <div>
        <Pagination
          pageStart={props.pageStart + 1}
          pageEnd={props.pageEnd}
          itemCount={props.membersViewStore.processedItems.length}
          handlePagePrev={props.storeActions.pagePrev}
          handlePageNext={props.storeActions.pageNext}
          page={props.membersViewStore.page}
          setPage={onSetPage}
          custom
        />
        <MemberHeaderRow membersViewStore={props.membersViewStore} />
      </div>
    )
  }

  const renderBody = () => {
    if (!props.membersViewStore.allItems.length) {
      return (
        <div className='clinician-members__message'>
          There are no members in your group.
        </div>
      )
    }

    if (!props.membersViewStore.currentPage.length) {
      return (
        <Alert
          sx={{ mt: 1 }}
          severity='warning'
          action={<Button color='inherit' size='small' onClick={handleResetMembersList}> Reset Filters </Button>}
        >
          No members matched your search.
        </Alert>
      )
    }

    return (
      <div>
        {props.membersViewStore.currentPage.map(
          m => <MemberRow key={m.user.id} memberObj={m} />
        )}
        <Pagination
          pageStart={props.pageStart + 1}
          pageEnd={props.pageEnd}
          itemCount={props.membersViewStore.processedItems.length}
          handlePagePrev={props.storeActions.pagePrev}
          handlePageNext={props.storeActions.pageNext}
          page={props.membersViewStore.page}
          setPage={onSetPage}
          custom
        />
      </div>
    )
  }

  const renderMembersPage = () => {
    if (!props.memberSummariesApi.sync) {
      return <LoadingIndicator sidebarVisible />
    }

    return (
      <>
        <RefineMembersSidebar resetView={handleResetMembersList} />

        <ContentMain drawerWidth={285}>
          <ContentMainIntro>
            {renderHeader()}
            {renderIntro()}
          </ContentMainIntro>

          <ContentMainBody>
            <AddMemberModal
              isOpen={isAddMemberModalOpen}
              onModalClose={handleCloseAddMemberModal}
            />

            {renderBody()}
          </ContentMainBody>
        </ContentMain>
      </>
    )
  }

  return renderMembersPage()
}

export default connect(getPropsFromStore, getPropsFromActions)(ClinicianMembersPage)
