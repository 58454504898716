import _ from 'lodash'
import { DAYS_OF_WEEK } from '../../js/constants/constants'
import baseChartOptions from './baseChartOptions'

function getPercentile (nums, percentile) {
  const sortedNums = _.clone(nums).sort((a, b) => a - b)

  const index = (percentile / 100) * sortedNums.length

  let result
  if (Math.floor(index) === index) {
    result = (sortedNums[(index - 1)] + sortedNums[index]) / 2
  } else {
    result = sortedNums[Math.floor(index)]
  }
  return result
}

function getMedian (nums) {
  if (!nums.length) return 0

  const sortedNums = _.clone(nums).sort((a, b) => a - b)
  const middle = Math.floor(sortedNums.length / 2)

  let median
  if (sortedNums.length % 2 === 0) {
    median = (sortedNums[middle] + sortedNums[middle - 1]) / 2
  } else {
    median = sortedNums[middle]
  }
  return median
}

function getActivityChartOptions (users) {
  const dayActivityData = _.map(DAYS_OF_WEEK, (d) => {
    const stepsData = _.filter(_.map(users, u =>
      u.activity.avg_daily_steps[d.toLowerCase()]
    ), dData => !!dData)

    return [
      Math.min(...stepsData),
      getPercentile(stepsData, 25),
      getMedian(stepsData),
      getPercentile(stepsData, 75),
      Math.max(...stepsData)
    ]
  })

  const chartOptions = {
    chart: {
      type: 'boxplot'
    },

    plotOptions: {
      boxplot: {
        medianWidth: 1,
        whiskerLength: '40%'
      }
    },

    legend: {
      enabled: false
    },

    xAxis: {
      categories: DAYS_OF_WEEK,
      labels: {
        formatter: function format () {
          return this.value.slice(0, 2)
        }
      },
      tickLength: 0
    },

    yAxis: {
      title: {
        text: 'Average # of steps'
      },
      min: 0
    },

    series: [{
      data: dayActivityData,
      tooltip: {
        headerFormat: null,
        pointFormatter: function format () {
          return `
            <span class="ax-text-bold">${this.category}</span><br/>
            Median: <em>${Math.round(this.median).toLocaleString()} steps</em>
          `
        }
      }
    }]
  }

  return _.merge(_.cloneDeep(baseChartOptions), chartOptions)
}

export default getActivityChartOptions
