import _ from 'lodash'
import baseChartOptions from './baseChartOptions'

const ADHERENCE_BUCKETS = {
  low: 'Low (under 30%)',
  med: 'Medium (30%–70%)',
  high: 'High (above 70%)'
}

function getAdherenceCounts (users, range, device) {
  return {
    low: _.filter(users, u => u[device].adherence < 0.30),
    med: _.filter(users, u => u[device].adherence >= 0.30 && u[device].adherence < 0.70),
    high: _.filter(users, u => u[device].adherence >= 0.70)
  }
}

function getAdherenceChartOptions (users, range, analyticsActions) {
  const activityAdherenceCounts = getAdherenceCounts(users, range, 'activity')
  const weightAdherenceCounts = getAdherenceCounts(users, range, 'weight')

  const chartOptions = {
    chart: {
      type: 'column'
    },

    xAxis: {
      categories: _.values(ADHERENCE_BUCKETS),
      tickLength: 0,
      title: {
        text: 'Adherence level',
        margin: 20
      },
      labels: {
        formatter: function format () {
          return `${this.value}`
        }
      }
    },

    yAxis: {
      title: {
        text: '# of members'
      }
    },

    series: [{
      name: 'Tracker',
      className: 'series-0 ax-chart-clickable',

      data: _.map(activityAdherenceCounts, (a, key) => ({
        y: a.length,
        events: {
          click: () => {
            analyticsActions.setDrilldown({
              title: `Tracker adherence - ${ADHERENCE_BUCKETS[key]}`,
              type: 'member',
              items: a
            })
          }
        }
      }))

    }, {
      name: 'Scale',
      className: 'series-1 ax-chart-clickable',
      data: _.map(weightAdherenceCounts, (a, key) => ({
        y: a.length,
        events: {
          click: () => {
            analyticsActions.setDrilldown({
              title: `Scale adherence - ${ADHERENCE_BUCKETS[key]}`,
              type: 'member',
              items: a
            })
          }
        }
      }))
    }],

    tooltip: {
      headerFormat: null,
      pointFormatter: function format () {
        return `
          <span class="ax-text-bold">
            ${this.series.name} adherence - ${this.category}
          </span><br/>
          Members: <em>${this.y}</em><br/>
        `
      }
    }

  }

  return _.merge(_.cloneDeep(baseChartOptions), chartOptions)
}

export default getAdherenceChartOptions
