/* global PRODUCT_NAME */
import allConfigs from './product-config.json'
import _ from 'lodash'

const ConfigService = {
  productConfig: () => {
    const defaultConfig = allConfigs.DEFAULT

    switch (PRODUCT_NAME) {
      case 'CHF':
        return _.merge({}, defaultConfig, allConfigs.CHF)
      case 'HTN':
        return _.merge({}, defaultConfig, allConfigs.HTN)
      case 'IMPACT':
        return _.merge({}, defaultConfig, allConfigs.IMPACT)
      case 'OBIMPACT':
        return _.merge({}, defaultConfig, allConfigs.OBIMPACT)
      case 'POINT32CHF':
        return _.merge({}, defaultConfig, allConfigs.POINT32CHF)
      case 'PREGNANCY':
        return _.merge({}, defaultConfig, allConfigs.PREGNANCY)
      default:
        return defaultConfig
    }
  }
}

export const {
  productConfig
} = ConfigService
