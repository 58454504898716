import React, { useState } from 'react'
import MemberDevicesForm from '../../../controllers/forms/MemberDevicesForm'
import { Alert, Card, CardContent, Snackbar } from '@mui/material'
import { getMemberProfileFormErrors, getProfileMemberFields } from '../../../../utils/member/baseProfileUtils'
import { getMemberApiBody, getProfileFormInitialValues } from '../../../../constants/constants'

function DeviceInformationPane (props) {
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const memberFields = getProfileMemberFields()

  const getInitialValues = () => {
    return getProfileFormInitialValues(memberFields, props.memberObj)
  }

  const handleFormSubmit = (formData) => {
    const submitPromise = new Promise((resolve, reject) => {
      const apiBody = getMemberApiBody(memberFields, formData, props.memberObj.user.group)

      props.apiActions.updateUser(
        {
          id: props.memberObj.user.id,
          promise: { resolve, reject },
          promiseTransformer: errors => getMemberProfileFormErrors(errors, memberFields)
        },
        apiBody
      )
    })

    return submitPromise
      .then(handleFormSubmitSuccess)
      .catch(data => data)
  }

  const handleFormSubmitSuccess = () => {
    setSnackbarMessage('Successfully updated member.')
    setSnackbarSeverity('success')
    setSnackbarOpen(true)
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  const renderForm = () => {
    return (
      <MemberDevicesForm
        memberObj={props.memberObj}
        onSubmit={handleFormSubmit}
        onCancel={() => props.profileViewActions.handleClearModal()}
        getInitialValues={getInitialValues}
      />
    )
  }

  const renderFeedback = () => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        key='edit-profile-pane__success__snack-bar'
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    )
  }

  const renderPaneHeader = () => {
    return <div className='edit-profile-pane__header'>Devices</div>
  }

  const renderDeviceInformationPane = () => {
    return (
      <Card variant='outlined'>
        <CardContent>
          {!props.hideTitle && renderPaneHeader()}

          {renderForm()}

          {renderFeedback()}
        </CardContent>
      </Card>
    )
  }

  return renderDeviceInformationPane()
}

export default DeviceInformationPane
