import _ from 'lodash'
import restApi from '../../../api'
import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateUser } from '../../../store/userSession'
import { COMMUNICATION_TYPES } from '../../../constants/constants'
import { EjentaForm, renderField, renderRadioInput } from '../../elements/EjentaForm'
import { Card, CardContent, Button, DialogActions, FormControl, RadioGroup, Typography } from '@mui/material'
import Snackbar from '../../elements/Snackbar'

function BaseMessagePrefsForm (props) {
  const renderMessagePrefItem = (messageType, messageDesc) => {
    const userMessageType = props.user.communication || 'email'
    const communicationRadioField = {
      name: 'communication',
      type: 'radio',
      label: messageDesc,
      key: messageType,
      value: messageType,
      checked: userMessageType === messageType,
      component: renderRadioInput
    }

    return (renderField({}, communicationRadioField))
  }

  const renderSubmitButton = ({ pristine, submitting, hasValidationErrors }) => {
    let submitButton
    if (submitting) {
      submitButton = (<Button variant='contained' disabled type='submit'>Saving...</Button>)
    } else {
      submitButton = (
        <Button variant='contained' type='submit' disabled={pristine || hasValidationErrors}>Update preferences</Button>
      )
    }

    return (<DialogActions>{submitButton}</DialogActions>)
  }

  const renderBaseMessagePreferences = () => {
    return (
      <Form
        onSubmit={props.onSubmit}
        initialValues={props.getInitialValues()}
      >
        {({ handleSubmit, pristine, submitting, submitError, hasValidationErrors }) => (
          <EjentaForm onSubmit={handleSubmit}>
            <FormControl>
              <RadioGroup name='communication-radio-buttons-group'>
                {_.map(_.keys(COMMUNICATION_TYPES.CLINICIAN), k =>
                  renderMessagePrefItem(k, COMMUNICATION_TYPES.CLINICIAN[k])
                )}
              </RadioGroup>
            </FormControl>

            {renderSubmitButton({ pristine, submitting, hasValidationErrors })}
          </EjentaForm>
        )}
      </Form>
    )
  }

  return renderBaseMessagePreferences()
}

function MessagePrefs (props) {
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const getInitialValues = () => {
    return {
      communication: props.user.communication
    }
  }

  const handleSubmit = (formData) => {
    const submitPromise = new Promise((resolve, reject) => {
      props.apiActions.updateAccount(
        {
          id: props.user.id,
          promise: { resolve, reject }
        },
        { body: JSON.stringify(formData) }
      )
    })

    return submitPromise.then(handleSubmitSuccess)
  }

  const handleSubmitSuccess = (data) => {
    props.updateUser(data)
    setSnackbarOpen(true)
  }

  const renderSubmitStatus = () => {
    return (
      <Snackbar
        isOpen={snackbarOpen}
        dataKey='message-preferences__success__snackbar'
        handleClose={() => setSnackbarOpen(false)}
        severity='success'
        message='Message preferences updated successfully'
      />
    )
  }

  const renderMessagePreferences = () => {
    return (
      <Card data-testid='message-prefs' variant='outlined' className='u-mobile-bottom-margin'>
        <CardContent>
          <Typography variant='h6' fontSize='1.1rem' sx={{ mb: 3 }}>Message Preferences</Typography>
          {renderSubmitStatus()}

          <BaseMessagePrefsForm user={props.user} getInitialValues={getInitialValues} onSubmit={handleSubmit} />
        </CardContent>
      </Card>
    )
  }

  return renderMessagePreferences()
}

function getPropsFromStore (state) {
  return {
    user: state.userSession.user
  }
}

function getPropsFromActions (dispatch) {
  return {
    apiActions: bindActionCreators(restApi.actions, dispatch),
    updateUser: bindActionCreators(updateUser, dispatch)
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(MessagePrefs)
