import React from 'react'
import AnalyticsChart from '../layout/AnalyticsChart'
import getAgeChartOptions from '../charts/ageChart'

export default function Age (props) {
  return (
    <div className='ax-module'>
      <div className='ax-module-header'>Age</div>
      <AnalyticsChart
        containerId='ax-age-chart'
        chartOptions={getAgeChartOptions(props.users, props.analyticsActions)}
      />
    </div>
  )
}
