/* eslint-disable object-shorthand */

import _ from 'lodash'

import {
  getBaseChartOptions,
  addPausePlotBands,
  getSimpleContinuousSeries,
  getSimpleBioStickerAlertsSeries
} from './baseChartUtils'
import {
  getXAxisForRange
} from './chartUtils'

export const baseRespiratoryRateChartOptions = {
  chart: { className: 'respiratory_rate-chart' },

  legend: {
    symbolRadius: 0,
    itemMarginTop: 12
  },

  tooltip: {
    useHTML: true,
    padding: 12,
    pointFormat: '<div>{point.description}</div>'
  },

  plotOptions: {
    line: {
      marker: { enabled: false }
    }
  },

  yAxis: [{
    title: { text: '' },
    labels: { format: '{value}' }
  }],

  series: []
}

/* ********************************************************************************
 * Chart options
 * ********************************************************************************/

export function getFullChartOptions (memberObj, dashboardViewStore, showMemberAlerts, handleAlertClick) {
  const xAxisOptions = { xAxis: getXAxisForRange(memberObj, dashboardViewStore) }

  let options = _.merge(
    getBaseChartOptions(),
    baseRespiratoryRateChartOptions,
    xAxisOptions
  )

  options = getSimpleContinuousSeries(options, memberObj, dashboardViewStore, 'respiratory_rate', 'Resting respiratory rate',
    ` ${memberObj.respiratory_rate.information.units.respiratory_rate}`)

  if (showMemberAlerts) {
    options = getSimpleBioStickerAlertsSeries(memberObj, options, handleAlertClick, 'respiratory_rate', 'Respiratory rate')
  }

  options = addPausePlotBands(memberObj.user.pauses, options, true)

  return options
}
