import _ from 'lodash'
import moment from 'moment'

import {
  getBaseChartOptions,
  getBaseSeriesOptions,
  getPointDateString,
  addPausePlotBands
} from './baseChartUtils'
import { preciseRound } from '../unitConversionUtils'
import { getColumnWidth, getXAxisForRange } from './chartUtils'

import { baseFoodChartOptions } from './baseFoodChart'

/* ********************************************************************************
 * Product-specific series
 * ********************************************************************************/

function getFoodMeasurementsSeries (options, memberObj, dashboardViewStore) {
  const newOptions = _.cloneDeep(options)

  const foodMeasurements = (_.cloneDeep(memberObj.food.data)).reverse().map((m) => {
    const date = moment(m.date)
    const point = {
      x: +date,
      y: m.calories,
      carbs: m.carbohydrates_grams,
      fat: m.fat_grams,
      fiber: m.fiber_grams,
      protein: m.protein_grams,
      sodium: m.sodium_milligrams,
      foods: m.foods,
      dataSource: m.source
    }
    return point
  })

  const foodMeasurementSeries = {
    ...getBaseSeriesOptions(),
    name: 'Calories',
    type: 'column',
    data: foodMeasurements,
    zIndex: 1000,
    className: 'food-series',
    color: '#27AAE1',

    tooltip: {
      headerFormat: null,
      useHTML: true,
      borderWidth: 0,
      pointFormatter: function format () {
        const nutrientsPortion = `
          <br/>
          Carbs: ${preciseRound(this.carbs, 0)} g<br/>
          Fat: ${preciseRound(this.fat, 0)} g <br/>
          Protein: ${preciseRound(this.protein, 0)} g <br/>
          Fiber: ${preciseRound(this.fiber, 0)} g <br/>
          Sodium: ${preciseRound(this.sodium, 0)} mg <br/>
        `

        let foodsPortion = ''
        if (this.foods && this.foods.length) {
          foodsPortion += '<br/>'
          this.foods.forEach((f) => {
            foodsPortion += `${f.name} (${f.calories} cal)<br/>`
          })
        }

        return `
        <div style="max-height: 9rem; overflow-y: scroll; z-index: 999999">
          <strong>${getPointDateString(moment(this.x), false)}</strong><br/>
          ${this.y.toLocaleString()} calories<br/>
          ${nutrientsPortion}
          ${foodsPortion}
          <small>Source: ${this.dataSource ?? 'Unknown'}</small>
          </div>
        `
      }
    },

    pointWidth: getColumnWidth(dashboardViewStore)
  }

  newOptions.series.push(foodMeasurementSeries)
  return newOptions
}

function getCalorieGoalSeries (options, memberObj) {
  const calorieGoal = memberObj.careplan?.find(item => item.type === 'calorie_target')
  if (!calorieGoal || calorieGoal.disabled) return options

  const newOptions = _.cloneDeep(options)
  const calorieGoals = [calorieGoal]

  const calorieGoalData = calorieGoals.reduce((acc, goal) => {
    // Ignore calorie goals that were active for less than an hour
    if ((goal.end_date - goal.set_date) < 1000 * 60 * 60) return acc
    acc[acc.length - 1].push(goal.value)
    acc.push([+moment(goal.end_date), goal/* .target */.value])
    acc.push([+moment(goal.end_date) + 1])

    return acc
  }, [[+moment().subtract(1, 'year')]])

  calorieGoalData.pop() // Remove the last (incomplete) data point

  const calorieGoalSeries = {
    ...getBaseSeriesOptions,
    name: 'Daily calorie goal',
    type: 'line',
    data: calorieGoalData,
    dashStyle: 'longdash',
    color: '#7DD3F6',

    enableMouseTracking: false
  }

  newOptions.series.push(calorieGoalSeries)
  return newOptions
}

/* ********************************************************************************
 * Food chart
 * ********************************************************************************/

function getFoodChartOptions ({
  memberObj, dashboardViewStore
  /* showMemberAlerts, handleAlertClick */ }) {
  const baseChartOptions = getBaseChartOptions()
  const xAxisOptions = { xAxis: getXAxisForRange(memberObj, dashboardViewStore) }

  let options = {
    ...baseFoodChartOptions,
    ...xAxisOptions,
    ...baseChartOptions
  }

  options = getFoodMeasurementsSeries(options, memberObj, dashboardViewStore)
  options = getCalorieGoalSeries(options, memberObj)
  options = addPausePlotBands(memberObj.user.pauses, options, true)

  return options
}

export default getFoodChartOptions
