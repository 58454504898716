import React from 'react'
import moment from 'moment'
import Adherence from '../modules/Adherence'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { analyticsSlice } from '../../js/store/analytics'

/* ****************************************************************************
 * Analytics => Trends => Adherence
 * ****************************************************************************/

function AnalyticsAdherenceSection (props) {
  const renderAxAdherenceSection = () => {
    const range = {
      start: moment(props.analyticsStore.dateRangeStart),
      end: moment(props.analyticsStore.dateRangeEnd)
    }

    return (
      <div className='ax-subsection' data-testid='ax-subsection__adherence'>
        <div className='ax-subsection__header'>Tracker and scale usage</div>

        <div>
          <div>
            <Adherence
              users={props.analyticsStore.onboardedUsers}
              range={range}
              analyticsActions={props.analyticsActions}
            />
          </div>
          <div />
        </div>
      </div>
    )
  }

  return renderAxAdherenceSection()
}

function getPropsFromStore (state) {
  return {
    analyticsStore: state.analytics
  }
}

function getPropsFromActions (dispatch) {
  return {
    analyticsActions: bindActionCreators(analyticsSlice.actions, dispatch)
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(AnalyticsAdherenceSection)
