import _ from 'lodash'
import restApi from '../api'
import { actions as csrfActions } from 'redux-csrf'
import { dispatchWithException } from './baseUtils'
import { setGAUserDimensions } from '../utils/googleAnalyticsUtils'
import { sessionExpire, setResources, setUser, setActiveGroup } from '../store/userSession'
import { setClinicianId, setSelectedLCU } from '../store/membersView'
import { clear } from '../store/rootReducer'

/* ********************************************************************************
 * Endpoints for logging in/out.
 * ********************************************************************************/
const authEndpoints = {
  csrf: {
    url: '/csrf',
    options: {
      method: 'get',
      headers: {
        'X-Web-App': true
      }
    },
    transformer (data) {
      return { csrfToken: data }
    },

    postfetch: [({ data, actions, dispatch }) => {
      dispatchWithException(csrfActions.setCsrfToken(data.csrfToken), dispatch)
      dispatchWithException(actions.session(), dispatch)
    }]
  },

  sendAnalytics: {
    url: 'users/:id/analytics',
    options: {
      method: 'post'
    }
  },

  setActiveGroup: {
    url: '/auth/setActiveGroup',
    option: {
      method: 'get'
    },
    urlOptions: {
      delimiter: '&'
    },

    postfetch: [
      ({ data, actions, dispatch }) => {
        dispatchWithException(setActiveGroup(data.activeGroup), dispatch)
        dispatchWithException(actions.resources(), dispatch)
        dispatchWithException(setSelectedLCU('All'), dispatch)
      }
    ]
  },

  session: {
    url: '/auth',
    options: {
      method: 'get'
    },

    postfetch: [
      // Set user object
      ({ data, actions, dispatch }) => {
        const userWithAllowedGroups = { ...data.user }

        if (data?.allowedGroups?.length > 1) {
          userWithAllowedGroups.allowedGroups = data.allowedGroups
        }
        dispatchWithException(setUser(userWithAllowedGroups), dispatch)
        dispatchWithException(setClinicianId(userWithAllowedGroups.id), dispatch)

        if (data?.activeGroup) {
          dispatchWithException(setActiveGroup(data.activeGroup), dispatch)
          dispatchWithException(actions.resources(), dispatch)
        } else {
          dispatchWithException(setActiveGroup(null), dispatch)
        }

        if (!_.isEmpty(data.user)) {
          // Send user data to Google Analytics
          setGAUserDimensions(data.user)
        }
      }
    ]
  },

  login: {
    url: '/auth/login',
    options: {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    },

    postfetch: [
      ({ data, actions, dispatch, request, getState }) => {
        if (!_.isEmpty(data.user)) {
          request.pathvars.promise.resolve()

          // Store user
          dispatchWithException(setUser({ ...data.user, allowedGroups: data.allowedGroups }), dispatch)
          dispatchWithException(setClinicianId(data.user.id), dispatch)

          if (data && data.activeGroup) {
            dispatchWithException(setActiveGroup(data.activeGroup), dispatch)
            dispatchWithException(actions.resources(), dispatch)
          } else {
            dispatchWithException(setActiveGroup(null), dispatch)
          }

          // Send user data to Google Analytics
          setGAUserDimensions(data.user)
        } else {
          let failedLoginMessage = 'Incorrect username and password combination.'

          if (getState().userSession.loggingInWithPhone) {
            failedLoginMessage = 'Incorrect phone and code combination. Please try again.'
          }

          request.pathvars.promise.reject(failedLoginMessage)
        }
      }
    ]
  },

  logout: {
    url: '/auth/logout',
    options: {
      method: 'get'
    },

    postfetch: [
      // Redirect done in Router, not here
      ({ dispatch, getState }) => {
        const showSessionExpireMsg = getState().userSession.showSessionExpireMessage
        dispatchWithException(clear(), dispatch)
        if (showSessionExpireMsg) {
          dispatchWithException(sessionExpire(), dispatch)
        }
        dispatch(restApi.actions.csrf())
      }
    ]
  },

  resources: {
    url: '/resources',
    options: {
      method: 'get'
    },

    postfetch: [({ data, dispatch }) => {
      dispatchWithException(setResources(data), dispatch)
    }]
  },

  // Link user's account to Amazon
  loginAmazon: {
    url: '/auth/link/amazon',
    options: {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    },

    postfetch: [({ data, request }) => {
      if (_.isEmpty(data) || !data.logged_in) {
        request.pathvars.promise.reject('Incorrect username and password combination.')
        return
      }

      // Redirect to whatever URL the InteractionService sends back
      window.location = data.redirect_url
    }]
  }
}

export default authEndpoints
