import React, { useEffect } from 'react'
import PDFObject from 'pdfobject'
import tosPdf from 'static/terms_of_service.pdf'

const tosHtml = require('html-loader!static/terms_of_service.html') // eslint-disable-line import/no-webpack-loader-syntax
const tosHtmlObj = { __html: tosHtml }

export default function TermsOfService (props) {
  const showPdf = !!(PDFObject.supportsPDFs)

  useEffect(() => {
    if (showPdf) {
      PDFObject.embed(tosPdf, '#tos-container')
    }
  }, [])

  const renderTermsOfService = () => {
    // Fall back to displaying our amazingly crufty HTML TOS
    if (!showPdf) {
      return (
        <div id='tos-container' style={{ height: '100%', width: '100%' }} dangerouslySetInnerHTML={tosHtmlObj} />
      )
    }

    return (<div id='tos-container' style={{ height: '100%', width: '100%' }} />)
  }

  return renderTermsOfService()
}
