import React from 'react'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import MemberDevicesFormFields from './MemberDevicesFormFields'
import { Form } from 'react-final-form'
import { Alert } from '@mui/material'
import { EjentaForm } from '../../elements/EjentaForm'

/* ****************************************************************************
 * This form is used for creating new members and editing existing members.
 * ****************************************************************************/
export default function MemberDevicesForm (props) {
  const handleCancel = (e) => {
    e.preventDefault()
    e.stopPropagation()

    props.onCancel()
  }

  const renderSubmit = ({ pristine, submitting }) => {
    const submitButtonText = submitting ? 'Updating...' : 'Save changes'

    return (
      <DialogActions>
        <Button variant='contained' color='primary' type='submit' disabled={pristine || submitting}>{submitButtonText}</Button>
        <Button variant='outlined' color='secondary' onClick={handleCancel}>Cancel</Button>
      </DialogActions>
    )
  }

  const renderMemberDevicesForm = () => {
    return (
      <Form onSubmit={props.onSubmit} initialValues={props.getInitialValues()}>
        {({ handleSubmit, pristine, submitting, submitError }) => (
          <EjentaForm onSubmit={handleSubmit}>
            {submitError && <Alert severity='error'>{submitError}</Alert>}

            <MemberDevicesFormFields />

            {renderSubmit({ pristine, submitting })}
          </EjentaForm>
        )}
      </Form>
    )
  }

  return renderMemberDevicesForm()
}
