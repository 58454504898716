import moment from 'moment'
import React from 'react'

import { formatNumber } from 'libphonenumber-js'

import { preciseRound } from './unitConversionUtils'

import SyncIcon from '@mui/icons-material/Sync'
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled'

import { Box, Link, Typography } from '@mui/material'
import { ArrowDownwardRounded, ArrowUpwardRounded } from '@mui/icons-material'

export const TIMEZONE_DISPLAY_MAP = {
  'America/Los_Angeles': '(UTC-08:00) US Pacific',
  'America/Denver': '(UTC-07:00) US Mountain',
  'America/Chicago': '(UTC-06:00) US Central',
  'America/New_York': '(UTC-05:00) US Eastern',
  'Canada/Atlantic': '(UTC-04:00) Atlantic',
  'Etc/GMT': '(UTC+00:00) Greenwich',
  'Europe/Paris': '(UTC+01:00) Central European',
  Japan: '(UTC+09:00) Japan'
}

export const DEVICE_PLATFORM_MAP = {
  IOS: 'iOS',
  ANDROID: 'Android',
  OTHER: 'Other'
}

export const GENDER_MAP = {
  FEMALE: 'Female',
  MALE: 'Male'
}

export const COMMUNICATION_PREFERENCE_MAP = {
  push: 'Push notification (app required)',
  text: 'Text message',
  email: 'Email',
  none: 'None'
}

export const momentCalendarConfig = {
  sameDay: '[Today], h:mm A',
  lastDay: '[Yesterday], h:mm A',
  lastWeek: 'MMM D, h:mm A',
  sameElse: function (now) {
    const sameYear = this?.isSame(now, 'year') || false
    if (sameYear) return 'MMM D, h:mm A'
    else return 'MMM D Y, h:mm A'
  },
  nextDay: '[Tomorrow]', // Not used
  nextWeek: 'dddd' // Not used
}

export const momentCalendarDateConfig = {
  sameDay: '[Today]',
  lastDay: '[Yesterday]',
  lastWeek: 'MMM D',
  sameElse: function (now) {
    const sameYear = this?.isSame(now, 'year') || false
    if (sameYear) return 'MMM D'
    else return 'M/D/YY'
  },
  nextDay: '[Tomorrow]', // Not used
  nextWeek: 'dddd' // Not used
}

export function generateRandomId () {
  return Math.random().toString(36).substr(2, 9)
}

/* *****************************************************************************
 * Simple transforms
 * *****************************************************************************/

export function getDisplayDateTime () {
  return 'Not yet!'
}

export function getDisplayWeight (kg) {
  const lbs = preciseRound(kg * 2.20462, 2)
  return `${lbs} lbs`
}

export function getDisplayBMI (bmi, weightCondition) {
  return `${preciseRound(bmi, 1)} (${weightCondition})`
}

export function getDisplayEmail (email) {
  if (email) {
    return <Link sx={{ wordBreak: 'break-all' }} underline='none' color='primary' title={email} href={`mailto:${email}`}>{email}</Link>
  } else return generateEmptySpan()
}

export function getDisplayPhoneNumber (p) {
  if (p) {
    try {
      return formatNumber({ country: 'US', phone: p }, 'National')
    } catch (err) {
      return ''
    }
  } else return generateEmptySpan()
}

export function getDisplayDelta (d) {
  if (d === 0) return '0'
  if (d > 0) return (`+${d}`)
  if (d < 0) return (`−${-1 * d}`)
  return '—'
}

export function getDeltaArrow (d, defaultOrder) {
  const [upClass, downClass] = defaultOrder ? ['green', 'red'] : ['red', 'green']

  if (d < 0) return <span className={`delta-arrow delta-arrow--${downClass}`}><ArrowDownwardRounded fontSize='small' /></span>
  if (d > 0) return <span className={`delta-arrow delta-arrow--${upClass}`}><ArrowUpwardRounded fontSize='small' /></span>
  return null
}

export function getDisplayWeightGain (weightGain) {
  if (weightGain === null) {
    return <span className='no-data'>Not enough data</span>
  }

  return (
    <span>
      <strong>{getDisplayDelta(weightGain)}</strong> lb
    </span>
  )
}

export function getDisplayTimezone (t) {
  const fullName = TIMEZONE_DISPLAY_MAP[t]
  const parenIndex = fullName.indexOf(')')
  if (parenIndex >= 0) {
    return fullName.slice(parenIndex + 1).trim()
  }

  return fullName
}

export function getDisplayPlatform (t) {
  return t ? DEVICE_PLATFORM_MAP[t] : generateEmptySpan('Unknown')
}

export function getDisplaySyncStatus (s) {
  if (s) {
    return (<Box sx={{ display: 'inline-flex', alignItems: 'center' }}><SyncIcon fontSize='small' /> Linked</Box>)
  }

  return (<Box sx={{ display: 'inline-flex', alignItems: 'center' }}><SyncDisabledIcon fontSize='small' /> Unlinked</Box>)
}

export function generateEmptySpan (emptyText = 'None') {
  return <span className='is-empty'>{emptyText}</span>
}

export function getDisplayBodyTraceScaleStatus (user) {
  if (user.bt_scale_imei) return 'Linked'
  return generateEmptySpan('No device')
}

export function getDisplayBodyTraceBpStatus (user) {
  if (user.bt_bp_imei) return 'Linked'
  return generateEmptySpan('No device')
}

export function getDisplayIGlucoseStatus (user) {
  if (user.iglucose_id) return 'Linked'
  return generateEmptySpan('No device')
}

export function getDisplayNoninStatus (user) {
  if (user.nonin_id) return 'Linked'
  return generateEmptySpan('No device')
}

export function getDisplayBioStickerStatus (user) {
  if (user.biosticker_id) {
    if (user.biohub_id) {
      return 'Button/Sticker and hub'
    }
    return 'Button/Sticker only'
  }
  // note that having a hub without a sticker is impossible
  return generateEmptySpan('No devices')
}

export function getHoursMinutes (h) {
  return {
    hours: Math.floor(h),
    minutes: Math.round((h % 1) * 60)
  }
}

export function secondsToTime (totalSeconds) {
  const h = Math.floor(totalSeconds / 3600).toString().padStart(2, '0')
  const m = Math.floor(totalSeconds % 3600 / 60).toString().padStart(2, '0')
  const s = Math.floor(totalSeconds % 60).toString().padStart(2, '0')

  return h + ':' + m + ':' + s
}
/* *****************************************************************************
 * User transforms - all of the below expect a standard {userObj}
 * *****************************************************************************/

export function getDisplayName (userObj, includeMedicalId = true, nameColor = 'rgba(0, 0, 0, 0.87)') {
  if (!userObj) return null
  const lastName = userObj.last_name ? ` ${userObj.last_name}` : ''
  const fullName = `${userObj.first_name}${lastName}`

  return (
    <Box component='span' sx={{ wordBreak: 'break-all', color: nameColor }}>
      {fullName} {includeMedicalId && <Typography variant='subtitle2' component='span' color='secondary' data-testid='medical-id'>#{userObj.medical_id}</Typography>}
    </Box>
  )
}

export function getInitials (userObj) {
  return `${userObj.first_name[0]}${userObj.last_name ? userObj.last_name[0] : ''}`
}

export function getSimpleDisplayName (userObj) {
  const lastName = userObj.last_name ? ` ${userObj.last_name}` : ''
  const fullName = `${userObj.first_name}${lastName}`
  return fullName
}

export function getDisplayCaregiverName (userObj) {
  if (userObj.caregiver_name) {
    return userObj.caregiver_name
  }
  return generateEmptySpan()
}

export function getDisplayStreetAddress (userObj) {
  if (userObj.street_address_1) {
    return <span>{userObj.street_address_1} {userObj.street_address_2} <br />{userObj.city}, {userObj.state} {userObj.zip_code}</span>
  }
  return generateEmptySpan()
}

export function getDisplayCaregiverEmail (userObj) {
  return getDisplayEmail(userObj.caregiver_email)
}

export function getDisplayHeight (userObj) {
  const cm = userObj.height_in_cm
  const inchesTotal = cm / 2.54
  const feet = Math.floor(inchesTotal / 12)
  const inches = preciseRound(inchesTotal % 12, 2)

  const feetDisplay = `${feet} ft`
  const inchesDisplay = inches ? `${inches} in` : ''

  return `${feetDisplay} ${inchesDisplay}`
}

export function getDisplayAge (userObj) {
  const ageInYears = moment().diff(userObj.date_of_birth, 'years')
  return `${ageInYears}`
}

export function getDisplayMedicalCondition (userObj) {
  return userObj.summary ? `(${userObj.summary})` : ''
}

export function getDisplayStartWeight (userObj) {
  if (!userObj.start_weight_in_kg) {
    return generateEmptySpan('Unknown')
  }

  return getDisplayWeight(userObj.start_weight_in_kg)
}

export function getDisplayDryWeight (userObj) {
  if (!userObj.dry_weight_in_kg) {
    return generateEmptySpan('Unknown')
  }

  return getDisplayWeight(userObj.dry_weight_in_kg)
}

export function getDisplayGestationalWeeks (userObj) {
  const numPregWeeks = moment().diff(moment(userObj.conception_date), 'weeks')
  const numPregWeeksStr = (numPregWeeks === 1) ? '1 week' : `${numPregWeeks} weeks`
  return numPregWeeksStr
}

export function cleanNoteContent (note) {
  let providerContent
  switch (note.provider_content) {
    case 'STATUS:READ':
    case 'Read':
      providerContent = <em>Read this alert</em>
      break
    case 'STATUS:CLOSED':
    case 'CLOSED':
      providerContent = <em>Closed this alert</em>
      break
    case 'STATUS:OPEN':
    case 'OPEN':
      providerContent = <em>Reopened this alert</em>
      break
    default:
      providerContent = note.provider_content.split('\n').map(
        (l, i) => (<span key={i}>{l} <br /></span>)
      )
  }

  return providerContent
}
