import React from 'react'
import { connect } from 'react-redux'
import {
  ContentMain,
  ContentMainIntro,
  ContentMainIntroHeader,
  ContentMainBody
} from '../../js/components/layout/ContentMain'
import LoadingIndicator from '../../js/components/elements/LoadingIndicator'
import AnalyticsDateRange from '../layout/AnalyticsDateRange'
import AnalyticsAdherenceSection from './AnalyticsAdherenceSection'
import AnalyticsMeasurementsSection from './AnalyticsMeasurementsSection'
import AnalyticsAlertsSection from './AnalyticsAlertsSection'

/* ****************************************************************************
 * Analytics overview page
 * ****************************************************************************/
function AnalyticsTrendsScreen (props) {
  const renderTrendsScreen = () => {
    return (
      <ContentMain data-testid='analytics-page__trends-screen' drawerWidth={220}>
        <ContentMainIntro>
          <ContentMainIntroHeader title='Trends [BETA]'>
            <AnalyticsDateRange />
          </ContentMainIntroHeader>
        </ContentMainIntro>

        {props.analyticsStore.isDateRangeLoading &&
          <div className='ax-main__loading'>
            <LoadingIndicator />
          </div>}

        <ContentMainBody>
          {!props.analyticsStore.isDateRangeLoading &&
            <div className='ax-main__body'>
              <AnalyticsAdherenceSection />
              <AnalyticsMeasurementsSection />
              <AnalyticsAlertsSection />
            </div>}
        </ContentMainBody>
      </ContentMain>
    )
  }

  return renderTrendsScreen()
}

function getPropsFromStore (state) {
  return {
    analyticsStore: state.analytics
  }
}

export default connect(getPropsFromStore, null)(AnalyticsTrendsScreen)
