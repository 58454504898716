import _ from 'lodash'
import moment from 'moment'
import restApi from '../api'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RANGE_VIEWS, getRangeBounds } from '../utils/member/dateUtils'
import 'regenerator-runtime' // necessary for async operations on thunk

function getLastUpdated (prevUpdated, nextUpdated) {
  if (!prevUpdated) return nextUpdated
  if (moment(prevUpdated).isAfter(moment(nextUpdated))) return prevUpdated
  return nextUpdated
}

const getRangeData = createAsyncThunk('dashboardView/setRange', async (params) => {
  const { dispatch, getState } = params
  const { memberObj, rangeZoom, rangeStart } = params.payload

  if (rangeZoom) dispatch(setRangeZoom(rangeZoom))
  else if (rangeStart) dispatch(setRangeStart(rangeStart))
  else return

  const urlParams = { id: memberObj.user.id }
  const { startDate, endDate } = getRangeBounds(
    memberObj,
    rangeZoom || getState().dashboardView.rangeZoom,
    rangeStart || getState().dashboardView.rangeStart
  )

  if (startDate && endDate) {
    _.merge(urlParams, { startDate, endDate })
  }

  await dispatch(restApi.actions.memberSummary(urlParams))
})

export const setRange = (payload) => {
  return (dispatch, getState) => {
    dispatch(getRangeData({ dispatch, getState, payload }))
  }
}

/* *****************************************************************************
 *
 * (Clinician experience only) Interface state for member profile
 * (Member experience only) Interface state for dashboard
 *
 * *****************************************************************************/

const initialState = {
  lastUpdated: null,
  focusChart: null,
  isRangeLoading: false,
  rangeZoom: RANGE_VIEWS.WEEK,
  rangeStart: null // {dateString}
}

const dashboardViewSlice = createSlice({
  name: 'dashboardView',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getRangeData.fulfilled, (state, action) => {
      state.isRangeLoading = false
      state.lastUpdated = getLastUpdated(state.lastUpdated, moment().valueOf())
    })

    builder.addCase(getRangeData.rejected, (state, action) => {
      state.isRangeLoading = false
    })

    builder.addCase(getRangeData.pending, (state, action) => {
      state.isRangeLoading = true
    })
  },
  reducers: {
    setFocusChart: (state, action) => {
      state.focusChart = action.payload.chartType
      state.rangeZoom = RANGE_VIEWS.WEEK
      state.rangeStart = null
    },
    setRangeLoading: (state, action) => {
      state.isRangeLoading = action.payload
    },
    setRangeZoom: (state, action) => {
      state.rangeZoom = action.payload || RANGE_VIEWS.WEEK
      state.rangeStart = null
    },
    setRangeStart: (state, action) => {
      state.rangeStart = action.payload
    },
    resetDashboardView: (state, action) => {
      state.lastUpdated = null
      state.focusChart = null
      state.rangeStart = null
      state.isRangeLoading = false
      state.rangeZoom = RANGE_VIEWS.WEEK
    },
    setLastUpdated: (state, action) => {
      state.lastUpdated = getLastUpdated(state.lastUpdated, action.payload.lastUpdated)
    }
  }
})

export const {
  setFocusChart,
  setRangeLoading,
  setRangeZoom,
  setRangeStart,
  resetDashboardView,
  setLastUpdated
} = dashboardViewSlice.actions

export { dashboardViewSlice }
