import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Exporting from 'highcharts/modules/exporting.js'
import ExportData from 'highcharts/modules/export-data.js'
import Accessibility from 'highcharts/modules/accessibility.js'
import OfflineExporting from 'highcharts/modules/offline-exporting.js'
import HighchartsMore from 'highcharts/highcharts-more'
import LoadingIndicator from '../elements/LoadingIndicator'

Exporting(Highcharts)
OfflineExporting(Highcharts)
ExportData(Highcharts)
Accessibility(Highcharts)
HighchartsMore(Highcharts)

export default function Chart (props) {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)
  const [chart, setChart] = useState(null)

  useEffect(() => {
    const handleWindowResize = _.debounce(() => setInnerWidth(window.innerWidth))
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    if (props.printed) printChart()
  }, [props.printed])

  const printChart = () => {
    if (chart) chart.print()
    props.handlePrintCompleted()
  }

  const renderChart = () => {
    if (props.dashboardViewStore.isRangeLoading) {
      return <LoadingIndicator fullPage={false} />
    }

    if (props.showMemberAlerts && (props.memberObj.alerts === null)) {
      return <LoadingIndicator fullPage={false} />
    }

    const chartOptions = props.getOptions({
      dashboardViewStore: props.dashboardViewStore,
      memberObj: props.memberObj,
      showMemberAlerts: props.showMemberAlerts,
      handleAlertClick: props.handleAlertClick,
      handleDeleteWeight: props.handleDeleteWeight,
      handleRestoreWeight: props.handleRestoreWeight
    })

    return (
      <HighchartsReact className='chart-container' highcharts={Highcharts} options={chartOptions} id={`${props.containerId}`} callback={(_chart) => setChart(_chart)} key={`${props.dashboardViewStore.lastUpdated}-${innerWidth}`} />
    )
  }

  return renderChart()
}
