import React from 'react'
import { connect } from 'react-redux'

/*
 * Higher-order component that provides the base component with a prop, featureGates, that
 * contains an object with the same keys as FEATURE_GATES (in constants/constants.js)
 *
 * Example usage:
 *
 *     function MyCoolComponent(props) {
 *       // Selectively render UI based on this.props.featureGates
 *     }
 *
 *     export default includeFeatureGates(MyCoolComponent)
 */

function includeFeatureGates (BaseComponent) {
  function getPropsFromStore (state) {
    return {
      userSession: state.userSession
    }
  }

  function FeaturedGatedComponent (props) {
    return <BaseComponent {...props} featureGates={props.userSession.featureGates} />
  }

  return connect(getPropsFromStore)(FeaturedGatedComponent)
}

export default includeFeatureGates
