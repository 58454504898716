import React from 'react'
import { CAREPLAN_VALUE_TYPES } from '../../../../constants/carePlanValueTypes'
import { productConfig } from '../../../../config/config-service'
import { renderLevel } from '../../../../utils/alertUtils'
import CarePlanItem from '../CarePlanItem'

export default class PulseOxRangeItem extends CarePlanItem {
  constructor (props) {
    super(props)
    this.type = 'pulse_ox_range'
    this.config = productConfig()
  }

  rangeOxygenSaturationMinInputElement () {
    return this.getInputElement(
      80, 99, 1,
      CAREPLAN_VALUE_TYPES.SPO2_MIN.get(val => val),
      CAREPLAN_VALUE_TYPES.SPO2_MIN.set(val => val)
    )
  }

  renderGoal () {
    return <div>Keep SpO₂ in range</div>
  }

  renderClinicianTrigger () {
    return <div>If SpO₂ is below {this.rangeOxygenSaturationMinInputElement()}%.</div>
  }

  renderCondensed () {
    return <div>{renderLevel(this.state.itemData.level, true)} SpO₂ &lt; {this.getValueElement(CAREPLAN_VALUE_TYPES.SPO2_MIN.get(val => val))}%</div>
  }
}

// Note that these are the defaults for kpCovid19 -- move these to a config if we bring that legacy app back
// export default class PulseOxRangeItem extends CarePlanItem {
//   constructor (props) {
//     super(props)
//     this.type = 'pulse_ox_range'
//     this.config = productConfig()
//   }

//   rangeOxygenSaturationMinInputElement () {
//     return this.getInputElement(
//       75, 99, 1,
//       CAREPLAN_VALUE_TYPES.SPO2_MIN.get(val => val),
//       CAREPLAN_VALUE_TYPES.SPO2_MIN.set(val => val)
//     )
//   }

//   renderGoal () {
//     return <div>Keep SpO₂ in range</div>
//   }

//   isDisabled () {
//     const minOxygen = this.getValue(CAREPLAN_VALUE_TYPES.SPO2_MIN.get(val => val))
//     const disabled = minOxygen <= 75
//     return disabled
//   }

//   renderClinicianTrigger () {
//     const disabled = this.isDisabled()
//     return <div className={disabled ? 'disabled' : ''}>If <b>SpO₂</b> is below {this.rangeOxygenSaturationMinInputElement()}% {disabled && '[Disabled]'}</div>
//   }

//   renderCondensed () {
//     if (this.isDisabled()) {
//       return <div className='disabled'>Low SpO₂ alerts disabled</div>
//     }
//     return <div>SpO₂ &lt; {this.getValueElement(CAREPLAN_VALUE_TYPES.SPO2_MIN.get(val => val))}%</div>
//   }
// }
