/* ********************************************************************************
 * Base chart options
 * ********************************************************************************/

export const baseFoodChartOptions = {
  chart: {
    className: 'food-chart',
    spacingTop: 40
  },

  legend: {
    symbolRadius: 0,
    itemMarginTop: 12
  },

  tooltip: {
    useHTML: true,
    shared: true, // https://api.highcharts.com/highcharts/tooltip.shared; helps a lot with stickiness
    stickOnContact: true // "Prevents the tooltip from switching or closing, when touched or pointed."
  },

  yAxis: {
    labels: {
      format: '{value}'
    },
    maxPadding: 0.2
  },

  series: []
}

// Add food alerts series here when it's time
