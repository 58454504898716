import React, { useState } from 'react'
import getPulseOxChartItems from '../../../utils/charts/pulseOxChart'

import ChartModule from './ChartModule'
import TabPanel from '../../layout/TabPanel'

import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import BloodOxygenTable from '../Tables/BloodOxygenTable'

function a11yProps (index) {
  return {
    id: `blood-oxygen-metric__tab-${index}`,
    'aria-controls': `blood-oxygen-metric__tab-${index}`
  }
}

export default function BloodOxygenChart (props) {
  const [selectedBloodOxygenTab, setSelectedBloodOxygenTab] = useState(0)

  const renderChartPanel = () => {
    return (
      <Card className='chart-card'>
        <CardContent sx={{ paddingTop: 0 }}>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'end', mb: 2 }}>
            <Typography variant='h6' color='#7f7f7f'> SpO₂ </Typography>
            <Tabs
              sx={{ borderBottom: 1, borderColor: 'divider' }}
              value={selectedBloodOxygenTab}
              onChange={(_e, val) => setSelectedBloodOxygenTab(val)}
              aria-label='Profile tabs'
            >
              <Tab data-testid='blood-oxygen__charts-tab' label='Graph' {...a11yProps(0)} />
              <Tab data-testid='blood-oxygen__table-tab' label='Table' {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={selectedBloodOxygenTab} index={0} noPadding>
            <ChartModule
              hideHeader
              hideBorder
              showChartActions
              dashboardViewStore={props.dashboardViewStore}
              memberObj={props.memberObj}
              metricType='pulse_ox'
              getChartOptions={getPulseOxChartItems}
              showMemberAlerts
              handleAlertClick={props.handleAlertClick} // eslint-disable-line react/jsx-handler-names
            />
          </TabPanel>
          <TabPanel value={selectedBloodOxygenTab} index={1} noPadding>
            <BloodOxygenTable
              hideHeader
              hideBorder
              dashboardViewStore={props.dashboardViewStore}
              memberObj={props.memberObj}
            />
          </TabPanel>
        </CardContent>
      </Card>

    )
  }

  return renderChartPanel()
}
