import _ from 'lodash'
import React from 'react'
import Tooltip from '../Tooltip'
import Snackbar from '../Snackbar'
import { Box, Checkbox, Grid, MenuItem, Select } from '@mui/material'
import { renderLevel } from '../../../utils/alertUtils'
import { productConfig } from '../../../config/config-service'
import { CAREPLAN_VALUE_TYPES } from '../../../constants/carePlanValueTypes'
import { CARE_PLAN_NOTIFY_OPTIONS } from '../../../constants/constants'
import { ALERT_LEVEL_COLORS } from '../../../utils/baseAlertUtils'
import PublicIcon from '@mui/icons-material/Public'
import PersonIcon from '@mui/icons-material/Person'

/*
 * Base React component for care plan items.
 * Read through this class to understand how care plan items are rendered!
 */
export default class CarePlanItem extends React.Component {
  constructor (props) {
    super(props)

    // Define type and subtype to let fetchItemData() automatically retrieve the care plan item data
    this.type = null
    this.subtype = props.subtype
    this.config = productConfig()
    this.snackbarOpen = false

    // Or you can set state.itemData yourself in the component's UNSAFE_componentWillMount() method
    this.state = {
      itemData: null
    }
  }

  UNSAFE_componentWillMount () {
    this.fetchItemData(this.props)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.fetchItemData(nextProps)
  }

  // Retrieve the relevant care plan item
  fetchItemData (props) {
    const hasMatchingSubtype = (item) => {
      if (!this.subtype) return true
      return this.subtype === item.subtype
    }

    const itemData = props.carePlanItems?.find(item => item.type === this.type && hasMatchingSubtype(item))
    this.setState({
      itemData
    })
  }

  hasTrigger (alertTrigger) {
    return !!this.state.itemData?.alert_triggers?.find(x => x.type === alertTrigger)
  }

  // Render custom value
  getValueElement (getItemVal) {
    if (!getItemVal) return null
    return <strong>{getItemVal(this.state.itemData)}</strong>
  }

  // Get raw value
  getValue (getItemVal) {
    return getItemVal(this.state.itemData)
  }

  // Render dropdown
  onInputElementChange = (updateItem) => {
    return (e) => {
      const currentItem = _.cloneDeep(_.omit({ ...this.state.itemData }, 'updatedTime'))
      const newItem = updateItem(currentItem, e.target.value)
      this.props.updateCarePlan(newItem)
    }
  }

  getSelectElement (options, getItemVal, updateItem) {
    const itemData = this.state.itemData

    return (
      <Select
        size='small'
        className='care-plan-item__select'
        value={getItemVal(itemData)}
        onChange={this.onInputElementChange(updateItem)}
        disabled={itemData.disabled === 1}
      >
        {options}
      </Select>
    )
  }

  getInputElement (min, max, step, getItemVal, updateItem) {
    const options = _.range(min, max + 1, step).map(v =>
      <MenuItem className='care-plan-item__select-item' key={v} value={v}>{v}</MenuItem>
    )

    return this.getSelectElement(options, getItemVal, updateItem)
  }

  getCustomInputElement (selectOptions, getItemVal, updateItem) {
    const options = selectOptions.map(v => {
      const display = v > 0 ? v : 'never'
      return <MenuItem className='care-plan-item__select-item' key={v} value={v}>{display}</MenuItem>
    })

    return this.getSelectElement(options, getItemVal, updateItem)
  }

  // Dropdown element for alert level/colors
  getLevelInput () {
    const getItemVal = CAREPLAN_VALUE_TYPES.LEVEL.get(val => Number(val))
    const updateItem = CAREPLAN_VALUE_TYPES.LEVEL.set(val => Number(val))

    const options = []
    for (const [level, color] of Object.entries(ALERT_LEVEL_COLORS)) {
      options.push(<MenuItem key={level} value={level}>{color}</MenuItem>)
    }

    return this.getSelectElement(options, getItemVal, updateItem)
  }

  renderNotify () {
    const itemData = this.state.itemData
    const options = CARE_PLAN_NOTIFY_OPTIONS.map(item => <MenuItem className='care-plan-item__select-item' key={item.key} value={item.key}>{item?.value}</MenuItem>)

    return (
      <Select
        fullWidth
        size='small'
        data-testid='care-plan-item__notify-select-dropdown'
        className='care-plan-item__select'
        value={itemData.notify}
        onChange={this.handleChangeNotify}
        id={itemData.notify}
        disabled={this.state.itemData.disabled === 1 || !this.config.carePlan.notifyEnabled}
      >
        {options}
      </Select>
    )
  }

  handleClickEnabled = () => {
    this.setState({
      itemData: { ...this.state.itemData, disabled: this.state.itemData.disabled ? 0 : 1 }
    }, () => {
      const currentItem = _.omit(this.state.itemData, 'updatedTime')
      const newItem = _.set(currentItem, 'disabled', this.state.itemData.disabled)
      this.props.updateCarePlan(newItem)
    })
  }

  handleChangeNotify = (event) => {
    this.setState({
      itemData: { ...this.state.itemData, notify: event.target.value }
    }, () => {
      const currentItem = _.omit(this.state.itemData, 'updatedTime')
      const newItem = _.set(currentItem, 'notify', this.state.itemData.notify)
      this.props.updateCarePlan(newItem)
    })
  }

  renderEnabledCheckbox () {
    const label = { inputProps: { 'aria-label': 'Enable/Disable care plan item', content: '"✔"' } }
    return (
      <Checkbox {...label} checked={this.state.itemData.disabled === 0} onChange={this.handleClickEnabled} sx={{ padding: 0, '& .MuiSvgIcon-root': { fontSize: 25 } }} />
    )
  }

  // User-facing columns
  renderGoal () {
    return <div />
  }

  renderClinicianTrigger () {
    return <div className='care-plan-row__empty'>No alert</div>
  }

  renderIsDefault () {
    let icon = <PublicIcon />
    let tooltipText = 'This care plan item matches the default group care plan settings.'

    if (!this.state.itemData.default) {
      icon = <PersonIcon />
      tooltipText = 'This care plan item has been modified from the default group care plan settings. This setting will persist even if you make changes to the default care plan settings.'
    }

    return (
      <div className='care-plan-row__is-default'>
        <Tooltip tooltipId='care-plan-row__is-default__tooltip' icon={icon} content={tooltipText} />
      </div>
    )
  }

  renderCondensed () {
    return <div>&middot;</div>
  }

  renderEnabled () {
    return this.renderEnabledCheckbox()
  }

  renderLevel () {
    const level = this.state.itemData.level
    return (
      <Box sx={{ display: 'flex' }}>
        <span className='alert-level-text'>{this.getLevelInput()}</span>{renderLevel(level, true)}
      </Box>
    )
  }

  render () {
    const itemData = this.state.itemData
    if (!itemData) return null
    if (this.props.shouldRenderCondensed) return this.renderCondensed()

    if (itemData.updatedTime) this.snackbarOpen = true
    else this.snackbarOpen = false

    const carePlan = this.config.carePlan
    let alertColumnSize = this.props.isDefault ? 5 : 4
    if (!carePlan.canBeDisabled) alertColumnSize++
    if (!carePlan.hasLevel && !carePlan.hasGoal) alertColumnSize += 4

    let carePlanRowClasses = 'care-plan-row'
    if (itemData.disabled) carePlanRowClasses += ' disabled'

    return (
      <Box sx={{ flexGrow: 1 }} className={carePlanRowClasses}>
        <Grid container spacing={3}>
          {!this.props.isDefault &&
            <Grid item xs={1}> <Box>{this.renderIsDefault()}</Box> </Grid>}
          {carePlan.canBeDisabled &&
            <Grid item xs={1}> <Box>{this.renderEnabled()}</Box> </Grid>}
          {(carePlan.hasLevel || carePlan.hasGoal) &&
            <Grid item xs={3}>
              <Box> {carePlan.hasLevel ? this.renderLevel() : this.renderGoal()} </Box>
            </Grid>}
          <Grid item xs={alertColumnSize} fontWeight={300}>
            <Box> {this.renderClinicianTrigger()} </Box>
          </Grid>
          <Grid item xs={3}>
            <Box>{this.renderNotify()}</Box>
          </Grid>
        </Grid>

        <Snackbar
          isOpen={this.snackbarOpen}
          dataKey='care-plan-item__success__snackbar'
          handleClose={() => { this.snackbarOpen = false }}
          severity='success'
          message='Care plan updated successfully'
        />
      </Box>
    )
  }
}
