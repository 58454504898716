import React from 'react'
import CarePlanItem from '../CarePlanItem'
import { CAREPLAN_VALUE_TYPES } from '../../../../constants/carePlanValueTypes'

export default class TemperatureBaselineRangeItem extends CarePlanItem {
  constructor (props) {
    super(props)
    this.type = 'temp_baseline_range'
  }

  rangeTemperatureDeltaInputElement () {
    return this.getInputElement(
      1, 10, 1,
      CAREPLAN_VALUE_TYPES.TEMPERATURE_DELTA.get(val => val),
      CAREPLAN_VALUE_TYPES.TEMPERATURE_DELTA.set(val => val)
    )
  }

  renderGoal () {
    return <div>Keep skin temperature in range compared to personal baseline</div>
  }

  renderClinicianTrigger () {
    return <div className='disabled'>If <b>skin temperature</b> is more than {this.rangeTemperatureDeltaInputElement()}°F above or below personal baseline. [Coming soon]</div>
  }

  renderCondensed () {
    return (
      <div className='disabled'>Skin temp. &gt; {this.getValueElement(CAREPLAN_VALUE_TYPES.TEMPERATURE_DELTA.get(val => val))} °F from personal baseline [Inactive]</div>
    )
  }
}
