import React from 'react'
import restApi from '../../../../api'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  ContentMain,
  ContentMainIntro,
  ContentMainIntroHeader,
  ContentMainBody
} from '../../../layout/ContentMain'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router'

function GroupPickerPage (props) {
  const navigate = useNavigate()

  const handleGroupClick = (selectedGroup) => {
    props.apiActions.setActiveGroup({ activeGroup: selectedGroup.name })
    const url = new URL(window.location.href)
    if (url.searchParams.has('group')) {
      url.searchParams.set('group', selectedGroup.name)
      navigate(`${url.pathname}${url.search}`, { replace: true })
    }
  }

  const renderGroups = (allowedGroups) => {
    return allowedGroups.map((allowedGroup) => {
      return (
        <Button
          fullWidth
          size='large'
          variant='outlined'
          color='secondary'
          key={allowedGroup.id}
          sx={{ justifyContent: 'left', p: 2, mt: 2, mb: 2 }}
          onClick={() => handleGroupClick(allowedGroup)}
        >
          {allowedGroup.description}
        </Button>
      )
    })
  }

  const renderGroupPicker = () => {
    return (
      <div className='u-top-page-margin'>
        {props.user && renderGroups(props.user.allowedGroups)}
      </div>
    )
  }

  const renderGroupPickerPage = () => {
    return (
      <ContentMain dropsSidebar>
        <ContentMainIntro>
          <ContentMainIntroHeader title='Select Group' />
        </ContentMainIntro>

        <ContentMainBody>
          {renderGroupPicker()}
        </ContentMainBody>
      </ContentMain>
    )
  }

  return renderGroupPickerPage()
}

function getPropsFromStore (state) {
  return {
    user: state.userSession.user,
    activeGroup: state.userSession.activeGroup
  }
}

function getPropsFromActions (dispatch) {
  return {
    apiActions: bindActionCreators(restApi.actions, dispatch)
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(GroupPickerPage)
