import _ from 'lodash'
import moment from 'moment'
import classnames from 'classnames'
import { getBaseSeriesOptions } from './baseChartUtils'
import { isAlertOpen } from '../../constants/constants'

/* ********************************************************************************
 * Base chart options
 * ********************************************************************************/

export const baseChartOptions = {
  chart: { className: 'bg-chart' },

  legend: {
    symbolRadius: 0,
    itemMarginTop: 12
  },

  plotOptions: {
    line: {
      marker: { enabled: true }
    }
  },

  yAxis: [{
    title: { text: '' },
    labels: { format: '{value}' } // no mg/dL
  }],

  exporting: {
    buttons: {
      contextButton: {
        enabled: false,
        menuItems: ['viewData', 'printChart', 'downloadPNG', 'downloadJPEG', 'downloadPDF']
      }
    },
    filename: 'BloodGlucoseChart - ' + Date.now()
  },

  series: []
}

/* ********************************************************************************
 * Blood glucose alerts series
 * ********************************************************************************/

export function getGlucoseAlertsSeries (memberObj, options, handleAlertClick) {
  if (!(memberObj.alerts && memberObj.alerts.length)) return options
  const alerts = _.filter(memberObj.alerts, a => a.metric_type === 'blood_glucose' || (a.metric_type === 'multi' && a.member_content.indexOf('glucose') !== -1))
  if (!alerts.length) return options

  const alertsData = alerts.map((alertObj) => {
    /*
     * Get the Y-value of the marker
     */
    let pointY = 0
    const measurement = memberObj.blood_glucose.data.find(
      d => d.timestamp === alertObj.measurement_timestamp
    )

    // If there is a corresponding measurement, display the alert marker above the actual blood_glucose
    if (measurement) pointY = measurement.blood_glucose * 1.1

    // Otherwise just use the first blood_glucose value we have, but be more generous about padding
    else pointY = memberObj.blood_glucose.data[0].blood_glucose * 1.2

    /*
     * Get marker styling
     */
    const marker = {}
    if (alertObj.type === 'bg_tracking' || alertObj.type === 'multi_tracking') {
      marker.symbol = 'circle'
    }

    const isOpen = isAlertOpen(alertObj)

    const classNames = classnames({
      'is-open': isOpen,
      'is-closed': !isOpen,
      'is-tracking': alertObj.type === 'bg_tracking' || alertObj.type === 'multi_tracking'
    })

    /*
     * Define Highcharts point
     */
    const point = {
      name: '',
      description: alertObj.provider_content,
      x: moment(alertObj.measurement_timestamp),
      y: pointY,
      marker,

      className: classNames,
      events: {
        click: () => {
          if (handleAlertClick) {
            handleAlertClick(alertObj)
          }
        }
      }
    }

    return point
  })

  const alertsSeries = _.extend({
    name: 'Blood glucose alerts',

    type: 'scatter',
    className: 'series-glucoseAlerts',
    lineWidth: 0,
    marker: {
      symbol: 'triangle-down',
      radius: 7,
      lineWidth: 0
    },

    tooltip: {
      useHTML: true,
      headerFormat: '<strong>Blood Glucose alert</strong>',
      pointFormat: '<div class="blood_glucose-alert">{point.name}</div>'
    },

    data: alertsData
  }, getBaseSeriesOptions())

  const newOptions = _.cloneDeep(options)
  newOptions.series.push(alertsSeries)
  return newOptions
}
