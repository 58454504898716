import React from 'react'
import classnames from 'classnames'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import StarIcon from '@mui/icons-material/Star'
/* *****************************************************************************
 * Star element that represents whether an item is starred.
 * *****************************************************************************/
function Star (props) {
  const noopClickHandler = () => {}
  const classNames = classnames(props.className)
  const callback = props.onClick || noopClickHandler

  if (props.filled) {
    return (<StarIcon color='primary' className={classNames} onClick={e => callback(e)} />)
  }
  return (<StarOutlineIcon color='primary' className={classNames} onClick={e => callback(e)} />)
}

export default Star
