import { getEmptyChart } from './/baseChartUtils'
import { getFullChartOptions } from './baseTemperatureChart'

export default function getTemperatureChartOptions ({
  memberObj, dashboardViewStore,
  showMemberAlerts, handleAlertClick
}) {
  if (!memberObj.temperature) return getEmptyChart()

  return getFullChartOptions(memberObj, dashboardViewStore, showMemberAlerts,
    handleAlertClick)
}
