import React from 'react'
import CarePlanItem from '../CarePlanItem'
import { CAREPLAN_VALUE_TYPES } from '../../../../constants/carePlanValueTypes'

export default class CalorieTargetItem extends CarePlanItem {
  constructor (props) {
    super(props)
    this.type = 'calorie_target'
  }

  getValueInput () {
    return this.getInputElement(
      1200, 3500, 50,
      CAREPLAN_VALUE_TYPES.VALUE.get(),
      CAREPLAN_VALUE_TYPES.VALUE.set()
    )
  }

  renderGoal () {
    return (
      <div>
        {this.getValueInput()} calories of food daily
      </div>
    )
  }

  renderCondensed () {
    return null
  }
}
