import React, { useState } from 'react'
import getGlucoseChartOptions from '../../../utils/charts/glucoseChart'
import ChartModule from './ChartModule'
import GlucoseTable from '../Tables/GlucoseTable'
import TabPanel from '../../layout/TabPanel'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

function a11yProps (index) {
  return {
    id: `blood-glucose-metric__tab-${index}`,
    'aria-controls': `blood-glucose-metric__tab-${index}`
  }
}

export default function BloodGlucoseChart (props) {
  const [selectedBloodGlucoseTab, setSelectedBloodGlucoseTab] = useState(0)

  const renderChartPanel = () => {
    return (
      <Card className='chart-card'>
        <CardContent sx={{ paddingTop: 0 }}>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'end', mb: 2 }}>
            <Typography variant='h6' color='#7f7f7f'> Blood Glucose</Typography>
            <Tabs
              sx={{ borderBottom: 1, borderColor: 'divider' }}
              value={selectedBloodGlucoseTab}
              onChange={(_e, val) => setSelectedBloodGlucoseTab(val)}
              aria-label='Profile tabs'
            >
              <Tab data-testid='blood-glucose__charts-tab__bg' label='Graph' {...a11yProps(0)} />
              <Tab data-testid='blood-glucose__table-tab__bg' label='Table' {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={selectedBloodGlucoseTab} index={0} noPadding>
            <ChartModule
              hideHeader
              hideBorder
              showChartActions
              dashboardViewStore={props.dashboardViewStore}
              memberObj={props.memberObj}
              metricType='blood_glucose'
              getChartOptions={getGlucoseChartOptions}
              showMemberAlerts
              handleAlertClick={props.handleAlertClick} // eslint-disable-line react/jsx-handler-names
            />
          </TabPanel>
          <TabPanel value={selectedBloodGlucoseTab} index={1} noPadding>
            <GlucoseTable
              hideHeader
              hideBorder
              dashboardViewStore={props.dashboardViewStore}
              memberObj={props.memberObj}
            />
          </TabPanel>
        </CardContent>
      </Card>

    )
  }

  return renderChartPanel()
}
