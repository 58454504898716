import React from 'react'
import ProfilePregnancyPane from './ProfilePregnancyPane'

function CustomMemberProfilePane (props) {
  const classItems = props.hideBorder ? '' : 'edit-profile-pane'

  const renderCustomProfilePane = () => {
    return (
      <div className={classItems}>
        <div className='edit-profile-pane__header'>
          {props.memberObj.user.first_name}'s pregnancy
        </div>

        <ProfilePregnancyPane variant='wide' memberObj={props.memberObj} />
      </div>
    )
  }

  return renderCustomProfilePane()
}

export default CustomMemberProfilePane
