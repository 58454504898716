import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import ProfileStatusPane from './ProfileStatusPane'
import PregnancyInformationPane from './PregnancyInformationPane'
import ProfileInformationPane from './ProfileInformationPane'
import CustomMemberProfilePane from './CustomMemberProfilePane'
import TabPanel from '../../../layout/TabPanel'
import { connect } from 'react-redux'
import { getSimpleDisplayName } from '../../../../utils/baseStringUtils'
import { isMemberPaused } from '../../../../utils/member/baseProfileUtils'
import { Box, Divider, Tab, Tabs } from '@mui/material'
import { productConfig } from '../../../../config/config-service'
import Modal from '../../../elements/Modal'

function a11yProps (index) {
  return {
    id: `edit-member-profile__tab-${index}`,
    'aria-controls': `edit-member-profile__tab-${index}`
  }
}

function EditMemberProfileModal (props) {
  const [isOpen, setIsOpen] = useState(true)
  const [value, setValue] = React.useState(0)
  const [tabIndeces, setTabIndeces] = useState({ profile: 0, status: 1, pregnancy: 2 })
  const config = productConfig()

  useEffect(() => {
    setValue(isOpen ? tabIndeces[props.profileViewStore.modalTabLabel] : 0)
  }, [props.profileViewStore.modalTabLabel])

  useEffect(() => {
    if (props.memberObj.user.archived_since) {
      setValue(0)
      setTabIndeces({ profile: 0, status: null, pregnancy: 1 })
    }
  }, [props.memberObj.user])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const closeModal = () => {
    setIsOpen(false)
    props.profileViewActions.handleClearModal()
  }

  const renderModal = () => {
    const user = props.memberObj.user
    const memberName = getSimpleDisplayName(user)

    const modalTitle = (
      <span>
        Member details for {memberName}
        {isMemberPaused(props.memberObj.user.pauses) &&
          <span className='profile__archived-text'>SUSPENDED</span>}
        {props.memberObj.user.archived_since &&
          <span className='profile__archived-text'>ARCHIVED</span>}
      </span>
    )

    const contentClass = classnames({
      'profile--archived': !!props.memberObj.user.archived_since
    })

    return (
      <Modal
        name='editMemberProfile'
        maxWidth='lg'
        targetUser={user}
        isOpen={isOpen}
        onModalClose={closeModal}
        modalTitle={modalTitle}
      >
        <Box sx={{ width: '100%', height: '100%' }} className={contentClass}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label='Edit profile tabs'>
              <Tab label='Profile' {...a11yProps(0)} />
              {!props.memberObj?.user?.archived_since && <Tab label='Status' {...a11yProps(1)} />}
              {config.profile.showPregnancyInfo && <Tab label='Pregnancy' {...a11yProps(2)} />}
            </Tabs>
          </Box>
          <TabPanel value={value} index={tabIndeces.profile} panelHeight={config.tabPanel.editMember} isModal>

            <ProfileInformationPane
              hideTitle
              hideBorder
              careManagers={props.userSession.resources?.care_managers}
              memberObj={props.memberObj}
              apiActions={props.apiActions}
              profileViewActions={props.profileViewActions}
            />
          </TabPanel>
          {tabIndeces.status &&
            <TabPanel value={value} index={tabIndeces.status} panelHeight={config.tabPanel.statusTab} isModal>
              <ProfileStatusPane
                hideTitle
                hideBorder
                memberObj={props.memberObj}
                onMemberArchived={closeModal}
              />
            </TabPanel>}

          {config.profile.showPregnancyInfo &&
            <TabPanel value={value} index={tabIndeces.pregnancy} panelHeight={config.tabPanel.statusTab} isModal sx={{ height: '100%' }}>
              <CustomMemberProfilePane
                hideTitle
                hideBorder
                memberObj={props.memberObj}
              />

              <Divider variant='middle' sx={{ m: 3 }} />

              <PregnancyInformationPane
                memberObj={props.memberObj}
                apiActions={props.apiActions}
                profileViewActions={props.profileViewActions}
              />
            </TabPanel>}

        </Box>
      </Modal>
    )
  }

  const renderEditProfile = () => {
    const isModalVisible = props.profileViewStore.openModal === 'editMemberProfile'
    if (!isModalVisible) return null
    if (isModalVisible && !isOpen) setIsOpen(true)
    return renderModal()
  }

  return renderEditProfile()
}

function getPropsFromStore (state) {
  return {
    userSession: state.userSession
  }
}

export default connect(getPropsFromStore)(EditMemberProfileModal)
