import React, { useState } from 'react'
import { connect } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import restApi from '../../../api'
import classnames from 'classnames'

import UserNavLabel from 'controllers/UserNav/UserNavLabel'
import SettingsIcon from '@mui/icons-material/Settings'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'

/* *****************************************************************************
 * Settings navigation menu element (consisting of icon & dropdown menu)
 * *****************************************************************************/
function getPropsFromActions (dispatch) {
  return {
    handleLogout () {
      dispatch(restApi.actions.logout())
    }
  }
}

function SettingsMenuItem (props) {
  const [anchorElUser, setAnchorElUser] = useState(null)
  const open = Boolean(anchorElUser)
  const location = useLocation()

  /*
   * Interaction handling
   */
  const handleOpenSettingsMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseSettingsMenu = () => {
    setAnchorElUser(null)
  }

  const handleSignOutClicked = () => {
    handleCloseSettingsMenu()
    props.handleLogout()
  }

  /*
   * Rendering
   */
  const renderTarget = () => {
    const isSettingsActive = ['/careplan', '/account', '/help'].some(p => location.pathname === p)

    const targetClassNames = classnames({
      'user-nav__item': true,
      'user-nav__item--is-selected': open,
      active: isSettingsActive
    })

    return (
      <div
        aria-owns={open ? 'mouse-over-popover' : undefined}
        className={targetClassNames}
        onMouseEnter={handleOpenSettingsMenu}
        aria-haspopup='true'
        data-testid='settings-menu-item__menu-button'
      >
        <SettingsIcon fontSize='large' />
        <UserNavLabel labelText='Settings' />
      </div>
    )
  }

  const renderCareplanLink = () => {
    return (
      <NavLink to='/careplan' data-testid='settings-menu-item__careplan-link' onClick={handleCloseSettingsMenu} className='no-decoration'>
        <li className='user-nav__dropdown__item'>
          Care plan
        </li>
      </NavLink>
    )
  }

  const renderDropdown = () => {
    return (
      <Box onMouseLeave={handleCloseSettingsMenu}>
        {renderCareplanLink()}

        <NavLink to='/account' data-testid='settings-menu-item__account-link' onClick={handleCloseSettingsMenu} className='no-decoration'>
          <li className='user-nav__dropdown__item'>Account</li>
        </NavLink>

        <NavLink to='/help' data-testid='settings-menu-item__help-link' onClick={handleCloseSettingsMenu} className='no-decoration'>
          <li className='user-nav__dropdown__item'>Help</li>
        </NavLink>

        <li
          className='user-nav__dropdown__item'
          data-testid='settings-menu-item__sign-out-link'
          onClick={handleSignOutClicked}
        >
          Sign out
        </li>
      </Box>
    )
  }

  const renderResponsiveSettingsMenu = () => {
    return (
      <Box sx={{ flexGrow: 0 }} className='user-nav'>
        {renderTarget()}

        <Menu
          sx={{
            mt: '45px'
          }}
          id='menu-appbar'
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          onClose={handleCloseSettingsMenu}
          open={open}
          className='user-nav__items'
          disableRestoreFocus
        >
          {renderDropdown()}
        </Menu>
      </Box>
    )
  }

  return renderResponsiveSettingsMenu()
}

export default connect(null, getPropsFromActions)(SettingsMenuItem)
