import _ from 'lodash'
import baseChartOptions from './baseChartOptions'
import { getSimpleDisplayName } from '../../js/utils/baseStringUtils'
import { useNavigate } from 'react-router'

export default function getWeightFluctuationChartOptions (users) {
  const usersWithData = _.filter(users, u => !!u.overall_stats.latest_weight_lbs)
  const navigate = useNavigate()

  const chartOptions = {
    chart: {
      type: 'scatter'
    },

    legend: {
      enabled: false
    },

    xAxis: {
      title: {
        text: 'Weight (lbs)'
      },
      minPadding: 0.2,
      maxPadding: 0.2
    },

    yAxis: {
      title: {
        text: 'Median weight fluctuation (lbs)'
      }
    },

    series: [{
      className: 'series-0',
      marker: {
        radius: 5
      },

      data: _.map(usersWithData, u => ({
        className: 'ax-chart-clickable',

        x: u.overall_stats.latest_weight_lbs,
        y: u.overall_stats.median_weight_fluctuation_lbs,
        name: getSimpleDisplayName(u.info),

        events: {
          click: () => {
            navigate(`/profile/${u.id}`)
          }
        }
      })),

      tooltip: {
        headerFormat: null,
        pointFormatter: function format () {
          return `
            <span class="ax-text-bold">${this.name}</span><br/>
            Weight: <em>${this.x} lb</em><br/>
            Median weight fluctuation: <em>${this.y} lb</em><br/>
          `
        }
      }
    }]
  }

  return _.merge(_.cloneDeep(baseChartOptions), chartOptions)
}
