import { createSlice } from '@reduxjs/toolkit'

const sidebarSlice = createSlice({
  name: 'sidebarView',
  initialState: {
    sidebarOpen: true,
    buttonOnlyWidth: 52
  },
  reducers: {
    setSidebarOpen: (state, action) => {
      state.sidebarOpen = action.payload
    },
    toggleSidebarOpen: (state) => {
      state.sidebarOpen = !state.sidebarOpen
    }
  }
})

export const {
  setSidebarOpen,
  toggleSidebarOpen
} = sidebarSlice.actions

export { sidebarSlice }
