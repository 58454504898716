import _ from 'lodash'
import moment from 'moment'
import classnames from 'classnames'
import { getBaseSeriesOptions } from './baseChartUtils'
import { isAlertOpen } from '../../constants/constants'

/* ********************************************************************************
 * Base chart options
 * ********************************************************************************/

export const baseActivityChartOptions = {
  chart: {
    className: 'activity-chart',
    spacingTop: 40
  },

  legend: {
    symbolRadius: 0,
    itemMarginTop: 12
  },

  tooltip: {
    useHTML: true,
    padding: 12
  },

  yAxis: {
    labels: {
      format: '{value}'
    },
    maxPadding: 0.2
  },

  series: []
}

/* ********************************************************************************
 * Activity alerts series
 * ********************************************************************************/

export function getActivityAlertsSeries (options, memberObj, handleAlertClick, getAlertUnits) {
  // Grab activity alerts
  if (!(memberObj.alerts && memberObj.alerts.length)) return options
  const activityAlerts = _.filter(memberObj.alerts, a => a.metric_type === 'activity')
  if (!activityAlerts.length) return options

  const alertsData = activityAlerts.map((alertObj) => {
    // Find the activity measurement corresponding to this alert (if it exists)
    const activityMeasurement = memberObj.activity.data.find(
      d => moment(d.date).isSame(moment(alertObj.measurement_date), 'day')
    )

    // Get the appropriate base y-value for the alert
    let alertY = 0
    if (getAlertUnits && activityMeasurement) {
      alertY += getAlertUnits(activityMeasurement)
    }

    /*
     * Get marker styling
     */
    const marker = {}
    if (alertObj.type === 'activity_tracking') {
      marker.symbol = 'circle'
    }
    const isOpen = isAlertOpen(alertObj)

    const classNames = classnames({
      'is-open': isOpen,
      'is-closed': !isOpen,
      'is-tracking': alertObj.type === 'activity_tracking'
    })

    /*
     * Define Highcharts point
     */
    const point = {
      name: alertObj.provider_content,
      x: moment(alertObj.measurement_date).startOf('day'),
      y: alertY + 10,
      marker,
      className: classNames,
      events: {
        click: () => {
          if (handleAlertClick) {
            handleAlertClick(alertObj)
          }
        }
      }
    }

    return point
  })

  const alertsSeries = _.extend({
    name: 'Activity alerts',
    type: 'scatter',
    className: 'series-activityAlerts',
    lineWidth: 0,
    color: '#EF6C00',

    // Places the alert triangles above everything else so that they are viewable/clickable
    zIndex: 10,

    marker: {
      symbol: 'triangle-down',
      radius: 7,
      lineWidth: 0
    },

    tooltip: {
      useHTML: true,
      headerFormat: '<strong>Activity alert</strong>',
      pointFormat: '<div class="activity-alert">{point.name}</div>'
    },

    data: alertsData
  }, getBaseSeriesOptions())

  const newOptions = _.cloneDeep(options)
  newOptions.series.push(alertsSeries)

  return newOptions
}
