import { Button } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

export default function SelfSignupPaginator (props) {
  const { hasPreviousSteps, onHandleBack, onHandleNext, hasMoreSteps, submitting, testId } = props
  const canContinue = props.canContinue ?? true
  const nextButtonText = submitting ? 'Submitting' : !hasMoreSteps ? 'Finish' : 'Next'
  const backId = `${testId ?? ''}__previous-button`
  const nextId = `${testId ?? ''}__continue-button`
  const isSubmit = props.isFormSubmit ?? true

  const buttonProps = {
    type: isSubmit ? 'submit' : 'button',
    disabled: !canContinue
  }
  if (!isSubmit) buttonProps.onClick = onHandleNext

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
      <Button
        data-testid={backId}
        color='inherit'
        disabled={!hasPreviousSteps}
        onClick={onHandleBack}
        sx={{ mr: 1 }}
      >
        Back
      </Button>
      <Box sx={{ flex: '1 1 auto' }} />
      <Button data-testid={nextId} {...buttonProps}>{nextButtonText}</Button>
    </Box>
  )
}
