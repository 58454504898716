import React from 'react'
import CarePlanItem from '../CarePlanItem'
import { CAREPLAN_VALUE_TYPES } from '../../../../constants/carePlanValueTypes'

export default
class HeartRateRangeItem extends CarePlanItem {
  constructor (props) {
    super(props)
    this.type = 'hr_range'
  }

  rangeHeartRateMinInputElement () {
    return this.getInputElement(
      30, 60, 5,
      CAREPLAN_VALUE_TYPES.HEART_RATE_MIN.get(val => val),
      CAREPLAN_VALUE_TYPES.HEART_RATE_MIN.set(val => val)
    )
  }

  rangeHeartRateMaxInputElement () {
    return this.getInputElement(
      65, 150, 5,
      CAREPLAN_VALUE_TYPES.HEART_RATE_MAX.get(val => val),
      CAREPLAN_VALUE_TYPES.HEART_RATE_MAX.set(val => val)
    )
  }

  renderGoal () {
    return <div>Keep resting heart rate in range</div>
  }

  renderClinicianTrigger () {
    return <div>If <b>resting heart rate</b> is below {this.rangeHeartRateMinInputElement()} bpm or above {this.rangeHeartRateMaxInputElement()} bpm.</div>
  }

  renderCondensed () {
    return (
      <div>Heart rate &lt; {this.getValueElement(CAREPLAN_VALUE_TYPES.HEART_RATE_MIN.get(val => val))} or
        &gt; {this.getValueElement(CAREPLAN_VALUE_TYPES.HEART_RATE_MAX.get(val => val))} bpm
      </div>
    )
  }
}
