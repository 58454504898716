
import React, { useState } from 'react'
import moment from 'moment'
import { momentCalendarConfig } from '../../../utils/baseStringUtils'
import EmptyCell from './EmptyCell'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

export default function RespiratoryRateCell (props) {
  const [memberObj] = useState(props.memberObj)

  if (!memberObj.user.has_respiratory_rate || !memberObj.respiratory_rate) {
    return <EmptyCell message='No respiratory rate data' />
  }

  const lastMeasurement = moment(memberObj.respiratory_rate.latest_measurement.timestamp)
  const latestCol = (
    <Box>
      <div className='member-row__latest'> {`${Math.round(memberObj.respiratory_rate.latest_measurement.respiratory_rate * 10) / 10} ${memberObj.respiratory_rate.information.units.respiratory_rate}`} </div>
      <div className='member-row__latest-timestamp'> {lastMeasurement.calendar(null, momentCalendarConfig)} </div>
    </Box>
  )

  return (
    <Grid container>
      <Grid item xs={12}> {latestCol} </Grid>
    </Grid>
  )
}
