import { Alert, Box, Button, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { EjentaForm, renderField, renderCheckboxInput } from '../../../elements/EjentaForm'
import SelfSignupPaginator from './SelfSignupPaginator'

export default function SignupChecks (props) {
  const [eligibleError, setEligibleError] = useState(false)
  const [userCanWeigh, setUserCanWeigh] = useState(true)
  const [userTakesActions, setUserTakesActions] = useState(true)
  const [userNoShare, setUserNoShare] = useState(true)
  const [userTakesMeds, setUserTakesMeds] = useState(true)
  const [userWeightUnderMax, setUserWeightUnderMax] = useState(true)

  const completeButtonClicked = (formData) => {
    const { canWeigh, takesActions, noShare, takesMeds, weightUnderMax } = formData
    setUserCanWeigh(canWeigh)
    setUserTakesActions(takesActions)
    setUserNoShare(noShare)
    setUserTakesMeds(takesMeds)
    setUserWeightUnderMax(weightUnderMax)

    if (canWeigh && takesActions && noShare && takesMeds && weightUnderMax) {
      props.sendAnalytics(false, { ...formData, eligibleError })

      return props.handleFormSubmit().then(() => {
        props.handleIncrementPage()
        return true
      }).catch(data => {
        return data
      })
    } else {
      setEligibleError(true)
      return false
    }
  }

  const createCheckbox = (name, label, defaultValue) => {
    return {
      name,
      label,
      defaultValue,
      testId: name,
      type: 'checkbox',
      component: renderCheckboxInput
    }
  }

  const renderEligibilityError = () => {
    return (
      <Box>
        <Typography variant='h4' mb={3} textAlign='center'>Unfortunately, you are not eligible for this program</Typography>

        <Box sx={{ p: 3 }}>
          {props.error && <Alert severity='error' sx={{ m: 1 }}>{props.error}</Alert>}
          <Typography variant='body1'>Based on the answers to the previous questions, you are not eligible to participate in the Heart Healthy Program.</Typography>
          <Typography variant='body1'><strong>You may go back and edit your responses.</strong></Typography>
          <Typography variant='body1'>We may contact you to participate in another program in the future.</Typography>

          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Button
              variant='contained'
              data-testid='login-form__go-back-button'
              onClick={() => setEligibleError(false)}
            >
              Go Back and Edit
            </Button>
          </Box>
        </Box>
      </Box>
    )
  }

  const renderChecks = () => {
    if (eligibleError) return renderEligibilityError()

    const canWeighCheckbox = createCheckbox('canWeigh', 'I am capable of standing on a scale and weighing myself.', userCanWeigh)
    const takesActionsCheckbox = createCheckbox('takesActions', 'I agree to take my weight and blood pressure regularly as directed.', userTakesActions)
    const noShareCheckbox = createCheckbox('noShare', 'I agree that I will only use the scale and blood pressure devices myself and will not allow others to use them.', userNoShare)
    const takesMedsCheckbox = createCheckbox('takesMeds', 'I agree to take any prescribed medications as directed by my health provider.', userTakesMeds)
    const weightUnderMaxCheckbox = createCheckbox('weightUnderMax', 'I currently weigh less than 397 pounds.', userWeightUnderMax)
    return (
      <Box>
        <Typography variant='h4' textAlign='center' mb={3}>Just a few more questions</Typography>

        <Box>
          <Typography textAlign='center' fontWeight={500}>You must agree to the following to be eligible for the program.</Typography>

          <Form onSubmit={completeButtonClicked}>
            {({ handleSubmit, submitError }) => (
              <EjentaForm onSubmit={handleSubmit}>
                {submitError && <Alert severity='error' sx={{ m: 1 }}>{submitError}</Alert>}

                <Stack spacing={2}>
                  <Box />
                  {renderField({}, canWeighCheckbox)}
                  {renderField({}, takesActionsCheckbox)}
                  {renderField({}, noShareCheckbox)}
                  {renderField({}, takesMedsCheckbox)}
                  {renderField({}, weightUnderMaxCheckbox)}
                </Stack>

                <SelfSignupPaginator
                  testId='login-form__complete'
                  isFormSubmit
                  hasPreviousSteps
                  onHandleBack={props.handleDecrementPage}
                  onHandleNext={props.handleIncrementPage}
                  hasMoreSteps
                />
              </EjentaForm>
            )}
          </Form>

        </Box>
      </Box>
    )
  }

  return renderChecks()
}
