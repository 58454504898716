export const humanizeAlertType = (alertType) => {
  switch (alertType) {
    case 'weight_delta':
      return 'Weight increase'
    case 'bp_range':
      return 'BP/HR out of range'
    case 'bg_range':
      return 'BG out of range'
    case 'pulse_ox_range':
      return 'Low SpO₂'
    case 'survey_range':
      return 'Survey out of range'
    case 'multi_tracking':
      return 'Missing data'
    case 'new_member':
      return 'New member'
    case 'health_status_change':
      return 'Health status change'
    case 'program_step_change':
      return 'Program step change'
    case 'new_chat':
      return 'New chat messages'

    // legacy strings for backwards compatibility
    case 'biosticker_tracking':
      return 'No bio data'
    case 'pulse_ox_tracking':
      return 'No SpO₂ data'
    case 'temp_range':
      return 'Temperature out of range'
    case 'hr_range':
      return 'Heart rate out of range'
    case 'rr_range':
      return 'Respiratory rate out of range'

    default:
      return ''
  }
}

export const humanizeAlertLevel = (level) => {
  switch (level) {
    case 'red':
      return 'Red'
    case 'orange':
      return 'Orange'
    case 'yellow':
      return 'Yellow'
    default:
      return ''
  }
}

export const bodytraceScaleTooltip = 'This is the 15-digit number on the bottom of the BodyTrace scale, under the barcode. Ignore any dashes.'
export const bodytraceBpTooltip = 'This is the 15-digit number on the bottom of the BodyTrace blood pressure device, under the barcode. Ignore any dashes.'
export const biostickerTooltip = 'This is the ID number printed on the BioButton/BioSticker. 10 digits maximum. Ignore any dashes and leading 0s.'
export const biohubTooltip = 'This is the ID number printed on the BioHub, if the member intends to sync with a BioHub (leave blank otherwise). 10 digits maximum. Ignore any dashes and leading 0s.'
export const iGlucoseTooltip = 'This is the ID number printed on the iGlucose device.'
export const noninTooltip = 'This is the ID number printed on the Nonin pulse ox device.'
