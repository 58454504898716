import React, { useEffect, useState } from 'react';
import { logGAModalOpen, logGAModalClose } from '../../utils/googleAnalyticsUtils';
/* *****************************************************************************
 * Modal element
 * *****************************************************************************/
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { IconButton } from '@mui/material';
import { useLocation } from 'react-router';
var Modal = function (props) {
    var _a;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var location = useLocation();
    useEffect(function () {
        var _a;
        setIsOpen(props.isOpen);
        if (props.isOpen)
            logGAModalOpen(props.name, (_a = props.targetUser) !== null && _a !== void 0 ? _a : {});
    }, [props.isOpen]);
    var handleModalBoxClick = function () {
        setIsOpen(false);
        logGAModalClose(location);
        props.onModalClose();
    };
    return (React.createElement(Dialog, { closeAfterTransition: true, fullWidth: true, maxWidth: (_a = props.maxWidth) !== null && _a !== void 0 ? _a : 'lg', open: isOpen, onClose: handleModalBoxClick, id: "".concat(props.name, "__").concat(Date.now()), sx: {
            '& .MuiDialog-paper': {
                paddingBottom: '1rem'
            }
        } },
        React.createElement(DialogTitle, { "data-testid": 'modal__title', sx: { justifyContent: 'space-between', display: 'inline-flex', color: function (theme) { return theme.palette.primary.main; } } },
            React.createElement("span", null, props.modalTitle),
            React.createElement(IconButton, { "data-testid": 'modal__close-x', onClick: handleModalBoxClick },
                React.createElement(CloseIcon, { sx: { color: 'black' } }))),
        React.createElement(DialogContent, { sx: { paddingBottom: 0 } }, props.children)));
};
export default Modal;
