import React, { useState } from 'react'
import classnames from 'classnames'
import restApi from '../../../api'
import includeFeatureGates from '../../controllers/FeatureGates'
import UserNavLabel from '../../controllers/UserNav/UserNavLabel'
import ChatMenuItem from '../../controllers/UserNav/ChatMenuItem'
import MenuIcon from '@mui/icons-material/Menu'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { productConfig } from '../../../config/config-service'
import { FEATURE_GATES } from '../../../constants/constants'
import { dispatchWithException } from '../../../api/baseUtils'
import { setActiveGroup } from '../../../store/userSession'
import { Box, Divider, Menu, MenuItem, IconButton, ListItemText, Typography } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import GroupIcon from '@mui/icons-material/Group'
import PieChartIcon from '@mui/icons-material/PieChart'

function ClinicianNav (props) {
  const navigate = useNavigate()
  const location = useLocation()
  const [anchorElNav, setAnchorElNav] = useState(null)
  const hasChat = productConfig().router.hasChat
  const pages = ['alerts', 'members', 'analytics']

  if (props.featureGates[FEATURE_GATES.SHOW_ONLY_ANALYTICS]) {
    if (location.pathname.includes('/analytics')) return null
  }

  const isMembersActive = ['/members', '/profile'].some(p =>
    location.pathname.includes(p)
  )

  const pathShouldNotShowGroup = location.pathname.startsWith('/groupPicker') || location.pathname.startsWith('/account') || location.pathname.startsWith('/help')
  const showGroupInfo = props.userSession.activeGroup && props.userSession.user?.allowedGroups?.length > 1 && !pathShouldNotShowGroup

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleGroupSwitchClicked = () => {
    handleCloseNavMenu()
    // important when on a profile, click switch group, we do not want to redirect back to the original profile in the old group
    dispatchWithException(setActiveGroup(null), props.dispatch)
    if (location.pathname.startsWith('/profile')) navigate('/', { replace: true })
  }

  const generateGroupInfo = (isMobile = false) => {
    if (!showGroupInfo) return null

    return (
      <Box sx={{ alignSelf: 'center' }}>
        {isMobile && <Divider />}

        <Box sx={{ alignSelf: 'center' }}>
          <div className='user-nav__group-info'>
            <Typography
              sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}
              fontSize='1.5rem'
              minWidth='13rem'
              className='user-nav__active-group'
            >{props.userSession.activeGroup.description}
            </Typography>
            <Typography
              sx={{ display: { xs: 'flex', md: 'none', lg: 'none', sm: 'flex' } }}
              fontSize='1rem'
              paddingLeft='1rem'
              paddingRight='1rem'
              paddingTop='.5rem'
            >{props.userSession.activeGroup.description}
            </Typography>
            <MenuItem key='groupSwitch' onClick={handleGroupSwitchClicked}>
              <ListItemText sx={{ color: theme => theme.palette.primary.main }}>
                Switch group
              </ListItemText>
            </MenuItem>
          </div>
        </Box>
      </Box>
    )
  }

  const generateDefaultNav = () => {
    return (
      <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

        <div className='user-nav'>
          {generateGroupInfo()}
          <div className='user-nav__items'>
            <NavLink
              key='alerts'
              onClick={handleCloseNavMenu}
              to='/alerts'
              data-testid='user-nav__alerts'
              className={({ isActive }) => 'user-nav__item' + (isActive ? ' active' : '')}
            >
              <NotificationsIcon fontSize='large' />
              <UserNavLabel labelText='Alerts' />
            </NavLink>

            {hasChat && <ChatMenuItem badgeCount={props.filterCounts['FILTER/UNREAD_NEW_CHAT']} isMobile={false} />}

            <NavLink
              key='members'
              onClick={handleCloseNavMenu}
              to='/members'
              data-testid='user-nav__members'
              className={classnames('user-nav__item', { active: isMembersActive })}
            >
              <GroupIcon fontSize='large' />
              <UserNavLabel labelText='Members' />
            </NavLink>

            <NavLink
              key='analytics'
              onClick={handleCloseNavMenu}
              to='/analytics'
              data-testid='user-nav__analytics'
              className={({ isActive }) => 'user-nav__item' + (isActive ? ' active' : '')}
            >
              <PieChartIcon fontSize='large' />
              <UserNavLabel labelText='Analytics' />
            </NavLink>
          </div>
        </div>
      </Box>
    )
  }

  const generateMobileNav = () => {
    return (
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
        <IconButton
          size='large'
          aria-label='account of current user'
          aria-controls='menu-appbar'
          aria-haspopup='true'
          onClick={handleOpenNavMenu}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id='menu-appbar'
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: 'block', md: 'none' }
          }}
        >

          {pages.map((page) => (
            <MenuItem
              className='user-nav-mobile'
              key={page}
              onClick={handleCloseNavMenu}
            >
              <NavLink to={'/' + page} className={({ isActive }) => 'user-nav-mobile__item' + (isActive ? ' active' : '')}>
                <Typography textAlign='center' fontSize='1.1rem'>
                  {page.charAt(0).toUpperCase() + page.slice(1)}
                </Typography>
              </NavLink>
            </MenuItem>
          ))}

          {hasChat && <ChatMenuItem badgeCount={props.filterCounts['FILTER/UNREAD_NEW_CHAT']} isMobile handleCloseNavMenu={handleCloseNavMenu} />}

          {generateGroupInfo(true)}
        </Menu>
      </Box>
    )
  }

  if (props.forDesktop) return generateDefaultNav()
  else return generateMobileNav()
}

function getPropsFromStore (state) {
  return {
    userSession: state.userSession
  }
}

function getPropsFromActions (dispatch) {
  return {
    apiActions: bindActionCreators(restApi.actions, dispatch)
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(includeFeatureGates(ClinicianNav))
