import React, { useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import { logGAPageView } from '../utils/googleAnalyticsUtils'
import { productConfig } from '../config/config-service'
import restApi from '../api'
import LoginForm from '../components/controllers/forms/LoginForm'
import ResetPasswordForm from '../components/controllers/forms/ResetPasswordForm'
import ForgotPasswordForm from '../components/controllers/forms/ForgotPasswordForm'
import PhoneLoginForm from '../components/controllers/forms/PhoneLoginForm'
import SelfSignupForm from '../components/controllers/forms/SelfSignup/SelfSignupForm'
import NotFoundPage from '../components/pages/NotFoundPage'
import OnboardingPage from '../components/pages/OnboardingPage'
import AccountSettingsPage from '../components/pages/AccountSettingsPage'
import HelpPage from '../components/pages/HelpPage'
import MemberGetAppPage from '../components/pages/MemberGetAppPage'
import GroupPickerPage from '../components/pages/clinician/GroupPickerPage'
import AlertsPage from '../components/pages/clinician/AlertsPage'
import MembersPage from '../components/pages/clinician/MembersPage'
import CarePlanPage from '../components/pages/clinician/CarePlanPage'
import ProfilePage from '../components/pages/clinician/ProfilePage'
import AnalyticsPage from '../../analytics/AnalyticsPage'

import SessionExpireWarning from '../components/controllers/SessionExpireWarning'
import { AppHeader } from '../components/layout/Header'
import { ejentaRoutes, isAllAccessRoute } from './browserRouter'
import { getHasValidConsentDocs } from '../utils/member/baseProfileUtils'

function handlePageLoad (location) {
  logGAPageView(location)
  window.scrollTo(0, 0)
}

/* *****************************************************************************
 * App router.
 * *****************************************************************************/
let loginTarget
export default function ({ state, dispatch }) {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const config = productConfig()
  const {
    userSession: { isLoggedIn, isClinician, activeGroup, user }
  } = state

  const isMultiGroup = user?.allowedGroups?.length > 1
  const currentURLAvailableWithoutGroup = location.pathname.startsWith(ejentaRoutes.accountPage) || location.pathname.startsWith(ejentaRoutes.helpPage)
  const currentURLIsProfile = location.pathname.startsWith(ejentaRoutes.profilePage)

  useEffect(() => handlePageLoad(location), [location])

  useEffect(() => { document.title = `${config.name} - Ejenta Dashboard` }, [])

  useEffect(() => {
    if (isLoggedIn) {
      if (!getHasValidConsentDocs(user)) {
        navigate(ejentaRoutes.welcomePage, { replace: true })
        loginTarget = null
        return
      }

      // if just logged in or at root
      if (location.pathname === ejentaRoutes.loginPage || location.pathname === '/') {
        // redirect to loginTarget after login
        if (loginTarget) {
          if (!isClinician) navigate(ejentaRoutes.memberDashboardPage, { replace: true })
          else {
            navigate(loginTarget, { replace: true })
            loginTarget = null
          }
        } else {
          // default inital screen after login
          if (isClinician) navigate(ejentaRoutes.alertsPage, { replace: true })
          else navigate(ejentaRoutes.memberDashboardPage, { replace: true })
        }
      }
    } else {
      const isSelfSignupPath = config.router.hasSelfSignup && location.pathname === ejentaRoutes.signupPage
      const allowedPathWhileLoggedOut = isAllAccessRoute(location.pathname) || isSelfSignupPath
      // save loginTarget until logged in
      if (!allowedPathWhileLoggedOut) {
        if (location.pathname === ejentaRoutes.logoutPage) loginTarget = null
        else loginTarget = `${location.pathname}${location.search}`
        navigate(ejentaRoutes.loginPage, { replace: true })
      }
    }
  }, [isLoggedIn, location.pathname])

  // sets group based on URL param
  useEffect(() => {
    if (isLoggedIn && location.search) {
      const url = new URL(window.location.href)
      const group = url.searchParams.get('group')
      if (group && group !== activeGroup) {
        dispatch(restApi.actions.setActiveGroup({ activeGroup: group }))
      }
    }
  }, [isLoggedIn, location.search])

  useEffect(() => {
    if (!isLoggedIn) return

    if (location.pathname === ejentaRoutes.logoutPage) {
      loginTarget = null
      dispatch(restApi.actions.logout())
      navigate(ejentaRoutes.loginPage, { replace: true })
      return
    }

    if (isAllAccessRoute(location.pathname)) navigate(ejentaRoutes.alertsPage, { replace: true })
  }, [location.pathname])

  const getIsValidRouteRoot = () => {
    const secondSlashIndex = location.pathname.slice(1).indexOf('/')
    const locationSegment = secondSlashIndex >= 0 ? location.pathname.slice(0, secondSlashIndex + 1) : location.pathname
    return Object.values(ejentaRoutes).indexOf(locationSegment) >= 0
  }

  const shouldShowHeader = () => {
    if (!isLoggedIn) return false
    if (!getHasValidConsentDocs(user)) return false
    if (location.pathname.startsWith(ejentaRoutes.welcomePage) || location.pathname.startsWith(ejentaRoutes.memberDashboardPage)) return false
    if (isAllAccessRoute(location.pathname)) return false

    return getIsValidRouteRoot()
  }

  const missingValidGroup = !activeGroup && isMultiGroup

  const getGroupBasedRoutes = () => {
    return (
      <>
        <Route name='alerts' path={ejentaRoutes.alertsPage} element={<AlertsPage />} />
        <Route name='chat' path={ejentaRoutes.chatPage} element={<AlertsPage />} />
        <Route name='members' path={ejentaRoutes.membersPage} element={<MembersPage />} />
        <Route name='careplan' path={ejentaRoutes.careplanPage} element={<CarePlanPage />} />
        <Route name='analytics' path={ejentaRoutes.analyticsPage} element={<AnalyticsPage />} />
      </>
    )
  }

  const getGroupPickerRoute = () => {
    let route = (<Route name='groupPicker' path='*' element={<GroupPickerPage />} />)
    if (currentURLAvailableWithoutGroup) route = <></>
    else if (currentURLIsProfile) route = <></>
    return (route)
  }

  return (
    <>
      {isLoggedIn && <SessionExpireWarning />}
      {shouldShowHeader() && <AppHeader />}

      <Routes>
        <Route name='onboarding' path={ejentaRoutes.welcomePage} element={<OnboardingPage />} />
        <Route name='download' path={ejentaRoutes.memberDashboardPage} element={<MemberGetAppPage />} />
        <Route name='account' path={ejentaRoutes.accountPage} element={<AccountSettingsPage />} />
        <Route name='help' path={ejentaRoutes.helpPage} element={<HelpPage />} />
        <Route name='profile' path={`${ejentaRoutes.profilePage}/:id`} element={<ProfilePage params={params} location={location} />} />

        {missingValidGroup ? getGroupPickerRoute() : getGroupBasedRoutes()}

        {/* Logged-out routes */}
        <Route name='login' path={ejentaRoutes.loginPage} element={<LoginForm />} />
        <Route name='forgotPassword' path={ejentaRoutes.forgotPasswordPage} element={<ForgotPasswordForm />} />
        <Route name='resetPassword' path={ejentaRoutes.resetPasswordPage} element={<ResetPasswordForm />} />
        <Route name='activateAccount' path={ejentaRoutes.activateAccountPage} element={<OnboardingPage />} />
        <Route name='phoneLogin' path={ejentaRoutes.phoneLoginPage} element={<PhoneLoginForm />} />
        <Route name='signup' path={ejentaRoutes.signupPage} element={<SelfSignupForm />} />
        {!getIsValidRouteRoot() && <Route path='*' element={<NotFoundPage />} />}
      </Routes>

    </>
  )
}
