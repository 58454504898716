import _ from 'lodash'

/* ******************************************************************************
 * Helper methods
 * ******************************************************************************/

export function countHumanNotes (alertObj) {
  const humanNotes = _.filter(alertObj.notes, (n) => {
    if (n.author === 'Ejenta Agent') return false

    const actions = ['READ', 'OPEN', 'CLOSED']
    if (_.includes(actions, n.provider_content.toUpperCase())) return false

    return true
  })

  return humanNotes.length
}

const BP_SUBMETRICS_REGEX = /systolic|diastolic/
const BP_VALUE_REGEX = /(\d+)\/(\d+)/

const HR_SUBMETRIC_REGEX = /heart rate/
const HR_VALUE_REGEX = /(\d+) bpm/

function findBPString (content, regex) {
  const result = regex.exec(content)
  if (result && result[0]) {
    return result[0]
  }
  return null
}

export function getBPRangeAlertDisplay (alert) {
  if (BP_SUBMETRICS_REGEX.test(alert.provider_content)) {
    const bpString = findBPString(alert.provider_content, BP_VALUE_REGEX)
    if (bpString) {
      return bpString
    }
  }

  if (HR_SUBMETRIC_REGEX.test(alert.provider_content)) {
    const hrString = findBPString(alert.provider_content, HR_VALUE_REGEX)
    if (hrString) {
      return hrString
    }
  }

  // fail case so things aren't totally broken if the regex fails to find:
  return `${Math.round(alert.alert_value)}`
}

/* ******************************************************************************
 * Base filters
 * ******************************************************************************/

export const MEMBER_STATUS_FILTERS = {
  displayTitle: 'Member status',
  filters: [
    {
      id: 'FILTER/STARRED',
      displayTitle: 'Starred'
    }
  ]
}

export const ALERT_NOTES_FILTERS = {
  displayTitle: 'Notes',
  filters: [
    {
      id: 'FILTER/WITH_NOTE',
      displayTitle: 'Alerts with notes'
    }
  ]
}

export const ALERT_STATUS_FILTERS = {
  displayTitle: 'Alert status',
  filters: [
    {
      id: 'FILTER/OPEN_UNREAD',
      displayTitle: 'Unread'
    },
    {
      id: 'FILTER/OPEN',
      displayTitle: 'Open'
    },
    {
      id: 'FILTER/CLOSED',
      displayTitle: 'Closed'
    }
  ]
}

export const DEFAULT_ALERT_FILTERS = ['FILTER/OPEN_UNREAD', 'FILTER/OPEN']

/* ******************************************************************************
 * Sorting
 * ******************************************************************************/

export const ALERT_SORTS = {
  SORT_STATUS: 'STATUS',
  SORT_TYPE: 'TYPE',
  DATE_DESC: 'DATE_DESC',
  DATE_ASC: 'DATE_ASC',
  LEVEL: 'LEVEL'
}

/* ******************************************************************************
 * URL filtering
 * ******************************************************************************/

// Allow query string to populate urlFilters
export const ALERT_URL_FILTERS = {
  USER_ID: 'user',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  ALERT_TYPE: 'type',
  FILTERS: 'filters'
}

/* ******************************************************************************
 * Alert level/color
 * ******************************************************************************/

export const ALERT_LEVEL_COLORS = {
  0: 'Default',
  1: 'Yellow',
  2: 'Orange',
  3: 'Red'
}
