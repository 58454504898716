import React from 'react'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import { AppFooter } from './Footer'
import { IconButton, Tooltip } from '@mui/material'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import LastPageIcon from '@mui/icons-material/LastPage'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { sidebarSlice } from '../../store/sidebarView'
import { dashboardViewSlice } from '../../store/dashboardView'

export const drawerWidth = 300

function ContentSidebar (props) {
  const sidebarWidth = props.drawerWidth ?? drawerWidth

  const toggleSidebar = () => {
    props.sidebarActions.toggleSidebarOpen()

    // Force any open charts to redraw
    props.dashboardActions.setRangeLoading(true)
    setTimeout(() => props.dashboardActions.setRangeLoading(false), 300)
  }

  const sidebarContent = () => {
    return (
      <Box
        sx={{
          height: '-webkit-fill-available',
          padding: '0 .75rem 1rem .75rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
        data-testid='profile-sidebar'
      >
        <Box>
          {props.children}
        </Box>

        <AppFooter />
      </Box>
    )
  }

  const renderResponsiveSidebar = () => {
    const sidebarOpen = props.sidebarStore.sidebarOpen
    const buttonOnlyWidth = `${props.sidebarStore.buttonOnlyWidth}px`
    const tooltipTitle = sidebarOpen ? 'Close sidebar' : 'Open sidebar'

    return (
      <Drawer
        variant='permanent' open={sidebarOpen}
        sx={{
          width: sidebarOpen ? sidebarWidth : buttonOnlyWidth,
          '& .MuiDrawer-paper': {
            backgroundColor: '#F5F5F5',
            width: sidebarOpen ? sidebarWidth : buttonOnlyWidth,
            boxSizing: 'border-box'
          }
        }}
      >
        <Toolbar sx={{ mb: 1 }} />
        <Box sx={{ justifyContent: 'right', display: 'flex' }}>
          <Tooltip title={tooltipTitle}>
            <IconButton
              size='large'
              aria-label='toggle sidebar open state'
              onClick={toggleSidebar}
              data-testid='toggle-sidbar__button'
            >
              {sidebarOpen && <FirstPageIcon />}
              {!sidebarOpen && <LastPageIcon />}
            </IconButton>
          </Tooltip>
        </Box>
        {sidebarOpen && sidebarContent()}
      </Drawer>
    )
  }

  return renderResponsiveSidebar()
}

function getPropsFromStore (state) {
  return {
    sidebarStore: state.sidebar,
    dashboardStore: state.dashboardView
  }
}

function getPropsFromActions (dispatch) {
  return {
    sidebarActions: bindActionCreators(sidebarSlice.actions, dispatch),
    dashboardActions: bindActionCreators(dashboardViewSlice.actions, dispatch)
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(ContentSidebar)
