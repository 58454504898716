import { createSlice } from '@reduxjs/toolkit'
import { FEATURE_GATES } from '../constants/constants'

/* ********************************************************************************
 * User session store.
 * ********************************************************************************/
const userSessionSlice = createSlice({
  name: 'userSession',
  initialState: {
    activeGroup: undefined,
    isLoggedIn: null,
    isFirstTimeUser: null,
    isClinician: null,
    user: null,
    resources: null,
    loginTarget: null,
    loggingInWithPhone: false,
    sessionExpireTime: null,
    showSessionExpireWarning: false,
    showSessionExpireMessage: false,
    featureGates: {}
  },
  reducers: {
    setActiveGroup: (state, action) => {
      state.activeGroup = action.payload
      state.resources = null
    },
    setResources: (state, action) => {
      state.resources = action.payload
    },
    sessionExpire: (state, _action) => {
      state.showSessionExpireMessage = true
    },
    setLoginTarget: (state, action) => {
      state.loginTarget = action.payload
    },
    setLoggingInWithPhone: (state, action) => {
      state.loggingInWithPhone = action.payload
    },
    setSessionExpireTime: (state, action) => {
      state.sessionExpireTime = action.payload
      state.showSessionExpireWarning = false
    },
    warnSessionExpire: (state, _action) => {
      state.showSessionExpireWarning = true
    },
    updateUser: (state, action) => {
      if (action.payload?.id) {
        state.user = { ...state.user, ...action.payload }
        state.featureGates = getFeatureGates(action.payload)
      }
    },
    setUser: (state, action) => {
      if (action.payload?.id) {
        state.isLoggedIn = true
        state.isClinician = action.payload.clinician
        state.user = action.payload
        state.featureGates = getFeatureGates(action.payload)
        state.showSessionExpireMessage = false
      } else {
        state.isLoggedIn = false
        state.user = null
      }
    }
  }
})

/*
 * Helpers
 */

function getFeatureGates (user) {
  // If non-Ejenta CHF_KP_MAS clinicians are using the app, only show analytics
  const showOnlyAnalytics = (user.group === 'CHF_KP_MAS') && (!user.email.endsWith('ejenta.com'))

  return {
    [FEATURE_GATES.SHOW_ONLY_ANALYTICS]: showOnlyAnalytics
  }
}

/*
 * Exports
 */

export const {
  setUser,
  updateUser,
  setActiveGroup,
  setResources,
  sessionExpire,
  setLoginTarget,
  setLoggingInWithPhone,
  setSessionExpireTime,
  warnSessionExpire
} = userSessionSlice.actions
export { userSessionSlice }
