import _ from 'lodash'
import moment from 'moment'

import { getBaseSeriesOptions, getPointDateString } from './baseChartUtils'
import { getHoursMinutes } from '../baseStringUtils'
import { getColumnWidth } from './chartUtils'

export const baseSleepChartOptions = {
  chart: {
    className: 'sleep-chart',
    spacingTop: 40
  },

  legend: {
    symbolRadius: 0,
    itemMarginTop: 12
  },

  tooltip: {
    useHTML: true
  },

  yAxis: [{
    title: { text: null },

    labels: {
      format: '{value} h'
    },

    maxPadding: 0.1,
    allowDecimals: false
  }],

  series: []
}

export function getSleepDurationSeries (options, memberObj, dashboardViewStore) {
  const newOptions = _.cloneDeep(options)

  const durationMeasurements = _.reverse(_.cloneDeep(memberObj.sleep.data)).map((m) => {
    const point = {
      x: +moment(m.date),
      y: m.duration.value,
      dataSource: m.source
    }
    return point
  })

  const durationSeries = _.extend({
    name: 'Sleep time',
    type: 'column',
    data: durationMeasurements,
    className: 'sleep-series',
    color: '#CAA8EF',

    zIndex: 1,
    pointWidth: getColumnWidth(dashboardViewStore),

    tooltip: {
      headerFormat: null,
      pointFormatter: function format () {
        const { hours, minutes } = getHoursMinutes(this.y)
        const durationStr = `${hours} hr ${minutes} min`
        return `
        <strong>${getPointDateString(moment(this.x), false)}</strong><br/>
        ${durationStr} <br/>
        <small>Source: ${this.dataSource ?? 'Unknown'}</small>
        `
      }
    }
  }, getBaseSeriesOptions())

  newOptions.series.push(durationSeries)
  return newOptions
}
