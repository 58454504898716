import React from 'react'
import HeaderLogo from './HeaderLogo'
import HeaderLogoSecondary from './HeaderLogoSecondary'
import UserNav from '../controllers/UserNav'
import SettingsMenuItem from '../controllers/UserNav/SettingsMenuItem'
import { AppBar, Box, Container, Toolbar } from '@mui/material'
import { useLocation } from 'react-router'

function BasicHeader () {
  return (
    <Box className='header header--basic no-print'>
      {/* <NavLink to='/'> */}
      <HeaderLogo forDesktop />
      {/* </NavLink> */}
      <HeaderLogoSecondary />
    </Box>
  )
}

function AppHeader () {
  const location = useLocation()

  const renderResponsiveAppHeader = () => {
    return (
      <AppBar
        className='no-print'
        position='fixed'
        sx={{
          backgroundColor: '#fcfcfc',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 7%), 0px 1px 10px 0px rgb(0 0 0 / 6%)'
        }}
      >
        <Container maxWidth='false' className='header--responsive'>
          <Toolbar disableGutters sx={{ mt: 0.5, mb: 0.5, pb: 0.5 }}>
            {/* Order is important!  */}
            <HeaderLogo forDesktop isAnalyticsActive={location.pathname === '/analytics'} />
            <UserNav forDesktop={false} />

            <HeaderLogo forDesktop={false} isAnalyticsActive={location.pathname === '/analytics'} />
            <UserNav forDesktop />

            <SettingsMenuItem />
          </Toolbar>
        </Container>
      </AppBar>
    )
  }

  return renderResponsiveAppHeader()
}

export { BasicHeader, AppHeader }
