import React, { useEffect, useState } from 'react'
import restApi from '../../../../api'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { EjentaForm } from '../../../elements/EjentaForm'
import { profileViewSlice, fetchPagingNotes, PROFILE_VIEW } from '../../../../store/profileView'
import { Alert, Box, Button, Card, CardContent, Grid, TextareaAutosize, Typography } from '@mui/material'
import LoadingIndicator from '../../../elements/LoadingIndicator'
import Pagination from '../../../elements/Pagination'
import NoteRow from '../../../elements/Notes/NoteRow'

function NotesView (props) {
  const localStorageID = `newNote_${props.memberObj.user.id}_${props.clinicianId}`
  const [newNote, setNewNote] = useState(window.localStorage.getItem(localStorageID) ?? '')
  const [noteCount, setNoteCount] = useState(props.profileViewStore.selectedAlert?.notes?.length)

  useEffect(() => {
    props.fetchPagingNotes()
  }, [])

  useEffect(() => {
    window.localStorage.setItem(localStorageID, newNote)
  }, [newNote])

  useEffect(() => {
    const selectedAlertNotesCount = props.profileViewStore.selectedAlert?.notes?.length
    if (!selectedAlertNotesCount) return

    if (selectedAlertNotesCount > noteCount) {
      props.fetchPagingNotes()
    }

    setNoteCount(selectedAlertNotesCount)
  }, [props.profileViewStore.selectedAlert])

  const handleNoteInputChange = (e) => {
    setNewNote(e.target.value.slice(0, 1000))
  }

  const handleAddNoteSubmit = (e) => {
    e.preventDefault()
    props.addNote({
      memberId: props.memberObj.user.id,
      clinicianId: props.clinicianId,
      providerContent: newNote.trim()
    })
    setNewNote('')
  }

  const onHandlePagePrev = () => {
    props.storeActions.pagePrev()
    props.fetchPagingNotes()
  }

  const onHandlePageNext = () => {
    props.storeActions.pageNext()
    props.fetchPagingNotes()
  }

  const renderNotes = () => {
    const notes = props.profileViewStore.currentPage
    if (notes) {
      return notes.map((noteDatum) => {
        return (
          <NoteRow
            key={noteDatum.id}
            noteData={noteDatum}
            memberObj={props.memberObj}
          />
        )
      })
    }
    return <Alert severity='error'>Failed to retrieve notes for this member</Alert>
  }

  const renderAddNote = () => {
    const numCharsInNote = newNote.length

    return (
      <EjentaForm onSubmit={handleAddNoteSubmit}>
        <Card sx={{ mt: 1, mb: 1 }}>
          <CardContent sx={{ padding: '.5rem !important', backgroundColor: '#fafafa' }}>
            <Grid container columnSpacing={2}>
              <Grid item sm={10}>
                <Box sx={{ width: '100%' }}>
                  <TextareaAutosize
                    minRows={2}
                    style={{ border: '1px solid #cacaca', width: '-webkit-fill-available', borderRadius: '5px', fontFamily: 'inherit', fontSize: '1.1rem', padding: '1rem' }}
                    data-testid='profile-note__add-note-textarea'
                    className='ejenta-textarea'
                    onChange={handleNoteInputChange}
                    value={newNote}
                    placeholder='Add a note...'
                  />
                </Box>
                <Box sx={{ width: '100%', textAlign: 'end' }} data-testid='profile-note__add-note-count'>
                  <Typography variant='caption'> {numCharsInNote} / 1000 characters </Typography>
                </Box>
              </Grid>
              <Grid item sm={2} className='profile-note__add-note-action'>
                {newNote?.trim() &&
                  <Button variant='contained' type='submit' data-testid='profile-note__add-note-button' onClick={handleAddNoteSubmit}>
                    Add note
                  </Button>}
                {(!newNote?.trim()) &&
                  <Button color='secondary' disabled type='submit'> Add note </Button>}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </EjentaForm>
    )
  }

  const renderNotesView = () => {
    if (props.profileViewStore.isLoading) {
      return <LoadingIndicator sidebarVisible />
    }

    return (
      <div>
        {renderAddNote()}
        {renderNotes()}
        {(props.profileViewStore.currentPage && props.profileViewStore.currentPage.length > 0) &&
          <Pagination
            pageStart={((props.profileViewStore.page - 1) * PROFILE_VIEW.NOTES_PAGE_SIZE) + 1}
            pageEnd={props.profileViewStore.page * PROFILE_VIEW.NOTES_PAGE_SIZE}
            itemCount={props.profileViewStore.totalCount}
            handlePagePrev={onHandlePagePrev}
            handlePageNext={onHandlePageNext}
          />}
      </div>
    )
  }

  return renderNotesView()
}

function getPropsFromStore (state) {
  return {
    profileViewStore: state.profileView,
    clinicianId: state.userSession.user && state.userSession.user.id
  }
}

function getPropsFromActions (dispatch) {
  return {
    storeActions: bindActionCreators(profileViewSlice.actions, dispatch),
    fetchPagingNotes: bindActionCreators(fetchPagingNotes, dispatch),
    addNote: ({ memberId, clinicianId, providerContent }) => {
      dispatch(restApi.actions.addNote({ id: memberId }, {
        body: JSON.stringify({
          author_id: clinicianId,
          provider_content: providerContent
        })
      }))
    }
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(NotesView)
