import React from 'react'
import restApi from '../../../../api'
import SuggestedAddressModal from '../../../elements/SuggestedAddressModal'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { updateMemberSuggestedAddress } from '../../../../store/membersCache'
import { Box, Button, Typography, Alert, Stack } from '@mui/material'
import { EjentaForm, renderField, renderInput } from '../../../elements/EjentaForm'
import { Form } from 'react-final-form'

function SignupAddressComponent (props) {
  const updateAddressToSuggested = () => {
    const { street_address_1: streetAddress1, street_address_2: streetAddress2, city, state, zip_code: zipCode } = props.suggestedAddress
    props.setUserInProgress({ ...props.userInProgress, streetAddress1, streetAddress2, city, state, zipCode })
  }

  const capitalizeAddress = () => {
    const updatedUser = { ...props.userInProgress }
    const { streetAddress1, streetAddress2, city, state, zipCode } = updatedUser
    props.setUserInProgress({ ...updatedUser, ...{ streetAddress1: streetAddress1?.toUpperCase(), streetAddress2: streetAddress2?.toUpperCase(), city: city.toUpperCase(), state: state.toUpperCase(), zipCode: zipCode.toUpperCase() } })
  }

  const handleSubmitAddress = (formData) => {
    props.setUserInProgress({
      ...props.userInProgress,
      streetAddress1: formData.street_address_1,
      streetAddress2: formData.street_address_2,
      city: formData.city,
      state: formData.state,
      zipCode: formData.zip_code
    })

    const submitPromise = new Promise((resolve, reject) => {
      props.apiActions.submitAddress(
        { promise: { resolve, reject } },
        { body: JSON.stringify(formData) }
      )
    })

    return submitPromise.catch(err => err)
  }

  const renderSubmitAddressButton = ({ submitting }) => {
    if (!props.suggestedAddress) {
      const buttonText = submitting ? 'Checking...' : 'Next'
      return <Button type='submit' data-testid='login-form__address__continue-button' disabled={submitting}>{buttonText}</Button>
    }
    return <Button data-testid='login-form__address__next-button' onClick={props.handleIncrementPage}>Next</Button>
  }

  const renderSuggestedAddressModal = () => {
    if (!props.suggestedAddress) return null

    return (
      <SuggestedAddressModal
        suggestedAddress={props.suggestedAddress}
        userInProgress={props.userInProgress}
        onConfirmOriginalAddress={() => { capitalizeAddress(); props.handleIncrementPage() }}
        onAcceptSuggestedAddress={() => { updateAddressToSuggested(); props.handleIncrementPage() }}
        onModalClose={() => { props.updateMemberSuggestedAddress({ data: null }) }}
      />
    )
  }

  const isSuggestedAddressEquivalentToSubmitted = () => {
    const suggestedAddress = props.suggestedAddress

    if (suggestedAddress && props.userInProgress) {
      return (
        suggestedAddress.street_address_1?.toUpperCase() === props.userInProgress.streetAddress1?.toUpperCase() &&
        suggestedAddress.street_address_2?.toUpperCase() === props.userInProgress.streetAddress2?.toUpperCase() &&
        suggestedAddress.city.toUpperCase() === props.userInProgress.city.toUpperCase() &&
        suggestedAddress.state.toUpperCase() === props.userInProgress.state.toUpperCase() &&
        suggestedAddress.zip_code.toUpperCase() === props.userInProgress.zipCode.toUpperCase()
      )
    }

    return false
  }

  const createAddressField = (name, label, value) => {
    return {
      name,
      label,
      type: 'text',
      testId: name,
      initialValue: value,
      component: renderInput
    }
  }

  const renderAddressForm = () => {
    if (props.suggestedAddress && !props.suggestedAddress.error && isSuggestedAddressEquivalentToSubmitted()) {
      updateAddressToSuggested()
      props.handleIncrementPage()
      return null
    }

    const streetAddress1Field = createAddressField('street_address_1', 'Address Line 1', props.userInProgress.streetAddress1)
    const streetAddress2Field = createAddressField('street_address_2', 'Address Line 2', props.userInProgress.streetAddress2)
    const cityField = createAddressField('city', 'City', props.userInProgress.city)
    const stateField = createAddressField('state', 'State', props.userInProgress.state)
    const zipCodeField = createAddressField('zip_code', 'Zip Code', props.userInProgress.zipCode)

    return (
      <Box>
        <Typography variant='h4' mb={3} textAlign='center'>Confirm your shipping address</Typography>
        {renderSuggestedAddressModal()}

        <Box>
          <Typography textAlign='center'>We will ship your free devices (a weight scale and blood pressure monitor) to this address.</Typography>

          <Form
            onSubmit={handleSubmitAddress}
            validate={values => {
              const errors = {}
              if (!values.street_address_1 && !values.street_address_2) errors.street_address_1 = 'Address Line 1 is required'
              if (!values.city) errors.city = 'City is required'
              if (!values.state) errors.state = 'State is required'
              if (!values.zip_code) errors.zip_code = 'Zip Code is required'
              return errors
            }}
          >
            {({ handleSubmit, submitError, submitting }) => (
              <EjentaForm onSubmit={handleSubmit}>
                {submitError && <Alert severity='error'>{submitError}</Alert>}
                <Stack spacing={1}>
                  {renderField({}, streetAddress1Field)}
                  {renderField({ optional: true }, streetAddress2Field)}
                  {renderField({}, cityField)}
                  {renderField({}, stateField)}
                  {renderField({}, zipCodeField)}

                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button color='inherit' onClick={props.handleDecrementPage} sx={{ mr: 1 }}>Back</Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {renderSubmitAddressButton({ submitting })}
                  </Box>
                </Stack>
              </EjentaForm>
            )}
          </Form>

        </Box>
      </Box>
    )
  }

  return renderAddressForm()
}

function getPropsFromStore (state) {
  return {
    suggestedAddress: state.membersCache?.suggestedAddress
  }
}

function getPropsFromActions (dispatch) {
  return {
    apiActions: bindActionCreators(restApi.actions, dispatch),
    updateMemberSuggestedAddress: (data) => {
      dispatch(updateMemberSuggestedAddress(data))
    }
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(SignupAddressComponent)
