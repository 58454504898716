import { FORM_ERROR } from 'final-form'

/* *****************************************************************************
 * Postfetch handlers      * Postfetch handlers
 *
 * The redux-api postfetch option swallows all exceptions thrown while dispatching, so
 * we're wrapping dispatch calls in postfetch() with dispatchWithException().
 * *****************************************************************************/
function dispatchWithException (action, dispatch) {
  dispatch(action)
}

function resolvePromise ({ data, request }) {
  if (!request.pathvars.promise) return

  if (data.error) {
    const errorObj = { [FORM_ERROR]: data.error }
    request.pathvars.promise.reject(errorObj)
    // Prevents the execution of the other postfetch handlers
    throw new Error('Api error')
  } else if (data.errors) { // Server has sent an object containing multiple errors
    const errorString = Object.values(data.errors).join(' ')
    let errorObj = { [FORM_ERROR]: `Something went wrong. ${errorString}` }

    if (request.pathvars.promiseTransformer) {
      errorObj = request.pathvars.promiseTransformer(data.errors)
    }

    request.pathvars.promise.reject(errorObj)
    // Prevents the execution of the other postfetch handlers
    throw new Error('Api error')
  } else {
    request.pathvars.promise.resolve(data)
  }
}

export { dispatchWithException, resolvePromise }
