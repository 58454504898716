import React, { useState } from 'react'
import Modal from './Modal'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

function SuggestedAddressModal (props) {
  const [selectedOriginalAddress, setSelectedOriginalAddress] = useState(false)

  const handleConfirmOriginalAddress = () => {
    props.onConfirmOriginalAddress()
    props.onModalClose()
  }

  const handleAcceptSuggestedAddress = () => {
    props.onAcceptSuggestedAddress()
    props.onModalClose()
  }

  const handleAddressSelectionChanged = (e) => {
    setSelectedOriginalAddress(e.target.value === 'original')
  }

  const renderModal = () => {
    const suggestedAddress = props.suggestedAddress
    const userInProgress = props.userInProgress

    if (!suggestedAddress || !userInProgress) { return null }

    if (suggestedAddress.error) {
      return (
        <Modal
          name='SuggestedAddress'
          maxWidth='sm'
          isOpen
          onModalClose={props.onModalClose}
          modalTitle='Address not found'
        >
          <Box>
            <Typography> We could not find your address. Please go back and edit your information. </Typography>
            <DialogActions>
              <Button variant='contained' onClick={props.onModalClose} data-testid='suggested-address__edit-button'>
                Edit address
              </Button>
            </DialogActions>
          </Box>
        </Modal>
      )
    }

    return (
      <Modal
        name='SuggestedAddress'
        isOpen
        maxWidth='sm'
        onModalClose={props.onModalClose}
        modalTitle='Confirm your address'
      >
        <form>
          <Box sx={{ m: 3 }}>
            <div className='suggested-address__header'><strong>USPS suggested address:</strong></div>
            <div className='suggested-address'>

              <div style={{ display: 'flex', marginTop: '1rem', flexDirection: 'row' }}>
                {/* Note: the name field for radio buttons should be identical, so that clicking another one replaces which one is checked */}
                <input id='usps' type='radio' name='suggestedAddress' value='usps' defaultChecked onChange={handleAddressSelectionChanged} />

                <label htmlFor='usps'>
                  {suggestedAddress.street_address_1 ? <>{suggestedAddress.street_address_1} <br /></> : null}
                  {suggestedAddress.street_address_2 ? <>{suggestedAddress.street_address_2} <br /></> : null}
                  <>{suggestedAddress.city}, {suggestedAddress.state} {suggestedAddress.zip_code}</>
                </label>
              </div>

            </div>
          </Box>

          <Box sx={{ m: 3 }}>
            <div className='suggested-address__header'>You entered:</div>
            <div className='suggested-address'>

              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1rem' }}>
                <input id='original' type='radio' name='suggestedAddress' value='original' onChange={handleAddressSelectionChanged} />

                <label htmlFor='original'>
                  {userInProgress.streetAddress1 ? <>{userInProgress.streetAddress1} <br /></> : null}
                  {userInProgress.streetAddress2 ? <>{userInProgress.streetAddress2} <br /></> : null}
                  <>{userInProgress.city}, {userInProgress.state} {userInProgress.zipCode}</>
                </label>
              </div>

            </div>
            <DialogActions>
              <Button
                variant='contained'
                onClick={selectedOriginalAddress ? handleConfirmOriginalAddress : handleAcceptSuggestedAddress}
                data-testid='suggested-address__use-button'
              >
                Use this address
              </Button>
            </DialogActions>
          </Box>
        </form>
      </Modal>
    )
  }

  return renderModal()
}

export default SuggestedAddressModal
