import _ from 'lodash'
import moment from 'moment'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

/* ******************************************************************************
 *
 * (Clinician experience only)
 * Data for global care plan page
 *
 * ******************************************************************************/
const getCareplanItemData = createAsyncThunk('clinicianCarePlan/updateCarePlanItem', async (params) => {
  const { dispatch, payload } = params
  const { itemId, data } = payload
  const updateTimestamp = moment().valueOf()
  await dispatch(setCarePlanRow({ itemId, data, updateTimestamp, clearUpdateTimestamp: false }))

  // Clear {updatedTime} after two seconds
  return setTimeout(() => {
    dispatch(setCarePlanRow({ itemId, data, updateTimestamp, clearUpdateTimestamp: true }))
  }, 2000)
})

export const updateCarePlanItem = (payload) => {
  return (dispatch) => {
    dispatch(getCareplanItemData({ dispatch, payload }))
  }
}

const initialState = {
  carePlanItems: []
}

const clinicianCarePlanSlice = createSlice({
  name: 'clinicianCarePlan',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCareplanItemData.fulfilled, (state, action) => {
      // todo could prob move the dispatch here from getCareplanItemData
    })

    builder.addCase(getCareplanItemData.rejected, (state, action) => {
      // noop
    })

    builder.addCase(getCareplanItemData.pending, (state, action) => {
      // todo could prob move the dispatch here from getCareplanItemData
    })
  },
  reducers: {
    populateCarePlan: (state, action) => {
      state.carePlanItems = action.payload.data.data
    },
    setCarePlanRow: (state, action) => {
      // Find original care plan item in care plan array
      const itemIndex = _.findIndex(state.carePlanItems, item => item.id === action.payload.itemId)
      const updatedItem = { ...action.payload.data } // Define new care plan item
      let updatedTime = action.payload.updateTimestamp // Set updated time

      if (action.payload.clearUpdateTimestamp) {
        // The care plan item might have been updated in the interim, so only clear this if the
        // timestamps match.
        if (state.carePlanItems[itemIndex].updatedTime === action.payload.updateTimestamp) {
          updatedTime = null
        } else return // Otherwise, don't update state at all
      }

      updatedItem.updatedTime = updatedTime // ITEM and TIME are ANAGRAMS!
      state.carePlanItems[itemIndex] = updatedItem // Update care plan array
    },
    resetCarePlan: (state, action) => {
      state.carePlanItems = []
    }
  }
})

/*
 * Exports
 */
export const {
  populateCarePlan,
  setCarePlanRow,
  resetCarePlan
} = clinicianCarePlanSlice.actions

export { clinicianCarePlanSlice }
