import _ from 'lodash'

import { ALERT_URL_FILTERS, DEFAULT_ALERT_FILTERS } from '../utils/baseAlertUtils'

import {
  ALERTS_VIEW,
  DEFAULT_ALERT_SORT
} from '../utils/alertUtils'

import { getInitialState } from '../utils/baseListUtils'

import restApi from '../api'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import 'regenerator-runtime' // necessary for async operations on thunk

/* ******************************************************************************
 * Redux store for alerts list page
 * ******************************************************************************/
const getData = createAsyncThunk('alertsView/fetchPagingAlerts', async (params) => {
  const { dispatch, getState } = params
  const { searchQuery, sortBy, filters, urlFilters, page, careManagerFilters } = getState().alertsView
  const urlParams = {
    per_page: ALERTS_VIEW.PAGE_SIZE,
    page,
    search_query: searchQuery,
    alert_sorting: sortBy.toLowerCase()
  }

  // Loop through all possible filters
  _.forOwn(ALERTS_VIEW.FILTER_GROUPS, (value, filterType) => {
    const availableFilters = _.map(value.filters, (f) => {
      return f.id
    })
    let enabledFilters = _.intersection(availableFilters, filters)

    enabledFilters = _.map(enabledFilters, (filter) => {
      // e.g. FILTER/ACTIVE => active
      return filter.replace('FILTER/', '').toLowerCase()
    })

    if (filterType === 'ALERT_TYPE' && enabledFilters.includes('multi_tracking')) {
      enabledFilters = enabledFilters.concat(oldTrackingAlertTypes)
    }

    urlParams[`${filterType.toLowerCase()}_filters`] = enabledFilters
  })

  if (careManagerFilters.length) {
    urlParams.care_manager_filters = careManagerFilters
  }

  const additionalUrlParams = _.mapKeys(urlFilters, (value, key) => {
    const transformations = {
      user: 'user',
      start_date: 'start',
      end_date: 'end',
      type: 'strict_alert_type_filter'
    }
    return transformations[key]
  })

  _.merge(urlParams, additionalUrlParams)

  await dispatch(restApi.actions.clinicianAlerts(urlParams))
})

export const fetchPagingAlerts = () => {
  return (dispatch, getState) => {
    dispatch(getData({ dispatch, getState }))
  }
}

// these are used to make the multi_tracking filter able to get old-style tracking alerts in addition to multi_tracking alerts:
const newChatPersistentFilter = 'FILTER/NEW_CHAT'
const oldTrackingAlertTypes = ['weight_tracking', 'bp_tracking', 'survey_tracking', 'bg_tracking']
const initialState = {
  ...getInitialState({
    sortBy: DEFAULT_ALERT_SORT,
    filters: DEFAULT_ALERT_FILTERS
  }),
  careManagerFilters: [],
  carePlanFilters: [],
  careManagerId: 'DEFAULT_OPTION',
  totalFilterOptions: 0,
  loadingAlerts: true,
  searchQueryReset: false,
  total: 0,
  urlFilters: {}
}

const alertsViewSlice = createSlice({
  name: 'alertsView',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.loadingAlerts = false
    })
    builder.addCase(getData.rejected, (state, action) => {
      state.loadingAlerts = false
    })
    builder.addCase(getData.pending, (state, action) => {
      state.loadingAlerts = true
    })
  },
  reducers: {
    setLoadingAlerts: (state, action) => {
      state.loadingAlerts = action.payload
    },
    resetView: (state, action) => {
      state.careManagerFilters = initialState.careManagerFilters
      state.sortBy = initialState.sortBy
      state.filters = [...initialState.filters].sort()
      state.careManagerId = initialState.careManagerId
      state.searchQuery = ''
      state.searchQueryReset = action.payload ?? true
      state.urlFilters = initialState.urlFilters
      state.page = 1
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload
      state.searchQueryReset = false
      state.page = 1
    },
    setSort: (state, action) => {
      state.sortBy = action.payload
      state.page = 1
      state.searchQueryReset = false
    },
    setFilter: (state, action) => {
      if (state.filters.includes(action.payload)) {
        state.filters = _.without(state.filters, action.payload)
        state.page = 1
      } else {
        state.filters = state.filters.concat(action.payload).sort()
        state.page = 1
      }

      state.searchQueryReset = false
    },
    setTotalFilterOptions: (state, action) => {
      state.totalFilterOptions = action.payload
    },
    setCarePlanFilter: (state, action) => {
      const carePlanItems = action.payload
      const prefix = 'FILTER/'
      const presetFilters = [newChatPersistentFilter]
      const hasChatFilter = state.filters?.indexOf(newChatPersistentFilter) >= 0
      let enabledCount = 0

      carePlanItems?.forEach(item => {
        if (!item.disabled && item.notify.indexOf('clinicians') > -1) {
          enabledCount++
          const filterName = prefix + item.type.toUpperCase()
          if (presetFilters.indexOf(filterName) < 0) presetFilters.push(filterName)
        }
      })

      // if all are enabled, or none are disabled, don't bother setting a filter
      if (presetFilters.length > 0 && enabledCount !== carePlanItems?.length) {
        // do nothing at all if the available filters are all enabled
        if (presetFilters.length - 1 < state.totalFilterOptions) {
          state.filters = !hasChatFilter ? state.filters.concat(presetFilters).sort() : state.filters
          state.carePlanFilters = presetFilters
          state.page = 1
        }
      }
    },
    setPage: (state, action) => {
      const maxPage = Math.ceil(state.total / ALERTS_VIEW.PAGE_SIZE)

      if (action.payload < 1) state.page = 1
      else if (action.payload > maxPage) state.page = maxPage
      else state.page = action.payload
    },
    pagePrev: (state, action) => {
      state.page = Math.max(1, state.page - 1)
    },
    pageNext: (state, action) => {
      const maxPage = Math.ceil(state.total / ALERTS_VIEW.PAGE_SIZE)
      state.page = Math.min(maxPage, state.page + 1)
    },
    setCareManagerId: (state, action) => {
      const careManagerId = action.payload
      state.careManagerId = careManagerId

      let careManagerFilters // FUTURE, FOR MUTLIPLE FILTERS = [...state.careManagerFilters]
      if (careManagerId === 'DEFAULT_OPTION' || careManagerId === null) {
        careManagerFilters = []
      } else {
        careManagerFilters = [careManagerId] // PUSH FOR MULTIPLE
      }

      state.careManagerFilters = careManagerFilters
      state.searchQueryReset = false
    },
    setUrlFilters: (state, action) => {
      const applyFilter = (nFilter) => {
        if (state.filters.includes(nFilter)) {
          state.filters = _.without(state.filters, nFilter)
        } else {
          state.filters = state.filters.concat(nFilter).sort()
        }
      }

      const query = Object.fromEntries(new URLSearchParams(action.payload).entries())

      if (query.type) {
        state.filters = [] // if type changes, clear the filters first

        // these fields use a lowercase name (workaround without a reverse lookup)
        const filter = `FILTER/${query.type.toUpperCase()}`
        applyFilter(filter)
      }

      // set checkboxes from the URL using the actual filter name
      if (query.filters) {
        // takes comma separated list of filters
        for (const filter of query.filters.split(',')) {
          applyFilter(filter)
        }
      }

      state.urlFilters = _.pick(query, _.values(ALERT_URL_FILTERS))
      state.page = 1
    },
    populateAlerts: (state, action) => {
      const filterCounts = {}

      _.forOwn(ALERTS_VIEW.FILTER_GROUPS, (value, filterType) => {
        _.forOwn(action.payload.filters[filterType.toLowerCase()], (count, key) => {
          filterCounts[`FILTER/${key.toUpperCase()}`] = count
        })
      })

      state.currentPage = action.payload.alerts
      state.total = action.payload.total
      state.filterCounts = filterCounts
    },
    setSelectedAlert: (state, action) => {
      state.selectedItemId = action.payload
    },
    setLocationKey: (state, action) => {
      state.prevLocationKey = action.payload
    },
    updateAlert: (state, action) => {
      if (state.currentPage) {
        // Find original alert in alert array
        const itemIndex = _.findIndex(state.currentPage, item => item.id === action.payload.alert.id)

        if (itemIndex >= 0) {
          const updatedItem = { ...action.payload.alert } // Define new alert
          state.currentPage[itemIndex] = updatedItem // Update alert array
        }
      }
    }
  }
})

/*
 * Exports
 */
export { ALERTS_VIEW, alertsViewSlice }
export const {
  setLoadingAlerts,
  resetView,
  setSearchQuery,
  setSort,
  setFilter,
  setCareManagerId,
  setCarePlanFilter,
  pagePrev,
  pageNext,
  setPage,
  setUrlFilters,
  populateAlerts,
  setSelectedAlert,
  setLocationKey,
  updateAlert
} = alertsViewSlice.actions
