import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { profileViewSlice } from '../../../../store/profileView'
import { productConfig } from '../../../../config/config-service'
import { Box, Button, Card, CardContent, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import ContentSidebar from '../../../layout/ContentSidebar'
import ProfileAlerts from '../../../pages/clinician/ProfilePage/ProfileAlerts'
import ProfilePregnancyPane from '../../../pages/clinician/ProfilePage/ProfilePregnancyPane'
import CarePlanRows from '../../../elements/CarePlan/CarePlanRows'
import DeviceStatusRows from '../../../elements/Devices/DeviceStatusRows'
import { getProfileDeviceFields, getProfileSidebarFields } from '../../../../utils/member/baseProfileUtils'

function ProfileSidebar (props) {
  const [carePlanItems, setCarePlanItems] = useState([])
  const cardStyle = { minWidth: 260, marginBottom: '1rem' }
  const deviceFields = getProfileDeviceFields()
  const sidebarFields = getProfileSidebarFields()

  useEffect(() => {
    setCarePlanItems(props.careplan)
  }, [props.careplan])

  const renderProfileFields = (userObj) => {
    if (!userObj) return null

    const fields = sidebarFields.map((f, fIndex) => {
      const profileField = f.accessor(userObj, props.careManagers)
      const shouldDisplay = (f.isSubfield && profileField) || !f.isSubfield
      const displayVariant = f.isSubfield ? 'caption' : 'body2'
      return (
        <React.Fragment key={fIndex}>
          {shouldDisplay &&
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant={displayVariant} sx={{ ml: f.isSubfield ? 2 : 0 }}>{f.fieldDisplayStr}: </Typography>
              <Typography variant='body1' fontWeight={400} ml={1}>{profileField}</Typography>
            </Box>}
        </React.Fragment>
      )
    })

    return (
      <div> {fields} </div>
    )
  }

  const renderCareplan = () => {
    return (
      <CarePlanRows
        carePlanItems={carePlanItems}
        shouldRenderCondensed
      />
    )
  }

  const renderDevicesStatus = (deviceFields) => {
    return (
      <DeviceStatusRows memberObj={props.memberObj} fields={deviceFields} shouldRenderCondensed />
    )
  }

  const renderCustomProfileModule = () => {
    return (
      <Card sx={cardStyle}>
        <CardContent>
          <Typography
            variant='h6'
            component='div'
            gutterBottom
            color='primary'
            marginBottom='1rem'
            sx={{ justifyContent: 'space-between', display: 'flex' }}
          >
            <Box>Pregnancy</Box>
            <Button
              size='small'
              variant='outlined'
              color='primary'
              data-testid='profile-sidebar__edit-pregnancy-button'
              onClick={() => props.profileViewActions.setOpenModal({ name: 'editMemberProfile', tabLabel: 'pregnancy' })}
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          </Typography>
          <Box>
            <ProfilePregnancyPane variant='narrow' memberObj={props.memberObj} />
          </Box>
        </CardContent>
      </Card>
    )
  }

  const renderProfileModule = () => {
    return (
      <Card sx={cardStyle} className='profile-sidebar'>
        <CardContent>
          <Typography
            variant='h6'
            component='div'
            gutterBottom
            color='primary'
            marginBottom='1rem'
            sx={{ justifyContent: 'space-between', display: 'flex' }}
          >
            <Box>Profile</Box>
            <Button
              size='small'
              variant='outlined'
              color='primary'
              data-testid='profile-sidebar__edit-profile-button'
              onClick={() => props.profileViewActions.setOpenModal({ name: 'editMemberProfile', tabLabel: 'profile' })}
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          </Typography>
          <Box>
            {renderProfileFields(props.memberObj.user)}
          </Box>
        </CardContent>
      </Card>
    )
  }

  const renderCareplanModule = () => {
    return (

      <Card sx={cardStyle}>
        <CardContent>
          <Typography
            variant='h6'
            component='div'
            gutterBottom
            color='primary'
            marginBottom='1rem'
            sx={{ justifyContent: 'space-between', display: 'flex' }}
          >
            <Box>Care plan</Box>
            <Button
              variant='outlined'
              size='small'
              color='primary'
              onClick={() => props.profileViewActions.setOpenModal({ name: 'editMemberCarePlan' })}
              data-testid='profile-sidebar__edit-careplan-button'
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          </Typography>

          <Box>
            <Typography variant='overline'>Send alerts when...</Typography>
            {renderCareplan()}
          </Box>
        </CardContent>
      </Card>
    )
  }

  const renderDevicesModule = () => {
    if (deviceFields?.length) {
      return (

        <Card sx={cardStyle}>
          <CardContent>
            <Typography
              variant='h6'
              component='div'
              gutterBottom
              color='primary'
              marginBottom='1rem'
              sx={{ justifyContent: 'space-between', display: 'flex' }}
            >
              <Box>Devices & Data</Box>
              <Button
                variant='outlined'
                size='small'
                color='primary'
                onClick={() => props.profileViewActions.setOpenModal({ name: 'editMemberDevices' })}
                data-testid='profile-sidebar__edit-devices-button'
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
            </Typography>

            <Box>
              {renderDevicesStatus(deviceFields)}
            </Box>
          </CardContent>
        </Card>
      )
    }
  }

  const renderProfileSidebar = () => {
    return (
      <ContentSidebar>
        <ProfileAlerts user={props.memberObj.user} cardStyle={cardStyle} />

        {productConfig().profile.showPregnancyInfo && renderCustomProfileModule()}
        {renderProfileModule()}
        {renderCareplanModule()}
        {renderDevicesModule()}
      </ContentSidebar>
    )
  }

  return renderProfileSidebar()
}

function getPropsFromActions (dispatch) {
  return {
    profileViewActions: bindActionCreators(profileViewSlice.actions, dispatch)
  }
}

export default connect(null, getPropsFromActions)(ProfileSidebar)
