/* eslint-disable object-shorthand */
import _ from 'lodash'

import {
  getBaseChartOptions,
  addPausePlotBands,
  getSimpleContinuousSeries,
  getSimpleBioStickerAlertsSeries,
  getBaselineContinuousSeries
} from './baseChartUtils'
import {
  getXAxisForRange
} from './chartUtils'

export const baseTemperatureChartOptions = {
  chart: { className: 'temperature-chart' },

  legend: {
    symbolRadius: 0,
    itemMarginTop: 12
  },

  tooltip: {
    useHTML: true,
    padding: 12,
    pointFormat: '<div>{point.description}</div>'
  },

  plotOptions: {
    line: {
      marker: { enabled: false }
    }
  },

  yAxis: [{
    title: { text: '' },
    labels: { format: '{value}' }
  }],

  series: []
}

/* ********************************************************************************
 * Chart options
 * ********************************************************************************/

export function getFullChartOptions (memberObj, dashboardViewStore, showMemberAlerts, handleAlertClick) {
  const xAxisOptions = { xAxis: getXAxisForRange(memberObj, dashboardViewStore) }

  let options = _.merge(
    getBaseChartOptions(),
    baseTemperatureChartOptions,
    xAxisOptions
  )

  options = getSimpleContinuousSeries(options, memberObj, dashboardViewStore, 'temperature', 'Skin temperature',
    memberObj.temperature.information.units.temperature)

  if (showMemberAlerts) {
    options = getSimpleBioStickerAlertsSeries(memberObj, options, handleAlertClick, 'temperature', 'Temperature')
  }

  if (memberObj.temperature.baseline) {
    options = getBaselineContinuousSeries(options, memberObj, dashboardViewStore, 'temperature', 'Baseline skin temperature',
      memberObj.temperature.information.units.temperature)
  }

  options = addPausePlotBands(memberObj.user.pauses, options, true)

  return options
}
