import _ from 'lodash'
import baseChartOptions from './baseChartOptions'

function getGenderCounts (users) {
  return {
    female: _.filter(users, u => u.info.gender === 'FEMALE'),
    male: _.filter(users, u => u.info.gender === 'MALE')
  }
}

function getGenderChartOptions (users, analyticsActions) {
  const genderCounts = getGenderCounts(users)

  const genderChartOptions = {
    chart: {
      type: 'pie'
    },

    plotOptions: {
      pie: {
        states: {
          hover: {
            brightness: 0,
            halo: {
              opacity: 0.1
            }
          }
        }
      }
    },

    series: [{
      name: 'Members',
      data: [{
        name: 'Female',
        y: genderCounts.female.length,
        sliced: true,
        events: {
          click: () => {
            analyticsActions.setDrilldown({
              title: 'Sex: Female',
              type: 'member',
              items: genderCounts.female
            })
          }
        }
      }, {
        name: 'Male',
        y: genderCounts.male.length,
        events: {
          click: () => {
            analyticsActions.setDrilldown({
              title: 'Sex: Male',
              type: 'member',
              items: genderCounts.male
            })
          }
        }
      }],
      tooltip: {
        headerFormat: null,
        pointFormatter: function format () {
          return `
            <span class="ax-text-bold">${this.name}</span><br/>
            Members: <em>${this.y}</em>
          `
        }
      },
      cursor: 'pointer'
    }]
  }

  return _.merge(_.cloneDeep(baseChartOptions), genderChartOptions)
}

export default getGenderChartOptions
