import React from 'react'
import MultiMetricTrackingItem from '../TrackingItems/MultiMetricTrackingItem'

export default function MissingDataCard (props) {
  return (
    <div className='care-plan__section' data-testid='care-plan__section__empty'>
      <div className='care-plan__section-header'>Missing Data</div>
      <MultiMetricTrackingItem {...props} />
    </div>
  )
}
