import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { clinicianCarePlanSlice } from '../../../../store/clinicianCarePlan'
import {
  ContentMain,
  ContentMainIntro,
  ContentMainIntroHeader,
  ContentMainBody
} from '../../../layout/ContentMain'
import { Alert } from '@mui/material'
import restApi from '../../../../api'
import LoadingIndicator from '../../../elements/LoadingIndicator'
import SettingsSidebar from '../../../layout/SettingsSidebar'
import CarePlanHeader from '../../../elements/CarePlan/CarePlanHeader'
import CarePlanRows from '../../../elements/CarePlan/CarePlanRows'

/* *****************************************************************************
 * Default care plan page (clinician-only)
 * *****************************************************************************/
function ClinicianCarePlanPage (props) {
  const [carePlanItems, setCarePlanItems] = useState([])

  useEffect(() => {
    props.storeActions.resetCarePlan()
    props.apiActions.getDefaultCarePlan()
  }, [])

  useEffect(() => {
    setCarePlanItems(props.clinicianCarePlanStore)
  }, [props.clinicianCarePlanStore])

  const renderCarePlanPage = () => {
    if (!props.defaultCarePlanApi.sync || !props.clinicianCarePlanStore || !props.clinicianCarePlanStore.length) {
      return (
        <LoadingIndicator />
      )
    }

    return (
      <>
        <SettingsSidebar />

        <ContentMain drawerWidth={220}>

          <ContentMainIntro>
            <ContentMainIntroHeader title='Group care plan' />
            <Alert severity='info' sx={{ mt: 1, mb: 2 }}>
              Updating the group care plan will update the default care plan for <strong>all</strong> current members.
            </Alert>
            <CarePlanHeader isDefault />
          </ContentMainIntro>

          <ContentMainBody>
            <CarePlanRows
              isDefault
              carePlanItems={carePlanItems}
              updateCarePlan={props.apiActions.updateDefaultCarePlan}
            />

          </ContentMainBody>
        </ContentMain>
      </>
    )
  }

  return renderCarePlanPage()
}

function getPropsFromStore (state) {
  return {
    clinicianCarePlanStore: state.clinicianCarePlan.carePlanItems,
    defaultCarePlanApi: state.api.getClinicianDefaultCarePlan
  }
}

function getPropsFromActions (dispatch) {
  return {
    storeActions: bindActionCreators(clinicianCarePlanSlice.actions, dispatch),
    apiActions: {
      getDefaultCarePlan: () => {
        dispatch(restApi.actions.getClinicianDefaultCarePlan())
      },
      updateDefaultCarePlan: (body) => {
        dispatch(restApi.actions.updateClinicianDefaultCarePlan({}, { body: JSON.stringify(body) }))
      }
    }
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(ClinicianCarePlanPage)
