import React from 'react'
import Loader from 'react-loaders'
import classnames from 'classnames'

/* *****************************************************************************
 * Simple loading indicator element.
 * *****************************************************************************/
function LoadingIndicator (props) {
  const classNames = classnames('loading-indicator', {
    'loading-indicator--sidebar-visible': props.sidebarVisible
  })
  return (
    <div className={classNames}>
      <Loader type='ball-scale-ripple' active />
    </div>
  )
}

export default LoadingIndicator
