import _ from 'lodash'

import {
  getEmptyChart,
  getBaseChartOptions,
  addPausePlotBands
} from './baseChartUtils'

import {
  baseWeightChartOptions,
  getWeightMeasurementsSeries,
  getWeightAlertsSeries,
  getDeletedWeightMeasurementsSeries,
  get2LbRangeSeries,
  get5LbLastWeekSeries
} from './baseWeightChart'
import { RANGE_VIEWS } from '../member/dateUtils'
import { getMarkerRadius, getXAxisForRange } from './chartUtils'

function getFullChartOptions ({
  memberObj, dashboardViewStore, isMobile, showMemberAlerts,
  handleAlertClick, handleDeleteWeight, handleRestoreWeight
}) {
  let options = _.merge(getBaseChartOptions(isMobile), baseWeightChartOptions)

  // Define x-axis
  options.xAxis = getXAxisForRange(memberObj, dashboardViewStore)

  // Define series
  options = getWeightMeasurementsSeries(options, memberObj, dashboardViewStore, handleDeleteWeight)
  options = getDeletedWeightMeasurementsSeries(options, memberObj, dashboardViewStore, handleRestoreWeight, getMarkerRadius)
  options = get2LbRangeSeries(memberObj, options)

  if (dashboardViewStore.rangeZoom === RANGE_VIEWS.WEEK) {
    options = get5LbLastWeekSeries(memberObj, options)
  }

  if (showMemberAlerts) options = getWeightAlertsSeries(memberObj, options, handleAlertClick)

  options = addPausePlotBands(memberObj.user.pauses, options, false)

  return options
}

function getWeightChartOptions (args) {
  if (!args?.memberObj?.weight) return getEmptyChart()

  return getFullChartOptions(args)
}

export default getWeightChartOptions
