import React from 'react'
import moment from 'moment'
import { english as ordinal } from 'ordinal'
import { getDisplayGestationalWeeks } from '../../../utils/baseStringUtils'
import { getTrimesterOfPregnancy } from '../../../utils/baseDateUtils'

function PregnancyProfileDetail (props) {
  if (props.user.delivery_date) {
    return <span>Delivered {moment(props.user.delivery_date).format('M/D/YY')}</span>
  } else if (props.user.archived_since) {
    return <span>Archived {moment(props.user.archived_since).format('M/D/YY')}</span>
  }

  let detailStr = props.detailStr
  if (!detailStr) {
    detailStr = getDisplayGestationalWeeks(props.user)
  }

  if (props.memberObj) {
    // Trimester ends up only being shown on the members page, not alerts page, to avoid extra plumbing
    const trimesterStr = `${ordinal(getTrimesterOfPregnancy(props.memberObj))} trimester`

    return <span>{detailStr} &middot; {trimesterStr}</span>
  }

  return <span>{detailStr}</span>
}

export default PregnancyProfileDetail
