import _ from 'lodash'
import React, { useEffect } from 'react'
import restApi from '../../../../api'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { alertsViewSlice, fetchPagingAlerts } from '../../../../store/alertsView'
import { ALERTS_VIEW } from '../../../../utils/alertUtils'
import {
  ALERT_URL_FILTERS,
  DEFAULT_ALERT_FILTERS
} from '../../../../utils/baseAlertUtils'
import { getDisplayName } from '../../../../utils/baseStringUtils'
import {
  ContentMain,
  ContentMainIntro,
  ContentMainIntroHeader,
  ContentMainBody
} from '../../../layout/ContentMain'
import LoadingIndicator from '../../../elements/LoadingIndicator'
import Pagination from '../../../elements/Pagination'

import RefineAlertsSidebar from '../../../pages/clinician/AlertsPage/RefineAlertsSidebar'
import AlertRow from '../../../pages/clinician/AlertsPage/AlertRow'
import AlertDetailModal from '../../../pages/clinician/AlertsPage/AlertDetailModal'
import { humanizeAlertType } from '../../../../constants/strings'
import { productConfig } from '../../../../config/config-service'
import { Typography, Box, Grid, Alert, Button } from '@mui/material'
import { useLocation, useNavigate } from 'react-router'

function ClinicianAlertsPage (props) {
  const navigate = useNavigate()
  const location = useLocation()
  const isChatPage = location.search?.indexOf('new_chat') > -1

  const presetCarePlanItems = () => {
    const { carePlanItems } = props
    if (!isChatPage && carePlanItems?.length) {
      props.alertsViewActions.setCarePlanFilter(carePlanItems)
    }

    props.fetchPagingAlerts()
  }

  useEffect(() => {
    if (props.alertsViewStore.searchQueryReset) presetCarePlanItems()
  }, [props.alertsViewStore.searchQueryReset])

  // Preset filters base on the carePlanItems enabled
  useEffect(() => {
    if (props.carePlanItems.length) presetCarePlanItems()
  }, [props.carePlanItems])

  // component did mount
  useEffect(() => {
    if (!isChatPage) {
      const { carePlanItems } = props
      if (carePlanItems?.length > 0) props.alertsViewActions.setCarePlanFilter(carePlanItems)
      else props.getDefaultCarePlan()
    }

    props.alertsViewActions.setTotalFilterOptions(ALERTS_VIEW.FILTER_GROUPS.ALERT_TYPE.filters?.length)
    props.alertsViewActions.setUrlFilters(location.search)
  }, [])

  const handleClearSearch = () => {
    // Resets the search query, sorts, and filters
    props.alertsViewActions.resetView()
    navigate({ query: {} })
    props.fetchPagingAlerts()
  }

  const handleAlertDetailModalOpen = (alertObj) => {
    props.alertsViewActions.setSelectedAlert(alertObj.id)
  }

  const handleAlertDetailModalClose = () => {
    props.alertsViewActions.setSelectedAlert(null)
  }

  const onHandlePagePrev = () => {
    props.alertsViewActions.pagePrev()
    props.fetchPagingAlerts()
  }

  const onHandlePageNext = () => {
    props.alertsViewActions.pageNext()
    props.fetchPagingAlerts()
  }

  const onSetPage = (value) => {
    props.alertsViewActions.setPage(value)
    props.fetchPagingAlerts()
  }

  const renderCurrentQuery = () => {
    // only display the information box when the filter comes from the URL
    const urlFilters = props.alertsViewStore.urlFilters ?? {}
    if (!location.search) return null

    const filters = props.alertsViewStore.filters
    let filterStr
    if (filters) {
      if (filters.includes('FILTER/WITH_NOTE')) filterStr = <strong> with notes </strong>
      // add more descriptions for more filters
    }

    let userQueryStr
    if (urlFilters[ALERT_URL_FILTERS.USER_ID] && props.alertsViewStore.currentPage?.length > 0) {
      const filterUser = getDisplayName(props.alertsViewStore.currentPage[0].user)
      userQueryStr = <span> for <strong>{filterUser}</strong></span>
    }

    let dateQueryStr
    const startDate = urlFilters[ALERT_URL_FILTERS.START_DATE]
    const endDate = urlFilters[ALERT_URL_FILTERS.END_DATE]
    if (startDate && endDate) {
      if (startDate === endDate) {
        dateQueryStr = <span> based on data from <strong>{startDate}</strong></span>
      } else {
        dateQueryStr = <span> based on data from <strong>{startDate}</strong> to <strong>{endDate}</strong></span>
      }
    } else if (startDate) {
      dateQueryStr = <span> based on data from after <strong>{startDate}</strong></span>
    } else if (endDate) {
      dateQueryStr = <span> based on data from before <strong>{endDate}</strong></span>
    }

    let typeQueryStr
    const filterType = urlFilters[ALERT_URL_FILTERS.ALERT_TYPE]
    if (filterType && filters.includes(`FILTER/${filterType.toUpperCase()}`)) {
      typeQueryStr = <span> about <strong>{humanizeAlertType(filterType).toLowerCase()}</strong></span>
    }

    return (
      <Alert
        sx={{ mt: 1, mb: 1 }}
        severity='warning'
        action={<Button color='inherit' size='small' onClick={handleClearSearch}> Reset Filters </Button>}
      >
        Showing all alerts{filterStr}{userQueryStr}{dateQueryStr}{typeQueryStr}.&nbsp;
      </Alert>
    )
  }

  const renderIntro = () => {
    if (props.alertsViewStore.total === 0 || props.alertsViewStore.loadingAlerts) {
      return null
    }

    return (
      <Box>
        <Pagination
          pageStart={props.pageStart + 1}
          pageEnd={props.pageEnd}
          itemCount={props.alertsViewStore.total}
          handlePagePrev={onHandlePagePrev}
          handlePageNext={onHandlePageNext}
          setPage={onSetPage}
          page={props.alertsViewStore.page}
          custom
        />
        {renderCurrentQuery()}
        {renderHeaderRow()}
      </Box>
    )
  }

  const renderHeaderRow = () => {
    return (
      <Box sx={{
        flexGrow: 1,
        fontWeight: '500',
        borderBottom: '1px dotted lightgray'
      }}
      >
        <Grid container columnSpacing={3}>
          <Grid item xs={productConfig().alerts.mainPage.memberNameColumnSize} />
          <Grid item container xs>
            {productConfig().alerts.mainPage.alertData.map(item =>
              <Grid item sx={{ overflow: 'hidden' }} key={item.header} xs={item.columnSize}>
                <Typography variant='subtitle1' fontWeight={400}>{item.header}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    )
  }

  const renderAlertRows = () => {
    return props.alertsViewStore.currentPage.map((a, i) =>
      <Box key={i}>
        <AlertRow
          key={a.id}
          alertObj={a}
          handleAlertDetailModalOpen={() => handleAlertDetailModalOpen(a)}
        />
      </Box>
    )
  }

  const renderBody = () => {
    if (props.alertsViewStore.loadingAlerts) {
      return <LoadingIndicator sidebarVisible />
    }

    if (props.alertsViewStore.total === 0 &&
      _.isEqual(props.alertsViewStore.filters, DEFAULT_ALERT_FILTERS) &&
      !props.alertsViewStore.searchQuery) {
      return (
        <div className='clinician-alerts__message'>
          There are no alerts.
        </div>
      )
    } else if (props.alertsViewStore.total === 0) {
      return (
        <Alert
          sx={{ mt: 1 }}
          severity='warning'
          action={<Button color='inherit' size='small' onClick={() => handleClearSearch()}> Reset Filters </Button>}
        >
          No alerts matched your search.
        </Alert>
      )
    }

    return (
      <Box>
        {renderAlertRows()}

        <Pagination
          pageStart={props.pageStart + 1}
          pageEnd={props.pageEnd}
          itemCount={props.alertsViewStore.total}
          handlePagePrev={onHandlePagePrev}
          handlePageNext={onHandlePageNext}
          page={props.alertsViewStore.page}
          setPage={onSetPage}
          custom
        />

      </Box>
    )
  }

  const renderClinicianAlertsPage = () => {
    if (!props.clinicianAlertsApi.sync) {
      return <LoadingIndicator sidebarVisible />
    }

    const selectedAlert = props.alertsViewStore.currentPage.find(
      a => a.id === props.alertsViewStore.selectedItemId
    )

    return (
      <>
        <AlertDetailModal
          selectedAlert={selectedAlert}
          onModalClose={handleAlertDetailModalClose}
        />

        <RefineAlertsSidebar
          resetView={handleClearSearch}
          alertsViewStore={props.alertsViewStore}
        />

        <ContentMain drawerWidth={285}>
          <ContentMainIntro>
            <ContentMainIntroHeader title='Alerts' />
            {renderIntro()}
          </ContentMainIntro>

          <ContentMainBody>
            {renderBody()}
          </ContentMainBody>
        </ContentMain>
      </>
    )
  }

  return renderClinicianAlertsPage()
}
function getPropsFromStore (state) {
  return {
    activeGroup: state.userSession.activeGroup,
    carePlanItems: state.clinicianCarePlan?.carePlanItems,
    clinicianId: state.userSession.user && state.userSession.user.id,
    clinicianAlertsApi: state.api.clinicianAlerts,
    alertsViewStore: state.alertsView,
    pageStart: (state.alertsView.page - 1) * ALERTS_VIEW.PAGE_SIZE,
    pageEnd: state.alertsView.page * ALERTS_VIEW.PAGE_SIZE
  }
}

function getPropsFromActions (dispatch) {
  return {
    alertsViewActions: bindActionCreators(alertsViewSlice.actions, dispatch),
    fetchPagingAlerts: bindActionCreators(fetchPagingAlerts, dispatch),
    getDefaultCarePlan: () => {
      dispatch(restApi.actions.getClinicianDefaultCarePlan())
    }
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(ClinicianAlertsPage)
