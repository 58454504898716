import React from 'react'
import Tooltip from './Tooltip'

import { FormGroup, Checkbox, FormControlLabel, Select, MenuItem, FormControl, OutlinedInput, FormHelperText, InputAdornment, TextField, Typography, Radio } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Field } from 'react-final-form'

export function EjentaForm (props) {
  return (
    <form method='post' {...props}>
      {props.children}
    </form>
  )
}

export function renderField (config, options) {
  return <Field optional={config.optional} {...options} />
}

/* *****************************************************************************
 * Render methods for forms decorated with react final form
 * *****************************************************************************/
function renderTooltip (tooltip) {
  if (!tooltip) return null
  const id = `rendered__tooltip__${Date.now()}`

  return (
    <Tooltip tooltipId={id} content={tooltip} />
  )
}

function renderRequiredIndicator (optional) {
  if (optional) return null

  return (
    <span><em>*</em></span>
  )
}

export function renderDateInput ({ input, label, optional, disabled, dateFormat, testId, meta: { error, submitError }, tooltip }) {
  const value = input.value !== '' ? input.value : null
  const helperText = value ? (error ?? submitError) : ''
  return (
    <FormControl fullWidth size='small' error={!!error} variant='outlined'>
      {label && <Typography variant='body1'>{label} {!disabled && renderRequiredIndicator(optional)} {renderTooltip(tooltip)}</Typography>}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          {...input}
          value={value}
          disabled={disabled}
          PopperProps={{ placement: 'bottom-start' }}
          onChange={(dateAsMoment) => { input.onChange(dateAsMoment?.format(dateFormat)) }}
          renderInput={(params) => <TextField size='small' {...params} id={input.name} name={input.name} data-testid={testId} helperText={helperText} />}
        />
      </LocalizationProvider>
    </FormControl>
  )
}

/*
 * Radio input
 */
export function renderRadioInput ({ input, label, testId }) {
  return (
    <FormControlLabel
      key={input.value}
      value={input.value}
      label={label}
      checked={input.checked}
      data-testid={testId}
      onClick={() => input.onChange(input.value)}
      control={<Radio data-testid={testId} />}
    />
  )
}

/*
 * Checkbox input
 */
export function renderCheckboxInput ({ input, label, testId, meta: { error, touched, submitError } }) {
  const checkboxControl = (
    <Checkbox
      checked={input.checked}
      name={input.name}
      required={input.required}
      data-testid={testId}
      onChange={() => input.onChange(!input.checked)}
      sx={{ padding: 0, '& .MuiSvgIcon-root': { fontSize: 25 } }}
    />
  )
  const labelControl = <Typography className='no-underline' variant='body1' htmlFor={input.name} dangerouslySetInnerHTML={{ __html: label }} />

  return (
    <FormGroup sx={{ ml: 1 }}>
      <FormControlLabel sx={{ '& .MuiTypography-root': { fontSize: 15, fontWeight: 'light' } }} control={checkboxControl} label={labelControl} />
      {touched && (error || submitError) && <FormHelperText id='form__error-text' error>{error ?? submitError}</FormHelperText>}
    </FormGroup>
  )
}

/*
 * Text input
 */
export function renderInput ({ input, label, optional, disabled, placeholder, testId, meta: { error, submitError, touched }, tooltip }) {
  return (
    <FormControl fullWidth size='small' error={!!error && touched} variant='outlined'>
      {label && <Typography variant='body1'>{label} {!disabled && renderRequiredIndicator(optional)} {renderTooltip(tooltip)}</Typography>}

      <OutlinedInput {...input} id={input.name} data-testid={testId} disabled={disabled} placeholder={placeholder} type={input.type} />
      {touched && (error || submitError) && <FormHelperText id='form__error-text' error>{error ?? submitError}</FormHelperText>}
    </FormControl>
  )
}

/*
 * Text input with units
 */
export function renderInputWithUnit ({
  input, label, optional,
  type, meta: { error, touched, submitError },
  unit, minValue, maxValue, step
}) {
  return (
    <FormControl fullWidth size='small' error={!!error} variant='outlined'>
      {label && <Typography variant='body1'>{label} {renderRequiredIndicator(optional)}</Typography>}

      <OutlinedInput
        {...input} id={input.name} type='number'
        min={minValue}
        max={maxValue}
        step={step}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        endAdornment={<InputAdornment position='end'>{unit}</InputAdornment>}
      />
      {touched && (error || submitError) && <FormHelperText id='form__error-text' error>{error ?? submitError}</FormHelperText>}
    </FormControl>
  )
}

/*
 * Dropdown input
 */
export function renderSelect ({ input, label, optional, meta: { error, touched, submitError }, options, testId, onChange }) {
  const selectOptions = Object.keys(options).map(key => {
    return { key, value: options[key] }
  })
  const selectedOption = selectOptions.find(option => option.key === input?.value)?.key ?? ''
  const handleChange = (e) => {
    if (onChange) onChange(e.target.value)
    input.onChange(e)
  }
  return (
    <div>
      <FormControl fullWidth size='small' error={!!error}>
        {label && <Typography variant='body1'>{label} {renderRequiredIndicator(optional)}</Typography>}

        <Select value={selectedOption} onChange={handleChange} id={input.name} data-testid={testId}>
          {optional && <MenuItem value='' />}
          {selectOptions.map(item => <MenuItem key={item.key} value={item.key}>{item?.value}</MenuItem>)}
        </Select>
        {touched && (error || submitError) && <FormHelperText id='form__error-text' error>{error ?? submitError}</FormHelperText>}
      </FormControl>
    </div>
  )
}
