import _ from 'lodash'
import baseChartOptions from './baseChartOptions'
import { getSimpleDisplayName } from '../../js/utils/baseStringUtils'
import { useNavigate } from 'react-router'

export default function getSleepChartOptions (users) {
  const navigate = useNavigate()
  const usersWithData = _.filter(users, u => !!u.sleep.avg_nightly_total_sleep)

  const chartOptions = {
    chart: {
      type: 'scatter'
    },

    legend: {
      enabled: false
    },

    xAxis: {
      title: {
        text: 'Average sleep length (hrs)'
      },
      minPadding: 0.2,
      maxPadding: 0.2
    },

    yAxis: {
      title: {
        text: 'Average sleep disruptions (#)'
      }
    },

    series: [{
      className: 'series-0',
      marker: {
        radius: 5
      },

      data: _.map(usersWithData, u => ({
        className: 'ax-chart-clickable',

        x: u.sleep.avg_nightly_total_sleep,
        y: u.sleep.avg_nightly_disruptions,
        name: getSimpleDisplayName(u.info),

        events: {
          click: () => {
            navigate(`/profile/${u.id}`)
          }
        }
      })),

      tooltip: {
        headerFormat: null,
        pointFormatter: function format () {
          return `
            <span class="ax-text-bold">${this.name}</span><br/>
            Avg. sleep length: <em>${this.x} hours</em><br/>
            Avg. nightly disruptions: <em>${this.y}</em><br/>
          `
        }
      }
    }]
  }

  return _.merge(_.cloneDeep(baseChartOptions), chartOptions)
}
