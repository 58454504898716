import React, { useEffect, useState } from 'react'
import NoteRow from '../../../elements/Notes/NoteRow'
import { TextareaAutosize, Button, Typography, Box, Stack } from '@mui/material'

function AlertDetailModalNotes (props) {
  const localStorageID = `alertNote_${props.alertObj.id}_${props.clinicianId}`
  const [alertNote, setAlertNote] = useState(window.localStorage.getItem(localStorageID) ?? '')

  useEffect(() => {
    window.localStorage.setItem(localStorageID, alertNote)
  }, [alertNote])

  const handleNoteInputChange = (e) => {
    setAlertNote(e.target.value.slice(0, 1000))
  }

  const handleAddNoteSubmit = (e) => {
    // TODO: this should not clear alertNote if the request failed.
    props.onAddNote(alertNote.trim())
    setAlertNote('')
  }

  const renderAddNote = () => {
    const numCharsInNote = alertNote.length
    const hasValidNote = !!alertNote?.trim()

    return (
      <Box className='alert-detail__notes__add-note'>
        <TextareaAutosize
          minRows={4}
          style={{ width: '-webkit-fill-available', border: '1px solid #cacaca', borderRadius: '5px', fontFamily: 'inherit', fontSize: '1.1rem', padding: '1rem' }}
          data-testid='alert-detail__notes__textarea'
          className='ejenta-textarea'
          onChange={handleNoteInputChange}
          value={alertNote}
          placeholder='Add a note...'
        />

        <div className='alert-detail__notes__add-note-action'>
          <Typography variant='caption' mr={1}> {numCharsInNote} / 1000 characters </Typography>

          <Button
            variant='contained'
            size='small'
            data-testid='alert-detail__notes__add-button'
            onClick={handleAddNoteSubmit}
            disabled={!hasValidNote}
          >
            Add note
          </Button>
        </div>
      </Box>
    )
  }

  const renderNotes = () => {
    const sortedNotes = props.alertObj.notes.slice(0)
    sortedNotes.sort((a, b) => {
      if (a.timestamp > b.timestamp) return -1
      else if (b.timestamp > a.timestamp) return 1
      return 0
    })

    return (
      <Stack sx={{ height: '20rem', overflowY: 'auto' }} p={1} spacing={1}>
        {sortedNotes.map((note) => {
          return (
            <NoteRow noteData={note} memberObj={props.memberObj} hideActions key={note.note_id} />
          )
        })}
      </Stack>
    )
  }

  const renderAlertDetailModalNotes = () => {
    return (
      <section className='alert-detail__notes'>
        <h3>Notes</h3>
        {renderAddNote()}
        {renderNotes()}
      </section>
    )
  }

  return renderAlertDetailModalNotes()
}

export default AlertDetailModalNotes
