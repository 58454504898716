import React from 'react'
import Grid from '@mui/material/Grid'
import { getProfileMemberFields } from '../../../utils/member/baseProfileUtils'

export default function AccountInformationFormFields () {
  const memberFields = getProfileMemberFields()

  return (
    <Grid container spacing={2} sx={{ width: '100%' }}>
      <Grid item xs={12} sm={6} md={6}>{memberFields.firstName.formInput()}</Grid>
      <Grid item xs={12} sm={6} md={6}>{memberFields.lastName.formInput()}</Grid>
      <Grid item xs={12} sm={6} md={6}>{memberFields.email.formInput()}</Grid>
      <Grid item xs={12} sm={6} md={6}>{memberFields.phone.formInput()}</Grid>
      <Grid item xs={12} sm={6} md={6}>{memberFields.timezone.formInput()}</Grid>
    </Grid>
  )
}
