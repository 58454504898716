import React from 'react'
import restApi from '../../js/api'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { analyticsSlice } from '../../js/store/analytics'
import Tooltip from '../../js/components/elements/Tooltip'
import AnalyticsChart from '../layout/AnalyticsChart'
import getAlertTypeChartOptions from '../charts/alertTypeChart'
import getAlertDayChartOptions from '../charts/alertDayChart'

/* ****************************************************************************
 * Analytics => Trends => Alerts
 * ****************************************************************************/

function AnalyticsAlertsSection (props) {
  const renderAxAlertsSection = () => {
    const alertDayTooltip = 'Click on the legend to drill down into a specific alert type.'

    return (
      <div className='ax-subsection' data-testid='ax-subsection__alerts'>
        <div className='ax-subsection__header'>Alerts</div>

        <div>
          <div>
            <div className='ax-module' data-testid='ax-module__alerts-by-type'>
              <div className='ax-module-header'>Alerts by type</div>
              <AnalyticsChart
                containerId='ax-alert-type-chart'
                chartOptions={getAlertTypeChartOptions(props.analyticsStore.allAlerts)}
              />
            </div>
          </div>
          <div>
            <div className='ax-module' data-testid='ax-module__alerts-by-dow'>
              <div className='ax-module-header'>
                Alerts by day of week
                <Tooltip tooltipId='ax-module__alerts-by-dow__tooltip' content={alertDayTooltip} />
              </div>
              <AnalyticsChart
                containerId='ax-alert-day-chart'
                chartOptions={getAlertDayChartOptions(props.analyticsStore.allAlerts)}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return renderAxAlertsSection()
}

function getPropsFromStore (state) {
  return {
    analyticsStore: state.analytics
  }
}

function getPropsFromActions (dispatch) {
  return {
    apiActions: bindActionCreators(restApi.actions, dispatch),
    analyticsActions: bindActionCreators(analyticsSlice.actions, dispatch)
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(AnalyticsAlertsSection)
