import _ from 'lodash'
import moment from 'moment'

/* ******************************************************************************
 * Helpers
 * ******************************************************************************/

/**
 * Returns an array containing only data from the current /calendar/ week, starting on
 * Monday.
 *
 * e.g. If today is Tuesday, return only data from Monday and Tuesday.
 */
export function getDataForThisWeek (data) {
  if (!data) return []

  const startOfWeek = moment().startOf('isoWeek')
  return _.filter(data, d => moment(d.date).isSameOrAfter(startOfWeek))
}

/* ******************************************************************************
 * Sleep
 * ******************************************************************************/

export function getSleepAverage (member) {
  if (!member) return null

  return _.meanBy(member.sleep.data, d => d.duration.value)
}

/* ******************************************************************************
 * Food
 * ******************************************************************************/

export function getCaloriesAverage (member) {
  if (!member) return null

  const thisPeriod = member.food.information.periods[0]

  if (thisPeriod && thisPeriod.avg_calories) return thisPeriod.avg_calories

  return null
}
