import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Box, Step, StepContent, StepLabel, Stepper, useMediaQuery, useTheme } from '@mui/material'
import restApi from '../../../../api'
import BasicFrame from '../../../layout/BasicFrame'
import SignupConfirmation from './SignupConfirmation'
import SignupWelcome from './SignupWelcome'
import SignupCode from './SignupCode'
import SignupContact from './SignupContact'
import SignupCommunication from './SignupCommunication'
import SignupAddress from './SignupAddress'
import SignupChecks from './SignupChecks'
import SelfSignupFooter from './SelfSignupFooter'
import { getDefaultMemberFieldValuesFromConfig } from '../../../../utils/member/baseProfileUtils'

function BaseSelfSignupForm (props) {
  const [activeStep, setActiveStep] = useState(0)
  const [userInProgress, setUserInProgress] = useState(null)
  const [potentialMember, setPotentialMember] = useState(null)
  const theme = useTheme()
  const isDesktopView = useMediaQuery(theme.breakpoints.up('lg'))

  useEffect(() => {
    return () => {
      sendAnalytics(true, { pageIndex: activeStep })
    }
  }, [])

  useEffect(() => {
    if (potentialMember?.id) {
      if (potentialMember?.first_name?.indexOf('autotest') < 0) sendAnalytics()

      if (potentialMember) {
        getInitialValues()
        setActiveStep(2)
      }
    }
  }, [potentialMember])

  const sendAnalytics = (unloadFlag, updatedData) => {
    if (!potentialMember) return

    props.apiActions.sendAnalytics({ id: potentialMember?.id },
      {
        body: JSON.stringify({
          type: 'event',
          key: `signup_screen${unloadFlag ? '_unload' : '_data'}`,
          value: JSON.stringify({ activeStep, ...updatedData })
        })
      })
  }

  const handleIncrementPage = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleDecrementPage = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const getInitialCommunicationPreference = (user, defaults) => {
    if (user.email && !user.phone) {
      return 'email'
    } else if (user.phone && !user.email) { // no guarantee that this is a cell phone, but it's still a reasonable first pick in this case
      return 'text'
    }
    return defaults.communicationPreference
  }

  const getInitialValues = () => {
    if (potentialMember) {
      const defaults = getDefaultMemberFieldValuesFromConfig()
      const userInProgress = {
        firstName: potentialMember.first_name,
        lastName: potentialMember.last_name,
        gender: potentialMember.gender || defaults.gender,
        communicationPreference: getInitialCommunicationPreference(potentialMember, defaults),
        email: potentialMember.email,
        phone: potentialMember.phone ?? '',
        homePhone: potentialMember.home_phone ?? '',
        streetAddress1: potentialMember.street_address_1,
        streetAddress2: potentialMember.street_address_2,
        city: potentialMember.city,
        state: potentialMember.state,
        zipCode: potentialMember.zip_code
      }

      setUserInProgress({ ...userInProgress, id: potentialMember.id })
      return userInProgress
    } else {
      setUserInProgress(null)
      return {}
    }
  }

  const handleFormSubmit = (callback) => {
    const submitPromise = new Promise((resolve, reject) => {
      const apiBody = {
        first_name: userInProgress.firstName,
        last_name: userInProgress.lastName,
        gender: userInProgress.gender,
        communication: userInProgress.communicationPreference,
        email: userInProgress.email,
        phone: userInProgress.phone?.length > 0 ? userInProgress.phone : null,
        home_phone: userInProgress.homePhone?.length > 0 ? userInProgress.homePhone : null,
        street_address_1: userInProgress.streetAddress1,
        street_address_2: userInProgress.streetAddress2,
        city: userInProgress.city,
        state: userInProgress.state,
        zip_code: userInProgress.zipCode,

        id: potentialMember?.id,
        medical_id: potentialMember?.medical_id,
        timezone: potentialMember?.timezone ?? 'America/New_York', // P32 default
        date_of_birth: potentialMember?.date_of_birth,
        group: potentialMember?.group
      }

      apiBody.signed_tos = 1
      apiBody.orderBodyTraceScale = 1
      apiBody.orderBodyTraceBloodPressure = 1

      // Make API request
      props.callCreateMemberApi({
        promise: { resolve, reject }
      }, apiBody)
    })

    if (typeof callback === 'function') callback()

    return submitPromise
  }

  const handlePreventEnterKey = (event) => {
    if (event.key === 'Enter') event.preventDefault()
  }

  const handleMemberInfoChange = (field, value) => {
    const newUser = { ...userInProgress }
    newUser[field] = value
    setUserInProgress(newUser)
  }

  const getSignupSteps = () => [
    {
      page: 'welcome',
      label: 'Welcome to Heart Healthy',
      component: (<SignupWelcome isMobile={!isDesktopView} handleIncrementPage={handleIncrementPage} />)
    },
    {
      page: 'signupCodeEntry',
      label: 'Enter sign up code',
      component: (<SignupCode
        sendAnalytics={sendAnalytics}
        handlePreventEnterKey={handlePreventEnterKey}
        handleDecrementPage={handleDecrementPage}
        setPotentialMember={setPotentialMember}
                  />)
    },
    {
      page: 'nameAndContactInfo',
      label: 'Confirm contact information',
      component: (<SignupContact
        handleMemberInfoChange={handleMemberInfoChange}
        handleDecrementPage={handleDecrementPage}
        handleIncrementPage={handleIncrementPage}
        userInProgress={userInProgress}
        setUserInProgress={setUserInProgress}
                  />)
    },
    {
      page: 'commPref',
      label: 'Select communication preference',
      component: (<SignupCommunication
        userInProgress={userInProgress}
        handleMemberInfoChange={handleMemberInfoChange}
        handlePreventEnterKey={handlePreventEnterKey}
        handleIncrementPage={handleIncrementPage}
        handleDecrementPage={handleDecrementPage}
                  />)
    },
    {
      page: 'addressForm',
      label: 'Enter shipping address',
      component: (<SignupAddress
        userInProgress={userInProgress}
        setUserInProgress={setUserInProgress}
        handleMemberInfoChange={handleMemberInfoChange}
        handlePreventEnterKey={handlePreventEnterKey}
        handleIncrementPage={handleIncrementPage}
        handleDecrementPage={handleDecrementPage}
                  />)
    },
    {
      page: 'checks',
      label: 'Just a few more questions',
      component: (<SignupChecks
        handleIncrementPage={handleIncrementPage}
        handleDecrementPage={handleDecrementPage}
        handleFormSubmit={handleFormSubmit}
        handlePreventEnterKey={handlePreventEnterKey}
        sendAnalytics={sendAnalytics}
                  />)
    },
    {
      page: 'confirmation',
      label: 'All done!',
      component: (<SignupConfirmation sendAnalytics={sendAnalytics} />)
    }
  ]

  const renderHorizontalSteps = (steps, formClass) => {
    return (
      <Box sx={{ maxWidth: '75vw' }} className={formClass}>
        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
          {steps.map((stepData, index) => {
            return (
              <Step key={stepData.label}>
                <StepLabel>{stepData.label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
        <Box sx={{ marginLeft: '15%', marginRight: '15%' }}>
          {steps[activeStep].component}
        </Box>
      </Box>
    )
  }

  const renderVerticalSteps = (steps, formClass) => {
    return (
      <Box sx={{ maxWidth: '75vw' }} className={formClass}>
        <Stepper
          activeStep={activeStep}
          sx={{ mb: 4 }}
          orientation='vertical'
        >
          {steps.map((stepData, index) => {
            return (
              <Step key={stepData.label}>
                <StepLabel>{stepData.label}</StepLabel>
                <StepContent>
                  {steps[activeStep].component}
                </StepContent>
              </Step>
            )
          })}
        </Stepper>
      </Box>
    )
  }

  const renderSelfSignup = () => {
    const steps = getSignupSteps()
    const formClass = 'login-form self-signup'

    return (
      <BasicFrame>
        {isDesktopView && renderHorizontalSteps(steps, formClass)}
        {!isDesktopView && renderVerticalSteps(steps, formClass)}
        <SelfSignupFooter />
      </BasicFrame>
    )
  }

  return renderSelfSignup()
}

function getPropsFromStore (state) {
  return {
    potentialMember: Object.values(state.membersCache.entities).length > 0 && Object.values(state.membersCache.entities)[0].user?.created === undefined && Object.values(state.membersCache.entities)[0],
    suggestedAddress: state.membersCache?.suggestedAddress
  }
}

function getPropsFromActions (dispatch) {
  return {
    apiActions: bindActionCreators(restApi.actions, dispatch),
    callCreateMemberApi: (pathVars, apiFields) => {
      dispatch(restApi.actions.createMember(pathVars, { body: JSON.stringify(apiFields) }))
    }
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(BaseSelfSignupForm)
