import { productConfig } from '../config/config-service'
import { MEMBER_SORTS } from '../utils/baseMembersListUtils'
import { compareActivityProgressAsc, compareActivityProgressDesc, compareAvgBpAsc, compareAvgBpDesc, compareBloodPressureDelta, compareBloodPressureDeltaAsc, compareLatestPulseOx, comparePregnancyLength, compareProgramStep, compareSeverityAsc, compareSeverityDesc, compareWeightDelta, compareWeightGain, compareWeightIOM } from './memberComparisons'

export const SORTS = [
  {
    id: 'SEVERITY_DESC',
    displayTitle: 'Severity (high→low)',
    compare: compareSeverityAsc // More negative severity means more severe
  },
  {
    id: 'SEVERITY_ASC',
    displayTitle: 'Severity (low→high)',
    compare: compareSeverityDesc // More negative severity means more severe
  },
  {
    id: 'WEIGHT_DELTA',
    displayTitle: 'Weight gain',
    compare: compareWeightDelta
  },
  {
    id: 'BLOOD_PRESSURE_DELTA',
    displayTitle: 'Blood pressure increase',
    compare: compareBloodPressureDelta
  },
  {
    id: 'BLOOD_PRESSURE_DELTA_DESC',
    displayTitle: '7d Δ BP (highest first)',
    compare: compareBloodPressureDelta
  },
  {
    id: 'BLOOD_PRESSURE_DELTA_ASC',
    displayTitle: '7d Δ BP (lowest first)',
    compare: compareBloodPressureDeltaAsc
  },
  {
    id: 'MEAN_BP_DESC',
    displayTitle: '7d Avg BP (highest first)',
    compare: compareAvgBpDesc
  },
  {
    id: 'MEAN_BP_ASC',
    displayTitle: '7d Avg BP (lowest first)',
    compare: compareAvgBpAsc
  },
  {
    id: 'LATEST_PULSE_OX',
    displayTitle: 'SpO₂ (low→high)',
    compare: compareLatestPulseOx
  },
  {
    id: 'PROGRAM_STEP',
    displayTitle: 'Program step',
    compare: compareProgramStep
  },
  {
    id: 'WEIGHT_GAIN_RATE',
    displayTitle: 'Weight gain rate',
    compare: compareWeightGain
  },
  {
    id: 'WEIGHT_IOM_FRACTION',
    displayTitle: 'Weight IOM %',
    compare: compareWeightIOM
  },
  {
    id: 'ACTIVITY_PROGRESS_ASC',
    displayTitle: 'Activity goal % (low→high)',
    compare: compareActivityProgressAsc
  },
  {
    id: 'ACTIVITY_PROGRESS_DESC',
    displayTitle: 'Activity goal % (high→low)',
    compare: compareActivityProgressDesc
  },
  {
    id: 'PREGNANCY_LENGTH',
    displayTitle: 'Gestational length',
    compare: comparePregnancyLength
  }
]

export const getMemberSorts = () => {
  const config = productConfig().members.refineSidebar
  const sorts = SORTS.filter(item => config.sortOptions.indexOf(item.id) > -1)
  return [...MEMBER_SORTS, ...sorts] // default sorts plus any custom sort options
}
