import _ from 'lodash'
import React from 'react'

function filterUsers (users) {
  return {
    onboarded: _.filter(users, u => !u.info.not_onboarded),
    new: _.filter(users, u => u.info.onboarded_in_range),
    not_onboarded: _.filter(users, u => u.info.not_onboarded)
  }
}

export default function Enrollment (props) {
  const handleDrilldown = (memberType, members) => {
    props.analyticsActions.setDrilldown({
      title: `${memberType} users`,
      type: 'member',
      items: members
    })
  }

  const renderEnrollment = () => {
    const filteredUsers = filterUsers(props.users)

    return (
      <div className='ax-module'>
        <div className='ax-enrollment'>
          <div className='ax-stat'>
            <span
              className='ax-drilldown-link'
              onClick={() => handleDrilldown('Enrolled', filteredUsers.onboarded)}
            >
              <span className='ax-stat-number'>
                {filteredUsers.onboarded.length}
              </span>
              <span className='ax-stat-label'>
                currently enrolled
              </span>
            </span>
          </div>

          <div className='ax-stat'>
            <span
              className='ax-drilldown-link'
              onClick={() => handleDrilldown('New', filteredUsers.new)}
            >
              <span className='ax-stat-number'>
                {filteredUsers.new.length}
              </span>
              <span className='ax-stat-label'>
                new this period
              </span>
            </span>
          </div>

          <div className='ax-stat'>
            <span
              className='ax-drilldown-link'
              onClick={() => handleDrilldown('Pending', filteredUsers.not_onboarded)}
            >
              <span className='ax-stat-number'>
                {filteredUsers.not_onboarded.length}
              </span>
              <span className='ax-stat-label'>
                pending enrollment
              </span>
            </span>
          </div>
        </div>
      </div>
    )
  }

  return renderEnrollment()
}
