import React, { useEffect, useState } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

export default function EjentaSnackbar (props) {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(props.isOpen)
  }, [props.isOpen])

  return (
    <Snackbar
      open={isOpen}
      onClose={props.handleClose}
      key={props.dataKey}
      data-testid={props.dataKey}
      autoHideDuration={props.duration ?? 6000}
      anchorOrigin={{ vertical: props.veriticalAnchor ?? 'bottom', horizontal: props.horizontalAnchor ?? 'center' }}
    >
      <Alert onClose={props.handleClose} severity={props.severity} sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </Snackbar>
  )
}
