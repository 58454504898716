import React from 'react'
import classnames from 'classnames'
import ProfileDetail from './ProfileDetail'
import PregnancyProfileDetail from './PregnancyProfileDetail'
import Star from '../Star'
import { Box, Chip, Grid, IconButton } from '@mui/material'
import { getDisplayName } from '../../../utils/baseStringUtils'
import { isMemberPaused, isMemberAwaitingDevices } from '../../../utils/member/baseProfileUtils'
import { productConfig } from '../../../config/config-service'

/* *****************************************************************************
 *  Profile cell, for use in the clinician alerts list and members list views
 * *****************************************************************************/
export default function ProfileCell (props) {
  const config = productConfig()
  const hasPregnancyInfo = config.profile.showPregnancyInfo

  const getStatusText = () => {
    if (props.user.archived_since) {
      return (<Chip label='Archived' size='small' color='secondary' />)
    }

    if (!props.user.signed_tos) {
      return (<Chip label='Pending' size='small' variant='outlined' />)
    }

    if (isMemberPaused(props.user.pauses)) {
      return (<Chip label='Suspended' size='small' color='secondary' />)
    }

    if (isMemberAwaitingDevices(props.user?.order_statuses)) {
      return (<Chip label='Onboarding' size='small' variant='outlined' />)
    }

    return null
  }

  const renderDefault = (
    className,
    displayNameText,
    profileDetailText,
    statusText
  ) => {
    const starClasses = classnames('profile-cell__star', {
      'profile-cell__star--starred': props.memberStarred,
      'profile-cell__star--unstarred': !props.memberStarred
    })

    return (
      <Grid container className={className} sx={{ minHeight: '4rem' }}>
        {props.handleStarClick &&
          <Grid item xs={2} sx={{ alignSelf: 'center' }}>
            <IconButton className='profile-cell__star' onClick={e => props.handleStarClick(e)}>
              <Star className={starClasses} filled={props.memberStarred} />
            </IconButton>
          </Grid>}
        <Grid item xs sx={{ alignSelf: 'center' }}>
          <Box data-testid='profile-cell__user-details'>
            <Box className='profile-cell__name'>{displayNameText}</Box>
            <Box
              className='profile-cell__detail'
              sx={{ color: theme => theme.palette.secondary.light, display: 'flex', justifyContent: 'space-between' }}
            >
              <Box container='span'>{profileDetailText}</Box>
              <Box className='profile-cell__status'>{statusText}</Box>
            </Box>
          </Box>

        </Grid>

      </Grid>
    )
  }

  const renderProfileCell = () => {
    const className = classnames(props.className, 'profile-cell')
    const displayNameText = getDisplayName(props.user)
    const ProfileDetailComponent = hasPregnancyInfo ? PregnancyProfileDetail : ProfileDetail
    const profileDetailText = (
      <ProfileDetailComponent user={props.user} memberObj={props.memberObj} detailStr={props.detailStr} />
    )

    return renderDefault(
      className,
      displayNameText,
      profileDetailText,
      getStatusText()
    )
  }

  return renderProfileCell()
}
