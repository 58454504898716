import _ from 'lodash'
import React from 'react'
import { Checkbox, Typography, FormGroup, FormControlLabel } from '@mui/material'

/* ***************************************************************************************
 *
 * Shared config & helpers between the alerts list and the members list.
 *
 * ***************************************************************************************/

/*
 * Redux store helpers
 */

export function getInitialState ({ sortBy, filters }) {
  return {
    prevLocationKey: null,

    currentPage: [], // Only the current page of the list; this is the actual CONTENT in the list

    searchQuery: '',
    searchQueryReset: false,

    sortBy,
    urlFilters: {},
    filters,
    filterCounts: {},
    page: 1, // this is the current page INDEX
    selectedItemId: null
  }
}

/*
 * List processing helpers
 */

export function filterBySearchQuery (objList, searchQuery) {
  const normalizedSearchQuery = searchQuery.toLowerCase()
  const processedObjList = objList.slice(0)

  return processedObjList.filter((obj) => {
    const firstName = obj.user.first_name
    const lastName = obj.user.last_name
    const studyId = obj.user.medical_id
    const phone = obj.user.phone
    const email = obj.user.email
    const lcu = obj.user.lcu
    const employerGroup = obj.user.employer_group
    const normalizedMemberName = `${firstName} ${lastName} ${studyId} ${phone} ${email} ${lcu} ${employerGroup}`
      .toLowerCase()
    return (normalizedMemberName.includes(normalizedSearchQuery))
  })
}

export function filterByFilters ({ currentList, selectedFilters, allFilters, clinicianId }) {
  const filteredList = currentList.filter(obj =>
    _.reduce(_.values(allFilters), (isAlertAllowed, filterGroup) => {
      // If no filters in a group are selected, allow everything through
      if (!_.intersection(selectedFilters, _.map(filterGroup.filters, f => f.id)).length) {
        return isAlertAllowed
      }

      // If >=1 filters in a group are selected, allow anything through that matches at least one
      const isAlertAllowedForGroup = _.reduce(filterGroup.filters, (acc, f) => {
        if (selectedFilters.indexOf(f.id) === -1) {
          return acc
        }

        return acc || f.test(obj, clinicianId)
      }, false)

      return isAlertAllowed && isAlertAllowedForGroup
    }, true)
  )

  return filteredList
}

export function filterByCareManager ({ currentList, careManagerId }) {
  if (careManagerId === 'DEFAULT_OPTION') return currentList
  else return currentList.filter(obj => obj.user.care_manager_id === careManagerId)
}

export function filterByLCU ({ currentList, selectedLCU }) {
  if (!selectedLCU || selectedLCU === 'All') return currentList
  else return currentList.filter(obj => obj.user.lcu === selectedLCU)
}

/*
 * Rendering helpers
 */

export function renderFilters (allFilters, selectedFilters, filterCounts, handleFilterChange) {
  const renderFiltersInGroup = function render (filters) {
    return _.map(filters, (f) => {
      const labelHtml = `${f.displayTitle} (${filterCounts[f.id] ?? 0})`
      const checkboxControl = <Checkbox size='small' onChange={() => handleFilterChange(f.id)} checked={selectedFilters.includes(f.id)} />

      return (
        <FormGroup key={f.id} sx={{ ml: 1 }}>
          <FormControlLabel sx={{ '& .MuiTypography-root': { fontSize: 14, fontWeight: 'light' } }} control={checkboxControl} label={labelHtml} />
        </FormGroup>
      )
    }
    )
  }

  const renderFilterGroup = function render (group) {
    if (!group?.displayTitle) return null

    const { displayTitle, filters } = group
    return (
      <div className='list-controls__group' key={displayTitle} data-testid='list-controls__group'>
        <Typography variant='subtitle2' color='#7f7f7f'>{displayTitle}</Typography>
        {renderFiltersInGroup(filters)}
      </div>
    )
  }

  return (
    <div className='list-controls__section' data-testid='list-controls__filter-section'>
      <Typography variant='subtitle2'>Filter by</Typography>
      {_.map(_.values(allFilters), group => renderFilterGroup(group))}
    </div>
  )
}
