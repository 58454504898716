import React from 'react'
import CarePlanItem from '../CarePlanItem'
import { CAREPLAN_VALUE_TYPES } from '../../../../constants/carePlanValueTypes'

export default class TemperatureRangeItem extends CarePlanItem {
  constructor (props) {
    super(props)
    this.type = 'temp_range'
  }

  rangeTemperatureMinInputElement () {
    return this.getInputElement(
      80, 92, 1,
      CAREPLAN_VALUE_TYPES.TEMPERATURE_MIN.get(val => val),
      CAREPLAN_VALUE_TYPES.TEMPERATURE_MIN.set(val => val)
    )
  }

  rangeTemperatureMaxInputElement () {
    return this.getInputElement(
      93, 105, 1,
      CAREPLAN_VALUE_TYPES.TEMPERATURE_MAX.get(val => val),
      CAREPLAN_VALUE_TYPES.TEMPERATURE_MAX.set(val => val)
    )
  }

  renderGoal () {
    return <div>Keep skin temperature in range</div>
  }

  renderClinicianTrigger () {
    return <div>If <b>skin temperature</b> is below {this.rangeTemperatureMinInputElement()}°F or above {this.rangeTemperatureMaxInputElement()}°F.</div>
  }

  renderCondensed () {
    return (
      <div>Skin temp. &lt; {this.getValueElement(CAREPLAN_VALUE_TYPES.TEMPERATURE_MIN.get(val => val))} or
        &gt; {this.getValueElement(CAREPLAN_VALUE_TYPES.TEMPERATURE_MAX.get(val => val))} °F
      </div>
    )
  }
}
