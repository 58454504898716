/* eslint-disable object-shorthand */
import _ from 'lodash'
import {
  getBaseChartOptions,
  addPausePlotBands,
  getSimpleContinuousSeries,
  getSimpleBioStickerAlertsSeries
} from './baseChartUtils'
import {
  getXAxisForRange
} from './chartUtils'

export const baseHeartRateChartOptions = {
  chart: { className: 'heart_rate-chart' },

  legend: {
    symbolRadius: 0,
    itemMarginTop: 12
  },

  tooltip: {
    useHTML: true,
    padding: 12,
    pointFormat: '<div>{point.description}</div>'
  },

  plotOptions: {
    line: {
      marker: { enabled: false }
    }
  },

  yAxis: [{
    title: { text: '' },
    labels: { format: '{value}' }
  }],

  series: []
}

/* ********************************************************************************
 * Chart options
 * ********************************************************************************/

export function getFullChartOptions (memberObj, dashboardViewStore, showMemberAlerts, handleAlertClick) {
  const xAxisOptions = { xAxis: getXAxisForRange(memberObj, dashboardViewStore) }

  let options = _.merge(
    getBaseChartOptions(),
    baseHeartRateChartOptions,
    xAxisOptions
  )

  options = getSimpleContinuousSeries(options, memberObj, dashboardViewStore, 'heart_rate', 'Resting heart rate',
    ` ${memberObj.heart_rate.information.units.heart_rate}`)

  if (showMemberAlerts) {
    options = getSimpleBioStickerAlertsSeries(memberObj, options, handleAlertClick, 'heart_rate', 'Heart rate')
  }

  options = addPausePlotBands(memberObj.user.pauses, options, true)

  return options
}
