import moment from 'moment'

import { dispatchWithException, resolvePromise } from 'api/baseUtils'

import { updateUser } from '../store/userSession'
import {
  changeStarStatus,
  updateMemberStarredNotes,
  updateMemberUser,
  updateMemberSummary,
  updateMemberSuggestedAddress,
  handleWeightDeletion,
  handleWeightRestoration,
  updateMemberCareplan,
  updateMemberCareplanRow
} from '../store/membersCache'
import {
  populateMembersFromCache,
  changeMemberViewStarStatus
} from '../store/membersView'
import { fetchPagingAlerts } from '../store/alertsView'
import { setLastUpdated } from '../store/dashboardView'
import { setMemberId, populateNotes, fetchPagingNotes, fetchStarredNotes } from '../store/profileView'

/* ********************************************************************************
 *
 * Endpoints for creating, reading, updating, destroying members...
 *
 * ********************************************************************************/

const memberEndpoints = {
  /*
   * New member
   */
  createMember: {
    url: '/auth/new',
    options: {
      method: 'post'
    },

    postfetch: [
      resolvePromise,
      ({ data, dispatch }) => {
        dispatchWithException(updateMemberUser({ data }), dispatch)
      },
      ({ dispatch, getState }) => {
        dispatchWithException(
          populateMembersFromCache(getState().membersCache),
          dispatch
        )
      }
    ]
  },

  /*
   * Member summary
   */
  memberSummary: { // Clinician-facing
    url: '/users/:id/summary?startdate=(:startDate)&enddate=(:endDate)',
    options: {
      method: 'get'
    },
    urlOptions: {
      delimiter: '&'
    },

    postfetch: [
      ({ data, dispatch, request }) => {
        dispatchWithException(updateMemberSummary({ data }), dispatch)
        dispatch(setMemberId({ memberId: request.pathvars.id }))
      },
      resolvePromise
    ]
  },

  alertCounts: {
    url: '/users/:id/alerts/counts',
    options: {
      method: 'get'
    },

    postfetch: [
      resolvePromise
    ]
  },

  /*
   * Member profile details
   */
  updateUser: {
    url: '/users/:id',
    options: {
      method: 'post'
    },

    postfetch: [
      resolvePromise,
      ({ data, dispatch }) => {
        dispatchWithException(updateMemberUser({ data }), dispatch)
      },
      ({ dispatch, getState }) => {
        dispatchWithException(
          populateMembersFromCache(getState().membersCache),
          dispatch
        )
      }
    ]
  },

  /*
   Member care plan
   */
  getMemberCarePlan: {
    url: '/users/:id/careplan',
    options: {
      method: 'get'
    },

    postfetch: [({ request, data, dispatch }) => {
      dispatchWithException(
        updateMemberCareplan({ userId: request.pathvars.id, data }),
        dispatch
      )
    }]
  },

  updateMemberCarePlan: {
    url: '/users/:id/careplan',
    options: {
      method: 'post'
    },

    postfetch: [({ request, data, dispatch }) => {
      const itemId = JSON.parse(request.params.body).id
      dispatchWithException(
        updateMemberCareplanRow({ userId: request.pathvars.id, itemId, data }),
        dispatch
      )
    }]
  },

  /*
   * Member devices
   */
  removeDevice: {
    url: '/users/:id/sensors?sensorType=(:sensorType)',
    options: {
      method: 'delete'
    },

    postfetch: [({ data, dispatch, request, getState }) => {
      if (request.pathvars.id === getState().userSession.user.id) {
        // Removing user's own device
        dispatchWithException(updateUser(data), dispatch)
      }

      // Removing another user's device
      dispatchWithException(updateMemberUser({ data }), dispatch)
    }]
  },

  /*
   * Member status
   */

  starUser: {
    url: '/users/:id/star',
    options: {
      method: 'post'
    },
    prefetch: [
      ({ dispatch, request, getState }, cb) => {
        // Optimistically star the member before server comes back with response
        const memberId = request.pathvars.id
        const clinicianId = getState().userSession.user.id
        dispatchWithException(changeStarStatus({ clinicianId, memberId, starred: true }),
          dispatch)
        dispatchWithException(changeMemberViewStarStatus({ clinicianId, memberId, starred: true }),
          dispatch)
        cb()
      }

    ],
    postfetch: [
      ({ dispatch, data }) => {
        dispatchWithException(updateMemberUser({ data }), dispatch)
      }
    ]
  },

  unstarUser: {
    url: '/users/:id/unstar',
    options: {
      method: 'post'
    },
    prefetch: [
      ({ dispatch, request, getState }, cb) => {
        // Optimistically unstar the member before server comes back with response
        const memberId = request.pathvars.id
        const clinicianId = getState().userSession.user.id
        dispatchWithException(changeStarStatus({ clinicianId, memberId, starred: false }),
          dispatch)
        dispatchWithException(changeMemberViewStarStatus({ clinicianId, memberId, starred: false }),
          dispatch)
        cb()
      }
    ],
    postfetch: [
      ({ dispatch, data }) => {
        dispatchWithException(updateMemberUser({ data }), dispatch)
      }
    ]
  },

  archiveUser: {
    url: '/users/:id/archive',
    options: {
      method: 'post'
    },

    postfetch: [
      ({ dispatch, data }) => {
        dispatchWithException(updateMemberUser({ data }), dispatch)
      },
      ({ dispatch, getState }) => {
        dispatchWithException(populateMembersFromCache(getState().membersCache), dispatch)
      },
      ({ dispatch }) => {
        dispatchWithException(fetchPagingAlerts(), dispatch)
      }
    ]
  },

  createPause: {
    url: '/users/:id/pause',
    options: {
      method: 'post'
    },
    postfetch: [
      ({ dispatch, data }) => {
        dispatchWithException(updateMemberUser({ data }), dispatch)
        dispatchWithException(setLastUpdated({ lastUpdated: moment().valueOf() }), dispatch)
      },
      ({ dispatch, getState }) => {
        dispatchWithException(
          populateMembersFromCache(getState().membersCache),
          dispatch
        )
      }
    ]
  },

  updatePause: {
    url: '/users/:id/pause/:pause_id',
    options: {
      method: 'post'
    },
    postfetch: [
      ({ dispatch, data }) => {
        dispatchWithException(updateMemberUser({ data }), dispatch)
        dispatchWithException(setLastUpdated({ lastUpdated: moment().valueOf() }), dispatch)
      },
      ({ dispatch, getState }) => {
        dispatchWithException(
          populateMembersFromCache(getState().membersCache),
          dispatch
        )
      }
    ]
  },

  deletePause: {
    url: '/users/:id/pause/:pause_id',
    options: {
      method: 'delete'
    },
    postfetch: [
      ({ dispatch, data }) => {
        dispatchWithException(updateMemberUser({ data }), dispatch)
        dispatchWithException(setLastUpdated({ lastUpdated: moment().valueOf() }), dispatch)
      },
      ({ dispatch, getState }) => {
        dispatchWithException(
          populateMembersFromCache(getState().membersCache),
          dispatch
        )
      }
    ]
  },

  getMemberNotes: {
    url: '/users/:id/notes',
    options: {
      method: 'get'
    },
    urlOptions: {
      delimiter: '&'
    },
    postfetch: [({ data, dispatch }) => {
      dispatchWithException(populateNotes(data), dispatch)
    }]
  },

  getMemberStarredNotes: {
    url: '/users/:id/notes?starred=1',
    options: {
      method: 'get'
    },
    postfetch: [({ request, data, dispatch }) => {
      dispatchWithException(updateMemberStarredNotes({ userId: request.pathvars.id, data }), dispatch)
    }]
  },

  addNote: {
    url: '/users/:id/notes',
    options: {
      method: 'post'
    },
    postfetch: [({ dispatch }) => {
      // Refetch notes so that state is updated with newly added note
      dispatchWithException(fetchPagingNotes(), dispatch)
    }]
  },

  deleteNote: {
    url: '/users/:id/notes/:noteId',
    options: {
      method: 'delete'
    },
    postfetch: [({ data, dispatch }) => {
      if (data.starred) { // deleted note was a starred note
        dispatchWithException(fetchStarredNotes(), dispatch)
      }
      // Refetch notes so that state is updated with deleted note
      dispatchWithException(fetchPagingNotes(), dispatch)
    }]
  },

  starNote: {
    url: '/users/:id/notes/:noteId/star',
    options: {
      method: 'post'
    },
    postfetch: [({ data, dispatch, request }) => {
      if (data.updated) {
        // Refetch notes so that state is updated with newly starred note
        dispatchWithException(fetchStarredNotes(), dispatch)
        dispatchWithException(fetchPagingNotes(), dispatch)
      }
    }]
  },

  unstarNote: {
    url: '/users/:id/notes/:noteId/unstar',
    options: {
      method: 'post'
    },
    postfetch: [({ dispatch }) => {
      // Refetch notes so that state is updated with newly unstarred note
      dispatchWithException(fetchStarredNotes(), dispatch)
      dispatchWithException(fetchPagingNotes(), dispatch)
    }]
  },

  unstarAndStarNotes: {
    url: '/users/:id/notes/:noteToUnstarId/unstar',
    options: {
      method: 'post'
    },
    postfetch: [({ data, dispatch, actions, request }) => {
      if (data.updated) {
        dispatchWithException(actions.starNote({
          id: request.pathvars.id,
          noteId: request.pathvars.noteToStarId
        }), dispatch)
      }
    }]
  },

  submitSignupCode: {
    url: '/potential_user',
    options: {
      method: 'post'
    },
    postfetch: [
      ({ data, dispatch }) => {
        if (!data.error) {
          dispatchWithException(updateMemberUser({ data }), dispatch)
        }
      },
      resolvePromise
    ]
  },

  submitAddress: {
    url: '/validate_address',
    options: {
      method: 'post'
    },
    postfetch: [
      ({ data, dispatch }) => {
        dispatchWithException(updateMemberSuggestedAddress({ data }), dispatch)
      },
      resolvePromise
    ]
  },

  deleteWeight: {
    url: '/users/:id/weight/:timestamp',
    options: {
      method: 'delete'
    },
    postfetch: [({ data, dispatch }) => {
      if (data?.user_id) {
        dispatchWithException(handleWeightDeletion(data), dispatch)
        dispatchWithException(setLastUpdated({ lastUpdated: moment().valueOf() }), dispatch)
      }
    }]
  },

  restoreWeight: {
    url: '/users/:id/weight/:timestamp',
    options: {
      method: 'post'
    },
    postfetch: [({ data, dispatch }) => {
      if (data?.observations) {
        dispatchWithException(handleWeightRestoration(data), dispatch)
        dispatchWithException(setLastUpdated({ lastUpdated: moment().valueOf() }), dispatch)
      }
    }]
  }
}

export default memberEndpoints
