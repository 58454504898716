import React, { useEffect, useState } from 'react'
import { productConfig } from '../../../../config/config-service'
import { getDisplayName } from '../../../../utils/baseStringUtils'
import { Box, Fade } from '@mui/material'
import Modal from '../../../elements/Modal'
import DeviceStatusRows from '../../../elements/Devices/DeviceStatusRows'
import LinkedAccountsPane from './LinkedAccountsPane'
import DeviceInformationPane from './DeviceInformationPane'

export default function EditMemberDevicesModal (props) {
  const [isOpen, setIsOpen] = useState(false)
  const [modalId] = useState('editMemberDevices')
  const devicesConfig = productConfig().profile.devices

  useEffect(() => {
    setIsOpen(props.profileViewStore.openModal === modalId)
  }, [props.profileViewStore.openModal])

  const memberHasOrderStatuses = () => {
    const orderStatuses = props.memberObj?.user?.order_statuses
    return orderStatuses && Object.keys(orderStatuses).length > 0
  }

  const renderModal = () => {
    const modalTitle = <span>Devices and accounts linked to {getDisplayName(props.memberObj.user)}</span>
    return (
      <Modal
        isOpen={isOpen}
        name='EditMemberDevices'
        targetUser={props.memberObj.user}
        onModalClose={() => props.profileViewActions.handleClearModal()}
        modalTitle={modalTitle}
      >
        <Fade in={isOpen}>
          <Box>
            {memberHasOrderStatuses() && <DeviceStatusRows memberObj={props.memberObj} fields={[]} />}
            {devicesConfig.hasLinkedAccounts && <LinkedAccountsPane memberObj={props.memberObj} />}
            <DeviceInformationPane
              memberObj={props.memberObj}
              apiActions={props.apiActions}
              profileViewActions={props.profileViewActions}
            />
          </Box>
        </Fade>
      </Modal>
    )
  }

  return renderModal()
}
