import { Alert, Box, Typography, Radio, RadioGroup, FormControlLabel } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SelfSignupPaginator from './SelfSignupPaginator'

export default function SignupCommunication (props) {
  const [userInProgress, setUserInProgress] = useState({
    firstName: '',
    lastName: '',
    gender: 'MALE',
    communicationPreference: '',
    email: '',
    phone: '',
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    state: '',
    zipCode: ''
  })

  useEffect(() => {
    setUserInProgress(props.userInProgress)
  }, [props.userInProgress])

  const hasPhoneNumber = () => {
    return !!userInProgress.phone && userInProgress?.phone !== '+1'
  }

  const renderCommPref = () => {
    const textLabel = (
      <Typography>
        Send me a text ({hasPhoneNumber() ? 'to mobile phone number' : 'mobile phone number required on previous page'})
      </Typography>
    )

    const emailRadio = (
      <Radio
        id='email'
        name='communicationPreference'
        data-testid='login-form__comm-pref__email'
        value='email'
        checked={userInProgress.communicationPreference === 'email'}
        onChange={e => props.handleMemberInfoChange('communicationPreference', 'email')}
        onKeyDown={props.handlePreventEnterKey}
      />
    )

    const textRadio = (
      <Radio
        id='text'
        name='communicationPreference'
        data-testid='login-form__comm-pref__text'
        value='text'
        checked={userInProgress.communicationPreference === 'text'}
        disabled={!hasPhoneNumber()}
        onChange={e => props.handleMemberInfoChange('communicationPreference', 'text')}
        onKeyDown={props.handlePreventEnterKey}
      />
    )

    return (
      <Box>
        <Typography variant='h4' mb={3} textAlign='center'>What is your preferred contact method?</Typography>

        <Typography textAlign='center' mb={2}>Select how you prefer to receive notifications during the program, which may include program instructions or reminders.</Typography>

        {props.error && <Alert severity='error'>{props.error}</Alert>}

        <form>
          <Box sx={{ m: 2 }}>
            {/* Note: the name field for radio buttons should be identical, so that clicking another one replaces which one is checked */}
            <RadioGroup defaultValue='email' name='communicationPreference'>
              <FormControlLabel value='email' control={emailRadio} label='Send me email' />
              <FormControlLabel value='text' control={textRadio} label={textLabel} sx={{ color: !hasPhoneNumber() ? 'lightgrey' : '' }} />
            </RadioGroup>

            <Typography variant='caption'>
              Note: Message frequency varies. Message and data rates may apply. You can opt out of receiving text messages at any time.
            </Typography>
          </Box>

          <SelfSignupPaginator
            testId='login-form__comm-pref'
            hasPreviousSteps
            isFormSubmit={false}
            onHandleBack={props.handleDecrementPage}
            onHandleNext={props.handleIncrementPage}
            canContinue={userInProgress.communicationPreference === 'email' || userInProgress.communicationPreference === 'text'}
            hasMoreSteps
          />
        </form>
      </Box>
    )
  }

  return renderCommPref()
}
