import moment from 'moment'
import { hasRecentActivityData, hasRecentBgData, hasRecentBpData, hasRecentPulseOxData, hasRecentWeightData, MEMBER_STATUS_FILTERS } from '../utils/baseMembersListUtils'
import { getIOMDelta } from '../utils/member/analyticsUtilityHelper'
import { getActivityGoal } from '../constants/constants'
import { productConfig } from '../config/config-service'
import { getWeightDeltaInRange } from '../utils/member/analyticsUtils'

export const PAGE_SIZE = 20

export const PROGRAM_STEP = {
  displayTitle: 'Program step',
  filters: [
    {
      id: 'FILTER/PROGRAM_STEP_3',
      displayTitle: 'Step 3',
      test: obj => obj.pregnancy_info && obj.pregnancy_info.program_step === 3
    },
    {
      id: 'FILTER/PROGRAM_STEP_2',
      displayTitle: 'Step 2',
      test: obj => obj.pregnancy_info && obj.pregnancy_info.program_step === 2
    },
    {
      id: 'FILTER/PROGRAM_STEP_1',
      displayTitle: 'Step 1',
      test: obj => obj.pregnancy_info && obj.pregnancy_info.program_step === 1
    }
  ]
}

export const PREGNANCY_STATUS = {
  displayTitle: 'Pregnancy status',
  filters: [
    {
      id: 'FILTER/PRE_DELIVERY',
      displayTitle: 'Pre-delivery',
      test: (obj) => {
        if (!obj.user.delivery_date) return true

        return moment(obj.user.delivery_date).isAfter(moment())
      }
    },
    {
      id: 'FILTER/POST_DELIVERY',
      displayTitle: 'Post-delivery',
      test: (obj) => {
        if (!obj.user.delivery_date) return false

        return moment(obj.user.delivery_date).isSameOrBefore(moment())
      }
    }
  ]
}

export const LIFESTYLE_COACH = {
  displayTitle: 'Lifestyle coach',
  filters: [
    {
      id: 'FILTER/TS',
      displayTitle: 'TS',
      test: obj => obj.user.lifestyle_coach === 'TS'
    },
    {
      id: 'FILTER/RN',
      displayTitle: 'RN',
      test: obj => obj.user.lifestyle_coach === 'RN'
    },
    {
      id: 'FILTER/HU',
      displayTitle: 'HU',
      test: obj => obj.user.lifestyle_coach === 'HU'
    }
  ]
}

export const WEIGHT_IOM = {
  displayTitle: 'Weight IOM %',
  filters: [
    {
      id: 'WEIGHT_ABOVE_RANGE',
      displayTitle: 'Above 100%',
      test: (obj) => {
        if (!hasRecentWeightData(obj)) return false
        return getIOMDelta(obj).delta > 0
      }
    },
    {
      id: 'WEIGHT_WITHIN_RANGE',
      displayTitle: '0% - 100%',
      test: (obj) => {
        if (!hasRecentWeightData(obj)) return false
        return getIOMDelta(obj).delta === 0
      }
    },
    {
      id: 'WEIGHT_BELOW_RANGE',
      displayTitle: 'Below 0%',
      test: (obj) => {
        if (!hasRecentWeightData(obj)) return false
        return getIOMDelta(obj).delta < 0
      }
    }
  ]
}

export const ACTIVITY_GOAL = {
  displayTitle: 'Activity weekly goal %',
  filters: [
    {
      id: 'ACTIVITY_BOTTOM_25',
      displayTitle: '25% or less',

      test: (obj) => {
        if (!hasRecentActivityData(obj)) return false
        return getActivityGoal(obj) < 25
      }
    },
    {
      id: 'ACTIVITY_MID_50',
      displayTitle: '25% - 75%',
      test: (obj) => {
        if (!hasRecentActivityData(obj)) return false
        return (getActivityGoal(obj) >= 25 && getActivityGoal(obj) <= 75)
      }
    },
    {
      id: 'ACTIVITY_TOP_25',
      displayTitle: '75% or more',
      test: (obj) => {
        if (!hasRecentActivityData(obj)) return false
        return getActivityGoal(obj) > 75
      }
    }
  ]
}

export const WEIGHT_GAIN = {
  displayTitle: 'Weight gain 7d',
  filters: [
    {
      id: 'WEIGHT_GAIN_OVER_5',
      displayTitle: '5 lb. or more',
      test: obj => getWeightDeltaInRange(obj) >= 5
    },
    {
      id: 'WEIGHT_GAIN_BETWEEN_3_5',
      displayTitle: '3 lb. – 5 lb.',
      test: (obj) => {
        const delta = getWeightDeltaInRange(obj)
        return delta >= 3 && delta < 5
      }
    },
    {
      id: 'WEIGHT_GAIN_BELOW_3',
      displayTitle: '0 lb. – 3 lb.',
      test: (obj) => {
        const delta = getWeightDeltaInRange(obj)
        return delta >= 0 && delta < 3
      }
    },
    {
      id: 'WEIGHT_GAIN_NONE',
      displayTitle: 'No weight gain',
      test: obj => getWeightDeltaInRange(obj) < 0
    }
  ]
}

export const IN_CARE_MANAGEMENT = {
  displayTitle: 'In care management',
  filters: [
    {
      id: 'IN_CARE_MANAGEMENT/YES',
      displayTitle: 'Yes',
      test: obj => !!obj.user.in_care_management
    },
    {
      id: 'IN_CARE_MANAGEMENT/NO',
      displayTitle: 'No',
      test: obj => !obj.user.in_care_management
    }
  ]
}

export const NO_DATA = {
  displayTitle: 'No data for 7 days',
  filters: [
    {
      id: 'NO_WEIGHT_DATA',
      displayTitle: 'Weight',
      test: obj => !hasRecentWeightData(obj)
    },
    {
      id: 'NO_ACTIVITY_DATA',
      displayTitle: 'Activity',
      test: obj => !hasRecentActivityData(obj)
    },
    {
      id: 'NO_BLOOD_PRESSURE_DATA',
      displayTitle: 'Blood pressure',
      test: obj => !hasRecentBpData(obj)
    },
    {
      id: 'NO_PULSEOX_DATA',
      displayTitle: 'SpO₂',
      test: obj => !hasRecentPulseOxData(obj)
    },
    {
      id: 'NO_BLOOD_GLUCOSE_DATA',
      displayTitle: 'Blood glucose',
      test: obj => !hasRecentBgData(obj)
    }
  ]
}

export const NO_DEVICE = {
  displayTitle: 'Device not set up',
  filters: [
    {
      id: 'NO_WEIGHT_DEVICE',
      displayTitle: 'Scale',
      test: obj => !obj.user.bt_scale_imei
    },
    {
      id: 'NO_ACTIVITY_DEVICE',
      displayTitle: 'Tracker',
      test: obj => !obj.user.has_fitbit
    },
    {
      id: 'NO_BLOOD_PRESSURE_DEVICE',
      displayTitle: 'BP monitor',
      test: obj => !obj.user.bt_bp_imei
    },
    {
      id: 'NO_PULSEOX_DEVICE',
      displayTitle: 'Pulse Ox',
      test: obj => !obj.user.has_pulseox
    },
    {
      id: 'NO_BLOOD_GLUCOSE_DEVICE',
      displayTitle: 'Glucometer',
      test: obj => !obj.user.iglucose_id
    }
  ]
}

export const MEMBER_STATUS = MEMBER_STATUS_FILTERS

export const getNoDeviceGroup = () => {
  const config = productConfig().members.refineSidebar
  const filters = NO_DEVICE.filters.filter(item => config.noDeviceOptions.indexOf(item.id) > -1)

  return {
    displayTitle: NO_DEVICE.displayTitle,
    filters
  }
}

export const getNoDataGroup = () => {
  const config = productConfig().members.refineSidebar
  const filters = NO_DATA.filters.filter(item => config.noDataOptions.indexOf(item.id) > -1)

  return {
    displayTitle: NO_DATA.displayTitle,
    filters
  }
}

export const getFilterGroups = () => {
  const configGroups = productConfig().members.refineSidebar.filterGroups
  const filterGroups = {}

  configGroups.forEach(item => {
    switch (item) {
      case 'PROGRAM_STEP':
        filterGroups[item] = PROGRAM_STEP
        break
      case 'PREGNANCY_STATUS':
        filterGroups[item] = PREGNANCY_STATUS
        break
      case 'LIFESTYLE_COACH':
        filterGroups[item] = LIFESTYLE_COACH
        break
      case 'WEIGHT_IOM':
        filterGroups[item] = WEIGHT_IOM
        break
      case 'ACTIVITY_GOAL':
        filterGroups[item] = ACTIVITY_GOAL
        break
      case 'WEIGHT_GAIN':
        filterGroups[item] = WEIGHT_GAIN
        break
      case 'IN_CARE_MANAGEMENT':
        filterGroups[item] = IN_CARE_MANAGEMENT
        break
      case 'NO_DATA':
        filterGroups[item] = getNoDataGroup()
        break
      case 'NO_DEVICE':
        filterGroups[item] = getNoDeviceGroup()
        break
      case 'MEMBER_STATUS':
        filterGroups[item] = MEMBER_STATUS
        break
      default:
        break
    }
  })

  return filterGroups
}
