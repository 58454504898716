import _ from 'lodash'
import React from 'react'
import moment from 'moment'
import { preciseRound, convertKgToLb } from '../unitConversionUtils'

import {
  TIMEZONE_DISPLAY_MAP, COMMUNICATION_PREFERENCE_MAP,
  getSimpleDisplayName,
  getDisplayAge,
  getDisplayHeight,
  getDisplayPhoneNumber,
  getDisplayEmail,
  getDisplayTimezone,
  getDisplayPlatform,
  getDisplaySyncStatus,
  getDisplayBodyTraceBpStatus,
  getDisplayBodyTraceScaleStatus,
  getDisplayIGlucoseStatus,
  getDisplayNoninStatus,
  getDisplayCaregiverName,
  getDisplayCaregiverEmail,
  getDisplayBioStickerStatus,
  getDisplayStreetAddress,
  generateEmptySpan,
  GENDER_MAP
} from '../../utils/baseStringUtils'

import {
  renderField,
  renderInput,
  renderDateInput,
  renderInputWithUnit,
  renderSelect
} from '../../components/elements/EjentaForm'
import Typography from '@mui/material/Typography'
import { biohubTooltip, biostickerTooltip, bodytraceBpTooltip, bodytraceScaleTooltip, iGlucoseTooltip, noninTooltip } from '../../constants/strings'
import { COACH_MAP, getCustomMemberFields, IN_CM_MAP } from '../../constants/customMemberFields'
import { productConfig } from '../../config/config-service'

export function isMemberStarred (clinicianId, memberStarInfo) {
  const hasInfo = memberStarInfo?.find(x => x.clinician_id === clinicianId)
  return !!hasInfo
}

export function getCurrentOrFuturePause (pauses) {
  if (pauses != null) {
    for (const pause of pauses) {
      const cond1 = pause.start != null
      // resume date either null or in the future
      const cond2 = pause.resume == null || moment().isBefore(moment(pause.resume))
      if (cond1 && cond2) {
        return pause
      }
    }
  }
  return null
}

export function isMemberPaused (pauses) {
  const pause = getCurrentOrFuturePause(pauses)
  if (pause != null) {
    return moment().isSameOrAfter(pause.start)
  }
  return false
}

export function isMemberAwaitingDevices (orderStatuses) {
  if (orderStatuses && Object.keys(orderStatuses).length > 0) {
    for (const deviceOrderStatuses of Object.values(orderStatuses)) {
      // TODO: simplify this logic once backend returns a more consistent structure
      const deviceDelivered = deviceOrderStatuses?.status === 'Delivered' ||
        (typeof (deviceOrderStatuses?.some) === 'function' && deviceOrderStatuses?.some(orderStatus => orderStatus.status === 'Delivered'))
      if (!deviceDelivered) {
        return true
      }
    }
  }
  return false
}

export function getPauseDescription (pause) {
  function formatDate (date) {
    return moment(date).format('M/DD/YY')
  }

  if (!pause) return null

  const startDate = pause.start ? moment(pause.start) : null
  const endDate = pause.resume ? moment(pause.resume).subtract(1, 'days') : null

  const presentOrFutureTense = moment().isSameOrAfter(pause.start) ? 'are' : 'will be'
  if (pause.resume == null) {
    return (<Typography variant='span'> Alerts {presentOrFutureTense} suspended starting <strong>{formatDate(startDate)}</strong> until canceled.</Typography>)
  } else {
    return (<Typography variant='span'> Alerts {presentOrFutureTense} suspended starting <strong>{formatDate(startDate)}</strong> up to and including <strong>{formatDate(endDate)}</strong>.</Typography>)
  }
}

/* *****************************************************************************
 * Member field validation (when creating a new member)
 * *****************************************************************************/

export function validateMemberForm (formData, fields, isNewMember = false) {
  const errors = {}

  _.forOwn(fields, (val, key) => {
    // If the field is not optional, require the a value
    if (!val.optional && !formData[key] && formData[key] !== 0) { // allow 0 as a valid value
      errors[key] = 'This field is required.'
    }
  })

  if (errors.care_manager_id) delete errors.care_manager_id // not required, but we don't want empty option either

  // Custom validation messages
  if (errors.signed_consent) errors.signed_consent = 'Consent is required.'
  if (errors.signed_tos) errors.signed_tos = 'Terms of Service consent is required.'

  if (errors.signedConsentAndTos) {
    if (isNewMember) errors.signedConsentAndTos = 'Consent is required.'
    else delete errors.signedConsentAndTos
  }

  return errors
}

// Transform keys from e.g. 'start_weight_in_kg' to 'startWeight'
export function getMemberProfileFormErrors (errors, memberFields) {
  const formErrors = {}

  _.forOwn(errors, (errorText, errorField) => {
    const formFieldName = _.findKey(memberFields, f => f.apiField === errorField)
    if (!formFieldName) return

    formErrors[formFieldName] = errorText
  })

  return formErrors
}

/* *****************************************************************************
 * Member fields (when creating a new member)
 *
 * Fields:
 *  - apiField: {string} name for the field in the JSON object sent to the server
 *  - required: {bool} whether or not this field is required, defaults to true
 * *****************************************************************************/

const getTextInputOptions = (target, name, label, disabled = false, type = 'text', normalize = undefined, tooltip = undefined) => {
  const fieldOptions = {
    name,
    label,
    disabled,
    type,
    normalize,
    tooltip,
    component: renderInput
  }
  return renderField(target, fieldOptions)
}

export const getSelectInputOptions = (target, name, label, options, disabled = false) => {
  const fieldOptions = {
    name,
    label,
    options,
    disabled,
    type: 'select',
    component: renderSelect
  }
  return renderField(target, fieldOptions)
}

export const getDateInputOptions = (target, name, label, disabled = false, tooltip = undefined) => {
  const fieldOptions = {
    name,
    label,
    disabled,
    tooltip,
    type: 'date',
    dateFormat: 'Y-MM-DD',
    component: renderDateInput
  }
  return renderField(target, fieldOptions)
}

const getUnitInputOptions = (target, name, label, unit, maxValue = undefined, minValue = 1, disabled = false) => {
  const fieldOptions = {
    name,
    label,
    unit,
    disabled,
    minValue,
    maxValue,
    component: renderInputWithUnit,
    type: 'text' // for compatibility with old browsers
  }
  return renderField(target, fieldOptions)
}

export const baseMemberFields = {
  studyId: {
    apiField: 'medical_id',

    formInput () {
      return getTextInputOptions(this, 'studyId', 'MRN')
    },
    readOnlyFormInput () {
      return getTextInputOptions(this, 'studyId', 'MRN', true)
    }
  },

  firstName: {
    apiField: 'first_name',
    formInput () {
      return getTextInputOptions(this, 'firstName', 'First name')
    }
  },

  lastName: {
    apiField: 'last_name',
    formInput () {
      return getTextInputOptions(this, 'lastName', 'Last name')
    }
  },

  birthDate: {
    apiField: 'date_of_birth',

    // Parse the date of birth in UTC timezone instead of browser timezone, since the server passes
    // down the date with a superfluous timestamp
    accessor: user => moment.utc(user.date_of_birth).format('Y-MM-DD'),

    formInput () {
      return getDateInputOptions(this, 'birthDate', 'Date of birth (mm/dd/yyyy)')
    },
    readOnlyFormInput () {
      return getDateInputOptions(this, 'birthDate', 'Date of birth (mm/dd/yyyy)', true)
    }
  },

  heightFeet: {
    optional: true,
    accessor: (user) => user.height_in_cm ? Math.floor((user.height_in_cm / 2.54) / 12) : '',
    formInput () {
      return getUnitInputOptions(this, 'heightFeet', 'Height (ft)', 'ft')
    }
  },

  heightInches: {
    optional: true,
    apiField: 'height_in_cm',
    accessor: (user) => user.height_in_cm ? (user.height_in_cm / 2.54) % 12 : '',
    formInput () {
      return getUnitInputOptions(this, 'heightInches', 'Height (in)', 'in', 11, 0)
    }
  },

  startWeight: {
    apiField: 'start_weight_in_kg',
    accessor: (user) => user.start_weight_in_kg ? preciseRound(convertKgToLb(user.start_weight_in_kg), 2) : '',
    formInput () {
      return getUnitInputOptions(this, 'startWeight', 'Start weight', 'lb', 999)
    }
  },

  deliveryDate: {
    optional: true,
    apiField: 'delivery_date',
    accessor: user => (user.delivery_date ? moment(user.delivery_date).format('Y-MM-DD') : null),
    formInput () {
      return getDateInputOptions(this, 'deliveryDate', 'Actual delivery date (mm/dd/yyyy)', false, 'Update once the member has delivered her baby.')
    }
  },

  email: {
    apiField: 'email',
    formInput () {
      return getTextInputOptions(this, 'email', 'Email address', false, 'email')
    }
  },

  phone: {
    apiField: 'phone',
    formInput () {
      return getTextInputOptions(this, 'phone', 'Phone number', false, 'tel')
    }
  },

  timezone: {
    apiField: 'timezone',
    formInput () {
      return getSelectInputOptions(this, 'timezone', 'Timezone', TIMEZONE_DISPLAY_MAP)
    }
  },

  caregiverName: {
    optional: true,
    apiField: 'caregiver_name',

    formInput () {
      return getTextInputOptions(this, 'caregiverName', 'Caregiver name')
    }
  },

  caregiverEmail: {
    optional: true,
    apiField: 'caregiver_email',

    formInput () {
      const normalize = value => value || null
      return getTextInputOptions(this, 'caregiverEmail', 'Caregiver email address', false, 'email', normalize)
    }
  },

  caregiverPhone: {
    optional: true,
    apiField: 'caregiver_phone',

    formInput () {
      return getTextInputOptions(this, 'caregiverPhone', 'Caregiver phone number', false, 'tel')
    }
  },

  bodyTraceScaleImei: {
    optional: true,
    apiField: 'bt_scale_imei',

    formInput () {
      return getTextInputOptions(this, 'bodyTraceScaleImei', 'BodyTrace scale ID number', false, 'text', null, bodytraceScaleTooltip)
    },
    readOnlyFormInput () {
      return getTextInputOptions(this, 'bodyTraceScaleImei', 'BodyTrace scale ID number', true, 'text', null, bodytraceScaleTooltip)
    }
  },

  bodyTraceBpImei: {
    optional: true,
    apiField: 'bt_bp_imei',

    formInput () {
      return getTextInputOptions(this, 'bodyTraceBpImei', 'BodyTrace BP cuff ID number', false, 'text', null, bodytraceBpTooltip)
    },
    readOnlyFormInput () {
      return getTextInputOptions(this, 'bodyTraceBpImei', 'BodyTrace BP cuff ID number', true, 'text', null, bodytraceBpTooltip)
    }
  },

  bioStickerId: {
    optional: true,
    apiField: 'biosticker_id',

    formInput () {
      return getTextInputOptions(this, 'bioStickerId', 'Bio ID number', false, 'text', null, biostickerTooltip)
    },
    readOnlyFormInput () {
      return getTextInputOptions(this, 'bioStickerId', 'Bio ID number', true, 'text', null, biostickerTooltip)
    }
  },

  bioHubId: {
    optional: true,
    apiField: 'biohub_id',

    formInput () {
      return getTextInputOptions(this, 'bioHubId', 'BioHub ID number', false, 'text', null, biohubTooltip)
    },
    readOnlyFormInput () {
      return getTextInputOptions(this, 'bioHubId', 'BioHub ID number', true, 'text', null, biohubTooltip)
    }
  },

  iGlucoseId: {
    optional: true,
    apiField: 'iglucose_id',

    formInput () {
      return getTextInputOptions(this, 'iGlucoseId', 'iGlucose ID number', false, 'text', null, iGlucoseTooltip)
    },

    readOnlyFormInput () {
      return getTextInputOptions(this, 'iGlucoseId', 'iGlucose ID number', true, 'text', null, iGlucoseTooltip)
    }
  },

  noninId: {
    optional: true,
    apiField: 'nonin_id',

    formInput () {
      return getTextInputOptions(this, 'noninId', 'Nonin ID number', false, 'text', null, noninTooltip)
    },
    readOnlyFormInput () {
      return getTextInputOptions(this, 'noninId', 'Nonin ID number', true, 'text', null, noninTooltip)
    }
  },

  communicationPreference: {
    apiField: 'communication',
    formInput () {
      return getSelectInputOptions(this, 'communicationPreference', 'Communication preference', COMMUNICATION_PREFERENCE_MAP)
    }
  }
}

/* *****************************************************************************
 * Member fields (when displaying member's profile)
 *
 * Every field should have the following properties:
 *    - {string} fieldDisplayStr
 *    - {user => string} accessor
 * *****************************************************************************/

export const baseMemberProfileFields = {
  NAME: {
    fieldDisplayStr: 'Name',
    accessor: user => getSimpleDisplayName(user)
  },
  MRN: { // way #1 to display medical_id
    fieldDisplayStr: 'MRN',
    accessor: user => user.medical_id
  },
  STUDY_ID: { // way #2 to display medical_id
    fieldDisplayStr: 'Study ID',
    accessor: user => user.medical_id
  },
  MEMBER_ID: { // way #3 to display medical_id
    fieldDisplayStr: 'Member ID',
    accessor: user => user.medical_id
  },
  STARTED: {
    fieldDisplayStr: 'Joined Ejenta',
    accessor: (user) => {
      if (user.signed_tos) return moment(user.signed_tos).format('MMM D, Y')
      else return generateEmptySpan('Pending')
    }
  },
  STATUS: {
    fieldDisplayStr: 'Status',
    accessor: (user) => {
      if (user.archived_since) {
        return `Archived ${moment(user.archived_since).format('MMM D, Y')}`
      } else if (isMemberPaused(user.pauses)) {
        return 'Suspended'
      } else if (!user.signed_tos) {
        return generateEmptySpan('Pending')
      }

      return 'Active'
    }
  },
  DOB: {
    fieldDisplayStr: 'DOB',
    accessor: user =>
      // Parse the date of birth in UTC timezone instead of browser timezone, since the server
      // passes down the date with a superfluous timestamp
      `${moment.utc(user.date_of_birth).format('MMM D, Y')} (age ${getDisplayAge(user)})`
  },
  HEIGHT: {
    fieldDisplayStr: 'Height',
    accessor: user => getDisplayHeight(user)
  },
  EMAIL: {
    fieldDisplayStr: 'Email',
    accessor: user => getDisplayEmail(user.email)
  },
  PHONE: {
    fieldDisplayStr: 'Phone',
    accessor: user => getDisplayPhoneNumber(user.phone)
  },
  TIMEZONE: {
    fieldDisplayStr: 'Timezone',
    accessor: user => getDisplayTimezone(user.timezone)
  },
  FITBIT: {
    fieldDisplayStr: 'Fitbit account',
    accessor: user => getDisplaySyncStatus(user.has_fitbit)
  },
  DEVICE_PLATFORM: {
    fieldDisplayStr: 'Device platform',
    accessor: user => getDisplayPlatform(user.device_platform)
  },
  BODYTRACE_SCALE: {
    fieldDisplayStr: 'BodyTrace scale',
    accessor: user => getDisplayBodyTraceScaleStatus(user)
  },
  BODYTRACE_BP: {
    fieldDisplayStr: 'BodyTrace BP',
    accessor: user => getDisplayBodyTraceBpStatus(user)
  },
  IGLUCOSE: {
    fieldDisplayStr: 'iGlucose',
    accessor: user => getDisplayIGlucoseStatus(user)
  },
  BIOSTICKER: {
    fieldDisplayStr: 'Bio',
    accessor: user => getDisplayBioStickerStatus(user)
  },
  NONIN: {
    fieldDisplayStr: 'Nonin',
    accessor: user => getDisplayNoninStatus(user)
  },
  STREET_ADDRESS: {
    fieldDisplayStr: 'Address',
    accessor: getDisplayStreetAddress
  },
  CAREGIVER_NAME: {
    fieldDisplayStr: 'Caregiver',
    accessor: user => getDisplayCaregiverName(user)
  },
  CAREGIVER_EMAIL: {
    fieldDisplayStr: '- Email',
    isSubfield: true,
    accessor: user => getDisplayCaregiverEmail(user)
  },
  CAREGIVER_PHONE: {
    fieldDisplayStr: '- Phone',
    isSubfield: true,
    accessor: user => getDisplayPhoneNumber(user.caregiver_phone)
  },
  GENDER: {
    fieldDisplayStr: 'Sex',
    accessor: user => GENDER_MAP[user.gender]
  },
  LIFESTYLE_COACH: {
    fieldDisplayStr: 'Lifestyle coach',
    accessor: user => COACH_MAP[user.lifestyle_coach]
  },
  CELL: {
    fieldDisplayStr: 'Cell',
    accessor: user => getDisplayPhoneNumber(user.phone)
  },
  HOME_PHONE: {
    fieldDisplayStr: 'Home',
    accessor: user => getDisplayPhoneNumber(user.home_phone)
  },
  PCP: {
    fieldDisplayStr: 'PCP',
    accessor: user => user.pcp || generateEmptySpan()
  },
  PCP_PHONE: {
    fieldDisplayStr: 'PCP phone',
    accessor: user => getDisplayPhoneNumber(user.pcp_phone)
  },
  IN_CARE_MANAGEMENT: {
    fieldDisplayStr: 'In CM',
    accessor: user => IN_CM_MAP[user.in_care_management ?? 0]
  },
  CARE_MANAGER: {
    fieldDisplayStr: 'Care Manager',
    accessor: (user, careManagers) => {
      if (user.care_manager_id) return getCareManagerOptions(careManagers)[user.care_manager_id] ?? 'Unknown'
      else return generateEmptySpan()
    }
  },
  LCU: {
    fieldDisplayStr: 'LCU',
    accessor: user => user.lcu || generateEmptySpan()
  },
  EMPLOYER_GROUP: {
    fieldDisplayStr: 'Employer group',
    accessor: user => user.employer_group || generateEmptySpan()
  }
}

export const getCareManagerOptions = (careManagers) => {
  const allOptions = {
    DEFAULT_OPTION: 'None'
  }

  if (careManagers?.length) {
    careManagers.forEach(option => {
      allOptions[option.id] = option.name
    })
  }
  return allOptions
}

export const getProfileSidebarFields = () => {
  const displayFields = []
  productConfig().profile.sidebarFields.forEach(field => {
    displayFields.push(baseMemberProfileFields[field])
  })

  return displayFields
}

export const getProfileDeviceFields = () => {
  const devices = productConfig().profile.devices
  const deviceFields = []

  if (devices.hasLinkedAccounts) deviceFields.push(baseMemberProfileFields.FITBIT)
  if (devices.hasBodyTraceScale) deviceFields.push(baseMemberProfileFields.BODYTRACE_SCALE)
  if (devices.hasBodyTraceBP) deviceFields.push(baseMemberProfileFields.BODYTRACE_BP)
  if (devices.hasIGlucose) deviceFields.push(baseMemberProfileFields.IGLUCOSE)
  if (devices.hasBiosticker) deviceFields.push(baseMemberProfileFields.BIOSTICKER)

  return deviceFields
}

export const getDefaultMemberFieldValuesFromConfig = (clinicianUser) => {
  const fieldValues = { ...productConfig().profile.defaultFormFieldValues }
  if (fieldValues.timezone) {
    fieldValues.timezone = clinicianUser?.timezone ?? fieldValues.timezone
  }
  return fieldValues
}

export const getProfileMemberFields = () => {
  const baseMemberFieldsToRemove = productConfig().profile.baseMemberFieldsToRemove
  return _.merge(_.omit(_.cloneDeep(baseMemberFields), baseMemberFieldsToRemove), getCustomMemberFields())
}

export const getHasValidConsentDocs = (user) => {
  if (productConfig().profile.hasCustomTOSRules) {
    return !!user.signed_tos && !!(user.clinician || user.signed_consent)
  } else return !!user.signed_tos
}
