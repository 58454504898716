import React from 'react'
import moment from 'moment'
import restApi from '../../js/api'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { analyticsSlice } from '../../js/store/analytics'
import { Box } from '@mui/material'
import DateRangeController from '../../js/components/elements/DateRangeController'

function AnalyticsDateRange (props) {
  const handleFetchAnalyticsDateRange = (selectedStartDate, selectedEndDate) => {
    const startDate = selectedStartDate
    const endDate = selectedEndDate

    if (startDate.isSame(moment(props.analyticsStore.dateRangeStart), 'day') &&
      endDate.isSame(moment(props.analyticsStore.dateRangeEnd), 'day')) {
      return
    }

    props.analyticsActions.setDateRange({ startDate: startDate.valueOf(), endDate: endDate.valueOf() })
    props.apiActions.getAnalyticsOverview({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD')
    })
  }

  const renderAxDateRange = () => {
    return (
      <Box data-testid='analytics-date-range'>
        <DateRangeController
          onRangeSelect={handleFetchAnalyticsDateRange}
          startDate={moment(props.analyticsStore.dateRangeStart)}
          endDate={moment(props.analyticsStore.dateRangeEnd)}
        />
      </Box>
    )
  }

  return renderAxDateRange()
}

function getPropsFromStore (state) {
  return {
    analyticsStore: state.analytics
  }
}

function getPropsFromActions (dispatch) {
  return {
    apiActions: bindActionCreators(restApi.actions, dispatch),
    analyticsActions: bindActionCreators(analyticsSlice.actions, dispatch)
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(AnalyticsDateRange)
