import React, { useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import restApi from '../../../../api'
import SchedulePause from './SchedulePause'
import { getCurrentOrFuturePause } from '../../../../utils/member/baseProfileUtils'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import { Checkbox, FormControlLabel } from '@mui/material'

function ProfileStatusPane (props) {
  const [isConfirmActive, setIsConfirmActive] = useState(false)
  const [isConfirmChecked, setIsConfirmChecked] = useState(false)

  const handleArchiveMember = () => {
    props.apiActions.archiveUser({ id: props.memberObj.user.id }, {})
    props.onMemberArchived()
  }

  const handleSetConfirmActive = () => {
    setIsConfirmActive(true)
  }

  const handleToggleConfirmCheckbox = () => {
    setIsConfirmChecked(!isConfirmChecked)
  }

  const handleCancelArchiveMember = () => {
    setIsConfirmChecked(false)
    setIsConfirmActive(false)
  }

  // Initial call to action, i.e. "Archive Mike's account"
  const renderArchiveInitialState = () => {
    const memberName = props.memberObj.user.first_name

    return (
      <Button color='secondary' variant='contained' data-testid='edit-profile-pane__archive-button' onClick={handleSetConfirmActive}>
        Archive {memberName}'s account
      </Button>
    )
  }

  // The confirmation call to action, i.e. checkbox and "Permanently archive Mike's account"
  const renderArchiveConfirmation = () => {
    return (
      <div className='edit-profile-pane__row'>
        <FormControlLabel
          sx={{ '& .MuiTypography-root': { fontSize: 16 } }}
          control={
            <Checkbox
              size='small'
              data-testid='edit-profile-pane__archive-checkbox'
              name='archive-confirm'
              checked={isConfirmChecked}
              onChange={() => handleToggleConfirmCheckbox()}
              onKeyDown={props.handlePreventEnterKey}
            />
          }
          label='Yes, permanently archive'
        />

        <DialogActions data-testid='edit-profile-pane__buttons'>
          <Button variant='outlined' data-testid='edit-profile-pane__cancel-archive-button' onClick={handleCancelArchiveMember}>Cancel</Button>

          <Button variant='contained' data-testid='edit-profile-pane__confirm-archive-button' onClick={handleArchiveMember} disabled={!isConfirmChecked}>
            Confirm
          </Button>
        </DialogActions>
      </div>
    )
  }

  const renderArchive = () => {
    return (
      <div>
        <div className='u-strong u-bottom-pad--small'>Archive Account</div>

        <div>Archiving a member means that data will no longer be collected. <span className='u-strong'>Archiving a member is permanent and cannot be undone!</span></div>

        <div className='u-top-pad'>
          {!isConfirmActive && renderArchiveInitialState()}
          {isConfirmActive && renderArchiveConfirmation()}
        </div>
      </div>
    )
  }

  const renderProfileStatusPane = () => {
    // Don't show if the user is already archived
    if (props.memberObj.user.archived_since) return null

    const pause = getCurrentOrFuturePause(props.memberObj.user.pauses)
    const classItems = props.hideBorder ? '' : 'edit-profile-pane'

    return (
      <div className={classItems}>
        {!props.hideTitle && <div className='edit-profile-pane__header'>Member status</div>}

        <SchedulePause pause={pause} memberObj={props.memberObj} apiActions={props.apiActions} />

        <hr />

        {renderArchive()}
      </div>
    )
  }

  return renderProfileStatusPane()
}

function getPropsFromStore () {
  return {}
}

function getPropsFromActions (dispatch) {
  return {
    apiActions: bindActionCreators(restApi.actions, dispatch)
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(ProfileStatusPane)
