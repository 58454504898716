import React from 'react'
import classnames from 'classnames'
import restApi from '../../../../api'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { membersViewSlice } from '../../../../store/membersView'
import { isMemberStarred } from '../../../../utils/member/baseProfileUtils'
import { productConfig } from '../../../../config/config-service'
import { Box, Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import ProfileCell from '../../../elements/ProfileCell/ProfileCell'
import WeightCell from '../../../elements/MemberCell/WeightCell'
import BloodPressureCell from '../../../elements/MemberCell/BloodPressureCell'
import BloodGlucoseCell from '../../../elements/MemberCell/BloodGlucoseCell'
import PregnancyWeightCell from '../../../elements/MemberCell/PregnancyWeightCell'
import ActivityCell from '../../../elements/MemberCell/ActivityCell'
import ProgramStepCell from '../../../elements/MemberCell/ProgramStepCell'
import BloodPressureRow from '../../../elements/MemberCell/BloodPressureRow'
import BloodOxygenCell from '../../../elements/MemberCell/BloodOxygenCell'
import SurveyResponseCell from '../../../elements/MemberCell/SurveyResponseCell'
import TemperatureCell from '../../../elements/MemberCell/TemperatureCell'
import HeartRateCell from '../../../elements/MemberCell/HeartRateCell'
import RespiratoryRateCell from '../../../elements/MemberCell/RespiratoryRateCell'

function MemberRow (props) {
  const handleStarClick = (e) => {
    const currentlyStarred = isMemberStarred(props.clinicianId, props.memberObj.user.stars)

    if (currentlyStarred) props.apiActions.unstarUser()
    else props.apiActions.starUser()
    e.preventDefault()
  }

  const renderColumnMetric = (metricType, member) => {
    switch (metricType) {
      case 'pregnancyWeight':
        return (<div className='members-list__weight-col'><PregnancyWeightCell memberObj={member} /></div>)
      case 'weight':
        return (<div className='members-list__weight-col'><WeightCell memberObj={member} /></div>)
      case 'bloodPressure':
        return (<div className='members-list__bp-col'><BloodPressureCell memberObj={member} /></div>)
      case 'extendedBloodPressure':
        return (<Grid container item className='members-list__bp-col' sx={{ borderLeft: '1px dotted #eee' }}> <BloodPressureRow memberObj={member} /> </Grid>)
      case 'bloodGlucose':
        return (<div className='members-list__bg-col'><BloodGlucoseCell memberObj={member} /></div>)
      case 'activity':
        return (<div className='members-list__activity-col'><ActivityCell memberObj={member} /></div>)
      case 'programStep':
        return (<div className='members-list__activity-col'><ProgramStepCell memberObj={member} /></div>)
      case 'pulseOxygen':
        return (<div className='members-list__pulseox-col'><BloodOxygenCell memberObj={member} /></div>)
      case 'survey':
        return (<div className='members-list__survey_response-col'><SurveyResponseCell memberObj={member} /></div>)
      case 'temperature':
        return (<div className='members-list__temp-col'><TemperatureCell memberObj={member} /></div>)
      case 'heartRate':
        return (<div className='members-list__hr-col'><HeartRateCell memberObj={member} /></div>)
      case 'respiratoryRate':
        return (<div className='members-list__rr-col'><RespiratoryRateCell memberObj={member} /></div>)

      default:
        return null
    }
  }

  const renderMemberRow = () => {
    const config = productConfig()
    const headerRows = config.members.mainPage.dataRow
    const isExtendedBPMetric = config.members.mainPage.isExtendedBloodPressureMetric
    const columnSize = 12.0 / headerRows.length

    const classNames = classnames({
      'members-list__row': true,
      'members-list__row--is-archived': props.memberObj.user.archived_since
    })

    const memberStarred = isMemberStarred(props.clinicianId, props.memberObj.user.stars)

    return (
      <Link
        to={`/profile/${props.memberObj.user.id}`}
        className='u-link-block'
        key={props.memberObj.user.id}
        data-testid='members-list__row'
        style={{ color: 'inherit', textDecoration: 'none' }}
      >
        <Grid container className={classNames}>
          <Grid item xs={4}>
            <Box sx={{ padding: '.5rem 0 .5rem', width: '100%' }}>
              <ProfileCell memberStarred={memberStarred} handleStarClick={e => handleStarClick(e)} user={props.memberObj.user} memberObj={props.memberObj} />
            </Box>
          </Grid>
          <Grid item container xs sx={{ alignContent: 'center' }}>
            {isExtendedBPMetric && renderColumnMetric('extendedBloodPressure', props.memberObj)}

            {!isExtendedBPMetric && headerRows.map(item => {
              const borderClass = classnames({
                'members-list__main-header': !item.hideBorder
              })
              return (
                <Grid item xs={item.columnSize ?? columnSize} key={item.metricType} className={borderClass}>
                  {renderColumnMetric(item.metricType, props.memberObj)}
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Link>
    )
  }

  return renderMemberRow()
}

function getPropsFromStore (state) {
  return {
    clinicianId: state.userSession.user && state.userSession.user.id
  }
}

function getPropsFromActions (dispatch, ownProps) {
  return {
    storeActions: bindActionCreators(membersViewSlice.actions, dispatch),
    apiActions: {
      starUser: () => {
        const userId = ownProps.memberObj.user.id
        dispatch(restApi.actions.starUser({ id: userId }))
      },
      unstarUser: () => {
        const userId = ownProps.memberObj.user.id
        dispatch(restApi.actions.unstarUser({ id: userId }))
      }
    }
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(MemberRow)
