import { getEmptyChart } from './baseChartUtils'
import { getFullChartOptions } from './baseRespiratoryRateChart'

export default function getRespiratoryRateChartOptions ({
  memberObj, dashboardViewStore,
  showMemberAlerts, handleAlertClick
}) {
  if (!memberObj.respiratory_rate) return getEmptyChart()

  return getFullChartOptions(memberObj, dashboardViewStore, showMemberAlerts,
    handleAlertClick)
}
