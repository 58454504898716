import React, { useState } from 'react'
import getPregnancyWeightChartOptions from '../../../utils/charts/pregnancyWeightChart'
import ChartModule from './ChartModule'
import TabPanel from '../../layout/TabPanel'
import WeightTable from '../Tables/WeightTable'
import { Box, Card, CardContent, Tab, Tabs, Typography } from '@mui/material'

function a11yProps (index) {
  return {
    id: `weight-metric__tab-${index}`,
    'aria-controls': `weight-metric__tab-${index}`
  }
}

export default function PregnancyWeightChart (props) {
  const [selectedWeightTab, setSelectedWeightTab] = useState(0)

  const renderChartPanel = () => {
    return (
      <Card className='chart-card'>
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'end', m: 0 }}>
            <Typography variant='h6' color='#7f7f7f'> Weight </Typography>
            <Tabs
              sx={{ borderBottom: 1, borderColor: 'divider' }}
              value={selectedWeightTab}
              onChange={(_e, val) => setSelectedWeightTab(val)}
              aria-label='Profile tabs'
            >
              <Tab data-testid='weight__charts-tab' label='Graph' {...a11yProps(0)} />
              <Tab data-testid='weight__table-tab' label='Table' {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={selectedWeightTab} index={0} noPadding>
            <ChartModule
              hideHeader
              hideBorder
              showChartActions
              dashboardViewStore={props.dashboardViewStore}
              memberObj={props.memberObj}
              metricType='weight'
              getChartOptions={getPregnancyWeightChartOptions}
              showMemberAlerts
              handleAlertClick={props.handleAlertClick} // eslint-disable-line react/jsx-handler-names
              handleDeleteWeight={props.handleDeleteWeight} // eslint-disable-line react/jsx-handler-names
              handleRestoreWeight={props.handleRestoreWeight} // eslint-disable-line react/jsx-handler-names
            />
          </TabPanel>
          <TabPanel value={selectedWeightTab} index={1} noPadding>
            <WeightTable
              hideHeader
              hideBorder
              dashboardViewStore={props.dashboardViewStore}
              memberObj={props.memberObj}
            />
          </TabPanel>
        </CardContent>
      </Card>

    )
  }

  return renderChartPanel()
}
