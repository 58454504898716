import React, { useState, useEffect, useMemo } from 'react'
import debounce from 'lodash.debounce'

import { InputAdornment, OutlinedInput, FormControl } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

/* *****************************************************************************
 * Search bar element.
 * *****************************************************************************/

function SearchBar (props) {
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    if (props.resetSearch) setSearchQuery('')
  }, [props.resetSearch])

  useEffect(() => {
    setSearchQuery(props.value)
  }, [props.value])

  useEffect(() => {
    // Stop the invocation of the debounced function after unmounting
    return () => {
      debounceSearchChange.cancel()
    }
  }, [])

  const handleSearchChange = (e) => {
    const queryValue = e.target.value
    props.handleChange(queryValue)
  }

  const debounceSearchChange = useMemo(() => debounce(handleSearchChange, 300), [])

  const renderSearchBar = () => {
    return (
      <FormControl fullWidth sx={{ backgroundColor: 'white' }}>
        <OutlinedInput
          id='search-bar__input'
          className='search-bar__input'
          value={searchQuery}
          placeholder={props.placeholder}
          onChange={(e) => { setSearchQuery(e.target.value); debounceSearchChange(e) }}
          onEmptied={handleSearchChange}
          size='small'
          endAdornment={
            <InputAdornment position='end'>
              <SearchIcon sx={{
                backgroundColor: theme => theme.palette.primary.main,
                color: 'white',
                p: 1,
                borderRadius: '0 4px 4px 0',
                mr: '-0.8rem'
              }}
              />
            </InputAdornment>
          }
          inputProps={{
            'aria-label': 'search query'
          }}
        />
      </FormControl>

    )
  }

  return renderSearchBar()
}

export default SearchBar
