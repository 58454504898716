import React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import DialogActions from '@mui/material/DialogActions'
import MemberPregnancyFormFields from './MemberPregnancyFormFields'
import { Form } from 'react-final-form'
import { EjentaForm } from '../../elements/EjentaForm'
import { Alert } from '@mui/material'
import { getProfileMemberFields, validateMemberForm } from '../../../utils/member/baseProfileUtils'

/* ****************************************************************************
 * This form is used for editing existing members with pregnancy information
 * ****************************************************************************/
export default function MemberPregnancyForm (props) {
  const handleCancel = (e) => {
    e.preventDefault()
    e.stopPropagation()

    props.onCancel()
  }

  const renderSubmit = ({ pristine, submitting, hasValidationErrors }) => {
    const submitButtonText = props.isNewMember ? 'Add member' : 'Save changes'
    let submitButton

    if (submitting) {
      submitButton = <Button variant='contained' disabled>Updating...</Button>
    } else {
      submitButton = <Button variant='contained' type='submit' data-testid='member-profile__add-member-button' disabled={pristine || hasValidationErrors}>{submitButtonText}</Button>
    }

    return (
      <DialogActions data-testid='add-member__buttons'>
        {submitButton} <Button variant='outlined' onClick={handleCancel}>Cancel</Button>
      </DialogActions>
    )
  }

  const renderMemberPregnancyForm = () => {
    return (
      <Form
        onSubmit={props.onSubmit}
        initialValues={props.getInitialValues()}
        validate={values => validateMemberForm(values, getProfileMemberFields(), false)}
      >
        {({ handleSubmit, pristine, submitting, submitError, hasValidationErrors }) => (
          <EjentaForm onSubmit={handleSubmit}>
            {submitError && <Alert severity='error'>{submitError}</Alert>}

            <Box sx={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
              <MemberPregnancyFormFields isPregnancy />

              {renderSubmit({ pristine, submitting, hasValidationErrors })}
            </Box>
          </EjentaForm>
        )}
      </Form>
    )
  }

  return renderMemberPregnancyForm()
}
