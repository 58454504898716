import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import restApi from '../../../api'
import { EjentaForm, renderField, renderInput } from '../../elements/EjentaForm'
import { Box, Button, Typography, Alert } from '@mui/material'
import { Form } from 'react-final-form'

/* ******************************************************************************
 * First-time password form
 * ******************************************************************************/
function BaseFirstTimePasswordForm (props) {
  const handleFormSubmit = (formData) => {
    const submitPromise = new Promise((resolve, reject) => {
      props.apiActions.firstTimePassword(
        {
          id: props.user.id,
          promise: { resolve, reject }
        },
        { body: JSON.stringify({ password: formData.newpassword }) }
      )
    })

    return submitPromise.catch(data => data)
  }

  const renderForm = () => {
    const newPasswordField = {
      name: 'newpassword',
      type: 'password',
      label: 'New password',
      component: renderInput
    }

    const newPasswordConfirmField = {
      name: 'newPasswordConfirm',
      type: 'password',
      label: 'Confirm new password',
      component: renderInput
    }

    return (
      <div>
        <Typography variant='h6' textAlign='center'>Welcome to Ejenta!</Typography>
        <Box sx={{ textAlign: 'center', width: '100%', mb: 2 }} data-testid='login-form__intro'>
          <Typography>To get started, create a new password.</Typography>
        </Box>
        <Form
          onSubmit={handleFormSubmit}
          validate={values => {
            const errors = {}
            if (!values.newpassword) errors.newpassword = 'Must enter a new password'
            if (values.newpassword !== values.newPasswordConfirm) errors.newPasswordConfirm = 'Passwords must match'
            return errors
          }}
        >
          {({ handleSubmit, pristine, submitting, submitError, hasValidationErrors }) => (
            <EjentaForm onSubmit={handleSubmit}>
              {submitError && <Alert severity='error'>{submitError}</Alert>}

              {renderField({}, newPasswordField)}
              {renderField({}, newPasswordConfirmField)}

              <Box mt={2} />

              {submitting && <Button variant='contained' color='secondary' disabled> Loading... </Button>}
              {!submitting && <Button variant='contained' fullWidth size='small' type='submit' disabled={pristine || hasValidationErrors}> Create password </Button>}
            </EjentaForm>
          )}
        </Form>
      </div>
    )
  }

  const renderFirstTimePasswordForm = () => {
    return (
      <div className='login-form'>
        {renderForm()}
      </div>
    )
  }

  return renderFirstTimePasswordForm()
}

function getPropsFromStore (state) {
  return {
    user: state.userSession.user
  }
}

function getPropsFromActions (dispatch) {
  return {
    apiActions: bindActionCreators(restApi.actions, dispatch)
  }
}

const FirstTimePasswordForm = connect(getPropsFromStore, getPropsFromActions)(
  BaseFirstTimePasswordForm
)

export default FirstTimePasswordForm
