import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { EjentaForm, renderField, renderInput } from '../../elements/EjentaForm'
import { Alert, Button, Box, Typography, Stack } from '@mui/material'
import restApi from '../../../api'
import BasicFrame from '../../layout/BasicFrame'
import { Form } from 'react-final-form'

function BaseForgotPasswordForm (props) {
  const [resetPasswordEmail, setResetPasswordEmail] = useState('')

  useEffect(() => {
    return () => {
      props.resetSendResetPasswordEmailApi()
    }
  }, [])

  const onSubmit = (formData) => {
    setResetPasswordEmail(formData.email)
    const submitPromise = new Promise((resolve, reject) => {
      props.apiActions.sendResetPasswordEmail(
        { promise: { resolve, reject } },
        { body: JSON.stringify({ email: formData.email }) }
      )
    })

    return submitPromise.catch(data => data)
  }

  const renderSubmit = ({ pristine, submitting, hasValidationErrors }) => {
    if (submitting) {
      return (
        <Button variant='outlined' color='secondary' disabled type='submit'> Sending... </Button>
      )
    }

    return (<Button variant='contained' type='submit' disabled={pristine || hasValidationErrors}> Send reset password link </Button>)
  }

  const renderForm = () => {
    const emailField = {
      name: 'email',
      type: 'email',
      label: 'Email Address',
      testId: 'forgot-password__email',
      placeholder: 'name@email.com',
      component: renderInput
    }

    return (
      <Box>
        <Box sx={{ width: '100%', textAlign: 'center', mb: 3 }}>
          <Typography variant='h6'>Forgot Password</Typography>
          <Typography variant='body2'>Enter your email address below. You'll receive a link to create a new password.</Typography>
        </Box>
        <Form
          onSubmit={onSubmit}
          validate={values => {
            const errors = {}
            if (!values.email) errors.email = 'Email address is required'
            return errors
          }}
        >
          {({ handleSubmit, pristine, submitting, submitError, hasValidationErrors }) => (
            <EjentaForm onSubmit={handleSubmit}>
              <Stack spacing={2}>
                {submitError && <Alert severity='error'>{submitError}</Alert>}

                {renderField({}, emailField)}

                {renderSubmit({ pristine, submitting, hasValidationErrors })}
              </Stack>
            </EjentaForm>
          )}
        </Form>
      </Box>
    )
  }

  const renderDone = () => {
    return (
      <Stack spacing={2}>
        <Typography variant='h6' textAlign='center' width='100%'>Forgot Password</Typography>

        <Alert severity='success' sx={{ mb: 1 }}>Email sent to {resetPasswordEmail}</Alert>
        <Typography variant='body1' textAlign='center'> Check your email for a link to create a new password. </Typography>
      </Stack>
    )
  }

  const renderForgotPasswordForm = () => {
    return (
      <BasicFrame>
        <div className='login-form'>
          {!props.sendResetPasswordEmailApi.sync && renderForm()}
          {props.sendResetPasswordEmailApi.sync && renderDone()}
        </div>
      </BasicFrame>

    )
  }

  return renderForgotPasswordForm()
}

function getPropsFromStore (state) {
  return {
    sendResetPasswordEmailApi: state.api.sendResetPasswordEmail
  }
}

function getPropsFromActions (dispatch) {
  return {
    apiActions: bindActionCreators(restApi.actions, dispatch),
    resetSendResetPasswordEmailApi: bindActionCreators(
      restApi.actions.sendResetPasswordEmail.reset,
      dispatch
    )
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(BaseForgotPasswordForm)
