import React from 'react'
import { getDisplayAge } from '../../../utils/baseStringUtils'

function ProfileDetail (props) {
  const detailStr = getDisplayAge(props.user)

  return <span>{detailStr} yrs</span>
}

export default ProfileDetail
