import React from 'react'
import formurlencoded from 'form-urlencoded'
import restApi from '../../../api'
import BasicFrame from '../../layout/BasicFrame'
import { connect } from 'react-redux'
import { EjentaForm, renderField, renderInput } from '../../elements/EjentaForm'
import { Alert, Button, Box, Link, Typography } from '@mui/material'
import { Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'

/* ******************************************************************************
 * Login form
 * ******************************************************************************/

function BaseLoginForm (props) {
  const handleLogin = (formData, resolve, reject) => {
    props.loginApi(
      { promise: { resolve, reject } },
      { body: formurlencoded(formData) }
    )
  }

  const handleSubmitClicked = async (formData) => {
    const submitPromise = new Promise((resolve, reject) => {
      handleLogin(formData, resolve, reject)
    })

    try {
      return await submitPromise
    } catch (data) {
      return { [FORM_ERROR]: data }
    }
  }

  const renderSubmitButton = ({ submitting }) => {
    if (submitting) {
      return <Button variant='outlined' color='secondary' fullWidth disabled>Signing in...</Button>
    }

    return <Button variant='contained' type='submit' tabIndex={1} data-testid='login-form__sign-in' fullWidth>Sign in</Button>
  }

  const renderLoginForm = () => {
    const emailAddressField = {
      name: 'email',
      label: 'Email Address',
      component: renderInput,
      type: 'email',
      placeholder: 'name@email.com',
      testId: 'login-form__email'
    }

    const passwordField = {
      testId: 'login-form__password',
      name: 'password',
      label: 'Password',
      component: renderInput,
      type: 'password'
    }

    return (
      <BasicFrame>
        <div className='login-form'>

          {props.userSessionStore.showSessionExpireMessage &&
            <Alert severity='info' data-testid='login-form__message' sx={{ mb: 2 }}>
              To protect your data, you have been signed out after a period of inactivity. Please sign in again.
            </Alert>}

          <Box sx={{ width: '100%', textAlign: 'center', mb: 3 }}>
            <Typography variant='h6'>Welcome</Typography>
            <Typography variant='subtitle2'>Sign in to view your dashboard.</Typography>
          </Box>

          <Box sx={{ mt: 1, mb: 2 }}>
            <Form
              onSubmit={handleSubmitClicked} validate={values => {
                const errors = {}
                if (!values.email) errors.email = 'Email address is required'
                if (!values.password) errors.password = 'Password is required'
                return errors
              }}
            >
              {({ handleSubmit, submitting, submitError }) => (
                <EjentaForm onSubmit={handleSubmit}>
                  {submitError && <Alert severity='error' data-testid='login-form__error' sx={{ mb: 2 }}>{submitError}</Alert>}
                  {renderField({}, emailAddressField)}
                  <Box mt={2} />
                  {renderField({}, passwordField)}
                  <Link href='/forgot' role='link' display='flex' justifyContent='right' underline='none' color='primary' tabIndex={4} data-testid='login-form__forgot-password'>
                    Forgot password?
                  </Link>

                  <Box sx={{ mt: 3, mb: 1 }}>{renderSubmitButton({ submitting })}</Box>
                </EjentaForm>
              )}
            </Form>
          </Box>
          <Typography variant='caption' mt={3}>
            <span>By signing in, you agree to Ejenta's </span>
            <Link href='//ejenta.com/terms.pdf' underline='none' target='_blank' data-testid='login-form__terms-of-service' rel='noreferrer'>Terms of Service</Link>
            <span> and </span>
            <Link href='//ejenta.com/privacy.pdf' underline='none' target='_blank' data-testid='login-form__privacy-policy' rel='noreferrer'>Privacy Policy</Link>.
          </Typography>
        </div>
      </BasicFrame>
    )
  }

  return renderLoginForm()
}

function getPropsFromStore (state) {
  return {
    userSessionStore: state.userSession
  }
}

function getPropsFromActions (dispatch) {
  return {
    loginApi (pathvars, options) {
      dispatch(restApi.actions.login(pathvars, options))
    },
    loginAmazonApi (pathvars, options) {
      dispatch(restApi.actions.loginAmazon(pathvars, options))
    }
  }
}

const LoginForm = connect(getPropsFromStore, getPropsFromActions)(BaseLoginForm)

export default LoginForm
