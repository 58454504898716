
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { productConfig } from '../../config/config-service'

export default function TabPanel (props) {
  const [panelHeight, setPanelHeight] = useState('100%')
  const { children, value, index, ...other } = props
  const config = productConfig().tabPanel

  useEffect(() => {
    if (!props.isModal) return
    setPanelHeight(props.panelHeight ?? config.panelHeight)
  }, [props.isModal])

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`edit-member-profile__tabpanel-${index}`}
      aria-labelledby={`edit-member-profile__tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: props.noPadding ? 0 : 3, height: panelHeight }}>
          {children}
        </Box>
      )}
    </Box>
  )
}
