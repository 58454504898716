import React from 'react'
import restApi from '../../js/api'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { analyticsSlice } from '../../js/store/analytics'
import {
  ContentMain,
  ContentMainIntro,
  ContentMainIntroHeader,
  ContentMainBody
} from '../../js/components/layout/ContentMain'
import Gender from '../modules/Gender'
import Age from '../modules/Age'
import Enrollment from '../modules/Enrollment'
import LoadingIndicator from '../../js/components/elements/LoadingIndicator'
import { productConfig } from '../../js/config/config-service'

/* ****************************************************************************
 * Analytics overview page
 * ****************************************************************************/
function AnalyticsPopulation (props) {
  const profileFormFields = productConfig().profile.customFormFields
  const renderEnrollmentSection = () => {
    return (
      <div className='ax-subsection'>
        <div className='ax-subsection__header' data-testid='ax-subsection__header-enrollment'>Enrollment</div>

        <div>
          <div data-testid='ax-column__enrollment'>
            <Enrollment
              data-testid='ax-subsection__enrollment'
              users={props.analyticsStore.allUsers}
              analyticsActions={props.analyticsActions}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderDemographicsSection = () => {
    const hasGender = profileFormFields.indexOf('gender') !== -1
    return (
      <div className='ax-subsection'>
        <div className='ax-subsection__header' data-testid='ax-subsection__header-demographics'>Demographics</div>

        <div>
          <div data-testid='ax-column__demographics'>
            <Age
              data-testid='ax-subsection__age'
              users={props.analyticsStore.onboardedUsers}
              analyticsActions={props.analyticsActions}
            />
          </div>
          {hasGender &&
            <div>
              <Gender
                data-testid='ax-subsection__gender'
                users={props.analyticsStore.onboardedUsers}
                analyticsActions={props.analyticsActions}
              />
            </div>}
        </div>
      </div>
    )
  }

  const renderAxPopulationScreen = () => {
    return (
      <ContentMain data-testid='analytics-page__population-screen' drawerWidth={220}>
        <ContentMainIntro>
          <ContentMainIntroHeader title='Population' />
        </ContentMainIntro>

        {props.analyticsStore.isDateRangeLoading &&
          <div className='ax-main__loading'>
            <LoadingIndicator />
          </div>}

        <ContentMainBody>
          {!props.analyticsStore.isDateRangeLoading &&
            <div className='ax-main__body'>
              {renderEnrollmentSection()}
              {renderDemographicsSection()}
            </div>}
        </ContentMainBody>

      </ContentMain>
    )
  }

  return renderAxPopulationScreen()
}

function getPropsFromStore (state) {
  return {
    analyticsStore: state.analytics
  }
}

function getPropsFromActions (dispatch) {
  return {
    apiActions: bindActionCreators(restApi.actions, dispatch),
    analyticsActions: bindActionCreators(analyticsSlice.actions, dispatch)
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(AnalyticsPopulation)
