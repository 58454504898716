
import _ from 'lodash'
import React, { useState } from 'react'
import moment from 'moment'
import { getDeltaArrow, getDisplayDelta, momentCalendarConfig } from '../../../utils/baseStringUtils'
import { getSystolicDeltaInRange, getDiastolicDeltaInRange, getBpDisplayString } from '../../../utils/member/analyticsUtils'

import EmptyCell from './EmptyCell'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'

export default function BloodPressureRow (props) {
  const [memberObj] = useState(props.memberObj)

  if (!memberObj.user.has_bp || !memberObj.blood_pressure) {
    const message = !memberObj.user.bt_bp_imei ? 'Device not set up' : 'No BP data'
    return <EmptyCell message={message} />
  }

  const latestBpData = _.get(memberObj, ['blood_pressure', 'latest_measurement'])
  const previousBpData = _.get(memberObj, ['blood_pressure', 'penultimate_measurement'])
  const systolicDelta = getSystolicDeltaInRange(memberObj)
  const diastolicDelta = getDiastolicDeltaInRange(memberObj)
  const lastBpMeasurement = moment(latestBpData.timestamp)
  const thisPeriod = memberObj.blood_pressure.information.periods[0]
  const numMeasurementsThisPeriod = _.get(memberObj, ['blood_pressure', 'data', 'length'])
  const columnSize = 12.0 / 5

  const previousCol = (
    <Box>
      <div className='member-row__latest'> {getBpDisplayString(previousBpData)} </div>
      <div className='member-row__latest-timestamp'> {previousBpData && moment(previousBpData.timestamp).calendar(null, momentCalendarConfig)} </div>
    </Box>
  )

  const latestBpCol = (
    <Box>
      <div className='member-row__latest'> {getBpDisplayString(latestBpData)} </div>
      <div className='member-row__latest-timestamp'> {lastBpMeasurement.calendar(null, momentCalendarConfig)} </div>
    </Box>
  )

  const latestHrCol = (
    <Box>
      <div className='member-row__latest'> {`${Math.round(latestBpData.heart_rate)}`} </div>
    </Box>
  )

  const avgBpTooltipId = `tooltip-avgbp-${memberObj.user.id}`
  let avgBpTooltipContent = `Based on ${numMeasurementsThisPeriod} measurement`
  if (numMeasurementsThisPeriod !== 1) {
    avgBpTooltipContent += 's'
  }

  const avgBpCol = (
    <Tooltip title={avgBpTooltipContent} data-testid={avgBpTooltipId} id={avgBpTooltipId} placement='bottom' arrow>
      <div className='member-row__latest'>
        {!!thisPeriod && `${thisPeriod.avg_systolic}/${thisPeriod.avg_diastolic}`}
        {!thisPeriod && '—/—'}
      </div>
    </Tooltip>
  )

  // by default, determine arrow direction by change in systolic
  const deltaArrow = systolicDelta !== 0 ? getDeltaArrow(systolicDelta, false) : getDeltaArrow(diastolicDelta, false)
  const deltaCol = (
    <span className='member-row__delta'> {getDisplayDelta(systolicDelta)}/{getDisplayDelta(diastolicDelta)} {deltaArrow} </span>
  )

  return (
    <>
      <Grid item xs={columnSize}> {latestBpCol} </Grid>
      <Grid item xs={columnSize}> {previousCol} </Grid>
      <Grid item xs={columnSize}> {latestHrCol} </Grid>
      <Grid item xs={columnSize}> {avgBpCol} </Grid>
      <Grid item xs={columnSize}> {deltaCol} </Grid>
    </>
  )
}
