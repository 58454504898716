import {
  convertLbToKg,
  convertFtInToCm
} from '../utils/unitConversionUtils'

export const SESSION_WARNING_LENGTH = 10 * 60 * 1000 // 10 minutes
export const SESSION_CHECK_INTERVAL = 2 * 1000 // 2 seconds

export const MS_IN_A_DAY = 86400000

export const DAYS_OF_WEEK = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
]

export const DATE_DAY_FORMAT_SANS_YEAR = 'ddd, MMM D'
export const DATE_DAY_FORMAT_WITH_YEAR = 'ddd, MMM D Y'

export const FEATURE_GATES = {
  SHOW_ONLY_ANALYTICS: 'fg/showOnlyAnalytics'
}

export const COMMUNICATION_TYPES = {
  CLINICIAN: {
    email: 'Send individual alert and alert digest emails',
    none: 'Do not send emails'
  },

  MEMBER: {
    email: 'Send emails',
    text: 'Send texts / SMS',
    none: 'Do not send messages'
  }
}

export const CARE_MANAGER_DEFAULT_OPTION = {
  id: 'DEFAULT_OPTION',
  name: 'All'
}

export const descendingComparator = (a, b) => {
  if (b < a) return -1
  if (b > a) return 1
  return 0
}

export const getProfileFormInitialValues = (memberFields, memberObj) => {
  const initialValues = {}
  Object.entries(memberFields).forEach(([inputName, field]) => {
    const value = field.accessor ? field.accessor(memberObj.user) : memberObj.user[field.apiField]
    initialValues[inputName] = value
  })

  return initialValues
}

export const getMemberApiBody = (memberFields, formData, group) => {
  const apiBody = {}

  Object.entries(formData).forEach(([formKey, formVal]) => {
    const apiField = memberFields[formKey].apiField
    if (apiField) apiBody[apiField] = formVal === '' ? null : formVal
  })

  // Get any keys missing from the formData, but present in the memberFields
  Object.keys(memberFields).forEach(key => {
    const validKey = !Object.hasOwn(formData, key) ? key : null
    if (validKey) {
      const apiField = memberFields[validKey].apiField
      if (apiField) apiBody[apiField] = null
    }
  })

  // Convert any measurements from IS to Metric
  if (formData.heightFeet) apiBody.height_in_cm = convertFtInToCm(formData.heightFeet, formData.heightInches)
  if (formData.dryWeight) apiBody.dry_weight_in_kg = convertLbToKg(formData.dryWeight)
  if (formData.startWeight) apiBody.start_weight_in_kg = convertLbToKg(formData.startWeight)

  // add any optional values
  if (formData.care_manager_id) {
    if (formData.care_manager_id === 'DEFAULT_OPTION') apiBody.care_manager_id = null
    else apiBody.care_manager_id = formData.care_manager_id
  }
  if (formData.in_care_management !== undefined) apiBody.in_care_management = +formData.in_care_management

  apiBody.group = group

  return apiBody
}

export const getActivityGoal = (member) => {
  return member.weekly_activity_goal.current_progress.value
}

export const CARE_PLAN_NOTIFY_OPTIONS = [
  {
    key: 'clinicians',
    value: 'Clinicians only'
  },
  {
    key: 'member',
    value: 'Members only'
  },
  {
    key: 'clinicians_and_member',
    value: 'Clinicians and Members'
  },
  {
    key: 'none',
    value: 'None (Dashboard Only)'
  }
]

export const isAlertOpen = (alertObj) => {
  return alertObj.status === 'OPEN'
}
