import _ from 'lodash'
import { createSlice } from '@reduxjs/toolkit'

export const ANALYTICS_SCREENS = {
  POPULATION: 'Population',
  TRENDS: 'Trends [BETA]'
}

/* ******************************************************************************
 *
 * (Clinician experience only)
 * Data & interface state for analytics page
 *
 * ******************************************************************************/

/*
 * Reducer
 */

const initialState = {
  prevLocationKey: null,

  // Page
  currentScreen: 'POPULATION',

  // Population
  allUsers: null,
  allAlerts: null,
  onboardedUsers: null,

  // Date range
  dateRangeStart: null,
  dateRangeEnd: null,
  isDateRangeLoading: false,

  // Drilldown
  drilldown: null
}

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    resetView: (state) => {
      state.prevLocationKey = null
      state.currentScreen = 'POPULATION'
      state.allUsers = null
      state.allAlerts = null
      state.onboardedUsers = null
      state.dateRangeStart = null
      state.dateRangeEnd = null
      state.isDateRangeLoading = false
      state.drilldown = null
    },
    setLocationKey: (state, action) => {
      state.prevLocationKey = action.payload
    },
    populateAnalytics: (state, action) => {
      state.isDateRangeLoading = false
      state.allUsers = action.payload.data.users
      state.allAlerts = action.payload.data.alerts
      state.onboardedUsers = _.filter(action.payload.data.users, u => !u.info.not_onboarded)
    },
    setCurrentScreen: (state, action) => {
      state.currentScreen = action.payload
    },
    setDateRange: (state, action) => {
      state.dateRangeStart = action.payload.startDate
      state.dateRangeEnd = action.payload.endDate
      state.isDateRangeLoading = true
    },
    setDrilldown: (state, action) => {
      state.drilldown = action.payload
    }
  }
})

/*
 * Exports
 */
export const {
  resetView,
  setLocationKey,
  populateAnalytics,
  setCurrentScreen,
  setDateRange,
  setDrilldown
} = analyticsSlice.actions

export { analyticsSlice }
