
import React, { useState } from 'react'
import moment from 'moment'
import { momentCalendarConfig } from '../../../utils/baseStringUtils'
import EmptyCell from './EmptyCell'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

export default function TemperatureCell (props) {
  const [memberObj] = useState(props.memberObj)

  if (!memberObj.user.has_temperature || !memberObj.temperature) {
    return <EmptyCell message='No temperature data' />
  }

  const lastMeasurement = moment(memberObj.temperature.latest_measurement.timestamp)
  const latestCol = (
    <Box>
      <div className='member-row__latest'> {`${Math.round(memberObj.temperature.latest_measurement.temperature * 10) / 10}${memberObj.temperature.information.units.temperature}`} </div>
      <div className='member-row__latest-timestamp'> {lastMeasurement.calendar(null, momentCalendarConfig)} </div>
    </Box>
  )

  return (
    <Grid container>
      <Grid item xs={12}> {latestCol} </Grid>
    </Grid>
  )
}
