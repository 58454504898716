/* eslint-disable object-shorthand */

import _ from 'lodash'
import moment from 'moment'
import classnames from 'classnames'
import {
  getBaseSeriesOptions,
  getPointDateString,
  getBaseChartOptions,
  addPausePlotBands
} from './baseChartUtils'
import {
  getMarkerRadius,
  getColumnWidth,
  getXAxisForRange
} from './chartUtils'
import { isAlertOpen } from '../../constants/constants'

export const baseBloodPressureChartOptions = {
  chart: { className: 'blood_pressure-chart' },

  legend: {
    symbolRadius: 0,
    itemMarginTop: 12
  },

  tooltip: {
    headerFormat: null,
    pointFormatter: function format () {
      return `
        <strong>${this.name}</strong><br/>
        ${this.description}<br />
        <small>Source: ${this.dataSource ?? 'Unknown'}</small>
        `
    },
    useHTML: true,
    padding: 12
  },

  plotOptions: {
    line: {
      marker: { enabled: true }
    }
  },

  yAxis: [{
    title: { text: '' },
    labels: { format: '{value}' }
  }],

  series: []
}

function getBloodPressureSeries (options, memberObj, dashboardViewStore, bloodPressureType) {
  const newOptions = _.cloneDeep(options)

  // Set marker radius
  _.set(newOptions, ['plotOptions', 'line', 'marker', 'radius'], getMarkerRadius(dashboardViewStore))

  const bloodPressureMeasurements = _.reverse(
    _.cloneDeep(memberObj.blood_pressure.data)).map((m) => {
    let unit = ''
    if (bloodPressureType === 'heart_rate') {
      unit = ' beats/min'
    }

    const point = {
      x: +moment(m.timestamp),
      y: m[bloodPressureType],
      dataSource: m.source,
      name: getPointDateString(moment(m.timestamp), true),
      description: Math.round(m[bloodPressureType]) + unit
    }
    return point
  })

  const bloodPressureMeasurementsSeries = _.extend({
    name: (bloodPressureType.charAt(0).toUpperCase() + bloodPressureType.slice(1)).split('_').join(' '),
    data: bloodPressureMeasurements,
    zIndex: 1,

    type: 'line',
    className: `series-${bloodPressureType}Measurements`
  }, getBaseSeriesOptions())

  newOptions.series.push(bloodPressureMeasurementsSeries)
  return newOptions
}

export function getHeartRateSeries (options, memberObj, dashboardViewStore) {
  let newOptions = _.cloneDeep(options)

  newOptions = getBloodPressureSeries(newOptions, memberObj, dashboardViewStore, 'heart_rate')

  return newOptions
}

export function getBloodPressureRangeSeries (options, memberObj, dashboardViewStore) {
  const newOptions = _.cloneDeep(options)

  const bloodPressureMeasurements = _.reverse(
    _.cloneDeep(memberObj.blood_pressure.data)).map((m) => {
    const point = {
      x: +moment(m.timestamp),
      dataSource: m.source,
      low: m.diastolic,
      high: m.systolic,
      name: getPointDateString(moment(m.timestamp), true),
      description: `${Math.round(m.systolic)}/${Math.round(m.diastolic)} mmHg`
    }
    return point
  })

  const bloodPressureMeasurementsSeries = _.extend({
    name: 'Blood pressure',
    data: bloodPressureMeasurements,
    zIndex: 1,
    pointWidth: Math.min(10, getColumnWidth(dashboardViewStore)),

    type: 'columnrange',
    className: 'series-bpMeasurements'
  }, getBaseSeriesOptions())

  newOptions.series.push(bloodPressureMeasurementsSeries)
  return newOptions
}

/* ********************************************************************************
 * Blood pressure alerts series
 * ********************************************************************************/

export function getBloodPressureAlertsSeries (memberObj, options, handleAlertClick) {
  if (!(memberObj.alerts && memberObj.alerts.length)) return options
  const bpAlerts = _.filter(memberObj.alerts, a => a.metric_type === 'blood_pressure' || (a.metric_type === 'multi' && a.member_content.indexOf('blood pressure') !== -1))
  if (!bpAlerts.length) return options

  const alertsData = bpAlerts.map((alertObj) => {
    /*
     * Get the Y-value of the marker
     */
    let pointY = 0
    const bpMeasurement = memberObj.blood_pressure.data.find(
      d => d.timestamp === alertObj.measurement_timestamp
    )

    // If there is a corresponding measurement, display alert marker above the highest submetric
    if (bpMeasurement) {
      pointY = _.max([bpMeasurement.systolic, bpMeasurement.diastolic, bpMeasurement.heart_rate]) * 1.05
    } else pointY = memberObj.blood_pressure.data[0].systolic * 1.05 // Otherwise just use the first systolic value we have

    /*
     * Get marker styling
     */
    const marker = {}
    if (alertObj.type === 'bp_tracking' || alertObj.type === 'multi_tracking') {
      marker.symbol = 'circle'
    }

    const isOpen = isAlertOpen(alertObj)

    const classNames = classnames({
      'is-open': isOpen,
      'is-closed': !isOpen,
      'is-tracking': alertObj.type === 'bp_tracking' || alertObj.type === 'multi_tracking'
    })

    /*
     * Define Highcharts point
     */
    const point = {
      name: 'Blood pressure alert',
      description: alertObj.provider_content,
      x: moment(alertObj.measurement_timestamp),
      y: pointY,
      marker,

      className: classNames,
      events: {
        click: () => {
          if (handleAlertClick) {
            handleAlertClick(alertObj)
          }
        }
      }
    }

    return point
  })

  const alertsSeries = _.extend({
    name: 'BP alerts',

    type: 'scatter',
    className: 'series-bloodPressureAlerts',
    lineWidth: 0,
    marker: {
      symbol: 'triangle-down',
      radius: 7,
      lineWidth: 0
    },

    tooltip: {
      useHTML: true,
      // do not use pointFormat. Despite documentation, it does not allow access to all point object properties.
      headerFormat: null,
      pointFormatter: function format () {
        return `
          <strong>${this.name}</strong><br/>
          ${this.description}
        `
      }
    },

    data: alertsData,

    zIndex: 2 // so the alerts are on top of the data
  }, getBaseSeriesOptions())

  const newOptions = _.cloneDeep(options)
  newOptions.series.push(alertsSeries)
  return newOptions
}

/* ********************************************************************************
 * Chart options
 * ********************************************************************************/

export function getFullChartOptions (memberObj, dashboardViewStore, showMemberAlerts,
  handleAlertClick) {
  const xAxisOptions = { xAxis: getXAxisForRange(memberObj, dashboardViewStore) }

  let options = _.merge(
    getBaseChartOptions(),
    baseBloodPressureChartOptions,
    xAxisOptions
  )

  options = getBloodPressureRangeSeries(options, memberObj, dashboardViewStore)
  options = getHeartRateSeries(options, memberObj, dashboardViewStore)

  if (showMemberAlerts) {
    options = getBloodPressureAlertsSeries(memberObj, options, handleAlertClick)
  }

  options = addPausePlotBands(memberObj.user.pauses, options, true)

  return options
}
