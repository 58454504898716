import React from 'react'
import CarePlanItem from '../CarePlanItem'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { CARE_PLAN_NOTIFY_OPTIONS } from '../../../../constants/constants'
import { Checkbox, FormControlLabel } from '@mui/material'

export default class StaticNewChatItem extends CarePlanItem {
  fetchItemData () {
    this.setState({ itemData: { default: true, notify: 'clinicians' } })
  }

  renderEnabled () {
    return (
      <FormControlLabel
        sx={{ '& .MuiTypography-root': { fontSize: 16 } }}
        control={<Checkbox size='small' id='newChatCheckbox' checked disabled />}
        label=''
      />
    )
  }

  renderGoal () {
    return (<div> Chat </div>)
  }

  renderClinicianTrigger () {
    return (<div> If a new chat message is sent. </div>)
  }

  renderCondensed () {
    return (<div>New chat message sent</div>)
  }

  renderNotify () {
    const itemData = this.state.itemData

    return (
      <Select
        disabled
        fullWidth
        size='small'
        className='care-plan-item__select'
        value={itemData.notify}
        id={itemData.notify}
      >
        {CARE_PLAN_NOTIFY_OPTIONS.map(item => <MenuItem className='care-plan-item__select-item' key={item.key} value={item.key}>{item?.value}</MenuItem>)}
      </Select>
    )
  }
}
