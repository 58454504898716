import React, { useState } from 'react'
import { Field } from 'react-final-form'
import { productConfig } from '../../../config/config-service'
import { Box, Grid } from '@mui/material'
import { renderCheckboxInput } from '../../elements/EjentaForm'
import MemberDevicesFormFields from './MemberDevicesFormFields'
import { getProfileMemberFields } from '../../../utils/member/baseProfileUtils'

export default function MemberProfileFormFields (props) {
  const memberFields = getProfileMemberFields()
  const [profileConfig] = useState(productConfig().profile)

  const getGridItem = (item, xs = 12, sm = 6) => {
    if (item === 'care_manager_id') { // special case
      return (
        <Grid container item columnSpacing={1} sx={{ mb: 2 }}>
          <Grid item xs={6}>{memberFields[item].formInput(props.careManagers, props.change)}</Grid>
        </Grid>
      )
    }

    return (<Grid item xs={xs} sm={sm} key={item}>{memberFields[item].formInput(props.change)}</Grid>)
  }

  const renderDefaultFormFields = () => {
    return (
      <Grid container spacing={1}>
        {profileConfig.defaultFormFields.map(item => getGridItem(item))}
      </Grid>
    )
  }

  const renderCustomFormFields = () => {
    const isSmallField = (fieldName) => {
      return fieldName.indexOf('height') > -1 || fieldName.indexOf('Weight') > 0
    }

    return (
      <Grid container spacing={1} mt={0.5}>
        {profileConfig.customFormFields.map(item => {
          const sm = isSmallField(item) ? 3 : 6
          const xs = isSmallField(item) ? 6 : 12
          return getGridItem(item, xs, sm)
        })}
      </Grid>
    )
  }

  const renderPregnancyFormFields = () => {
    return (
      <Grid container spacing={1}>
        {profileConfig.pregnancyFields.map(item => getGridItem(item))}
      </Grid>
    )
  }

  const renderOrderFields = () => {
    if (!profileConfig.orderFields.length) return null
    const hasScale = profileConfig.orderFields.includes('orderBodyTraceScale')
    const hasBP = profileConfig.orderFields.includes('orderBodyTraceBloodPressure')

    return (
      <Grid container spacing={1}>
        {hasScale &&
          <Grid item sm={6} xs={12}>
            <Field
              type='checkbox'
              name='orderBodyTraceScale'
              component={renderCheckboxInput}
              label='Order and ship BodyTrace scale to this member'
            />
          </Grid>}
        {hasBP &&
          <Grid item sm={6} xs={12}>
            <Field
              type='checkbox'
              name='orderBodyTraceBloodPressure'
              component={renderCheckboxInput}
              label='Order and ship BodyTrace BP cuff to this member'
            />
          </Grid>}
      </Grid>
    )
  }

  const renderCheckboxes = () => {
    return (
      <Grid container spacing={1} mt={0.5}>
        {profileConfig.formCheckboxes.map(item => getGridItem(item, 12, 6))}
      </Grid>
    )
  }

  const renderProfileFormFields = () => {
    return (
      <Box data-testid='member-profile-form-fields' sx={{ mb: 2 }}>
        {renderDefaultFormFields()}
        {renderCustomFormFields()}
        {props.isNewMember && renderPregnancyFormFields()}
        {props.isNewMember && profileConfig.orderFields.length === 0 &&
          <Box sx={{ mt: 1 }}> <MemberDevicesFormFields /> </Box>}
        {props.isNewMember && renderOrderFields()}
        {props.isNewMember && renderCheckboxes()}
      </Box>
    )
  }

  return renderProfileFormFields()
}
