import { Box, Divider } from '@mui/material'
import React from 'react'
import WeightRangeItem from '../RangeItems/WeightRangeItem'

export default function WeightCard (props) {
  const sectionData = props.sectionData

  const renderDivider = (shouldShow) => {
    return (<>{shouldShow && !props.shouldRenderCondensed && <Divider className='care-plan-item__divider' />}</>)
  }

  return (
    <div className='care-plan__section' data-testid='care-plan__section__weight'>
      <div className='care-plan__section-header'>Weight</div>
      {sectionData.rangeSubTypes.map((item, i, allElements) => {
        return (
          <Box key={`weightitem__${item}`}>
            <WeightRangeItem {...props} subtype={item} />
            {renderDivider(i < allElements.length - 1)}
          </Box>
        )
      })}
    </div>
  )
}
