import moment from 'moment'
import { isMemberStarred, isMemberPaused } from './member/baseProfileUtils'

export const MEMBER_SORTS = [
  {
    id: 'LAST_NAME',
    displayTitle: 'Last name',
    compare: compareLastName
  },
  {
    id: 'JOIN_DATE',
    displayTitle: 'Date joined',
    compare: compareJoinDate
  },
  {
    id: 'CREATED_DATE',
    displayTitle: 'Date created',
    compare: compareCreatedDate
  }
]

export const MEMBER_STATUS_FILTERS = {
  displayTitle: 'Member status',
  filters: [
    {
      id: 'FILTER/STARRED',
      displayTitle: 'Starred',
      test: (userEntity, clinicianId = null) => clinicianId && isMemberStarred(clinicianId, userEntity.user.stars)
    },
    {
      id: 'FILTER/ACTIVE',
      displayTitle: 'Active',
      test: obj => !(!!obj.user.archived_since || isMemberPaused(obj.user.pauses))
    },
    {
      id: 'FILTER/PAUSED',
      displayTitle: 'Suspended',
      test: obj => isMemberPaused(obj.user.pauses) && !obj.user.archived_since
    },
    {
      id: 'FILTER/ARCHIVED',
      displayTitle: 'Archived',
      test: (obj) => !!obj.user.archived_since
    }
  ]
}

/* ******************************************************************************
 * Helpers
 * ******************************************************************************/

function hasRecentMetricData (member, metric) {
  return !!member[metric] && !!member[metric].data && !!member[metric].data.length
}

export function hasRecentWeightData (member) {
  return hasRecentMetricData(member, 'weight')
}

export function hasRecentActivityData (member) {
  return hasRecentMetricData(member, 'activity')
}

export function hasRecentBpData (member) {
  return hasRecentMetricData(member, 'blood_pressure')
}

export function hasRecentBgData (member) {
  return hasRecentMetricData(member, 'blood_glucose')
}

export function hasRecentPulseOxData (member) {
  return hasRecentMetricData(member, 'pulse_ox')
}

/* ******************************************************************************
 * Sorting
 * ******************************************************************************/

export function compareMemberStatus (a, b) {
  if (a.user.archived_since) return 1
  if (b.user.archived_since) return -1
  return 0
}

export function compareJoinDate (a, b) {
  // Archived members are always last
  const memberStatus = compareMemberStatus(a, b)
  if (memberStatus) return memberStatus

  if (!a.user.signed_tos) return 1
  if (!b.user.signed_tos) return -1

  if (moment(a.user.signed_tos).isBefore(b.user.signed_tos)) return 1
  if (moment(b.user.signed_tos).isBefore(a.user.signed_tos)) return -1

  return 0
}

export function compareCreatedDate (a, b) {
  // Archived members are always last
  const memberStatus = compareMemberStatus(a, b)
  if (memberStatus) return memberStatus

  if (!a.user.created) return 1
  if (!b.user.created) return -1

  if (moment(a.user.created).isBefore(b.user.created)) return 1
  if (moment(b.user.created).isBefore(a.user.created)) return -1

  return 0
}

export function compareLastName (a, b) {
  return (a.user.last_name < b.user.last_name) ? -1 : 1
}

export function compareDeviceStatus (a, b) {
  // If there's no device status for either member, sort by date the member joined
  if (!a.user.bt_scale_imei && !b.user.bt_scale_imei) {
    return compareJoinDate(a, b)
  }
  if (!a.user.bt_scale_imei) return 1
  if (!b.user.bt_scale_imei) return -1

  return 0
}

/* ******************************************************************************
 * URL filtering
 * ******************************************************************************/

export const MEMBERS_URL_FILTERS = {
  PAUSED: 'paused'
}

export class MembersListFilterUtils {
  static [MEMBERS_URL_FILTERS.PAUSED] (obj, isPaused) {
    // If isPaused is undefined or '0', stop here
    if (!+isPaused) return true

    // Otherwise, only allow user objects with a 'paused' value
    return isMemberPaused(obj.user.pauses)
  }
}
