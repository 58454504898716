
import React from 'react'
import { renderLevel } from '../../../../utils/alertUtils'
import { productConfig } from '../../../../config/config-service'
import CarePlanItem from '../CarePlanItem'
import { CAREPLAN_VALUE_TYPES } from '../../../../constants/carePlanValueTypes'

export default class BloodGlucoseRangeItem extends CarePlanItem {
  constructor (props) {
    super(props)
    this.type = 'bg_range'
    this.config = productConfig()
  }

  rangeBloodGlucoseMinInputElement () {
    return this.getInputElement(
      50, 150, 10,
      CAREPLAN_VALUE_TYPES.BLOOD_GLUCOSE_MIN.get(val => val),
      CAREPLAN_VALUE_TYPES.BLOOD_GLUCOSE_MIN.set(val => val)
    )
  }

  rangeBloodGlucoseMaxInputElement () {
    return this.getInputElement(
      200, 500, 10,
      CAREPLAN_VALUE_TYPES.BLOOD_GLUCOSE_MAX.get(val => val),
      CAREPLAN_VALUE_TYPES.BLOOD_GLUCOSE_MAX.set(val => val)
    )
  }

  renderGoal () {
    return <div>Keep blood glucose in range</div>
  }

  renderClinicianTrigger () {
    return <div>If blood glucose is below {this.rangeBloodGlucoseMinInputElement()} or above {this.rangeBloodGlucoseMaxInputElement()} mg/dL.</div>
  }

  renderCondensed () {
    const level = this.state.itemData.level ? renderLevel(this.state.itemData.level, true) : ''
    return <div>{level} BG &lt; {this.getValueElement(CAREPLAN_VALUE_TYPES.BLOOD_GLUCOSE_MIN.get(val => val))} or &gt; {this.getValueElement(CAREPLAN_VALUE_TYPES.BLOOD_GLUCOSE_MAX.get(val => val))} mg/dL</div>
  }
}
