import React from 'react'
import { productConfig } from '../../config/config-service'
import { Box } from '@mui/material'
import 'static/logo-kp.png'
import 'static/logo-point32.png'

export default function HeaderLogoSecondary () {
  const homeConfig = productConfig().home
  const name = productConfig().name

  if (!homeConfig.productLogoLocation) return null

  return (
    <Box sx={{ opacity: 0.8 }}>
      <img src={homeConfig.productLogoLocation} alt={name} width={250} />
    </Box>
  )
}
