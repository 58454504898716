import React, { useState } from 'react'
import moment from 'moment'
import { productConfig } from '../../../config/config-service'
import { getNoDataString, getWeightDeltaInRange } from '../../../utils/member/analyticsUtils'
import { getDisplayDelta, getDeltaArrow, momentCalendarDateConfig } from '../../../utils/baseStringUtils'
import EmptyCell from './EmptyCell'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

export default function WeightCell (props) {
  const [memberObj] = useState(props.memberObj)

  if (!memberObj.user.has_weight || !memberObj.weight) {
    let message = !memberObj.user.bt_scale_imei ? 'Device not set up' : 'No weight data'
    if (productConfig().members.mainPage.hasShippingData) {
      message = getNoDataString(memberObj.user, !!memberObj.user.bt_scale_imei, 'BodyTrace scale')
    }

    return <EmptyCell message={message} />
  }

  const lastWeightMeasurement = moment(memberObj.weight.latest_measurement.timestamp)

  const latestCol = (
    <Box>
      <div className='member-row__latest'> {Math.round(memberObj.weight.latest_measurement.weight.value * 10) / 10} </div>
      <div className='member-row__latest-timestamp'> {lastWeightMeasurement.calendar(null, momentCalendarDateConfig)} </div>
    </Box>
  )

  const weightDelta7d = getWeightDeltaInRange(memberObj)

  const deltaCol = (<span className='member-row__delta'> {getDisplayDelta(weightDelta7d)} {getDeltaArrow(weightDelta7d, false)} </span>)

  return (
    <Grid container>
      <Grid item xs={6}> {latestCol} </Grid>
      <Grid item xs={6}> {deltaCol} </Grid>
    </Grid>
  )
}
