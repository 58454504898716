import React from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { membersViewSlice } from '../../../../store/membersView'
import { productConfig } from '../../../../config/config-service'
import { Grid, Typography } from '@mui/material'
import Tooltip from '../../../elements/Tooltip'

function MemberHeaderRow () {
  const renderHeaderCols = (columns) => {
    const columnSize = 12.0 / columns.length
    return (
      <Grid container item className='members-list__subheader'>
        {columns.map(item =>
          <Grid item xs={columnSize} key={`subcolumn__${item}__${Date.now()}`}>
            <Typography variant='subtitle2' fontWeight={300}>{item}</Typography>
          </Grid>)}
      </Grid>
    )
  }

  const renderMemberHeaderRow = () => {
    const headerRows = productConfig().members.mainPage.dataRow
    const headerColumnSize = 12.0 / headerRows.length

    return (
      <Grid container className='members-list__header'>
        <Grid item xs={4} />
        <Grid container item xs={8}>
          {headerRows.map(item => {
            const headerColumnClass = classnames({
              'members-list__main-header': !item.hideBorder
            })

            return (
              <Grid item xs={item.columnSize ?? headerColumnSize} key={`header__${item.name}__${Date.now()}`} className={headerColumnClass}>
                <Typography variant='subtitle1' fontWeight={400}>
                  {item.name} {item.tooltip && <Tooltip tooltipId={item.tooltip.id} content={item.tooltip.content} direction={item.tooltip.direction} />}
                </Typography>
                {item.columns?.length > 0 && renderHeaderCols(item.columns)}
              </Grid>
            )
          }
          )}
        </Grid>
      </Grid>
    )
  }

  return renderMemberHeaderRow()
}

function getPropsFromStore (state) {
  return {
    expandedColumn: state.membersView.expandedColumn
  }
}

function getPropsFromActions (dispatch) {
  return {
    storeActions: bindActionCreators(membersViewSlice.actions, dispatch)
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(MemberHeaderRow)
