import _ from 'lodash'
import { productConfig } from '../../js/config/config-service'
import { DAYS_OF_WEEK } from '../../js/constants/constants'

export const getAlertDayCounts = (alerts) => {
  if (productConfig().profile.showPregnancyInfo) return getPregnancyAlertDayCounts(alerts)
  else getDefaultAlertDayCounts(alerts)
}

export const getAlertTypeCounts = (alerts) => {
  if (productConfig().profile.showPregnancyInfo) return getPregnancyAlertTypeCounts(alerts)
  else getDefaultAlertTypeCounts(alerts)
}

/* ****************************************************************************
 * Configuration for alertDayChart.js and alertTypeChart.js
 * ****************************************************************************/
export function getDefaultAlertDayCounts (alerts) {
  function sortByDay (alertType) {
    const alertsOfType = _.filter(alerts,
      a => a.alert_type === 'weight_tracking'
        ? (a.alert_type === alertType || a.alert_type === 'multi_tracking')
        : a.alert_type === alertType
    )
    return _.map(DAYS_OF_WEEK, day => _.filter(alertsOfType, a => a.day_of_week === day).length)
  }

  return [
    {
      name: 'Weight gain',
      value: sortByDay('weight_delta'),
      className: 'series-0'
    },
    {
      name: 'Activity decline',
      value: sortByDay('activity_relative'),
      className: 'series-1'
    },
    {
      name: 'No weight data',
      value: sortByDay('weight_tracking'),
      className: 'noData-0'
    },
    {
      name: 'No activity data',
      value: sortByDay('activity_tracking'),
      className: 'noData-1'
    }
  ]
}

export function getDefaultAlertTypeCounts (alerts) {
  return [
    {
      name: 'Weight gain',
      value: _.filter(alerts, a => a.alert_type === 'weight_delta').length,
      className: 'series-0'
    },
    {
      name: 'Activity decline',
      value: _.filter(alerts, a => a.alert_type === 'activity_relative').length,
      className: 'series-1'
    },
    {
      name: 'No weight data',
      value: _.filter(alerts, a => a.alert_type === 'weight_tracking' || a.alert_type === 'multi_tracking').length,
      className: 'noData-0'
    },
    {
      name: 'No activity data',
      value: _.filter(alerts, a => a.alert_type === 'activity_tracking').length,
      className: 'noData-1'
    }
  ]
}

export function getPregnancyAlertDayCounts (alerts) {
  function sortByDay (alertType) {
    const alertsOfType = _.filter(alerts,
      a => a.alert_type === 'weight_tracking'
        ? (a.alert_type === alertType || a.alert_type === 'multi_tracking')
        : a.alert_type === alertType
    )
    return _.map(DAYS_OF_WEEK, day => _.filter(alertsOfType, a => a.day_of_week === day).length)
  }

  return [
    {
      name: 'Weight gain',
      value: sortByDay('weight_relative'),
      className: 'series-0'
    },
    {
      name: 'Over recommended weight',
      value: sortByDay('pregnancy_iom'),
      className: 'series-1'
    },
    {
      name: 'Low activity',
      value: sortByDay('activity_target'),
      className: 'series-2'
    },
    {
      name: 'No weight data',
      value: sortByDay('weight_tracking'),
      className: 'noData-0'
    },
    {
      name: 'No activity data',
      value: sortByDay('activity_tracking'),
      className: 'noData-1'
    }
  ]
}

export function getPregnancyAlertTypeCounts (alerts) {
  return [
    {
      name: 'Weight gain',
      value: _.filter(alerts, a => a.alert_type === 'weight_relative').length,
      className: 'series-0'
    },
    {
      name: 'Over recommended weight',
      value: _.filter(alerts, a => a.alert_type === 'pregnancy_iom').length,
      className: 'series-1'
    },
    {
      name: 'Low activity',
      value: _.filter(alerts, a => a.alert_type === 'activity_target').length,
      className: 'series-2'
    },
    {
      name: 'No weight data',
      value: _.filter(alerts, a => a.alert_type === 'weight_tracking' || a.alert_type === 'multi_tracking').length,
      className: 'noData-0'
    },
    {
      name: 'No activity data',
      value: _.filter(alerts, a => a.alert_type === 'activity_tracking').length,
      className: 'noData-1'
    }
  ]
}
