import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { analyticsSlice } from '../../js/store/analytics'
import Modal from '../../js/components/elements/Modal'
import { Button, Typography } from '@mui/material'

function AnalyticsDrilldownModal (props) {
  const renderMember = (axMemberObj) => {
    return (
      <Button
        variant='outlined'
        color='secondary'
        href={`/profile/${axMemberObj.id}`}
        key={axMemberObj.id}
        sx={{ display: 'flex', justifyContent: 'space-between', mt: 1, mb: 1 }}
        fullWidth
      >
        <Typography> #{axMemberObj.info.medical_id} </Typography>
        <Typography>
          {axMemberObj.info.first_name} <Typography variant='span' fontWeight={500}>{axMemberObj.info.last_name}</Typography>
        </Typography>
      </Button>
    )
  }

  const renderDrilldown = () => {
    if (!props.analyticsStore.drilldown.items.length) {
      return (<div>No members to show.</div>)
    }

    if (props.analyticsStore.drilldown.type === 'member') {
      return props.analyticsStore.drilldown.items.map(renderMember)
    }

    return null
  }

  const renderModalTitle = () => {
    const drilldownCount = props.analyticsStore.drilldown.items.length
    return (
      <span className='ax-drilldown__title'>
        {props.analyticsStore.drilldown.title}
        <span className='ax-drilldown__title__count'>({drilldownCount} members)</span>
      </span>
    )
  }

  const renderModal = () => {
    return (
      <Modal
        name='AnalyticsDrilldown'
        isOpen
        modalTitle={renderModalTitle()}
        onModalClose={() => props.analyticsActions.setDrilldown(null)}
      >
        <div className='ax-drilldown__body'>
          {renderDrilldown()}
        </div>
      </Modal>
    )
  }

  const isModalVisible = !!props.analyticsStore.drilldown
  if (!isModalVisible) return null
  return renderModal()
}

function getPropsFromStore (state) {
  return {
    analyticsStore: state.analytics
  }
}

function getPropsFromActions (dispatch) {
  return {
    analyticsActions: bindActionCreators(analyticsSlice.actions, dispatch)
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(AnalyticsDrilldownModal)
