import moment from 'moment'
import restApi from '../js/api'
import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { analyticsSlice } from '../js/store/analytics'
import AnalyticsSidebar from './layout/AnalyticsSidebar'
import AnalyticsDrilldownModal from './layout/AnalyticsDrilldownModal'
import AnalyticsPopulationScreen from './AnalyticsPopulation/AnalyticsPopulationScreen'
import AnalyticsTrendsScreen from './AnalyticsTrends/AnalyticsTrendsScreen'
import { useLocation } from 'react-router'

/* ****************************************************************************
 * Analytics wrapper
 * ****************************************************************************/

function AnalyticsPage (props) {
  const location = useLocation()

  useEffect(() => {
    // User navigated here by hitting the back button, so don't reset
    const locKey = location?.key
    if (locKey && locKey === props.analyticsStore.prevLocationKey) {
      return
    }

    // Reset the analytics page
    props.analyticsActions.setLocationKey(locKey)
    props.analyticsActions.resetView()
    fetchAnalyticsOverview()
  }, [location])

  const fetchAnalyticsOverview = () => {
    const startDate = moment().subtract(1, 'week')
    const endDate = moment()

    props.analyticsActions.setDateRange({ startDate: startDate.valueOf(), endDate: endDate.valueOf() })
    props.apiActions.getAnalyticsOverview({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD')
    })
  }

  const renderAxPage = () => {
    return (
      <>
        <AnalyticsDrilldownModal />
        <AnalyticsSidebar />

        {props.analyticsStore.currentScreen === 'POPULATION' &&
          <AnalyticsPopulationScreen />}
        {props.analyticsStore.currentScreen === 'TRENDS' &&
          <AnalyticsTrendsScreen />}
      </>
    )
  }

  return renderAxPage()
}

function getPropsFromStore (state) {
  return {
    analyticsStore: state.analytics
  }
}

function getPropsFromActions (dispatch) {
  return {
    apiActions: bindActionCreators(restApi.actions, dispatch),
    analyticsActions: bindActionCreators(analyticsSlice.actions, dispatch)
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(AnalyticsPage)
