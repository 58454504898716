/* global INTERACTION_SERVER */
/* global EJENTA_APP_VERSION */
import { productConfig } from '../config/config-service'

/*
 * Define base API URL
 */
let apiHost
let webSocketUrl
const apiConfig = productConfig().api

switch (INTERACTION_SERVER) {
  case 'staging':
    apiHost = apiConfig.stagingServer.url
    webSocketUrl = apiConfig.stagingServer.webSocketUrl
    break

  case 'production':
    apiHost = apiConfig.productionServer.url
    webSocketUrl = apiConfig.productionServer.webSocketUrl
    break

  case 'local':
  default:
    apiHost = apiConfig.localServer.url
    webSocketUrl = apiConfig.localServer.webSocketUrl
    break
}

export const customApiUrl = `${apiHost}/${apiConfig.urlSuffix}`
export const customWebSocketUrl = webSocketUrl
export const ejentaAppVersion = EJENTA_APP_VERSION

/*
 * Define custom headers
 */

export const customApiHeaders = apiConfig.apiHeaders
