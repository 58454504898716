import _ from 'lodash'
import React from 'react'
import LoadingIndicator from '../../../elements/LoadingIndicator'
import PregnancyProfileDetail from '../../../elements/ProfileCell/PregnancyProfileDetail'
import { productConfig } from '../../../../config/config-service'
import { getDisplayName } from '../../../../utils/baseStringUtils'
import {
  getActivityProfileStats,
  getBloodGlucoseProfileStats,
  getBloodPressureProfileStats,
  getPulseOxProfileStats,
  getWeightProfileStats,
  getPregnancyWeightProfileStats
} from '../../../../utils/member/alertDetailUtils'
import { Card, CardContent } from '@mui/material'
import { Link } from 'react-router-dom'

export default function AlertDetailSidebar (props) {
  const renderProfileLink = () => {
    if (props.hideProfileLink) return null

    return (
      <Link
        to={`/profile/${props.member.user.id}`}
        style={{ fontWeight: 500, textDecoration: 'none', color: '#EF6C00' }}
        onClick={props.onModalClose}
      >
        View member profile
      </Link>
    )
  }

  const renderWeight = () => {
    if (!props.member.weight || !props.member.weight.latest_measurement) {
      return null
    }

    const { latestWeight } = getWeightProfileStats(props.member)

    return (
      <section data-testid='profile__section-weight'>
        <div className='profile__section-header'>Weight</div>
        <div className='profile__data'>
          <div><span className='profile__data-label'>Latest weight</span> {latestWeight} lb.</div>
        </div>
      </section>
    )
  }

  const renderPregnancyWeight = () => {
    const {
      weightGain14D,
      weightIOMDelta,
      weightGainTotal
    } = getPregnancyWeightProfileStats(props.member)

    return (
      <section data-testid='profile__section-pregnancy-weight'>
        <div className='profile__section-header'>Weight</div>
        <div className='profile__data'>
          <div><span className='profile__data-label'>14d gain rate</span> {weightGain14D}</div>
          <div><span className='profile__data-label'>IOM delta</span> {weightIOMDelta}</div>
          <div><span className='profile__data-label'>Total gain</span> {weightGainTotal}</div>
        </div>
      </section>
    )
  }

  const renderBloodPressure = () => {
    if (!props.member.blood_pressure || !props.member.blood_pressure.latest_measurement) {
      return null
    }

    const { latestBloodPressure } = getBloodPressureProfileStats(props.member)

    return (
      <section data-testid='profile__section-blood-pressure'>
        <div className='profile__section-header'>Blood Pressure</div>
        <div className='profile__data'>
          <div><span className='profile__data-label'>Latest blood pressure</span> {latestBloodPressure.systolic}/{latestBloodPressure.diastolic}</div>
          <div><span className='profile__data-label'>Latest heart rate</span> {latestBloodPressure.heart_rate}</div>
        </div>
      </section>
    )
  }

  const renderBloodGlucose = () => {
    if (!props.member.blood_glucose || !props.member.blood_glucose.latest_measurement) {
      return null
    }

    const { latestBloodGlucose } = getBloodGlucoseProfileStats(props.member)

    return (
      <section data-testid='profile__section-blood-glucose'>
        <div className='profile__section-header'>Blood Glucose</div>
        <div className='profile__data'>
          <div><span className='profile__data-label'>Latest blood glucose</span> {latestBloodGlucose.blood_glucose}</div>
        </div>
      </section>
    )
  }

  const renderPulseOx = () => {
    if (!props.member.pulse_ox || !props.member.pulse_ox.latest_measurement) {
      return null
    }

    const { latestPulseOx } = getPulseOxProfileStats(props.member)

    return (
      <section data-testid='profile__section-pulse-ox'>
        <div className='profile__section-header'>SpO₂</div>
        <div className='profile__data'>
          <div><span className='profile__data-label'>Latest SpO₂</span> {latestPulseOx.oxygen_saturation}%</div>
        </div>
      </section>
    )
  }

  const renderActivity = () => {
    const {
      activityTotal,
      activityProgress
    } = getActivityProfileStats(props.member)

    return (
      <section data-testid='profile__section-activity'>
        <div className='profile__section-header'>Activity</div>
        <div className='profile__data'>
          <div><span className='profile__data-label'>Last 7d</span> {activityTotal}</div>
          <div><span className='profile__data-label'>Goal %</span> {activityProgress}</div>
        </div>
      </section>
    )
  }

  const renderAlertDetailSidebar = () => {
    const detailSidebar = productConfig().alerts.detailSidebar
    if (!detailSidebar.exists) return null

    if (_.isEmpty(props.member)) {
      return <LoadingIndicator />
    }

    return (
      <Card sx={{ m: 1 }} variant='outlined'>
        <CardContent>
          <div className='profile__header'> {getDisplayName(props.member.user)} </div>
          <div className='profile__subheader' />

          {detailSidebar.hasPregnancyWeight &&
            <div className='profile__subheader'> <PregnancyProfileDetail user={props.member.user} /> </div>}

          {renderProfileLink()}
          {detailSidebar.hasWeight && renderWeight()}
          {detailSidebar.hasPregnancyWeight && renderPregnancyWeight()}
          {detailSidebar.hasActivity && renderActivity()}
          {detailSidebar.hasBloodPressure && renderBloodPressure()}
          {detailSidebar.hasBloodGlucose && renderBloodGlucose()}
          {detailSidebar.hasPulseOx && renderPulseOx()}
        </CardContent>
      </Card>
    )
  }

  return renderAlertDetailSidebar()
}
