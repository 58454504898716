import { getEmptyChart } from './baseChartUtils'
import { getFullChartOptions } from './baseHeartRateChart'

export default function getHeartRateChartOptions ({
  memberObj, dashboardViewStore,
  showMemberAlerts, handleAlertClick
}) {
  if (!memberObj.heart_rate) return getEmptyChart()

  return getFullChartOptions(memberObj, dashboardViewStore, showMemberAlerts,
    handleAlertClick)
}
