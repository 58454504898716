import moment from 'moment'
import restApi from '../api'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getInitialState } from '../utils/baseListUtils'

/* ******************************************************************************
 * Interface state for member profile page (clinician-only)
 * ******************************************************************************/
const PROFILE_VIEW = {
  NOTES_PAGE_SIZE: 10
}

const initialState = {
  ...getInitialState({
    sortBy: null,
    filters: []
  }),
  openModal: null, // Used for "edit member profile" and "edit member care plan" modals
  openModalTimestamp: null,
  selectedAlertId: null, // Used for opening alerts shown in member charts, by alert ID
  selectedAlert: null, // Used for showing an alert modal when on the notes page, by full alert object
  memberId: null,
  isLoading: true,
  activeTab: 0,
  totalCount: 0
}

const getNotesData = createAsyncThunk('profileView/fetchPagingNotes', async (params) => {
  const { dispatch, getState } = params
  return dispatch(restApi.actions.getMemberNotes({ id: getState().profileView.memberId, page: getState().profileView.page, per_page: PROFILE_VIEW.NOTES_PAGE_SIZE })).then()
})

const getStarredNotesData = createAsyncThunk('profileView/fetchStarredNotes', async (params) => {
  const { dispatch, getState } = params
  return dispatch(restApi.actions.getMemberStarredNotes({ id: getState().profileView.memberId })).then()
})

export const fetchPagingNotes = () => {
  return (dispatch, getState) => {
    dispatch(getNotesData({ dispatch, getState }))
  }
}

export const fetchStarredNotes = () => {
  return (dispatch, getState) => {
    dispatch(getStarredNotesData({ dispatch, getState }))
  }
}

const profileViewSlice = createSlice({
  name: 'profileView',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getNotesData.fulfilled, (state, _action) => {
      state.isLoading = false
    })
    builder.addCase(getStarredNotesData.fulfilled, (state, _action) => {
      state.isLoading = false
    })

    builder.addCase(getNotesData.rejected, (state, _action) => {
      state.isLoading = false
    })
    builder.addCase(getStarredNotesData.rejected, (state, _action) => {
      state.isLoading = false
    })

    builder.addCase(getNotesData.pending, (state, _action) => {
      state.isLoading = true
    })
    builder.addCase(getStarredNotesData.pending, (state, _action) => {
      state.isLoading = true
    })
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload.isLoading
    },
    setMemberId: (state, action) => {
      state.memberId = action.payload.memberId
    },
    setOpenModal: (state, action) => {
      state.openModal = action.payload.name
      state.modalTabLabel = action.payload.tabLabel
      state.openModalTimestamp = moment().valueOf()
    },
    handleClearModal: (state, _action) => {
      state.openModal = null
      state.openModalTimestamp = null
      state.modalTabLabel = 0
    },
    setSelectedAlert: (state, action) => {
      state.selectedAlertId = action.payload
    },
    handleClearSelectedAlert: (state, _action) => {
      state.selectedAlert = null
      state.selectedAlertId = null
    },
    setSelectedAlertObject: (state, action) => {
      state.selectedAlertId = action.payload.id
      state.selectedAlert = action.payload
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload.tabIndex
      state.currentPage = []
      state.totalCount = 0
    },
    populateNotes: (state, action) => {
      state.currentPage = action.payload.notes
      state.totalCount = action.payload.total
    },
    resetTabView: (state, _action) => {
      state.page = 1
      state.currentPage = []
      state.totalCount = 0
      state.isLoading = true
      state.activeTab = 0
    },
    pagePrev: (state, _action) => {
      state.page = Math.max(1, state.page - 1)
    },
    pageNext: (state, _action) => {
      const maxPage = Math.ceil(state.totalCount / PROFILE_VIEW.NOTES_PAGE_SIZE)
      state.page = Math.min(maxPage, state.page + 1)
    }
  }
})

export const {
  setLoading,
  setMemberId,
  setOpenModal,
  handleClearModal,
  setSelectedAlert,
  handleClearSelectedAlert,
  setSelectedAlertObject,
  setActiveTab,
  populateNotes,
  resetTabView,
  pagePrev,
  pageNext
} = profileViewSlice.actions
export { profileViewSlice, PROFILE_VIEW }
