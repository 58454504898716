import React from 'react'
import AnalyticsChart from '../layout/AnalyticsChart'
import getGenderChartOptions from '../charts/genderChart'

export default function Gender (props) {
  return (
    <div className='ax-module'>
      <div className='ax-module-header'>Sex</div>
      <AnalyticsChart
        containerId='ax-gender-chart'
        chartOptions={getGenderChartOptions(props.users, props.analyticsActions)}
      />
    </div>
  )
}
