import _ from 'lodash'
import restApi from '../../../api'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateUser } from '../../../store/userSession'
import { getMemberProfileFormErrors, getProfileMemberFields, validateMemberForm } from '../../../utils/member/baseProfileUtils'
import { EjentaForm } from '../../elements/EjentaForm'
import { Card, CardContent, Typography, Button, DialogActions, Alert } from '@mui/material'
import AccountInformationFormFields from './AccountInformationFormFields'
import Snackbar from '../../elements/Snackbar'
import { Form } from 'react-final-form'

const ACCOUNT_FIELDS = ['studyId', 'birthDate', 'firstName', 'lastName', 'email', 'phone', 'bodyTraceScaleImei', 'bodyTraceBpImei', 'timezone']
const EDITABLE_ACCOUNT_FIELDS = ['firstName', 'lastName', 'email', 'phone', 'bodyTraceScaleImei', 'bodyTraceBpImei', 'timezone']

function BaseAccountInformationForm (props) {
  const memberFields = getProfileMemberFields()

  const renderSubmitButton = ({ pristine, submitting, errors }) => {
    const hasErrors = Object.keys(errors)?.length > 0
    let submitButton

    if (submitting) {
      submitButton = (
        <Button variant='contained' color='primary' disabled type='submit' data-testid='account-information__in-progress-button'>Saving...</Button>
      )
    } else {
      submitButton = (
        <Button variant='contained' color='primary' type='submit' data-testid='account-information__update-button' disabled={pristine || hasErrors}>Update account</Button>
      )
    }

    return (<DialogActions>{submitButton}</DialogActions>)
  }

  const renderAccountInformation = () => {
    if (!props.user) {
      return null
    }
    const formFields = _.pick(memberFields, EDITABLE_ACCOUNT_FIELDS)

    return (
      <Form
        onSubmit={props.onSubmit}
        initialValues={props.getInitialValues()}
        validate={values => validateMemberForm(values, formFields, false)}
      >
        {({ handleSubmit, pristine, submitting, submitError, errors }) => (
          <EjentaForm onSubmit={handleSubmit} data-testid='account-information__form'>
            {submitError && <Alert severity='error'>{submitError}</Alert>}
            <AccountInformationFormFields />

            {renderSubmitButton({ pristine, submitting, errors })}
          </EjentaForm>
        )}
      </Form>
    )
  }

  return renderAccountInformation()
}

/* *****************************************************************************
 * Account information form controller
 * *****************************************************************************/

function AccountInformationForm (props) {
  const memberFields = getProfileMemberFields()
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const getInitialValues = () => {
    const initialValues = {}

    _.forOwn(memberFields, (field, inputName) => {
      if (!ACCOUNT_FIELDS.includes(inputName)) {
        return
      }

      let value
      if (field.accessor) value = field.accessor(props.user)
      else value = props.user[field.apiField]
      initialValues[inputName] = value
    })

    return initialValues
  }

  const handleSubmit = (formData) => {
    const submitPromise = new Promise((resolve, reject) => {
      const formFields = _.pick(memberFields, EDITABLE_ACCOUNT_FIELDS)
      const apiFields = {}

      _.forOwn(formData, (val, key) => {
        apiFields[memberFields[key].apiField] = val
      })

      props.apiActions.updateAccount(
        {
          id: props.user.id,
          promise: { resolve, reject },
          promiseTransformer: errors => getMemberProfileFormErrors(errors, formFields)
        },
        { body: JSON.stringify(apiFields) }
      )
    })

    return submitPromise.then(handleSubmitSuccess).catch(data => data)
  }

  const handleSubmitSuccess = (data) => {
    props.updateUser(data)
    setSnackbarOpen(true)
  }

  const renderAccountInformationForm = () => {
    return (
      <Card variant='outlined'>
        <CardContent>
          <Typography variant='h6' fontSize='1.1rem' sx={{ mb: 3 }}>Account Information</Typography>
          <Snackbar
            isOpen={snackbarOpen}
            dataKey='account-information__success__snackbar'
            handleClose={() => setSnackbarOpen(false)}
            severity='success'
            message='Account updated successfully'
          />

          <BaseAccountInformationForm
            user={props.user}
            getInitialValues={getInitialValues}
            onSubmit={handleSubmit}
          />
        </CardContent>
      </Card>
    )
  }

  return renderAccountInformationForm()
}

function getPropsFromStore (state) {
  return {
    user: state.userSession.user,
    updateAccountApi: state.api.updateAccount
  }
}

function getPropsFromActions (dispatch) {
  return {
    apiActions: bindActionCreators(restApi.actions, dispatch),
    updateUser: bindActionCreators(updateUser, dispatch)
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(AccountInformationForm)
