import _ from 'lodash'
import moment from 'moment'
import { getTrimesterOfPregnancy } from '../baseDateUtils'
import { preciseRound, convertKgToLb } from '../unitConversionUtils'

/*
 * Returns total weight gain since the beginning of the pregnancy.
 */
export function getWeightGainInPregnancy (memberObj) {
  const latestMeasurement = memberObj?.weight?.latest_measurement
  if (!latestMeasurement) return null

  const startWeight = convertKgToLb(memberObj.user.start_weight_in_kg)
  const mostRecentWeight = latestMeasurement.weight.value
  return preciseRound(mostRecentWeight - startWeight, 2)
}

/*
 * Returns TOTAL active minutes/steps in range
 */
export function getActivityMinutesInRange (memberObj) {
  if (!memberObj.activity) return 0

  return _.sumBy(memberObj.activity.data, (d) => {
    const moderateSeconds = d.intensities.moderate.value
    const intenseSeconds = d.intensities.intense.value
    return Math.round((moderateSeconds + intenseSeconds) / 60)
  })
}

export function getIOMDelta (memberObj) {
  if (!memberObj.weight || !memberObj.weight.data || !memberObj.weight.data.length) return null

  const mostRecentMeasurement = _.first(memberObj.weight.data)
  const mostRecentWeight = mostRecentMeasurement.weight.value
  const { min: minIOM, max: maxIOM } = getIOMRange(memberObj, mostRecentMeasurement.timestamp)
  let delta

  if (mostRecentWeight > maxIOM) {
    delta = mostRecentWeight - maxIOM
  } else if (mostRecentWeight < minIOM) {
    delta = mostRecentWeight - minIOM
  } else {
    delta = 0
  }

  return {
    delta: preciseRound(delta, 2),
    date: mostRecentMeasurement.timestamp
  }
}

/* ******************************************************************************
 * Institude of Medicine (IOM) recommended range calculations
 * ******************************************************************************/

/**
 * Get a member's IOM recommended weight range (min, max) for the provided date
 */
export function getIOMRange (memberObj, date) {
  const MS_IN_TRIMESTER = 13.3 * 7 * (24 * 60 * 60 * 1000)

  const trimester = getTrimesterOfPregnancy(memberObj, date)
  const startWeight = convertKgToLb(memberObj.user.start_weight_in_kg)
  const pregnancyLength = +(moment(date) - moment(memberObj.user.conception_date))

  const getIOMWeight = (firstTrimesterSlope, restSlope) => {
    if (trimester === 1) {
      return startWeight + (firstTrimesterSlope * pregnancyLength)
    }

    const firstTrimesterGain = firstTrimesterSlope * MS_IN_TRIMESTER
    const restGain = restSlope * (pregnancyLength - MS_IN_TRIMESTER)
    return startWeight + firstTrimesterGain + restGain
  }

  return {
    min: getIOMWeight(
      +memberObj.pregnancy_info.trimester_1.min_iom_slope.value,
      +memberObj.pregnancy_info.trimester_2.min_iom_slope.value
    ),
    max: getIOMWeight(
      +memberObj.pregnancy_info.trimester_1.max_iom_slope.value,
      +memberObj.pregnancy_info.trimester_2.max_iom_slope.value
    )
  }
}

/**
 * Returns a list of { date: { min, max } } objects representing the inflection points in a
 * member's recommended IOM weight range
 *
 */
export function getIOMRangesForPregnancy (memberObj) {
  const pregnancyStartDate = memberObj.user.conception_date
  const secondTrimesterStartDate = memberObj.pregnancy_info.trimester_2.start_date
  const futureDate = moment.max(moment(pregnancyStartDate).add(40, 'weeks'), moment())
  const ranges = [];

  [pregnancyStartDate, secondTrimesterStartDate, futureDate].forEach((d) => {
    ranges.push({
      date: d,
      ranges: getIOMRange(memberObj, d)
    })
  })

  return ranges
}
