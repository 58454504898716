import moment from 'moment'
import React, { useState } from 'react'
import { DateRangePicker } from 'react-dates'
import { ANCHOR_RIGHT } from 'react-dates/constants'
import DateRangeIcon from '@mui/icons-material/DateRange'

/* *****************************************************************************
 * Wrapper around third-party react-dates component.
 * *****************************************************************************/

function DateRangeController (props) {
  const [focusedInput, setFocusedInput] = useState(null)
  const [startDate, setStartDate] = useState(props.startDate ? moment(props.startDate) : moment().subtract(7, 'days'))
  const [endDate, setEndDate] = useState(props.endDate ? moment(props.endDate) : moment())

  /*
   * Interaction handling
   */
  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  const handleFocusChange = (newFocus) => {
    const newEndDate = !newFocus && !endDate ? startDate : endDate
    setFocusedInput(newFocus)
    setEndDate(newEndDate)

    if (!newFocus) {
      props.onRangeSelect(startDate, newEndDate)
    }
  }

  /*
   * Rendering
   */
  const renderDateRangePicker = () => {
    return (
      <div className='date-range-picker'>
        <DateRangePicker
          isOutsideRange={d => d.isAfter(moment())}
          onDatesChange={handleDatesChange}
          onFocusChange={handleFocusChange}
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
          initialVisibleMonth={() => moment().subtract(1, 'month')}
          showDefaultInputIcon
          customInputIcon={<DateRangeIcon />}
          displayFormat='MMM D, Y'
          anchorDirection={ANCHOR_RIGHT}
          hideKeyboardShortcutsPanel
          startDatePlaceholderText='Select start date'
          endDatePlaceholderText='Select end date'
        />
      </div>
    )
  }

  return renderDateRangePicker()
}

export default DateRangeController
