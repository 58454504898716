/* eslint-disable react/prop-types */
import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { profileViewSlice } from '../../../store/profileView'

import LoadingIndicator from '../../elements/LoadingIndicator'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import TableSortLabel from '@mui/material/TableSortLabel'
import NotificationImportantRounded from '@mui/icons-material/NotificationImportantRounded'
import { descendingComparator } from '../../../constants/constants'

const getComparator = (order) => {
  return order === 'desc' ? (a, b) => descendingComparator(a, b) : (a, b) => -descendingComparator(a, b)
}

function BloodOxygenHeader (props) {
  const order = props.order

  const createSortHandler = (property) => (event) => {
    props.onRequestSort(event, property)
  }

  const renderHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell key='date'>
            <TableSortLabel active direction={order} onClick={createSortHandler('date')}>
              Date
            </TableSortLabel>
          </TableCell>

          <TableCell align='center' className='odd-col'>Time</TableCell>
          <TableCell align='center'>Oxygen Saturation</TableCell>
          <TableCell align='center' className='odd-col'>Heart Rate</TableCell>
          <TableCell align='center'>Alert</TableCell>
        </TableRow>
      </TableHead>
    )
  }

  return renderHeader()
}

function BloodOxygenRow (props) {
  const dateAsMoment = moment(props.date)
  const formattedDate = moment().isSame(dateAsMoment, 'year') ? dateAsMoment.format('MMM DD') : dateAsMoment.format('MMM DD, YYYY')

  // sort the measurements by timestamp in asc or desc based on date sort
  props.data.measurements.sort((a, b) => props.order === 'desc' ? descendingComparator(a.timestamp, b.timestamp) : -descendingComparator(a.timestamp, b.timestamp))

  const renderRow = () => {
    return (
      <>
        {props.data?.measurements.map(measurement => {
          return (
            <TableRow sx={{ backgroundColor: theme => `${theme.palette.background.paper}!important` }} key={measurement.timestamp}>
              <TableCell> <Typography display='flex'> {formattedDate} </Typography> </TableCell>
              <TableCell align='center' className='odd-col'>{moment(measurement.timestamp).format('h:mm a')}</TableCell>
              <TableCell align='center'>{measurement.oxygen_saturation}{props.units.oxygen_saturation}</TableCell>
              <TableCell align='center' className='odd-col'>{measurement.heart_rate} {props.units.heart_rate}</TableCell>
              <TableCell align='center'>
                {measurement.alerts?.length > 0 &&
                  <IconButton aria-label='open alert' size='small' color='primary' onClick={() => props.actions.setSelectedAlert(measurement.alerts[0].id)}>
                    <NotificationImportantRounded fontSize='small' />
                  </IconButton>}
              </TableCell>
            </TableRow>
          )
        })}
      </>
    )
  }

  return renderRow()
}

function BloodOxygenTable (props) {
  const [order, setOrder] = React.useState('asc')
  const handleRequestSort = () => setOrder(order === 'asc' ? 'desc' : 'asc')

  const groupByDateAndMealStatus = (data, alerts) => {
    const bloodOxygenAlerts = alerts.filter(item => item.metric_type === 'pulse_ox')
    const groupedData = {}

    data.forEach((d) => {
      if (!groupedData[d.date]) groupedData[d.date] = { measurements: [] }

      const matchingAlerts = bloodOxygenAlerts.filter(alert => alert.measurement_timestamp === d.timestamp) ?? []
      const newItem = {
        ...d,
        alerts: matchingAlerts.length ? matchingAlerts : []
      }
      groupedData[d.date].measurements.unshift(newItem)
    })
    return groupedData
  }

  const renderTable = () => {
    if (props.dashboardViewStore.isRangeLoading) {
      return (
        <div className='metrics-section__inner'> <LoadingIndicator /> </div>
      )
    }

    const dataSection = props.memberObj.pulse_ox
    if (!dataSection?.data?.length) {
      return (
        <Typography variant='body1' color='#7f7f7f' marginTop={1.5}>No SpO₂ data for this period</Typography>
      )
    }

    const units = dataSection.information.units
    const dataByDate = groupByDateAndMealStatus(dataSection.data, props.memberObj.alerts)
    const sectionClass = !props.hideBorder ? 'metrics-section' : ''
    return (
      <TableContainer className={sectionClass} sx={{ height: 408 }}>
        {!props.hideHeader && <h3 className='metrics-section__header'>SpO₂</h3>}
        <Table className='blood-pressure-table__container' stickyHeader>
          <BloodOxygenHeader order={order} onRequestSort={handleRequestSort} />
          <TableBody>
            {Object.keys(dataByDate).slice().sort(getComparator(order)).map((date, i) => {
              return <BloodOxygenRow units={units} order={order} date={date} data={dataByDate[date]} key={i} actions={props.profileViewActions} />
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return renderTable()
}

function getPropsFromActions (dispatch) {
  return {
    profileViewActions: bindActionCreators(profileViewSlice.actions, dispatch)
  }
}

export default connect(null, getPropsFromActions)(BloodOxygenTable)
