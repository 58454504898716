import _ from 'lodash'
import moment from 'moment'

import {
  getBaseChartOptions,
  getBaseSeriesOptions,
  addPausePlotBands,
  getPointDateString
} from './baseChartUtils'

import {
  getXAxisForRange,
  getMarkerRadius
} from './chartUtils'

import {
  baseChartOptions,
  getGlucoseAlertsSeries
} from './baseGlucoseChart'

/* ********************************************************************************
 * Product-specific chart options
 * ********************************************************************************/

const chartOptions = _.assign(baseChartOptions, {
  tooltip: {
    headerFormat: null,
    pointFormatter: function format () {
      return `
        <strong>${this.name}</strong><br/>
        ${this.description}
      `
    },
    useHTML: true,
    padding: 12
  }
})

/* ********************************************************************************
 * Product-specific series
 * ********************************************************************************/

function getSeries (options, memberObj, dashboardViewStore) {
  const newOptions = _.cloneDeep(options)

  // Set marker radius
  _.set(newOptions, ['plotOptions', 'line', 'marker', 'radius'], getMarkerRadius(dashboardViewStore))

  const measurements = _.reverse(_.cloneDeep(memberObj.blood_glucose.data)).map((m) => {
    const point = {
      x: +moment(m.timestamp),
      y: m.blood_glucose,
      name: getPointDateString(moment(m.timestamp), true),
      description: `
        Blood Glucose: ${m.blood_glucose} mg/dL<br/>
        Before Meal: ${m.before_meal ? 'Yes' : 'No'}<br/>
        <small>Source: ${m.source ?? 'Unknown'}</small>
      `
    }
    return point
  })

  const measurementsSeries = _.extend({
    name: 'Blood glucose',
    data: measurements,
    zIndex: 1,

    type: 'line',
    className: 'series-weightMeasurements'
  }, getBaseSeriesOptions())

  newOptions.series.push(measurementsSeries)
  return newOptions
}

function getYAxisForRanges (memberObj, options) {
  /*
    THIS ASSUMES THAT THE FIRST SERIES OF THE CHART CONTAINS MEASUREMENTS!
    Set the bounds for the yAxis, since for some reason it won't automatically adjust as of 5.0.10
  */
  const newOptions = _.cloneDeep(options)

  // Get min/max blood_glucose measurement
  const minMeasurement = _.min(_.map(options.series[0].data, 'y'))
  const maxMeasurement = _.max(_.map(options.series[0].data, 'y'))

  // Set min/max for yAxis
  newOptions.yAxis.min = Math.round(Math.min(minMeasurement, 0) * 0.98)
  newOptions.yAxis.max = Math.round(maxMeasurement * 1.02)

  return newOptions
}

/* ********************************************************************************
 * Chart types
 * ********************************************************************************/
function getFullChartOptions ({
  memberObj, dashboardViewStore,
  showMemberAlerts, handleAlertClick
}) {
  const xAxisOptions = { xAxis: getXAxisForRange(memberObj, dashboardViewStore) }

  let options = _.merge(
    getBaseChartOptions(),
    chartOptions,
    xAxisOptions
  )

  // Add individual series
  options = getSeries(options, memberObj, dashboardViewStore)
  options = getYAxisForRanges(memberObj, options)
  if (showMemberAlerts) options = getGlucoseAlertsSeries(memberObj, options, handleAlertClick)
  options = addPausePlotBands(memberObj.user.pauses, options, false)

  return options
}

/* ********************************************************************************
 * Parent method
 * ********************************************************************************/

export default function ({
  memberObj, dashboardViewStore,
  showMemberAlerts, handleAlertClick
}) {
  return getFullChartOptions({
    memberObj,
    dashboardViewStore,
    showMemberAlerts,
    handleAlertClick
  })
}
