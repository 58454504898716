import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ALERT_SORTS } from '../../../../utils/baseAlertUtils'
import { ALERTS_VIEW } from '../../../../utils/alertUtils'
import { renderFilters } from '../../../../utils/baseListUtils'
import { CARE_MANAGER_DEFAULT_OPTION } from '../../../../constants/constants'
import { alertsViewSlice, fetchPagingAlerts } from '../../../../store/alertsView'
import { productConfig } from '../../../../config/config-service'
import { Box, Button, MenuItem, TextField, Typography } from '@mui/material'
import ContentSidebar from '../../../layout/ContentSidebar'
import SearchBar from '../../../elements/SearchBar'

/* ******************************************************************************
 * Sidebar
 * ******************************************************************************/
function RefineAlertsSidebar (props) {
  const [careManagerOptions, setCareManagerOptions] = useState([])
  const [selectedCareManager, setSelectedCareManager] = useState('')
  const [isDefaultState, setIsDefaultState] = useState(true)
  const config = productConfig().alerts.refineSidebar

  const onSearchChange = (searchQuery) => {
    props.alertsViewActions.setUrlFilters(null)
    props.alertsViewActions.setSearchQuery(searchQuery)
    props.fetchAlerts()
  }

  const handleSortChange = (e) => {
    props.alertsViewActions.setSort(e.target.value)
    props.fetchAlerts()
  }

  const handleFilterChange = (filterId) => {
    props.alertsViewActions.setFilter(filterId)
    props.fetchAlerts()
  }

  const handleCareManagerChange = (e) => {
    props.alertsViewActions.setCareManagerId(e.target.value)
    setSelectedCareManager(e.target.value)
    props.fetchAlerts()
  }

  useEffect(() => {
    const isReset = props.alertsViewStore.searchQueryReset
    if (isReset && careManagerOptions?.length > 0) setSelectedCareManager(careManagerOptions[0].id)
  }, [props.alertsViewStore.searchQueryReset])

  useEffect(() => {
    const { careManagerId, careManagerFilters, filters, searchQuery, sortBy } = config.defaultFilterState
    const storeFilters = [...props.alertsViewStore.filters].sort()

    const defaultFiltersWithCareplan = [...filters, ...props.alertsViewStore.carePlanFilters].sort()
    const matches =
      _.isMatch(props.alertsViewStore, { careManagerId, careManagerFilters, searchQuery, sortBy }) &&
      _.isEqual(storeFilters, defaultFiltersWithCareplan)
    setIsDefaultState(matches)
  }, [props.alertsViewStore.filters, props.alertsViewStore.searchQuery])

  useEffect(() => {
    const fetchedCareManagers = props.userSessionStore.resources?.care_managers ?? []
    const constructedOptions = [CARE_MANAGER_DEFAULT_OPTION, ...fetchedCareManagers]
    const filters = props.alertsViewStore.careManagerFilters

    if (filters.length > 0) setSelectedCareManager(filters[0])
    else setSelectedCareManager(constructedOptions[0]?.id)

    setCareManagerOptions(constructedOptions)

    // we call fetch alerts again (once in parent), now that we have everything
    props.fetchAlerts()
  }, [])

  const renderResetFilterButton = () => {
    if (isDefaultState) return null

    return (
      <Box sx={{ width: '100%', textAlign: 'right' }}>
        <Button sx={{ padding: 0 }} onClick={() => props.resetView()}>Reset Filters</Button>
      </Box>
    )
  }

  const renderSearchBar = () => {
    return (
      <section className='list-controls__section'>
        <Typography variant='subtitle2'>Search</Typography>

        <SearchBar
          handleChange={onSearchChange}
          placeholder='Member name or study ID'
          value={props.alertsViewStore.searchQuery}
          resetSearch={props.alertsViewStore.searchQueryReset}
        />
      </section>
    )
  }

  const renderSortSection = () => {
    let displayStrMap = {}
    const defaultDisplayStrMap = {
      [ALERT_SORTS.SORT_STATUS]: 'Alert status (open/closed)',
      [ALERT_SORTS.SORT_TYPE]: 'Alert type',
      [ALERT_SORTS.DATE_DESC]: 'Date (newest first)',
      [ALERT_SORTS.DATE_ASC]: 'Date (oldest first)'
    }

    if (config.hasLevelSort) {
      displayStrMap[ALERT_SORTS.LEVEL] = 'Alert level (red/orange/yellow)'
    }
    displayStrMap = { ...displayStrMap, ...defaultDisplayStrMap }

    const options = Object.keys(displayStrMap).map(key =>
      <MenuItem value={key} key={key}>{displayStrMap[key]}</MenuItem>
    )

    return (
      <section className='list-controls__section'>
        <Typography variant='subtitle2'>Sort by</Typography>

        <TextField
          fullWidth
          select
          size='small'
          id='refine-alerts__sort-by'
          value={props.alertsViewStore.sortBy}
          onChange={handleSortChange}
          sx={{ backgroundColor: 'white' }}
        >
          {options}
        </TextField>
      </section>
    )
  }

  const renderCareManagerSection = () => {
    const options = careManagerOptions.map(s =>
      <MenuItem key={s.id ?? s.name} value={s.id ?? s.name}>{s.name}</MenuItem>
    )

    return (
      <section className='list-controls__section' data-testid='list-controls__care-manager-section'>
        <Typography variant='subtitle2'>Care Manager</Typography>

        <TextField
          fullWidth
          select
          size='small'
          color='primary'
          id='refine-alerts__care-manager-select'
          data-testid='refine-alerts__care-manager-select'
          value={selectedCareManager}
          onChange={handleCareManagerChange}
          sx={{ backgroundColor: 'white' }}
        >
          {options}
        </TextField>
      </section>
    )
  }

  const renderRefineAlertsSidebar = () => {
    return (
      <ContentSidebar className='alerts-sidebar' drawerWidth={285}>
        {renderResetFilterButton()}

        <div className='content-sidebar__module'>
          {renderSearchBar()}

          {renderSortSection()}

          {config.hasCareManagerFilter && renderCareManagerSection()}

          <section>
            {renderFilters(
              ALERTS_VIEW.FILTER_GROUPS,
              props.alertsViewStore.filters,
              props.alertsViewStore.filterCounts,
              handleFilterChange
            )}
          </section>
        </div>

      </ContentSidebar>
    )
  }

  return renderRefineAlertsSidebar()
}

function getPropsFromStore (state) {
  return {
    userSessionStore: state.userSession,
    alertsViewStore: state.alertsView
  }
}

function getPropsFromActions (dispatch) {
  return {
    alertsViewActions: bindActionCreators(alertsViewSlice.actions, dispatch),
    fetchAlerts: bindActionCreators(fetchPagingAlerts, dispatch)
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(RefineAlertsSidebar)
