import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { membersViewSlice, MEMBERS_LIST } from '../../../../store/membersView'
import { renderFilters } from '../../../../utils/baseListUtils'
import { CARE_MANAGER_DEFAULT_OPTION } from '../../../../constants/constants'
import { productConfig } from '../../../../config/config-service'
import { Autocomplete, Box, Button, MenuItem, Select, TextField, Typography } from '@mui/material'
import ContentSidebar from '../../../layout/ContentSidebar'
import SearchBar from '../../../elements/SearchBar'

function getPropsFromStore (state) {
  return {
    membersCacheStore: state.membersCache,
    membersViewStore: state.membersView,
    userSessionStore: state.userSession
  }
}

function getPropsFromActions (dispatch) {
  return {
    membersViewActions: bindActionCreators(membersViewSlice.actions, dispatch)
  }
}

function MembersSidebar (props) {
  const [careManagerOptions, setCareManagerOptions] = useState([CARE_MANAGER_DEFAULT_OPTION])
  const [searchQuery, setSearchQuery] = useState('')
  const config = productConfig().members.refineSidebar

  useEffect(() => {
    setSearchQuery(props.membersViewStore.searchQuery)
  }, [props.membersViewStore.searchQuery])

  const onSearchChange = (searchQuery) => {
    setSearchQuery(searchQuery)
    props.membersViewActions.setSearchQuery(searchQuery)
  }

  const handleSortChange = (e) => {
    props.membersViewActions.setSort(e.target.value)
  }

  const handleCareManagerChange = (e) => {
    props.membersViewActions.setCareManagerId(e.target.value)
  }

  const handleLCUChange = (value) => {
    props.membersViewActions.setSelectedLCU(value)
  }

  const handleFilterChange = (filterId) => {
    props.membersViewActions.setFilter(filterId)
  }

  useEffect(() => {
    const fetchedCareManagers = props.userSessionStore.resources?.care_managers ?? []
    setCareManagerOptions([CARE_MANAGER_DEFAULT_OPTION, ...fetchedCareManagers])
  }, [props.userSessionStore])

  /*
   * Rendering
   */
  const renderResetFilterButton = () => {
    const { careManagerId, selectedLCU, filters, searchQuery, sortBy } = config.defaultFilterState

    const isDefaultState =
      _.isMatch(props.membersViewStore, { careManagerId, searchQuery, sortBy, selectedLCU }) &&
      _.isEqual(props.membersViewStore.filters, filters)

    if (isDefaultState) return null

    return (
      <Box sx={{ width: '100%', textAlign: 'right' }}>
        <Button sx={{ padding: 0 }} onClick={() => { onSearchChange(''); props.resetView() }}>Reset Filters</Button>
      </Box>
    )
  }

  const renderSearchBar = () => {
    return (
      <section className='list-controls__section' data-testid='list-controls__search-section'>
        <Typography variant='subtitle2'>Search</Typography>
        <SearchBar
          handleChange={onSearchChange}
          placeholder='Member name or study ID'
          value={searchQuery}
          resetSearch={props.membersViewStore.searchQueryReset}
        />
      </section>
    )
  }

  const renderSortSection = () => {
    const options = MEMBERS_LIST.SORTS.map(s =>
      <MenuItem data-testid='refine-members__sort-by-option' key={s.id} value={s.id}>{s.displayTitle}</MenuItem>
    )

    return (
      <section className='list-controls__section' data-testid='list-controls__sort-section'>
        <Typography variant='subtitle2'>Sort by</Typography>
        <Select
          fullWidth
          size='small'
          color='primary'
          id='refine-members__sort-by'
          value={props.membersViewStore.sortBy}
          onChange={handleSortChange}
          sx={{ backgroundColor: 'white' }}
        >
          {options}
        </Select>
      </section>
    )
  }

  const renderLCUSection = () => {
    if (!productConfig().members.refineSidebar.hasLCUFilter) return null
    const lcuList = props.membersCacheStore.lcuList

    return (
      <section className='list-controls__section' data-testid='list-controls__lcu-section'>
        <Typography variant='subtitle2'>LCU</Typography>
        <Autocomplete
          disablePortal
          fullWidth
          size='small'
          id='refine-members__lcu-autocomplete'
          className='list-controls__autocomplete'
          data-testid='refine-members__lcu-autocomplete'
          options={['All', ...lcuList]}
          value={props.membersViewStore.selectedLCU}
          onChange={(_e, value) => handleLCUChange(value)}
          onBlur={(e) => {
            if (!e.target.value) handleLCUChange('All')
          }}
          sx={{ backgroundColor: 'white' }}
          renderInput={(params) => <TextField sx={{ border: 'none!important' }} {...params} />}
        />
      </section>
    )
  }

  const renderCareManagerSection = () => {
    if (!productConfig().members.refineSidebar.hasCareManagerFilter) return null

    const options = careManagerOptions.map(s =>
      <MenuItem key={s.id ?? s.name} value={s.id ?? s.name}>{s.name}</MenuItem>
    )

    return (
      <section className='list-controls__section' data-testid='list-controls__care-manager-section'>
        <Typography variant='subtitle2'>Care Manager</Typography>
        <Select
          fullWidth
          size='small'
          color='primary'
          id='refine-members__care-manager-select'
          data-testid='refine-members__care-manager-select'
          value={props.membersViewStore.careManagerId}
          onChange={handleCareManagerChange}
          sx={{ backgroundColor: 'white' }}
        >
          {options}
        </Select>
      </section>
    )
  }

  const renderFilterSection = () => {
    return renderFilters(
      MEMBERS_LIST.FILTER_GROUPS,
      props.membersViewStore.filters,
      props.membersViewStore.filterCounts,
      handleFilterChange
    )
  }

  const renderRefineMembersSidebar = () => {
    return (
      <ContentSidebar drawerWidth={285} className='members-sidebar'>
        {renderResetFilterButton()}
        <div className='content-sidebar__module'>
          {renderSearchBar()}
          {renderSortSection()}
          {renderLCUSection()}
          {renderCareManagerSection()}
          {renderFilterSection()}
        </div>
      </ContentSidebar>
    )
  }

  return renderRefineMembersSidebar()
}

export default connect(getPropsFromStore, getPropsFromActions)(MembersSidebar)
