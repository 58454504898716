import React from 'react'
import { MS_IN_A_DAY } from '../../../../constants/constants'
import { productConfig } from '../../../../config/config-service'
import { renderLevel } from '../../../../utils/alertUtils'
import CarePlanItem from '../CarePlanItem'
import { CAREPLAN_VALUE_TYPES } from '../../../../constants/carePlanValueTypes'
import { Box } from '@mui/material'

export default class MultiMetricTrackingItem extends CarePlanItem {
  constructor (props) {
    super(props)
    this.type = 'multi_tracking'
    this.carePlanConfig = productConfig().carePlan
  }

  multiTrackingModifiers () {
    return {
      weight: {
        get: CAREPLAN_VALUE_TYPES.MULTITRACKING_WEIGHT.get(val => val / MS_IN_A_DAY),
        set: CAREPLAN_VALUE_TYPES.MULTITRACKING_WEIGHT.set(val => val * MS_IN_A_DAY)
      },
      bloodPressure: {
        get: CAREPLAN_VALUE_TYPES.MULTITRACKING_BLOOD_PRESSURE.get(val => val / MS_IN_A_DAY),
        set: CAREPLAN_VALUE_TYPES.MULTITRACKING_BLOOD_PRESSURE.set(val => val * MS_IN_A_DAY)
      },
      bloodGlucose: {
        get: CAREPLAN_VALUE_TYPES.MULTITRACKING_BLOOD_GLUCOSE.get(val => val / MS_IN_A_DAY),
        set: CAREPLAN_VALUE_TYPES.MULTITRACKING_BLOOD_GLUCOSE.set(val => val * MS_IN_A_DAY)
      },
      survey: {
        get: CAREPLAN_VALUE_TYPES.MULTITRACKING_SURVEY.get(val => val / MS_IN_A_DAY),
        set: CAREPLAN_VALUE_TYPES.MULTITRACKING_SURVEY.set(val => val * MS_IN_A_DAY)
      },
      timeBetween: {
        get: CAREPLAN_VALUE_TYPES.MIN_TIME_BETWEEN.get(val => val / MS_IN_A_DAY),
        set: CAREPLAN_VALUE_TYPES.MIN_TIME_BETWEEN.set(val => val * MS_IN_A_DAY)
      },
      stopAfter: {
        get: CAREPLAN_VALUE_TYPES.STOP_AFTER.get(val => Math.trunc(val / MS_IN_A_DAY)),
        set: CAREPLAN_VALUE_TYPES.STOP_AFTER.set(val => val * MS_IN_A_DAY)
      }

    }
  }

  thresholdInputElementWeight () {
    const multiMetric = this.multiTrackingModifiers().weight
    return this.getInputElement(1, 13, 1, multiMetric.get, multiMetric.set)
  }

  thresholdInputElementBloodPressure () {
    const multiMetric = this.multiTrackingModifiers().bloodPressure
    return this.getInputElement(1, 13, 1, multiMetric.get, multiMetric.set)
  }

  thresholdInputElementBloodGlucose () {
    const multiMetric = this.multiTrackingModifiers().bloodGlucose
    return this.getInputElement(1, 13, 1, multiMetric.get, multiMetric.set)
  }

  thresholdInputElementSurvey () {
    const multiMetric = this.multiTrackingModifiers().survey
    return this.getInputElement(1, 13, 1, multiMetric.get, multiMetric.set)
  }

  thresholdInputTimeBetween () {
    const multiMetric = this.multiTrackingModifiers().timeBetween
    return this.getInputElement(1, 10, 1, multiMetric.get, multiMetric.set)
  }

  thresholdInputStopAfter () {
    const multiMetric = this.multiTrackingModifiers().stopAfter
    return this.getCustomInputElement([0, 7, 14, 30, 60, 90], multiMetric.get, multiMetric.set)
  }

  renderGoal () {
    const has = this.triggerExists()
    const hasMoreThanWeight = (has.bloodPressure || has.bloodGlucose || this.hasSurvey) && has.weight
    const goal = `Take ${has.weight ? 'weight' : ''}${hasMoreThanWeight ? '/' : ''}${has.bloodPressure ? 'BP' : ''}${has.bloodGlucose ? '/BG' : ''}${this.hasSurvey ? '/survey' : ''} daily`

    return <div>{goal}</div>
  }

  triggerExists () {
    return {
      minTimeBetween: this.hasTrigger('min_time_between'),
      stopAfter: this.hasTrigger('stop_after'),
      weight: this.hasTrigger('weight'),
      bloodPressure: this.hasTrigger('blood_pressure'),
      bloodGlucose: this.hasTrigger('blood_glucose'),
      survey: this.carePlanConfig.hasSurvey
    }
  }

  renderClinicianTrigger () {
    const has = this.triggerExists()
    const modifiers = this.multiTrackingModifiers()

    const numDaysWeight = has.weight ? this.getValue(modifiers.weight.get) : null
    const numDaysBp = has.bloodPressure ? this.getValue(modifiers.bloodPressure.get) : null
    const numDaysBg = has.bloodGlucose ? this.getValue(modifiers.bloodGlucose.get) : null
    const numDaysSurvey = has.survey ? this.getValue(modifiers.survey.get) : null
    const numDaysBetween = has.minTimeBetween ? this.getValue(modifiers.timeBetween.get) : null
    const numDaysStopAfter = has.stopAfter ? this.getValue(modifiers.stopAfter.get) : null

    const line2Pt1 = `${!has.survey && has.weight ? 'and/or ' : ''}  ${!has.weight ? 'If' : ''} no blood pressure for`
    const line2Pt2 = `day${numDaysBp === 1 ? '' : 's'}${this.hasSurvey || has.bloodGlucose ? ',' : '.'}`

    return (
      <div>
        {has.weight && <div>If no weight data for {this.thresholdInputElementWeight()} day{numDaysWeight === 1 ? '' : 's'},</div>}
        {has.bloodPressure && <div>{line2Pt1} {this.thresholdInputElementBloodPressure()} {line2Pt2}</div>}
        {has.bloodGlucose && <div>and/or no blood glucose data for {this.thresholdInputElementBloodGlucose()} day{numDaysBg === 1 ? '' : 's'}.</div>}
        {has.survey && <div>and/or no survey for {this.thresholdInputElementSurvey()} day{numDaysSurvey === 1 ? '' : 's'}.</div>}

        {has.minTimeBetween && <Box sx={{ mt: 2 }}>Send a maximum of one alert every {this.thresholdInputTimeBetween()} day{numDaysBetween === 1 ? '' : 's'}.</Box>}
        {has.stopAfter && <Box sx={{ mt: 1 }}>Stop these alerts {numDaysStopAfter > 0 ? 'after' : ''} {this.thresholdInputStopAfter()} {numDaysStopAfter > 0 ? 'days without data.' : ''} </Box>}
        {!has.stopAfter && <div className='care-plan-row__empty'>These alerts stop after 14 days without data.</div>}
      </div>
    )
  }

  renderCondensedWeightLine (modifiers, has) {
    if (!has.weight) return null
    const numberElement = this.getValueElement(modifiers.weight.get)
    const prefix = 'No weight for '
    const suffix = `d${has.survey || has.bloodGlucose || has.bloodPressure ? ',' : '.'}`

    return (
      <Box>
        {renderLevel(this.state.itemData.level, true)} {prefix}{numberElement}{suffix}
      </Box>
    )
  }

  renderCondensedBPLine (modifiers, has) {
    if (!has.bloodPressure) return null
    const numberElement = this.getValueElement(modifiers.bloodPressure.get)
    const prefix = `${!has.survey && !has.bloodGlucose ? 'and/or' : ''} no BP for `
    const suffix = `d${has.survey || has.bloodGlucose ? ',' : '.'}`

    return (
      <Box>
        {prefix}{numberElement}{suffix}
      </Box>
    )
  }

  renderCondensedBGLine (modifiers, has) {
    if (!has.bloodGlucose) return null
    const numberElement = this.getValueElement(modifiers.bloodGlucose.get)
    const prefix = `${!has.survey ? 'and/or' : ', '} no BG for `
    const suffix = `d${has.survey ? ',' : '.'}`

    return (
      <Box>
        {prefix}{numberElement}{suffix}
      </Box>
    )
  }

  renderCondensedSurveyLine (modifiers, has) {
    if (!has.survey) return null
    const numberElement = this.getValueElement(modifiers.survey.get)
    const prefix = 'and/or no survey for '
    const suffix = 'd'

    return (
      <Box>
        {prefix}{numberElement}{suffix}
      </Box>
    )
  }

  renderCondensed () {
    const has = this.triggerExists()
    const modifiers = this.multiTrackingModifiers()

    return (
      <div>
        {this.renderCondensedWeightLine(modifiers, has)}
        {this.renderCondensedBPLine(modifiers, has)}
        {this.renderCondensedBGLine(modifiers, has)}
        {this.renderCondensedSurveyLine(modifiers, has)}
      </div>
    )
  }
}
