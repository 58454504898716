import _ from 'lodash'
import { getDeliveryDate } from '../baseDateUtils'
import { preciseRound } from '../unitConversionUtils'
import { getIOMRange } from './analyticsUtilityHelper'

/* ******************************************************************************
 * Weight measurements
 * ******************************************************************************/
export function getWeightDeltaInRange (member) {
  const periods = _.get(member, ['weight', 'information', 'periods'])

  if (!periods || periods.length !== 2 || _.includes(periods, null)) return null

  const weightThisWeek = periods[0].avg_weight.value
  const weightLastWeek = periods[1].avg_weight.value

  return Math.round((weightThisWeek - weightLastWeek) * 10) / 10
}

export function getWeightSpreadInRange (member) {
  const maxObj = _.maxBy(member.weight.data, d => d.weight.value)
  const minObj = _.minBy(member.weight.data, d => d.weight.value)

  return {
    maxWeight: {
      value: Math.round((maxObj?.weight?.value ?? 0) * 10) / 10,
      date: maxObj.timestamp
    },
    minWeight: {
      value: Math.round((minObj?.weight?.value ?? 0) * 10) / 10,
      date: minObj.timestamp
    }
  }
}

export function getWeightGainInRange (memberObj) {
  if (!memberObj.weight || !memberObj.weight.data || !memberObj.weight.data.length) return null

  // Sort weight data
  const weights = _.sortBy(memberObj.weight.data, d => d.timestamp)
  const startWeight = _.head(weights).weight.value
  const endWeight = _.last(weights).weight.value

  const weightGain = preciseRound(endWeight - startWeight, 2)
  return weightGain
}

/**
 * Get a member's weight classification using their height and pre-pregnancy weight.
 */
export function getMemberBMI (userObj) {
  const kg = userObj.start_weight_in_kg
  const mSquared = Math.pow(userObj.height_in_cm / 100, 2)

  return kg / mSquared
}

/**
 * Get a member's weight classification using their BMI.
 * Returns one of ['OB', 'OW', 'Norm.']
 *
 * NOTE: This is a coarse-grained classification for the pregnancy app; if we need to use BMI
 * elsewhere this method should be fleshed out more to include underweight BMI &c.
 */
export function getMemberWeightCondition (userObj) {
  const bmi = getMemberBMI(userObj)

  if (bmi <= 25) {
    return 'Norm.'
  }
  if (bmi > 25 && bmi <= 30) {
    return 'OW'
  }
  return 'OB'
}

export function getBpDisplayString (bpMeasurement) {
  if (!bpMeasurement) {
    return '—'
  }

  return `${Math.round(bpMeasurement.systolic)}/${Math.round(bpMeasurement.diastolic)}`
}

function getBloodPressureDeltaInRange (memberObj, submetric) {
  const periods = _.get(memberObj, ['blood_pressure', 'information', 'periods'])

  if (!periods || periods.length !== 2 || _.includes(periods, null)) return null

  const valueThisPeriod = periods[0][submetric]
  const valueLastPeriod = periods[1][submetric]

  return Math.round(valueThisPeriod - valueLastPeriod)
}

export function getSystolicDeltaInRange (memberObj) {
  return getBloodPressureDeltaInRange(memberObj, 'avg_systolic')
}

export function getDiastolicDeltaInRange (memberObj) {
  return getBloodPressureDeltaInRange(memberObj, 'avg_diastolic')
}

export function getBloodPressureHeartRateDeltaInRange (memberObj) {
  return getBloodPressureDeltaInRange(memberObj, 'avg_heart_rate')
}

/* ******************************************************************************
 * Blood glucose measurements
 * ******************************************************************************/
export function getBloodGlucoseDeltaInRange (member) {
  const periods = _.get(member, ['blood_glucose', 'information', 'periods'])

  if (!periods || periods.length !== 2 || _.includes(periods, null)) return null

  const bgThisWeek = periods[0].avg_blood_glucose
  const bgLastWeek = periods[1].avg_blood_glucose

  return Math.round((bgThisWeek - bgLastWeek) * 10) / 10
}

/* ******************************************************************************
 * Activity measurements
 * ******************************************************************************/

export function getActivityStepsAverageInRange (memberObj) {
  const mean = _.meanBy(memberObj.activity.data, d => d.steps)
  return Math.round(mean)
}

/*
 * Returns AVERAGE daily active minutes/steps in range
 */
export function getActivityMinutesAverageInRange (memberObj) {
  const mean = _.meanBy(memberObj.activity.data, (d) => {
    const moderateSeconds = d.intensities.moderate.value
    const intenseSeconds = d.intensities.intense.value
    return (moderateSeconds + intenseSeconds) / 60
  })

  return Math.round(mean)
}

/*
 * Returns TOTAL active steps in range
 */
export function getActivityStepsInRange (memberObj) {
  return _.sumBy(memberObj.activity.data, d => d.steps)
}

export function getIOMRangeOnExpectedDeliveryDate (memberObj) {
  const expectedDeliveryDate = getDeliveryDate(memberObj.user)
  const expectedDeliveryDateIOMRange = getIOMRange(memberObj, expectedDeliveryDate)

  return {
    min: preciseRound(expectedDeliveryDateIOMRange.min, 0),
    max: preciseRound(expectedDeliveryDateIOMRange.max, 0)
  }
}

/* ******************************************************************************
 * Survey responses
 * ******************************************************************************/

const typeToFriendlyType = {
  sob_day: 'SOB (during day)',
  sob_laying_down: 'SOB (laying down)',
  chest_pain: 'Chest pain',
  leg_swelling: 'Leg/ankle swelling',
  difficulty_sleeping: 'Difficulty sleeping',
  dizziness: 'Dizziness/balance'
}

function getFriendlyQuestionType (question) {
  const friendlyType = typeToFriendlyType[question.type]
  if (!friendlyType) return question.type
  return friendlyType
}

const scoreToFriendlyScore = {
  0: 'None',
  2: 'Slight',
  3: 'Moderate',
  4: 'Severe'
}

function getFriendlyScore (score) {
  const friendlyScore = scoreToFriendlyScore[score]
  if (!friendlyScore) return ''
  return friendlyScore
}

function computeScoreForQuestions (questions) {
  if (!questions) return 0 // base case

  let score = 0

  for (const question of questions) {
    score += question.answer.score + computeScoreForQuestions(question.answer.questions)
  }

  return score
}

export function getSurveyResponseTooltipContent (surveyResponse) {
  if (!surveyResponse.questions) {
    // we don't expect to hit this case for well-formed data, but just in case
    return ''
  }

  let content = ''

  for (const question of surveyResponse.questions) {
    const score = question.answer.score + computeScoreForQuestions(question.answer.questions)
    content += `${getFriendlyQuestionType(question)}: ${getFriendlyScore(score)} (${score})<br/>`
  }

  return content
}

export const getNoDataString = (user, deviceIsLinked, deviceType) => {
  const latestOrderStatus = _.maxBy(user.order_statuses?.[deviceType], s => s.timestamp)?.status

  if (deviceIsLinked) {
    switch (latestOrderStatus) {
      case 'Pending':
      case 'Submitted':
      case 'Processing':
        return 'Device manually added' // unlikely
      case 'Fulfilled': return 'Device in transit'
      case 'Exception': return 'Shipment exception'
      case 'Delivered': return 'Device delivered; no data yet'
      default: return 'No data yet'
    }
  } else {
    switch (latestOrderStatus) {
      case 'Pending': return 'Order pending'
      case 'Submitted': return 'Order submitted'
      case 'Processing': return 'Order processing'
      case 'Exception': return 'Shipment exception'
      case 'Fulfilled':
      case 'Delivered':
        return 'Device manually unlinked' // unlikely
      default: return 'No device added or ordered'
    }
  }
}
