import React from 'react'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import { drawerWidth } from './ContentSidebar'
import { connect } from 'react-redux'
/*

  HANDY PAGE LAYOUT REFERENCE
   ____________________________________________________
  |                                                    |
  | <Header />                                         |
  |____________________________________________________|  _
  |                    |                               |   |
  | <ContentSidebar>   | <ContentMainIntro>            |   |
  |     <Footer />     |     <ContentMainHeader />     |   |
  | </ContentSidebar>  | </ContentMainIntro>           |   |
  |                    |_______________________________|   |
  |                    |                               |   | <ContentMain />
  |                    | <ContentMainBody />           |   |
  |                    |                               |   |
  |                    |                               |   |
  |                    |                               |   |
  |                    |                               |   |
  |____________________|_______________________________|  _|

*/

function BaseContent (props) {
  return (
    <Box className='content content--desktop'> {props.children} </Box>
  )
}

const Content = BaseContent

/* ********************************************************************************
 * <ContentMain />
 * ********************************************************************************/

function BaseContentMain (props) {
  let sidebarWidth = props.drawerWidth ?? drawerWidth
  if (!props.sidebarStore.sidebarOpen) sidebarWidth = props.sidebarStore.buttonOnlyWidth

  return (
    <Box
      component='main'
      sx={{
        flexGrow: 1,

        marginLeft: props.dropsSidebar ? 0 : `${sidebarWidth}px`
      }}
    >
      <Toolbar sx={{ mb: 1 }} disableGutters />
      <Box sx={{ pl: 1, pr: 1 }}>
        {props.children}
      </Box>
    </Box>
  )
}
function getPropsFromStore (state) {
  return {
    sidebarStore: state.sidebar
  }
}

const ContentMain = connect(getPropsFromStore, null)(BaseContentMain)

function ContentMainIntroHeader (props) {
  return (
    <Box className='content-main__header'>
      <Box component='h2' className='content-main__header__text'>{props.title}</Box>
      <Box component='div' className='content-main__header__children'>{props.children}</Box>
    </Box>
  )
}

/*
 * <ContentMain />
 */

function ContentMainIntro (props) {
  return (
    <Box
      component='div' className='content-main__intro' sx={{
        position: 'sticky',
        top: '75px',
        pt: 3,
        zIndex: 1
      }}
    >
      {props.children}
    </Box>
  )
}

/*
 * <ContentMain />
 */

function ContentMainBody (props) {
  return (
    <Box data-testid='content-main__body'>
      {props.children}
    </Box>
  )
}

/*
 * Exports
 */

export {
  Content,
  ContentMain,
  ContentMainIntro,
  ContentMainIntroHeader,
  ContentMainBody
}
