import moment from 'moment'
import React from 'react'

import ProfileCell from '../../../elements/ProfileCell/ProfileCell'
import BloodPressureIcon from '../../../elements/icons/BloodPressureIcon'
import PulseOxIcon from '../../../elements/icons/PulseOxIcon'
import OpacityIcon from '@mui/icons-material/Opacity'
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight'
import AirIcon from '@mui/icons-material/Air'
import MonitorHeartRoundedIcon from '@mui/icons-material/MonitorHeartRounded'
import ThermostatIcon from '@mui/icons-material/Thermostat'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import StickyNote2SharpIcon from '@mui/icons-material/StickyNote2Sharp'
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded'
import PersonAddSharpIcon from '@mui/icons-material/PersonAddSharp'
import ChatIcon from '@mui/icons-material/Chat'
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun'
import { Tooltip, Box, Grid, Typography } from '@mui/material'
import { renderLevel } from '../../../../utils/alertUtils'
import { countHumanNotes, getBPRangeAlertDisplay } from '../../../../utils/baseAlertUtils'
import { momentCalendarConfig } from '../../../../utils/baseStringUtils'
import { productConfig } from '../../../../config/config-service'
import { Link } from 'react-router-dom'

export default function AlertRow (props) {
  const renderProfileCol = (alertObj, readClass = '') => {
    const alertDate = moment(alertObj.measurement_timestamp)
    const numPregWeeks = alertDate.diff(moment(alertObj.user.conception_date), 'weeks')
    const numPregWeeksStr = (numPregWeeks === 1) ? '1 week' : `${numPregWeeks} weeks`

    return (
      <Box className={`alert-row__member ${readClass}`} sx={{ padding: '.5rem 0 .5rem' }}>
        <Link to={`/profile/${alertObj.user.id}`} color='inherit' style={{ textDecoration: 'none' }}>
          <ProfileCell user={alertObj.user} detailStr={numPregWeeksStr} />
        </Link>
      </Box>
    )
  }

  const renderStatusCol = (alertObj, statusClass = '', readClass = '') => {
    return <Box className={`alert-row__status ${statusClass} ${readClass}`} />
  }

  const renderMeasurementCol = (alertObj) => {
    let measurementValStr
    let measurementMetric

    const measurementVal = Math.round(alertObj.alert_value * 10) / 10

    switch (alertObj.type) {
      case 'weight_tracking':
      case 'activity_tracking':
      case 'bp_tracking':
      case 'bg_tracking':
      case 'pulse_ox_tracking':
      case 'multi_tracking':
      case 'survey_tracking':
        return (
          <Typography data-testid='alert-row__no-data' variant='body2' fontSize={15}>
            No data for <strong>{moment(alertObj.alert_value).diff(moment(0), 'days')}d</strong>
          </Typography>
        )

      case 'survey_range':
        return (
          <Typography data-testid='alert-row__survey-range' variant='body2' fontSize={15}>
            Total score of <strong>{measurementVal}</strong>
          </Typography>
        )

      case 'bp_range':
        measurementValStr = `${getBPRangeAlertDisplay(alertObj)}`
        break

      case 'activity_relative':
        measurementValStr = `–${Math.abs(measurementVal)}${alertObj.alert_unit}`
        measurementMetric = '7d'
        break

      case 'weight_delta':
        measurementValStr = `+${measurementVal} ${alertObj.alert_unit}`
        measurementMetric = `${alertObj.alert_period / 86400000}d`
        break

      case 'weight_relative':
        measurementValStr = `+ ${measurementVal} ${alertObj.alert_unit}`
        measurementMetric = '7d'
        break

      case 'pregnancy_iom':
        measurementValStr = `+ ${measurementVal} ${alertObj.alert_unit}`
        measurementMetric = 'IOM'
        break

      case 'activity_target':
        measurementValStr = `${measurementVal}${alertObj.alert_unit}`
        measurementMetric = '7d'
        break

      case 'temp_range': // 87.6°F
        measurementValStr = `${measurementVal}°${alertObj.alert_unit}`
        break

      case 'hr_range': // 73.21 bpm
      case 'rr_range': // 17.43 breaths/min // this is likely to go on two lines, but still looks decent
        measurementValStr = `${measurementVal} ${alertObj.alert_unit}`
        break

      case 'pulse_ox_range': // 92%
        measurementValStr = `${measurementVal}${alertObj.alert_unit}`
        break

      case 'health_status_change': // Health Status: Bed Rest
        measurementValStr = alertObj.provider_content
        break

      case 'program_step_change': // Program step 3
        measurementValStr = `Program step ${alertObj.alert_value}`
        break

      case 'new_chat':
        measurementValStr = 'New chat message'
        break

      case 'bg_range':
        measurementValStr = `${measurementVal} ${alertObj.alert_unit}`
        break

      case 'new_member':
        measurementValStr = 'New member'
        break

      default:
        console.warn(`Unexpected alert type: ${alertObj.type}`)
        break
    }

    return (
      <Box data-testid='alert-row__measurement' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography data-testid='alert-row__measurement__val' variant='body2' fontSize={15}>{measurementValStr}</Typography>
        <Typography data-testid='alert-row__measurement__metric' variant='body2' fontSize={15} ml={1}>{measurementMetric}</Typography>
      </Box>
    )
  }

  const renderTypeIcon = (alertObj) => {
    switch (alertObj.metric_type) {
      case 'weight':
        return <Tooltip title='Weight'><MonitorWeightIcon /></Tooltip>
      case 'activity':
        return <Tooltip title='Activity'><DirectionsRunIcon /></Tooltip>
      case 'blood_pressure':
        return <Tooltip title='Blood Pressure'><BloodPressureIcon /></Tooltip>
      case 'temperature':
        return <Tooltip title='Temperature'><ThermostatIcon /></Tooltip>
      case 'heart_rate':
        return <Tooltip title='Heart Rate'><MonitorHeartRoundedIcon /></Tooltip>
      case 'respiratory_rate':
        return <Tooltip title='Respiratory Rate'><AirIcon /></Tooltip>
      case 'blood_glucose':
        return <Tooltip title='Blood Glucose'><OpacityIcon /></Tooltip>
      case 'pulse_ox':
        return <Tooltip title='SpO2'><PulseOxIcon /></Tooltip>
      case 'survey_response':
        return <Tooltip title='Survey Out of Range'><FormatListNumberedRoundedIcon /></Tooltip>
      case 'chat':
        return <Tooltip title='Chat'><ChatIcon /></Tooltip>
      case 'multi':
        return <Tooltip title='Missing Data'><DoNotDisturbIcon /></Tooltip>
      case 'new_member':
        return <Tooltip title='New Member'><PersonAddSharpIcon /></Tooltip>
      default:
        return null
    }
  }

  const renderTypeCol = (alertObj) => {
    return <Typography data-testid='alert-row__type' component='span' variant='body2' fontSize={15}> {renderTypeIcon(alertObj)} </Typography>
  }

  const renderDateCol = (alertObj) => {
    const dateTimeData = moment(alertObj.timestamp).calendar(null, momentCalendarConfig)

    return (<Typography data-testid='alert-row__date' component='span' variant='body2' fontSize={15}> {dateTimeData} </Typography>)
  }

  const renderNotesCol = (alertObj) => {
    const noteData = countHumanNotes(alertObj) ? <StickyNote2SharpIcon /> : '·'

    return (
      <Typography component='span' variant='body2' fontSize={15} ml={2}> {noteData} </Typography>
    )
  }

  const renderLevelCol = (alertObj, hideText) => {
    const level = renderLevel(alertObj.level, hideText)

    return (
      <Typography component='span' variant='body2' fontSize={15} ml='-5px'>  {level} </Typography>
    )
  }

  const getRowFromConfig = (headerName, alertObj, statusClass, readClass) => {
    if (headerName.indexOf('Level') >= 0) return renderLevelCol(alertObj)
    if (headerName.indexOf('Status') >= 0) return renderStatusCol(alertObj, statusClass, readClass)
    if (headerName.indexOf('Measurement') >= 0) return renderMeasurementCol(alertObj)
    if (headerName.indexOf('Type') >= 0) return renderTypeCol(alertObj)
    if (headerName.indexOf('Date') >= 0) return renderDateCol(alertObj)
    if (headerName.indexOf('Notes') >= 0) return renderNotesCol(alertObj)
  }

  const renderDesktop = (alertObj, statusClass = '', readClass = '') => {
    const className = statusClass ? `alert-row__${statusClass}` : ''
    return (
      <Box
        sx={{
          flexGrow: 1,
          borderBottom: '1px dotted lightgray',
          alignItems: 'center'
        }}
        className={className}
        name='alert-row'
      >
        <Grid container columnSpacing={3} sx={{ height: 'inherit' }}>
          <Grid item xs={productConfig().alerts.mainPage.memberNameColumnSize}> {renderProfileCol(alertObj)} </Grid>
          <Grid className='alert-row__info' item container xs onClick={props.handleAlertDetailModalOpen}>
            {productConfig().alerts.mainPage.alertData.map(item => {
              return (
                <Grid item key={`${item.header}__${Date.now()}`} xs={item.columnSize} sx={{ display: 'flex', alignItems: 'center' }}>
                  {getRowFromConfig(item.header, alertObj, statusClass, readClass)}
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Box>
    )
  }

  const renderAlertRow = () => {
    const alertObj = props.alertObj

    const isAlertClosed = (alertObj.status.toUpperCase() === 'CLOSED')
    const isAlertRead = alertObj.read_status

    const statusClass = isAlertClosed ? 'is-closed' : 'is-open'
    const readClass = (isAlertClosed || isAlertRead) ? 'is-read' : 'is-unread'

    return renderDesktop(alertObj, statusClass, readClass)
  }

  return renderAlertRow()
}
