import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'

export default function SignupConfirmation (props) {
  useEffect(() => {
    return () => {
      props.sendAnalytics(false, { signup: 'Sign up confirmed' })
    }
  }, [])

  const renderConfirmation = () => {
    return (
      <Box>
        <Typography variant='h4' mb={3} textAlign='center'>That's it, you're all signed up!</Typography>
        <Typography gutterBottom>You will receive an email to confirm your registration, and a Harvard Pilgrim Care Manager may reach out to you by phone to discuss the program and your health goals.</Typography>
        <Typography gutterBottom>Your digital health devices and a welcome kit have been ordered and are on their way to you now!</Typography>
        <Typography gutterBottom>Have a smartphone?  Download our free mobile app to view your health data and connect to your care manager (the app is an optional part of the program).</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', p: 1 }}>
          <a className='appLink' href='https://apps.apple.com/us/app/heart-healthy-by-ejenta/id1587972304' target='_blank' rel='noreferrer'><img src='https://s3-us-west-2.amazonaws.com/ejenta-images/ios-app-store-link.png' alt='Get Heart Healthy on Apple' width='200' /></a>

          <a className='appLink' href='https://play.google.com/store/apps/details?id=com.ejenta.point32' target='_blank' rel='noreferrer'><img src='https://s3-us-west-2.amazonaws.com/ejenta-images/google-play-link.png' alt='Get Heart Healthy on Android' width='200' /></a>
        </Box>
      </Box>
    )
  }

  return renderConfirmation()
}
