import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import ContentSidebar from '../../js/components/layout/ContentSidebar'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ANALYTICS_SCREENS, analyticsSlice } from '../../js/store/analytics'

/* ****************************************************************************
 * Analytics navigation
 * ****************************************************************************/

function AnalyticsSidebar (props) {
  const renderNavItem = (val, key) => {
    const classNames = classnames({
      'content-sidebar__nav__item': true,
      'is-selected': key === props.analyticsStore.currentScreen
    })

    return (
      <div
        key={key}
        className={classNames}
        onClick={() => props.analyticsActions.setCurrentScreen(key)}
      >
        {val}
      </div>
    )
  }

  const renderAxSidebar = () => {
    return (
      <ContentSidebar drawerWidth={220}>
        <div className='content-sidebar__nav'>
          {_.map(ANALYTICS_SCREENS, renderNavItem)}
        </div>
      </ContentSidebar>
    )
  }

  return renderAxSidebar()
}

function getPropsFromStore (state) {
  return {
    analyticsStore: state.analytics
  }
}

function getPropsFromActions (dispatch) {
  return {
    analyticsActions: bindActionCreators(analyticsSlice.actions, dispatch)
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(AnalyticsSidebar)
