
import React from 'react'
import { preciseRound } from '../unitConversionUtils'
import { getDisplayDelta } from '../baseStringUtils'
import { getIOMDelta, getIOMRange, getWeightGainInPregnancy, getActivityMinutesInRange } from './analyticsUtilityHelper'

export function getActivityProfileStats (memberObj) {
  const activityTotal = (
    <span><strong>{getActivityMinutesInRange(memberObj)}</strong> min</span>
  )

  // Activity goal progress
  const activityProgressNum = memberObj.weekly_activity_goal.current_progress.value
  const activityProgress = (
    <span>{activityProgressNum}%</span>
  )

  // Activity performance
  const activityPerfRaw = memberObj.weekly_activity_goal.activity_performance || 0
  const activityPerf = getDisplayDelta(preciseRound(activityPerfRaw, 2))

  return {
    activityTotal,
    activityProgress,
    activityProgressNum,
    activityPerf
  }
}

export function getBloodGlucoseProfileStats (memberObj) {
  return {
    latestBloodGlucose: memberObj?.blood_glucose?.latest_measurement
  }
}

export function getBloodPressureProfileStats (memberObj) {
  return {
    latestBloodPressure: memberObj?.blood_pressure?.latest_measurement
  }
}

export function getPulseOxProfileStats (memberObj) {
  return {
    latestPulseOx: memberObj?.pulse_ox?.latest_measurement
  }
}

export function getWeightProfileStats (memberObj) {
  return {
    latestWeight: memberObj?.weight?.latest_measurement?.weight?.value
  }
}

/* ******************************************************************************
 * Profile Preg Weight stats (latest, average, delta over time, performance, etc.)
 * ******************************************************************************/

export function getPregnancyWeightProfileStats (memberObj) {
  const noWeightData = !memberObj.weight || !memberObj.weight.data || !memberObj.weight.data.length || !memberObj.weight.latest_measurement

  const gainRateObj = memberObj.weight.information.gain_rate
  const weightGain14Dnum = gainRateObj ? preciseRound(gainRateObj.value, 1) : null
  const weightGain14D = gainRateObj ? `${weightGain14Dnum} ${gainRateObj.unit}` : '—'

  // Weight IOM delta
  let weightIOMDelta
  if (noWeightData) weightIOMDelta = '—'
  else {
    const weightIOMDeltaRaw = getDisplayDelta(getIOMDelta(memberObj).delta)

    if (weightIOMDeltaRaw !== 0) {
      weightIOMDelta = <span>{weightIOMDeltaRaw} lb</span>
    } else {
      weightIOMDelta = <span className='in-range'>in range</span>
    }
  }

  let weightIOMStatus
  let weightIOMStatusNum = null
  if (noWeightData) weightIOMStatus = '—'
  else {
    const { min: minIOM, max: maxIOM } = getIOMRange(memberObj, memberObj.weight.latest_measurement.timestamp)
    weightIOMStatusNum = preciseRound(((memberObj.weight.latest_measurement.weight.value - minIOM) / (maxIOM - minIOM)) * 100, 0)
    weightIOMStatus = `${weightIOMStatusNum}%`
  }

  // Weight gain total
  let weightGainTotal = getWeightGainInPregnancy(memberObj) || 0
  weightGainTotal = preciseRound(weightGainTotal, 2)
  weightGainTotal = <span><strong>{getDisplayDelta(weightGainTotal)}</strong> lb</span>

  return {
    weightGain14D,
    weightGain14Dnum,
    weightIOMDelta,
    weightGainTotal,
    weightIOMStatus,
    weightIOMStatusNum
  }
}
