import React from 'react'
import SettingsSidebar from '../../layout/SettingsSidebar'
import HelpContactSection from '../../pages/HelpPage/HelpContactSection'
import Box from '@mui/material/Box'
import {
  ContentMain,
  ContentMainIntro,
  ContentMainIntroHeader,
  ContentMainBody
} from '../../layout/ContentMain'
import { productConfig } from '../../../config/config-service'

function HelpPage (props) {
  const renderMemberHelp = () => {
    return (
      <Box data-testid='help-page' className='u-top-page-margin'>
        {productConfig().help.contact.exists && <HelpContactSection />}
      </Box>
    )
  }

  const renderHelpPage = () => {
    return (
      <>
        <SettingsSidebar />

        <ContentMain drawerWidth={220}>
          <ContentMainIntro> <ContentMainIntroHeader title='Help' /> </ContentMainIntro>
          <ContentMainBody> {renderMemberHelp()} </ContentMainBody>
        </ContentMain>
      </>
    )
  }

  return renderHelpPage()
}

export default HelpPage
