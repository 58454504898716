import { Alert, Box, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import restApi from '../../../../api'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { EjentaForm, renderCheckboxInput, renderDateInput, renderField, renderInput } from '../../../elements/EjentaForm'
import { Form } from 'react-final-form'
import SelfSignupPaginator from './SelfSignupPaginator'

function SignupCodeComponent (props) {
  const [signupCode, setSignupCode] = useState('')

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const token = params.get('token')
    if (token && signupCode === '') setSignupCode(token)

    return () => {
      props.sendAnalytics(false, { signupCode, doB: '', signedTos: false })
    }
  }, [])

  const handleSubmitCode = (data) => {
    setSignupCode(data.signup_code)

    const submitPromise = new Promise((resolve, reject) => {
      props.apiActions.submitSignupCode(
        { promise: { resolve, reject } },
        { body: JSON.stringify(data) }
      )
    })

    return submitPromise.then(newPotential => props.setPotentialMember(newPotential)).catch(data => data)
  }

  const renderEnterCodeForm = () => {
    const label =
        'I have read and agree to Ejenta\'s&nbsp;<a href=\'//ejenta.com/terms.pdf\' target=\'_blank\'>Terms of Service</a> and&nbsp;<a href=\'//ejenta.com/privacy.pdf\' target=\'_blank\'>Privacy&nbsp;Policy</a>.'

    const signupCodeField = {
      name: 'signup_code',
      type: 'text',
      label: 'Sign-up Code',
      testId: 'signupCode',
      component: renderInput
    }

    const dateOfBirthField = {
      name: 'date_of_birth',
      type: 'date',
      label: 'Date of birth (mm/dd/yyyy)',
      testId: 'dateOfBirth',
      component: renderDateInput
    }

    const signedTosField = {
      name: 'signedTos',
      type: 'checkbox',
      testId: 'signedTos',
      label,
      component: renderCheckboxInput
    }

    return (
      <Box>
        <Typography variant='h4' textAlign='center'>Sign up for the Heart Healthy Program</Typography>

        <Box className='login-form__content' sx={{ marginTop: '1rem' }}>
          <Typography textAlign='center'>Have a sign-up code? Enter it here.</Typography>

          <Form
            onSubmit={handleSubmitCode}
            keepDirtyOnReinitialize
            initialValues={() => {
              return {
                signup_code: signupCode
              }
            }}
            validate={values => {
              const errors = {}
              if (!values.signup_code) errors.signup_code = 'Sign up code is required'
              if (!values.signedTos) errors.signedTos = 'Signed Terms of Service is required'

              if (!values.date_of_birth) errors.date_of_birth = 'Date of birth is required'
              else if (values.date_of_birth === 'Invalid date') errors.date_of_birth = 'Date must be in \'MM/DD/YYYY\' format'
              return errors
            }}
          >
            {({ handleSubmit, pristine, submitting, submitError, hasValidationErrors }) => (
              <EjentaForm onSubmit={handleSubmit}>
                {submitError && <Alert severity='error'>{submitError}</Alert>}

                <Stack spacing={2}>
                  {renderField({}, signupCodeField)}
                  {renderField({}, dateOfBirthField)}

                  <Box ml={2}>{renderField({}, signedTosField)}</Box>

                  <SelfSignupPaginator
                    isFormSubmit
                    hasPreviousSteps
                    onHandleBack={props.handleDecrementPage}
                    canContinue={!pristine && !hasValidationErrors}
                    submitting={submitting}
                    hasMoreSteps
                  />
                </Stack>
              </EjentaForm>
            )}
          </Form>
        </Box>
      </Box>
    )
  }

  return renderEnterCodeForm()
}

function getPropsFromActions (dispatch) {
  return {
    apiActions: bindActionCreators(restApi.actions, dispatch)
  }
}

export default connect(null, getPropsFromActions)(SignupCodeComponent)
