import React, { useState } from 'react'
import { connect } from 'react-redux'
import restApi from '../../api'
import TermsOfService from '../elements/TermsOfService'
import BasicFrame from '../layout/BasicFrame'
import ResetPasswordForm from '../controllers/forms/ResetPasswordForm'
import FirstTimePasswordForm from '../controllers/forms/FirstTimePasswordForm'
import ConsentForm from '../elements/ConsentForm'
import { Box, Button, Card, CardContent, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { productConfig } from '../../config/config-service'

/**
 * New user workflow:
 * 1) User clicks activate account link in email.
 * 2) User sets password, and is automatically logged in.
 * 3) User signs consent (if product required, if they are not a clinician).
 * 4) User signs terms of service.
 *
 * User will also be directed here if they have logged-in successfully
 * but haven't yet signed TOS/consent.
 */
function OnboardingPage (props) {
  const [isTosChecked, setIsTosChecked] = useState(false)
  const [isConsentChecked, setIsConsentChecked] = useState(false)

  const handleToggleTosChecked = () => {
    setIsTosChecked(!isTosChecked)
  }

  const handleTosContinue = () => {
    if (!isTosChecked) return
    props.apiActions.signToS(props.user)
  }

  const handleToggleConsentChecked = () => {
    setIsConsentChecked(!isConsentChecked)
  }

  const handleConsentContinue = () => {
    if (!isConsentChecked) return
    props.apiActions.signConsent(props.user)
  }

  /*
   * Rendering
   */
  const renderTermsOfService = () => {
    return (
      <Card className='welcome-page__tos'>
        <CardContent sx={{ textAlign: 'center' }}>
          <Typography variant='h6'>Welcome to Ejenta!</Typography>
          <Typography variant='body1' mb={2}>Please agree to the Terms of Service to continue.</Typography>

          <Box className='welcome-page__tos-actions'>
            <FormControlLabel
              sx={{ '& .MuiTypography-root': { fontSize: 14, fontWeight: 'light' } }}
              control={<Checkbox size='small' onClick={handleToggleTosChecked} checked={isTosChecked} />}
              label='I have read and agree to the Terms of Service'
            />

            <Box className='welcome-page__tos-continue'>
              {!isTosChecked && <Button variant='contained' color='secondary' disabled>Continue</Button>}
              {isTosChecked && <Button variant='contained' onClick={handleTosContinue}>Continue</Button>}
            </Box>
          </Box>

          <div className='welcome-page__tos-container'>
            <TermsOfService />
          </div>
        </CardContent>
      </Card>
    )
  }

  const renderConsent = () => {
    return (
      <div className='welcome-page__tos'>
        <Typography variant='h6'>Welcome to Ejenta!</Typography>
        <Typography variant='body1' mb={2}>Please agree to the Consent Form to continue.</Typography>

        <Box className='welcome-page__tos-actions'>
          <FormControlLabel
            sx={{ '& .MuiTypography-root': { fontSize: 14, fontWeight: 'light' } }}
            control={<Checkbox size='small' onClick={handleToggleConsentChecked} checked={isConsentChecked} />}
            label='I have read and agree to the Consent Form'
          />

          <Box className='welcome-page__tos-continue'>
            {!isConsentChecked && <Button variant='contained' color='secondary' disabled>Continue</Button>}
            {isConsentChecked && <Button variant='contained' onClick={handleConsentContinue}>Continue</Button>}
          </Box>
        </Box>

        <div className='welcome-page__tos-container'>
          <ConsentForm />
        </div>
      </div>
    )
  }

  const renderFirstTimePasswordForm = () => {
    return (
      <Box data-testid='welcome-page__pw'>
        <FirstTimePasswordForm />
      </Box>
    )
  }

  const renderDone = () => {
    return (
      <Box
        sx={{ textAlign: 'center', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '9rem' }}
        className='login-form'
      >
        <Typography variant='h6'>Welcome to Ejenta!</Typography>

        <Box>
          <Typography variant='body1' mb={2}>You've successfully set up your account.</Typography>
          <Button variant='contained' href='/alerts' fullWidth>Continue to dashboard</Button>
        </Box>
      </Box>
    )
  }

  const renderOnboardingPage = () => {
    let content
    const needsToLogin = !props.user
    const needsTOS = !props.user.signed_tos
    const needsPasswordChange = !props.user.changed_password
    const needsSignedConsent = productConfig().onboarding.hasConsentForm ? !props.user.clinician && !props.user.signed_consent : false

    // If they are not logged-in
    if (needsToLogin) {
      content = <ResetPasswordForm />
    } else if (needsSignedConsent) {
      content = renderConsent()
    } else if (needsTOS) {
      content = renderTermsOfService()
    } else if (needsPasswordChange) {
      content = renderFirstTimePasswordForm()
    } else {
      content = renderDone()
    }

    return (
      <BasicFrame>
        <div className='welcome-page'> {content} </div>
      </BasicFrame>
    )
  }

  return renderOnboardingPage()
}

function getPropsFromStore (state) {
  return {
    user: state.userSession.user
  }
}

function getPropsFromActions (dispatch) {
  return {
    apiActions: {
      signToS (user) {
        dispatch(restApi.actions.signToS({ id: user.id }))
      },
      signConsent (user) {
        dispatch(restApi.actions.signConsent({ id: user.id }))
      },
      changePassword (user) {
        dispatch(restApi.actions.changePassword({ id: user.id }))
      }
    }
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(OnboardingPage)
