import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import restApi from '../../../../api'
import { getDisplayName } from '../../../../utils/baseStringUtils'
import Modal from '../../../elements/Modal'
import CarePlanHeader from '../../../elements/CarePlan/CarePlanHeader'
import CarePlanRows from '../../../elements/CarePlan/CarePlanRows'

function EditMemberCarePlanModal (props) {
  const [carePlanItems, setCarePlanItems] = useState([])

  useEffect(() => {
    setCarePlanItems(props.memberObj?.careplan)
  }, [props.memberObj?.careplan])

  const renderModal = () => {
    const memberName = getDisplayName(props.memberObj.user)
    const modalTitle = (<span> Edit care plan for {memberName} </span>)

    return (
      <Modal
        isOpen
        name='EditMemberCarePlan'
        targetUser={props.memberObj.user}
        onModalClose={() => props.profileViewActions.handleClearModal()}
        modalTitle={modalTitle}
      >
        <CarePlanHeader />
        <CarePlanRows
          carePlanItems={carePlanItems}
          updateCarePlan={props.apiActions.updateMemberCarePlan}
        />
      </Modal>
    )
  }

  const renderEditMemberCarePlanModal = () => {
    const isModalVisible = props.profileViewStore.openModal === 'editMemberCarePlan'
    if (!isModalVisible) return null
    return renderModal()
  }

  return renderEditMemberCarePlanModal()
}

function getPropsFromActions (dispatch, ownProps) {
  return {
    apiActions: {
      updateMemberCarePlan: body => dispatch(
        restApi.actions.updateMemberCarePlan(
          { id: ownProps.memberObj.user.id },
          { body: JSON.stringify(body) }
        )
      )
    }
  }
}
export default connect(null, getPropsFromActions)(EditMemberCarePlanModal)
