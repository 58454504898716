import { renderField, renderInput, renderCheckboxInput, renderInputWithUnit, renderSelect } from '../components/elements/EjentaForm'
import { DEVICE_PLATFORM_MAP, GENDER_MAP } from '../utils/baseStringUtils'
import { getCareManagerOptions, getDateInputOptions, getSelectInputOptions } from '../utils/member/baseProfileUtils'
import { convertKgToLb, preciseRound } from '../utils/unitConversionUtils'
import { productConfig } from '../config/config-service'
import moment from 'moment'

export const IN_CM_MAP = {
  0: 'No',
  1: 'Yes'
}

export const COACH_MAP = {
  TS: 'TS',
  RN: 'RN',
  HU: 'HU'
}

export const genderSelect = {
  apiField: 'gender',

  formInput () {
    const fieldOptions = {
      name: 'gender',
      label: 'Sex',
      component: renderSelect,
      type: 'select',
      options: GENDER_MAP
    }

    return renderField(this, fieldOptions)
  }
}

export const diagnosisInput = {
  optional: true,
  apiField: 'summary',

  formInput () {
    const fieldOptions = {
      name: 'diagnosis',
      label: 'Diagnosis',
      component: renderInput,
      type: 'text'
    }

    return renderField(this, fieldOptions)
  }
}

export const dryWeightInputWithUnit = {
  optional: true,
  apiField: 'dry_weight_in_kg',

  accessor: (user) => {
    if (user.dry_weight_in_kg) {
      return preciseRound(convertKgToLb(user.dry_weight_in_kg), 2)
    }
    return ''
  },

  formInput () {
    const fieldOptions = {
      name: 'dryWeight',
      label: 'Dry weight',
      component: renderInputWithUnit,
      type: 'text', // for compatibility with old browsers
      unit: 'lb',
      minValue: 1,
      maxValue: 999,
      step: 'any'
    }

    return renderField(this, fieldOptions)
  }
}

export const startWeightInputWithUnit = {
  apiField: 'start_weight_in_kg',
  optional: productConfig().name !== 'LEAP',

  accessor: (user) => {
    if (user.start_weight_in_kg) {
      return preciseRound(convertKgToLb(user.start_weight_in_kg), 2)
    }
    return ''
  },

  formInput () {
    const fieldOptions = {
      name: 'startWeight',
      label: 'Pre-pregnancy weight',
      component: renderInputWithUnit,
      type: 'text', // for compatibility with old browsers
      step: 'any',
      unit: 'lb',
      minValue: 1,
      maxValue: 999
    }

    return renderField(this, fieldOptions)
  }
}

export const homePhoneInput = {
  apiField: 'home_phone',
  optional: true,

  formInput () {
    const fieldOptions = {
      name: 'homePhone',
      label: 'Home phone',
      component: renderInput,
      type: 'tel'
    }

    return renderField(this, fieldOptions)
  }
}

export const streetAddress1Input = {
  apiField: 'street_address_1',

  formInput () {
    const fieldOptions = {
      name: 'streetAddress1',
      label: 'Address line 1',
      component: renderInput,
      type: 'text'
    }

    return renderField(this, fieldOptions)
  }
}

export const streetAddress2Input = {
  apiField: 'street_address_2',
  optional: true,

  formInput () {
    const fieldOptions = {
      name: 'streetAddress2',
      label: 'Address line 2',
      component: renderInput,
      type: 'text'
    }

    return renderField(this, fieldOptions)
  }
}

export const cityInput = {
  apiField: 'city',

  formInput () {
    const fieldOptions = {
      name: 'city',
      label: 'City',
      component: renderInput,
      type: 'text'
    }

    return renderField(this, fieldOptions)
  }
}

export const stateInput = {
  apiField: 'state',

  formInput () {
    const fieldOptions = {
      name: 'state',
      label: 'State',
      component: renderInput,
      type: 'text'
    }

    return renderField(this, fieldOptions)
  }
}

export const zipCodeInput = {
  apiField: 'zip_code',

  formInput () {
    const fieldOptions = {
      name: 'zipCode',
      label: 'Zip code',
      component: renderInput,
      type: 'text'
    }

    return renderField(this, fieldOptions)
  }
}

export const pcpNameInput = {
  apiField: 'pcp',
  optional: true,

  formInput () {
    const fieldOptions = {
      name: 'pcp',
      label: 'Primary care physician',
      component: renderInput,
      type: 'text'
    }

    return renderField(this, fieldOptions)
  }
}

export const pcpPhoneInput = {
  apiField: 'pcp_phone',
  optional: true,

  formInput () {
    const fieldOptions = {
      name: 'pcpPhone',
      label: 'PCP phone number',
      component: renderInput,
      type: 'tel'
    }

    return renderField(this, fieldOptions)
  }
}

export const cellPhoneInput = {
  optional: true,

  formInput () {
    const fieldOptions = {
      name: 'phone',
      label: 'Cell phone',
      component: renderInput,
      type: 'tel'
    }

    return renderField(this, fieldOptions)
  }
}

export const careManagerIdSelect = {
  apiField: 'care_manager_id',
  formInput (careManagers, change) {
    const fieldOptions = {
      name: 'care_manager_id',
      label: 'Care Manager',
      component: renderSelect,
      type: 'select',
      options: getCareManagerOptions(careManagers),
      onChange: (value) => {
        if (value !== 'DEFAULT_OPTION') {
          change('in_care_management', '1')
        } else {
          change('in_care_management', '0')
        }
      }
    }

    return renderField(this, fieldOptions)
  }
}

export const inCareManagementSelect = {
  apiField: 'in_care_management',

  formInput (change) {
    const fieldOptions = {
      name: 'in_care_management',
      label: 'In care management',
      component: renderSelect,
      type: 'select',
      options: IN_CM_MAP,
      onChange: (value) => {
        if (value === '0') {
          change('care_manager_id', 'DEFAULT_OPTION')
        }
      }
    }

    return renderField(this, fieldOptions)
  },

  accessor: user => {
    const hasCareManager = !!user.care_manager_id && user.care_manager_id !== 'DEFAULT_OPTION'
    return hasCareManager ? '1' : user.in_care_management || '0'
  }
}

export const lcuInput = {
  apiField: 'lcu',
  optional: true,

  formInput () {
    const fieldOptions = {
      name: 'lcu',
      label: 'LCU',
      component: renderInput,
      type: 'text'
    }

    return renderField(this, fieldOptions)
  }
}

export const lifestyleCoachSelect = {
  apiField: 'lifestyle_coach',

  formInput () {
    const fieldOptions = {
      name: 'lifestyleCoach',
      label: 'Lifestyle Coach',
      component: renderSelect,
      type: 'select',
      options: COACH_MAP
    }

    return renderField(this, fieldOptions)
  }
}

export const studyIdInput = {
  apiField: 'medical_id',

  formInput () {
    const fieldOptions = {
      name: 'studyId',
      label: 'Study ID',
      component: renderInput,
      type: 'text'
    }

    return renderField(this, fieldOptions)
  },

  readOnlyFormInput () {
    const fieldOptions = {
      name: 'studyId',
      label: 'Study ID',
      component: renderInput,
      type: 'text',
      disabled: true
    }

    return renderField(this, fieldOptions)
  }
}

export const employerGroupInput = {
  apiField: 'employer_group',
  optional: true,

  formInput () {
    const fieldOptions = {
      name: 'employer_group',
      label: 'Employer group',
      component: renderInput,
      type: 'text'
    }

    return renderField(this, fieldOptions)
  }
}

export const signedConsentCheckbox = {
  apiField: 'signed_consent',
  optional: false,
  formInput () {
    const fieldOptions = {
      name: 'signed_consent',
      label: 'Member has signed paper Consent Form.*',
      component: renderCheckboxInput,
      checked: false,
      required: true,
      type: 'checkbox'
    }

    return renderField(this, fieldOptions)
  }
}

export const signedTOSCheckbox = {
  apiField: 'signed_tos',
  optional: false,
  formInput () {
    const fieldOptions = {
      name: 'signed_tos',
      label: 'Member has agreed to <a href=\'//ejenta.com/terms.pdf\' target=\'blank\'> &nbsp; Terms of Service.*</a>',
      component: renderCheckboxInput,
      checked: false,
      required: true,
      type: 'checkbox'
    }

    return renderField(this, fieldOptions)
  }
}

export const signedConsentAndTosCheckbox = {
  optional: false,
  formInput () {
    const fieldOptions = {
      name: 'signedConsentAndTos',
      label: 'Member has signed paper Consent and <a href=\'//ejenta.com/terms.pdf\' target=\'_blank\'> &nbsp;Terms of Service.*</a>',
      component: renderCheckboxInput,
      checked: false,
      required: true,
      type: 'checkbox'
    }

    return renderField(this, fieldOptions)
  }
}

export const devicePlatformSelect = {
  apiField: 'device_platform',
  optional: true,
  formInput () {
    return getSelectInputOptions(this, 'devicePlatform', 'Mobile device type', DEVICE_PLATFORM_MAP)
  }
}

export const conceptionDateInput = {
  apiField: 'conception_date',
  accessor: user => moment(user.conception_date).format('Y-MM-DD'),
  formInput () {
    return getDateInputOptions(this, 'conceptionDate', 'Pregnancy start birth (mm/dd/yyyy)', false, 'Use the first day of the last menstrual period.')
  }
}

export const customMemberFields = {
  gender: genderSelect,
  diagnosis: diagnosisInput,
  dryWeight: dryWeightInputWithUnit,
  heightFeet: { optional: true },
  heightInches: { optional: true },
  startWeight: startWeightInputWithUnit,
  phone: cellPhoneInput,
  signedConsentAndTos: signedConsentAndTosCheckbox,
  studyId: studyIdInput,
  lifestyleCoach: lifestyleCoachSelect,
  devicePlatform: devicePlatformSelect,
  conceptionDate: conceptionDateInput,
  signed_consent: signedConsentCheckbox,
  homePhone: homePhoneInput,
  streetAddress1: streetAddress1Input,
  streetAddress2: streetAddress2Input,
  city: cityInput,
  state: stateInput,
  zipCode: zipCodeInput,
  pcp: pcpNameInput,
  pcpPhone: pcpPhoneInput,
  care_manager_id: careManagerIdSelect,
  in_care_management: inCareManagementSelect,
  lcu: lcuInput,
  employer_group: employerGroupInput,
  signed_tos: signedTOSCheckbox
}

export const getCustomMemberFields = () => {
  const profileConfig = productConfig().profile
  const configCustomFields = [...profileConfig.customFormFields, ...profileConfig.formCheckboxes, ...profileConfig.pregnancyFields]
  const memberFields = {}

  configCustomFields.forEach(field => {
    memberFields[field] = customMemberFields[field]
  })

  return memberFields
}
