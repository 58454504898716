import React from 'react'
import { NavLink } from 'react-router-dom'
import UserNavLabel from './UserNavLabel'
import ChatIcon from '@mui/icons-material/Chat'
import Badge from '@mui/material/Badge'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'

export default function ChatMenuItem (props) {
  const chatLink = '/chat?type=new_chat&filters=FILTER/OPEN,FILTER/OPEN_UNREAD'

  const MobileBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 10,
      backgroundColor: '#EF6C00',
      color: 'white',
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px'
    }
  }))

  const DesktopBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 5,
      backgroundColor: 'red',
      color: 'white',
      height: '1.15rem',
      maxWidth: '1.15rem',
      border: `1px solid ${theme.palette.background.paper}`,
      padding: '4px'
    }
  }))

  const renderDefaultMenuItem = () => {
    return (
      <NavLink
        key='chat'
        to={chatLink}
        className={({ isActive }) => 'user-nav__item' + (isActive ? ' active' : '')}
      >
        <DesktopBadge badgeContent={props.badgeCount}>
          <ChatIcon fontSize='large' />
        </DesktopBadge>
        <UserNavLabel labelText='Chat' />
      </NavLink>
    )
  }

  const renderMobileMenuItem = () => {
    return (
      <MenuItem key='chat' onClick={props.handleCloseNavMenu}>
        <NavLink to={chatLink} className={({ isActive }) => 'user-nav-mobile__item' + (isActive ? ' active' : '')}>
          <MobileBadge badgeContent={props.badgeCount}>
            <Typography textAlign='center' fontSize='1.1rem'> Chat </Typography>
            <Box sx={{ width: '1rem' }} />
          </MobileBadge>
        </NavLink>
      </MenuItem>
    )
  }

  if (props.isMobile) return renderMobileMenuItem()
  else return renderDefaultMenuItem()
}
