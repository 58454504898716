import React, { useEffect } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import restApi from '../../../../api'
import AlertDetailModalNotes from './AlertDetailModalNotes'
import AlertDetailSidebar from './AlertDetailSidebar'
import Modal from '../../../elements/Modal'
import { FormControlLabel, Switch } from '@mui/material'
import { isAlertOpen } from '../../../../constants/constants'
import { useLocation } from 'react-router'

/* ******************************************************************************
 * Alert detail modal
 * ******************************************************************************/
function AlertDetailModal (props) {
  const location = useLocation()

  useEffect(() => {
    if (props.selectedAlert) initAlert()
  }, [props.selectedAlert])

  const initAlert = () => {
    if (!props.member) {
      props.apiActions.fetchInitialMemberSummary(props.selectedAlert.user.id)
    }
    handleReadAlert(props.selectedAlert)
  }

  const handleReadAlert = (alertObj) => {
    // If alert is unread, mark it as read
    if (!alertObj.read_status) {
      props.markAlertAsRead({
        alertId: alertObj.id,
        memberId: alertObj.user_id,
        clinicianId: props.clinicianId,
        isRead: true
      })
    }
  }

  const handleUpdateAlertStatus = () => {
    const alertObj = props.selectedAlert
    const isOpen = isAlertOpen(alertObj)

    props.updateAlertStatus({
      alertId: alertObj.id,
      memberId: alertObj.user_id,
      clinicianId: props.clinicianId,
      status: isOpen ? 'CLOSED' : 'OPEN'
    })
  }

  const handleAddNote = (note) => {
    const alertObj = props.selectedAlert

    props.addAlertNote({
      alertId: alertObj.id,
      memberId: alertObj.user_id,
      clinicianId: props.clinicianId,
      alertNote: note
    })
  }

  /*
   * Rendering
   */
  const renderStatus = (alertObj) => {
    const isOpen = isAlertOpen(alertObj)
    const label = isOpen ? 'Open' : 'Closed'
    return (
      <FormControlLabel
        control={
          <Switch
            id='alert-status'
            checked={isOpen}
            onChange={handleUpdateAlertStatus}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        label={label}
      />
    )
  }

  const renderDetails = (alertObj) => {
    const endDateMoment = moment(alertObj.measurement_timestamp)
    const startDateMoment = moment(endDateMoment).subtract(alertObj.alert_period)
    const timePeriodStr = `${startDateMoment.format('MMM D, Y')} – ${endDateMoment.format('MMM D, Y')}`
    const sentDateStr = moment(alertObj.timestamp).format('MMM D, Y')

    return (
      <section className='alert-detail__details'>
        <div className='alert-detail__dates'>
          <div className='alert-detail__time-period'>
            <h3>Time period</h3>
            <div>{timePeriodStr}</div>
          </div>
          <div className=''>
            <h3>Alert sent on</h3>
            <div>{sentDateStr}</div>
          </div>
        </div>
        <div className='alert-detail__status'>
          <h3>Status</h3>
          {renderStatus(alertObj)}
        </div>
      </section>
    )
  }

  const renderModal = () => {
    const alertObj = props.selectedAlert

    // If we're already on a member profile page, don't show the link to the member profile page
    let hideProfileLink = false
    if (location.pathname.startsWith('/profile')) {
      hideProfileLink = true
    }

    return (
      <Modal
        name='AlertDetail'
        targetUser={alertObj.user}
        maxWidth={props.maxWidth}
        isOpen
        onModalClose={props.onModalClose} // eslint-disable-line react/jsx-handler-names
        modalTitle='Alert detail'
      >
        <div className='alert-detail'>
          <div className='alert-detail__alert'>
            <div className='alert-detail__title' dangerouslySetInnerHTML={{ __html: alertObj.provider_content.replace(/\n/g, '<br />') }} />

            {renderDetails(alertObj)}

            <AlertDetailModalNotes
              alertObj={alertObj}
              clinicianId={props.clinicianId}
              memberObj={props.member}
              onAddNote={handleAddNote}
            />
          </div>

          <div className='alert-detail__profile'>
            <AlertDetailSidebar member={props.member} hideProfileLink={hideProfileLink} onModalClose={props.onModalClose} /> {/* eslint-disable-line react/jsx-handler-names */}
          </div>
        </div>
      </Modal>
    )
  }

  if (!props.selectedAlert) return null
  return renderModal()
}

function getPropsFromStore (state, ownProps) {
  return {
    member: ownProps.selectedAlert && state.membersCache.entities[ownProps.selectedAlert.user_id],
    clinicianId: state.userSession.user && state.userSession.user.id
  }
}

function getPropsFromActions (dispatch) {
  return {
    apiActions: {
      fetchInitialMemberSummary: id => dispatch(restApi.actions.memberSummary({ id }))
    },

    markAlertAsRead: ({ alertId, memberId, clinicianId, isRead }) => {
      dispatch(restApi.actions.updateAlert({ alertId }, {
        body: JSON.stringify({
          read_status: isRead ? 0 : 1,
          user_id: memberId,
          author_id: clinicianId
        })
      }))
    },

    updateAlertStatus: ({ alertId, memberId, clinicianId, status }) => {
      dispatch(restApi.actions.updateAlert({ alertId }, {
        body: JSON.stringify({
          status,
          user_id: memberId,
          author_id: clinicianId
        })
      }))
    },

    addAlertNote: ({ alertId, memberId, clinicianId, alertNote, status }) => {
      dispatch(restApi.actions.updateAlert({ alertId }, {
        body: JSON.stringify({
          status,
          user_id: memberId,
          author_id: clinicianId,
          provider_content: alertNote
        })
      }))
    }
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(AlertDetailModal)
