import React from 'react'
import SurveyRangeItem from '../RangeItems/SurveyRangeItem'

export default function SymptomSurveyCard (props) {
  return (
    <div className='care-plan__section' data-testid='care-plan__section__survey'>
      <div className='care-plan__section-header'>Symptom Survey</div>
      <SurveyRangeItem {...props} />
    </div>
  )
}
