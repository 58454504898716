
import React, { useState } from 'react'
import moment from 'moment'
import { getActivityProfileStats } from '../../../utils/member/alertDetailUtils'
import { momentCalendarDateConfig } from '../../../utils/baseStringUtils'
import EmptyCell from './EmptyCell'
import Grid from '@mui/material/Grid'

export default function ActivityCell (props) {
  const [memberObj] = useState(props.memberObj)

  if (!memberObj.user.has_activity || !memberObj.activity) {
    const message = !memberObj.user.has_fitbit ? 'Device not set up' : 'No activity data'
    return <EmptyCell message={message} />
  }

  const { activityProgress } = getActivityProfileStats(memberObj)
  const lastActivityMeasurement = moment(memberObj.activity.latest_measurement.date)

  return (
    <Grid container>
      <Grid item xs={12} className='member-row__latest'> {activityProgress} </Grid>
      <Grid item xs={12}> <aside>{lastActivityMeasurement.calendar(null, momentCalendarDateConfig)}</aside> </Grid>
    </Grid>
  )
}
