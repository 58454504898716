import React from 'react'
import classnames from 'classnames'
import moment from 'moment'
import { english as ordinal } from 'ordinal'

import { RANGE_VIEWS } from '../../../utils/member/dateUtils'

import { getTrimesterOfPregnancy, getTrimesterBounds } from '../../../utils/baseDateUtils'
import { DATE_DAY_FORMAT_SANS_YEAR, DATE_DAY_FORMAT_WITH_YEAR } from '../../../constants/constants'
import { Box, IconButton, Typography } from '@mui/material'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'

function PregnancyDateRange (props) {
  const handleRangeStartChange = (direction) => {
    const currentRangeStart = (getRangeStart() || moment().subtract(6, 'days'))
    const rangeZoom = props.dashboardViewStore.rangeZoom
    const memberObj = props.memberObj

    let rangeStart

    if (rangeZoom === RANGE_VIEWS.WEEK) {
      // Week view
      if (direction === -1) {
        // Go backward in time
        rangeStart = moment(currentRangeStart).subtract(1, 'week')
      } else {
        // Go forward in time
        const addOneWeek = moment(currentRangeStart).add(1, 'week')
        rangeStart = addOneWeek.isSameOrBefore(moment()) ? addOneWeek : currentRangeStart
      }
    } else { // Trimester view
      if (direction === -1) {
        // Go backward in time
        const currTrimester = getTrimesterOfPregnancy(memberObj, currentRangeStart)
        const trimester = Math.max(currTrimester - 1, 1)
        rangeStart = getTrimesterBounds(memberObj, trimester).startDate
      } else {
        // Go forward in time
        const currTrimester = getTrimesterOfPregnancy(memberObj, currentRangeStart)
        const trimester = Math.min(currTrimester + 1, memberObj.pregnancy_info.current_trimester)
        rangeStart = getTrimesterBounds(memberObj, trimester).startDate
      }
    }

    props.handleRangeStartChange(rangeStart)
  }

  const renderWeekRange = (rangeStart) => {
    const weekStart = moment(rangeStart)
    const weekEnd = moment(rangeStart).add(6, 'days')
    const startDateFormat = moment(weekStart).isSame(moment(), 'year') ? DATE_DAY_FORMAT_SANS_YEAR : DATE_DAY_FORMAT_WITH_YEAR
    const endDateFormat = moment(weekEnd).isSame(moment(), 'year') ? DATE_DAY_FORMAT_SANS_YEAR : DATE_DAY_FORMAT_WITH_YEAR

    const weekStartStr = weekStart.format(startDateFormat)
    const weekEndStr = weekEnd.format(endDateFormat)

    return (

      <Box sx={{ display: 'flex' }}>
        <IconButton color='secondary' aria-label='arrow backward' onClick={() => handleRangeStartChange(-1)}>
          <ChevronLeft />
        </IconButton>

        <Typography variant='subtitle2' data-testid='date-range__carousel-current' sx={{ p: 2 }}>
          {weekStartStr} – {weekEndStr}
        </Typography>

        {!weekEnd.isSame(moment(), 'day') && (
          <IconButton color='secondary' aria-label='arrow forward' onClick={() => handleRangeStartChange(1)}>
            <ChevronRight />
          </IconButton>
        )}
      </Box>
    )
  }

  const renderTrimesterRange = (rangeStart) => {
    const trimesterIndex = getTrimesterOfPregnancy(props.memberObj, rangeStart)
    const trimesterStr = ordinal(trimesterIndex)

    return (
      <Box sx={{ display: 'flex' }}>
        {trimesterIndex > 1 &&
          <IconButton color='secondary' aria-label='arrow backward' onClick={() => handleRangeStartChange(-1)}>
            <ChevronLeft />
          </IconButton>}
        <Typography variant='subtitle2' data-testid='date-range__carousel-current' sx={{ p: 2 }}>
          {trimesterStr} trimester
        </Typography>

        {trimesterIndex < props.memberObj.pregnancy_info.current_trimester &&
          <IconButton color='secondary' aria-label='arrow forward' onClick={() => handleRangeStartChange(1)}>
            <ChevronRight />
          </IconButton>}
      </Box>
    )
  }

  const renderPregnancyRange = (memberObj) => {
    const conceptionDate = moment(memberObj.user.conception_date).format('M/D')
    return (
      <Box sx={{ display: 'flex' }}>
        <Typography variant='subtitle2' data-testid='date-range__carousel-current' sx={{ p: 2 }}>
          Full pregnancy ({conceptionDate}—)
        </Typography>
      </Box>
    )
  }

  const renderRangeZoomSelector = () => {
    const isActive = {
      week: props.dashboardViewStore.rangeZoom === RANGE_VIEWS.WEEK,
      trimester: props.dashboardViewStore.rangeZoom === RANGE_VIEWS.TRIMESTER,
      pregnancy: props.dashboardViewStore.rangeZoom === RANGE_VIEWS.PREGNANCY
    }

    const buttonGroupClassNames = classnames({
      'date-range__zoom': true
    })

    return (
      <div className={buttonGroupClassNames}>
        <a
          className={classnames(
            { 'is-active': isActive.week },
            'date-range__zoom-option',
            'u-link-block'
          )}
          onClick={() => props.handleRangeZoomChange(RANGE_VIEWS.WEEK)}
        >
          Week
        </a>
        <a
          className={classnames(
            { 'is-active': isActive.trimester },
            'date-range__zoom-option',
            'u-link-block'
          )}
          onClick={() => props.handleRangeZoomChange(RANGE_VIEWS.TRIMESTER)}
        >
          Trimester
        </a>
        <a
          className={classnames(
            { 'is-active': isActive.pregnancy },
            'date-range__zoom-option',
            'u-link-block'
          )}
          onClick={() => props.handleRangeZoomChange(RANGE_VIEWS.PREGNANCY)}
        >
          Full pregnancy
        </a>
      </div>
    )
  }

  const getRangeStart = () => {
    const startVal = props.dashboardViewStore.rangeStart
    return startVal ? moment(startVal) : null
  }

  const renderPregnancyDateRange = () => {
    const rangeStart = (getRangeStart() || moment().subtract(6, 'days'))
    let currentRangeDisplay
    switch (props.dashboardViewStore.rangeZoom) {
      case RANGE_VIEWS.WEEK:
        currentRangeDisplay = renderWeekRange(rangeStart)
        break

      case RANGE_VIEWS.TRIMESTER:
        currentRangeDisplay = renderTrimesterRange(rangeStart)
        break

      case RANGE_VIEWS.PREGNANCY:
        currentRangeDisplay = renderPregnancyRange(props.memberObj)
        break

      default:
        break
    }

    return (
      <section>
        <div className='date-range'>
          {renderRangeZoomSelector()}
          {currentRangeDisplay}
        </div>
      </section>
    )
  }

  return renderPregnancyDateRange()
}

export default PregnancyDateRange
