import { Alert, Box, FormControl, FormHelperText, MenuItem, OutlinedInput, Select, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SelfSignupPaginator from './SelfSignupPaginator'

export default function SignupContact (props) {
  const [userInProgress, setUserInProgress] = useState({
    firstName: '',
    lastName: '',
    gender: 'MALE',
    communicationPreference: '',
    email: '',
    phone: '',
    homePhone: '',
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    state: '',
    zipCode: ''
  })
  const [fieldErrors, setFieldErrors] = useState({})

  useEffect(() => {
    setUserInProgress(props.userInProgress)
  }, [props.userInProgress])

  const validateFields = () => {
    const foundErrors = {}
    const emailPattern = /[^@]+@[^.]+..+/
    const phonePattern = /[0-9]{3}[0-9]{3}[0-9]{4}/

    if (userInProgress.phone.length > 0) {
      if (userInProgress.phone.length !== 10) {
        foundErrors.phone = 'Phone number must be exactly 10 digits'
      } else if (!phonePattern.test(userInProgress.phone)) {
        foundErrors.phone = 'Phone number is invalid'
      }
    }

    if (userInProgress.homePhone.length > 0) {
      if (userInProgress.homePhone.length !== 10) {
        foundErrors.homePhone = 'Home phone number must be exactly 10 digits'
      } else if (!phonePattern.test(userInProgress.homePhone)) {
        foundErrors.homePhone = 'Phone number is invalid'
      }
    }

    if (!emailPattern.test(userInProgress.email)) {
      foundErrors.email = 'Email address is invalid'
    }

    if (!userInProgress.firstName) foundErrors.firstName = 'First name is required'
    if (!userInProgress.lastName) foundErrors.lastName = 'Last name is required'

    setFieldErrors(foundErrors)
    return foundErrors
  }

  const continueClicked = () => {
    const form = document.getElementsByTagName('form')[0]
    const foundErrors = validateFields()

    if (!form.checkValidity() || Object.keys(foundErrors).length > 0) return

    if (!userInProgress.phone) {
      /* If phone is not provided, automatically select email as communicationPreference */
      props.handleMemberInfoChange('communicationPreference', 'email')
    }

    props.handleIncrementPage()
  }
  const updateMemberInfo = (field, value) => {
    props.handleMemberInfoChange(field, value)
    const newVal = {
      ...userInProgress,
      [field]: value
    }
    setUserInProgress(newVal)
  }

  const renderNameAndContactInfo = () => {
    const headerText = userInProgress?.firstName?.length ? `Great, welcome ${userInProgress.firstName}!` : 'Great, welcome!'

    return (
      <Box>
        <Typography variant='h4' mb={3} textAlign='center'>{headerText}</Typography>

        <div className='login-form__content'>
          <Typography textAlign='center'>Let's confirm your contact information. An email address is required.</Typography>
          {(props.error) && <Alert severity='error'>{props.error}</Alert>}

          <form>
            <Box>
              <FormControl fullWidth size='small' variant='outlined' sx={{ mb: 2 }}>
                <Typography>First name *</Typography>
                <OutlinedInput id='first_name' name='first_name' value={userInProgress.firstName} onChange={e => updateMemberInfo('firstName', e.target.value)} />
                {fieldErrors.firstName && <FormHelperText id='form__error-text' error>{fieldErrors.firstName}</FormHelperText>}
              </FormControl>

              <FormControl fullWidth size='small' variant='outlined' sx={{ mb: 2 }}>
                <Typography>Last name *</Typography>
                <OutlinedInput id='last_name' name='last_name' value={userInProgress.lastName} onChange={e => updateMemberInfo('lastName', e.target.value)} />
                {fieldErrors.lastName && <FormHelperText id='form__error-text' error>{fieldErrors.lastName}</FormHelperText>}
              </FormControl>

              <FormControl fullWidth size='small' variant='outlined' sx={{ mb: 2 }}>
                <Typography>Sex</Typography>
                <Select id='gender' name='gender' value={userInProgress.gender} onChange={e => updateMemberInfo('gender', e.target.value)}>
                  <MenuItem value='FEMALE'>Female</MenuItem>
                  <MenuItem value='MALE'>Male</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth size='small' variant='outlined' sx={{ mb: 2 }}>
                <Typography>Email *</Typography>
                <OutlinedInput
                  id='email' name='email' type='email' value={userInProgress.email} onChange={e => updateMemberInfo('email', e.target.value)}
                  required pattern='[^@]+@[^\.]+\..+'
                />
                {fieldErrors.email && <FormHelperText id='form__error-text' error>{fieldErrors.email}</FormHelperText>}
              </FormControl>

              <FormControl fullWidth size='small' variant='outlined' sx={{ mb: 2 }}>
                <Typography>Mobile Number </Typography>
                <OutlinedInput id='phone' name='phone' value={userInProgress.phone} pattern='[0-9]{3}[0-9]{3}[0-9]{4}' onChange={e => updateMemberInfo('phone', e.target.value)} />
                {fieldErrors.phone && <FormHelperText id='form__error-text' error>{fieldErrors.phone}</FormHelperText>}
              </FormControl>

              <FormControl fullWidth size='small' variant='outlined' sx={{ mb: 2 }}>
                <Typography>Home Number</Typography>
                <OutlinedInput id='homePhone' name='homePhone' value={userInProgress.homePhone} pattern='[0-9]{3}[0-9]{3}[0-9]{4}' onChange={e => updateMemberInfo('homePhone', e.target.value)} />
                {fieldErrors.homePhone && <FormHelperText id='form__error-text' error>{fieldErrors.homePhone}</FormHelperText>}
              </FormControl>
            </Box>

            <SelfSignupPaginator
              hasPreviousSteps
              isFormSubmit={false}
              onHandleBack={props.handleDecrementPage}
              onHandleNext={continueClicked}
              canContinue={userInProgress.email || userInProgress.phone || userInProgress.homePhone}
              hasMoreSteps
            />
          </form>
        </div>
      </Box>
    )
  }

  return renderNameAndContactInfo()
}
