import _ from 'lodash'
import moment from 'moment'
import { compareDeviceStatus, compareMemberStatus, hasRecentActivityData, hasRecentWeightData } from '../utils/baseMembersListUtils'
import { getActivityProfileStats, getPregnancyWeightProfileStats } from '../utils/member/alertDetailUtils'
import { getBloodPressureHeartRateDeltaInRange, getDiastolicDeltaInRange, getSystolicDeltaInRange, getWeightDeltaInRange } from '../utils/member/analyticsUtils'

export const compareWeightDelta = (memberA, memberB) => {
  // Archived members are always last
  const memberStatus = compareMemberStatus(memberA, memberB)
  if (memberStatus) return memberStatus

  const aDelta = getWeightDeltaInRange(memberA)
  const bDelta = getWeightDeltaInRange(memberB)

  if (aDelta > bDelta) return -1
  else if (aDelta < bDelta) return 1
  return 0
}

export const compareBloodPressureDelta = (a, b) => {
  // Archived members are always last
  const memberStatus = compareMemberStatus(a, b)
  if (memberStatus) return memberStatus

  let aDelta = getSystolicDeltaInRange(a)
  let bDelta = getSystolicDeltaInRange(b)

  if (aDelta > bDelta) return -1
  else if (aDelta < bDelta) return 1

  aDelta = getDiastolicDeltaInRange(a)
  bDelta = getDiastolicDeltaInRange(b)

  if (aDelta > bDelta) return -1
  else if (aDelta < bDelta) return 1

  aDelta = getBloodPressureHeartRateDeltaInRange(a)
  bDelta = getBloodPressureHeartRateDeltaInRange(b)

  if (aDelta > bDelta) return -1
  else if (aDelta < bDelta) return 1

  return 0
}

export const compareBloodPressureDeltaAsc = (a, b) => {
  // Archived members are always last
  const memberStatus = compareMemberStatus(a, b)
  if (memberStatus) return memberStatus

  return -1 * compareBloodPressureDelta(a, b)
}

export const comparePregnancyLength = (a, b) => {
  // Archived members are always last
  const memberStatus = compareMemberStatus(a, b)
  if (memberStatus) return memberStatus

  return (moment(a.user.conception_date).isSameOrBefore(moment(b.user.conception_date))) ? -1 : 1
}

export const compareWeightIOM = (a, b) => {
  // Archived members are always last
  const memberStatus = compareMemberStatus(a, b)
  if (memberStatus) return memberStatus

  const { weightIOMStatusNum: weightIOMa } = getPregnancyWeightProfileStats(a)
  const { weightIOMStatusNum: weightIOMb } = getPregnancyWeightProfileStats(b)

  // If there's no weight IOM status (or they're 0) for both members, sort by device status
  if (!weightIOMa && !weightIOMb) return compareDeviceStatus(a, b)

  if (!hasRecentWeightData(a)) return 1
  if (!hasRecentWeightData(b)) return -1

  return weightIOMa > weightIOMb ? -1 : 1
}

export const compareWeightGain = (a, b) => {
  // Archived members are always last
  const memberStatus = compareMemberStatus(a, b)
  if (memberStatus) return memberStatus

  const { weightGain14Dnum: weightGain14Da } = getPregnancyWeightProfileStats(a)
  const { weightGain14Dnum: weightGain14Db } = getPregnancyWeightProfileStats(b)

  // If there's no weight gain rate (or it's 0) for both members, sort by device status
  if (!weightGain14Da && !weightGain14Db) return compareDeviceStatus(a, b)

  if (!hasRecentWeightData(a)) return 1
  if (!hasRecentWeightData(b)) return -1

  if (weightGain14Da > weightGain14Db) return -1
  else if (weightGain14Da < weightGain14Db) return 1
  return compareWeightIOM(a, b)
}

export const compareActivityProgressAsc = (a, b) => {
  // Archived members are always last
  const memberStatus = compareMemberStatus(a, b)
  if (memberStatus) return memberStatus

  if (!hasRecentActivityData(a)) return 1
  if (!hasRecentActivityData(b)) return -1

  const { activityProgressNum: aProgress } = getActivityProfileStats(a)
  const { activityProgressNum: bProgress } = getActivityProfileStats(b)

  return (aProgress < bProgress) ? -1 : 1
}

export const compareActivityProgressDesc = (a, b) => {
  // Archived members are always last
  const memberStatus = compareMemberStatus(a, b)
  if (memberStatus) return memberStatus

  if (!hasRecentActivityData(a)) return 1
  if (!hasRecentActivityData(b)) return -1

  return -1 * compareActivityProgressAsc(a, b)
}

export const compareProgramStep = (a, b) => {
  // Archived members are always last
  const memberStatus = compareMemberStatus(a, b)
  if (memberStatus) return memberStatus

  const aStep = a.pregnancy_info?.program_step
  const bStep = b.pregnancy_info?.program_step

  // break ties with date they entered that step (descending)
  if (aStep === bStep) return (b.pregnancy_info?.program_step_date < a.pregnancy_info?.program_step_date) ? -1 : 1

  return (bStep < aStep) ? -1 : 1 // descending
}

export const compareAvgBp = (a, b, defaultValue) => {
  const aPeriod = _.get(a, ['blood_pressure', 'information', 'periods', '0'])
  const bPeriod = _.get(b, ['blood_pressure', 'information', 'periods', '0'])

  let aAvg = aPeriod ? aPeriod.avg_systolic : defaultValue
  let bAvg = bPeriod ? bPeriod.avg_systolic : defaultValue

  if (aAvg < bAvg) return -1
  else if (aAvg > bAvg) return 1

  aAvg = aPeriod ? aPeriod.avg_diastolic : defaultValue
  bAvg = bPeriod ? bPeriod.avg_diastolic : defaultValue

  if (aAvg < bAvg) return -1
  else if (aAvg > bAvg) return 1

  aAvg = aPeriod ? aPeriod.avg_heart_rate : defaultValue
  bAvg = bPeriod ? bPeriod.avg_heart_rate : defaultValue

  if (aAvg < bAvg) return -1
  else if (aAvg > bAvg) return 1

  return 0
}

export const compareAvgBpAsc = (a, b) => {
  // Archived members are always last
  const memberStatus = compareMemberStatus(a, b)
  if (memberStatus) return memberStatus

  return compareAvgBp(a, b, Infinity)
}

export const compareAvgBpDesc = (a, b) => {
  // Archived members are always last
  const memberStatus = compareMemberStatus(a, b)
  if (memberStatus) return memberStatus

  // return -1 * compareAvgBpAsc; /////
  return -1 * compareAvgBp(a, b, -Infinity)
}

export const compareSeverityAsc = (a, b) => {
  // Archived members are always last
  const memberStatus = compareMemberStatus(a, b)
  if (memberStatus) return memberStatus

  if (a.severity < b.severity) return -1
  else if (a.severity > b.severity) return 1
  return 0
}

export const compareSeverityDesc = (a, b) => {
  // Archived members are always last
  const memberStatus = compareMemberStatus(a, b)
  if (memberStatus) return memberStatus

  return -1 * compareSeverityAsc(a, b)
}

export const compareLatestPulseOx = (a, b) => {
  // Archived members are always last
  const memberStatus = compareMemberStatus(a, b)
  if (memberStatus) return memberStatus

  const aLatest = _.get(a, ['pulse_ox', 'latest_measurement'])
  const bLatest = _.get(b, ['pulse_ox', 'latest_measurement'])

  const aOxygenSaturation = aLatest ? aLatest.oxygen_saturation : Infinity
  const bOxygenSaturation = bLatest ? bLatest.oxygen_saturation : Infinity

  if (aOxygenSaturation < bOxygenSaturation) return -1
  else if (bOxygenSaturation < aOxygenSaturation) return 1

  const aHeartRate = aLatest ? aLatest.heart_rate : Infinity
  const bHeartRate = bLatest ? bLatest.heart_rate : Infinity

  if (aHeartRate < bHeartRate) return -1
  else if (bHeartRate < aHeartRate) return 1

  return 0
}
