import React from 'react'
import CarePlanItem from '../CarePlanItem'

export default class HealthStatusChangeItem extends CarePlanItem {
  constructor (props) {
    super(props)
    this.type = 'health_status_change'
  }

  renderGoal () {
    return (
      <div> Health Status </div>
    )
  }

  renderClinicianTrigger () {
    return (
      <div> If a health status change is submitted. </div>
    )
  }

  renderCondensed () {
    return <div>Health status changes</div>
  }
}
