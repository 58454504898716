import moment from 'moment'
import React from 'react'
import restApi from '../../api'
import Modal from '../elements/Modal'
import { connect } from 'react-redux'
import { sessionExpire } from '../../store/userSession'
import { Box, Button, Typography } from '@mui/material'

/*
 * Modal component that warns the user that their session is expiring soon.
 */
function SessionExpireWarning (props) {
  const renderModal = () => {
    return (
      <Modal
        isOpen
        name='SessionExpireWarning'
        modalTitle='Are you still there?'
        data-testid='session-expire-warning'
        onModalClose={() => props.extendSession}
        maxWidth='sm'
      >
        <Typography data-testid='session-expire-warning__text' gutterBottom>
          To protect your data, you will be signed out after a period of inactivity.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'right', mt: 3 }}>
          <Button variant='contained' onClick={() => props.extendSession()} data-testid='session-expire-warning__button'>
            Keep me signed in
          </Button>
        </Box>
      </Modal>
    )
  }

  if (!props.userSessionStore.showSessionExpireWarning) return null
  return renderModal()
}

function getPropsFromStore (state) {
  return {
    userSessionStore: state.userSession,
    user: state.userSession.user
  }
}

function getPropsFromActions (dispatch) {
  return {
    extendSession () {
      // Make sure that the session hasn't already expired
      const sessionExpireTime = moment(this.userSessionStore.sessionExpireTime)
      const now = moment()
      if (now.isAfter(sessionExpireTime)) {
        dispatch(sessionExpire())
        dispatch(restApi.actions.logout())
      } else {
        dispatch(restApi.actions.session()).then((data) => {
          // Handles the case of the web app already being logged out via another tab.
          // Without this clicking the button 'Keep me signed in' does nothing and the
          // user is forced to reload the page.
          if (!data?.logged_in) {
            dispatch(restApi.actions.logout())
          }
        })
      }
    }
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(SessionExpireWarning)
