import React from 'react'
import { EjentaForm } from '../../elements/EjentaForm'
import MemberProfileFormFields from './MemberProfileFormFields'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import { Form } from 'react-final-form'
import { Alert } from '@mui/material'
import { getProfileMemberFields, validateMemberForm } from '../../../utils/member/baseProfileUtils'

/* ****************************************************************************
 * This form is used for creating new members and editing existing members.
 * ****************************************************************************/
function MemberProfileForm (props) {
  const handleCancel = (e) => {
    e.preventDefault()
    e.stopPropagation()

    props.onCancel()
  }

  const renderSubmit = ({ pristine, submitting, errors }) => {
    const submitButtonText = props.isNewMember ? 'Add member' : 'Save changes'
    let submitButton
    const hasErrors = Object.keys(errors)?.length > 0

    if (submitting) {
      submitButton = <Button variant='contained' disabled>Updating...</Button>
    } else {
      submitButton = <Button variant='contained' type='submit' data-testid='member-profile__add-member-button' disabled={pristine || hasErrors}>{submitButtonText}</Button>
    }

    return (
      <DialogActions data-testid='add-member__buttons'>
        {submitButton} <Button variant='outlined' onClick={handleCancel}>Cancel</Button>
      </DialogActions>
    )
  }

  const renderMemberProfileForm = () => {
    return (
      <Form
        onSubmit={props.onSubmit}
        initialValues={props.getInitialValues()}
        validate={values => validateMemberForm(values, getProfileMemberFields(), props.isNewMember)}
      >
        {({ handleSubmit, pristine, submitting, submitError, errors, form }) => (
          <EjentaForm onSubmit={handleSubmit}>
            {submitError && <Alert severity='error'>{submitError}</Alert>}

            <MemberProfileFormFields isNewMember={props.isNewMember} change={form.change} careManagers={props.careManagers} />

            {renderSubmit({ pristine, submitting, errors })}
          </EjentaForm>
        )}
      </Form>
    )
  }

  return renderMemberProfileForm()
}

export default MemberProfileForm
