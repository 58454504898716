import React from 'react'
import bodytracecombo2 from 'static/bodytrace-combo2.png'
import { Box, Typography } from '@mui/material'
import SelfSignupPaginator from './SelfSignupPaginator'

export default function SignupWelcome (props) {
  const renderDesktopImage = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '30px' }}>
        <img className='bpm' src={bodytracecombo2} alt='BodyTrace BPM' style={{ width: '10rem' }} />
      </Box>
    )
  }

  const renderMobileImage = () => {
    return (
      <Box>
        <img className='bpm' src={bodytracecombo2} alt='BodyTrace BPM' style={{ width: '12rem' }} />
      </Box>
    )
  }

  const renderWelcome = () => {
    return (
      <Box>
        <Typography variant='h4' mb={3} textAlign='center'>Welcome to the Heart Healthy Program!</Typography>

        <Box sx={{ display: props.isMobile ? '' : 'flex', m: 3 }}>
          <Box sx={{ ml: 2 }}>
            <Typography fontSize={18} mb={0.5}>This program will help you <strong>manage your health from the comfort of your home.</strong></Typography>
            <Typography fontSize={18} mb={0.5}>After sign-up, you'll receive a&nbsp;<strong>weight scale</strong>, a&nbsp;<strong>blood pressure monitor</strong>, and may receive&nbsp;<strong>support from a care manager</strong>, all&nbsp;<strong>free of charge</strong>!</Typography>
            <Typography fontSize={18} mb={0.5}>It only takes a minute to sign up!</Typography>
          </Box>
          {props.isMobile && renderMobileImage()}
          {!props.isMobile && renderDesktopImage()}
        </Box>
        <SelfSignupPaginator isFormSubmit={false} hasPreviousSteps={false} onHandleBack={props.handleDecrementPage} onHandleNext={props.handleIncrementPage} hasMoreSteps />
      </Box>
    )
  }

  return renderWelcome()
}
