import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Exporting from 'highcharts/modules/exporting.js'
import ExportData from 'highcharts/modules/export-data.js'
import Accessibility from 'highcharts/modules/accessibility.js'
import OfflineExporting from 'highcharts/modules/offline-exporting.js'
import HighchartsMore from 'highcharts/highcharts-more'
import { Card, CardContent } from '@mui/material'

HighchartsMore(Highcharts)

try {
  Exporting(Highcharts)
  OfflineExporting(Highcharts)
  ExportData(Highcharts)
  Accessibility(Highcharts)
} catch (e) {
  // noop
}

function AnalyticsChart (props) {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleWindowResize = _.debounce(() => setInnerWidth(window.innerWidth))
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const renderAnalyticsChart = () => {
    const {
      dateRangeStart: start,
      dateRangeEnd: end,
      isDateRangeLoading: loading
    } = props.analyticsStore

    return (
      <Card
        id={props.containerId}
      >
        <CardContent className={`ax-chart-container ${props.containerId}`}>
          <HighchartsReact
            highcharts={Highcharts}
            options={props.chartOptions}
            key={`${moment(start)}-${moment(end)}-${loading}-${innerWidth}`}
          />
        </CardContent>
      </Card>
    )
  }

  return renderAnalyticsChart()
}

function getPropsFromStore (state) {
  return {
    analyticsStore: state.analytics
  }
}

export default connect(getPropsFromStore)(AnalyticsChart)
