import React from 'react'
import moment from 'moment'
import { momentCalendarConfig } from '../../../utils/baseStringUtils'
import { Box, Typography } from '@mui/material'

export default function OrderStatus (props) {
  const renderCondensed = (latestStatus) => {
    return (
      <Box sx={{ flexDirection: 'column' }} className='device__section'>
        <div className='device__section-meta-text'>{props.device}</div>
        <Box sx={{ display: 'inline-flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Typography variant='body1'>&ensp;{latestStatus.status}</Typography>
          -
          <Typography variant='body2'>{moment(latestStatus.timestamp).calendar(null, momentCalendarConfig)}</Typography>
        </Box>
      </Box>
    )
  }

  const renderStatuses = (statuses) => {
    return statuses.map((status, i) => {
      return (
        <div className='device__section-meta-text' key={i}>
          &emsp;{status.status} – {moment(status.timestamp).calendar(null, momentCalendarConfig)}
        </div>
      )
    })
  }

  const renderOrderStatus = () => {
    const deviceStatuses = [...props.orderStatuses].sort((a, b) => b.timestamp - a.timestamp)
    const latestStatus = deviceStatuses[0]

    // when an Exception status occurs later in time than a Fulfilled status, the IMEI and tracking number won't be in the latest status,
    // so we have to find the latest status that *does* have that shipping info.
    const latestStatusWithShipmentInfo = deviceStatuses.filter(s => s.tracking_number)?.[0]
    const { imei, tracking_number: trackingNumber } = latestStatusWithShipmentInfo ?? {}

    return props.shouldRenderCondensed
      ? renderCondensed(latestStatus)
      : (
        <Box sx={{ justifyContent: 'left' }} key={props.device}>
          <div>{props.device}</div>
          <div className='device__section-meta-text'>&emsp;Device IMEI: {imei ?? 'TBD'}</div>
          <div className='device__section-meta-text'>&emsp;Tracking Number: {trackingNumber ?? 'TBD'}</div>
          <div>&ensp;Status Updates</div>
          {renderStatuses(deviceStatuses)}
        </Box>
        )
  }

  return renderOrderStatus()
}
