import _ from 'lodash'
import moment from 'moment'

import {
  getBaseChartOptions,
  getBaseSeriesOptions,
  getPointDateString,
  addPausePlotBands
} from './baseChartUtils'

import { baseActivityChartOptions, getActivityAlertsSeries } from './baseActivityChart'
import { getXAxisForRange, getColumnWidth } from './chartUtils'

function compareByDate (a, b) {
  if (a.set_date < b.set_date) {
    return -1
  }
  if (a.set_date > b.set_date) {
    return 1
  }
  return 0
}

/* ********************************************************************************
 * Product-specific series
 * ********************************************************************************/

function getActivityMeasurementsSeries (options, memberObj, dashboardViewStore) {
  const newOptions = _.cloneDeep(options)

  const activityMeasurements = (_.cloneDeep(memberObj.activity.data)).reverse().map((m) => {
    const date = moment(m.date)
    const point = {
      x: +date,
      y: Math.round((m.intensities.moderate.value + m.intensities.intense.value) / 60),
      dataSource: m.source,

      // Store information in the point so tooltip can display it
      description: `${m.steps.toLocaleString()} steps`
    }
    return point
  })

  const activityMeasurementSeries = {
    ...getBaseSeriesOptions(),
    name: 'Moderate activity',
    type: 'column',
    data: activityMeasurements,
    zIndex: 1,
    className: 'activity-series',
    color: '#8DC63F',

    tooltip: {
      headerFormat: null,
      pointFormatter: function format () {
        return `
          <strong>${getPointDateString(moment(this.x), false)}</strong><br/>
          ${this.y.toLocaleString()} minutes<br/>
          ${this.description} <br />
          <small>Source: ${this.dataSource ?? 'Unknown'}</small>
          `
      }
    },

    pointWidth: getColumnWidth(dashboardViewStore)
  }

  newOptions.series.push(activityMeasurementSeries)
  return newOptions
}

function getActivityGoalSeries (options, memberObj) {
  const dailyActivityGoal = memberObj.careplan?.find(item => item.type === 'activity_target' && item.subtype === 'daily')
  if (!dailyActivityGoal || dailyActivityGoal.disabled) return options

  const newOptions = _.cloneDeep(options)
  const dailyActivityGoals = [...memberObj.activity.information.periods[0].daily_activity_goals]
  const activityGoals = dailyActivityGoals.sort(compareByDate).reverse()

  const activityGoalData = activityGoals.reduce((acc, goal) => {
    // Ignore activity goals that were active for less than an hour
    if ((goal.end_date - goal.set_date) < 1000 * 60 * 60) return acc
    acc[acc.length - 1].push(goal.target.value)
    acc.push([+moment(goal.end_date), goal.target.value])
    acc.push([+moment(goal.end_date) + 1])

    return acc
  }, [[+moment().subtract(1, 'year')]])

  activityGoalData.pop() // Remove the last (incomplete) data point

  const activityGoalSeries = {
    ...getBaseSeriesOptions(),
    name: 'Daily activity goal',
    type: 'line',
    data: activityGoalData,
    dashStyle: 'dash',
    color: '#D7DF23',

    enableMouseTracking: false
  }

  newOptions.series.push(activityGoalSeries)
  return newOptions
}

/* ********************************************************************************
 * Chart types
 * ********************************************************************************/
function getFullChartOptions ({
  memberObj, dashboardViewStore,
  showMemberAlerts, handleAlertClick
}) {
  const baseChartOptions = getBaseChartOptions()
  const xAxisOptions = { xAxis: getXAxisForRange(memberObj, dashboardViewStore) }

  let options = {
    ...baseActivityChartOptions,
    ...xAxisOptions,
    ...baseChartOptions
  }

  options = getActivityMeasurementsSeries(options, memberObj, dashboardViewStore)
  options = getActivityGoalSeries(options, memberObj)
  if (showMemberAlerts) {
    const getAlertUnits = (measurement) => {
      let result = 0
      result += measurement.intensities.moderate.value / 60
      result += measurement.intensities.intense.value / 60
      return result
    }

    options = getActivityAlertsSeries(options, memberObj, handleAlertClick, getAlertUnits)
  }

  options = addPausePlotBands(memberObj.user.pauses, options, true)
  return options
}

/* ********************************************************************************
 * Parent method
 * ********************************************************************************/

function getActivityChartOptions ({
  dashboardViewStore, memberObj,
  showMemberAlerts, handleAlertClick
}) {
  return getFullChartOptions({
    memberObj,
    dashboardViewStore,
    showMemberAlerts,
    handleAlertClick
  })
}

export default getActivityChartOptions
