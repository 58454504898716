import React from 'react'
import CarePlanItem from '../CarePlanItem'
import { MS_IN_A_DAY } from '../../../../constants/constants'
import { CAREPLAN_VALUE_TYPES } from '../../../../constants/carePlanValueTypes'

export default class BioStickerTrackingItem extends CarePlanItem {
  constructor (props) {
    super(props)
    this.type = 'biosticker_tracking'
  }

  thresholdInputElement () {
    return this.getInputElement(
      1, 7, 1,
      CAREPLAN_VALUE_TYPES.THRESHOLD.get(val => val / MS_IN_A_DAY),
      CAREPLAN_VALUE_TYPES.THRESHOLD.set(val => val * MS_IN_A_DAY)
    )
  }

  renderGoal () {
    return <div>Wear BioButton/BioSticker at all times</div>
  }

  renderClinicianTrigger () {
    const numDays = this.getValue(CAREPLAN_VALUE_TYPES.THRESHOLD.get(val => val / MS_IN_A_DAY))
    return <div>If no <b>Bio</b> data for {this.thresholdInputElement()} day{numDays === 1 ? '' : 's'}</div>
  }

  renderCondensed () {
    const getNumDays = CAREPLAN_VALUE_TYPES.THRESHOLD.get(val => val / MS_IN_A_DAY)
    const numDays = this.getValue(getNumDays)
    const days = this.getValueElement(getNumDays)
    return <div>No Bio data for {days} day{numDays === 1 ? '' : 's'}</div>
  }
}
