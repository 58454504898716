import _ from 'lodash'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getSimpleDisplayName } from '../../../../utils/baseStringUtils'
import { getDefaultMemberFieldValuesFromConfig, getMemberProfileFormErrors, getProfileMemberFields } from '../../../../utils/member/baseProfileUtils'
import restApi from '../../../../api'
import Modal from '../../../elements/Modal'
import MemberProfileForm from '../../../controllers/forms/MemberProfileForm'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Link from '@mui/material/Link'

import { getMemberApiBody } from '../../../../constants/constants'

function AddMemberModal (props) {
  const memberFields = getProfileMemberFields()

  useEffect(() => {
    handleFormReset()
  }, [])

  const getInitialValues = () => {
    return getDefaultMemberFieldValuesFromConfig(props.clinicianUser)
  }

  /*
   * Interaction handling
   */
  const handleFormSubmit = (formData) => {
    let apiFormData = { ...formData }

    const submitPromise = new Promise((resolve, reject) => {
      apiFormData = _.omit(apiFormData, ['signedConsentAndTos', 'orderBodyTraceScale', 'orderBodyTraceBloodPressure'])
      const group = props.userSession.activeGroup || props.clinicianUser.group

      // Build API request body
      const apiBody = getMemberApiBody(memberFields, apiFormData, group)

      // the interaction service will turn these into timestamps
      if (formData.signedConsentAndTos) {
        apiBody.signed_tos = 1
        apiBody.signed_consent = 1
      }

      if (formData.orderBodyTraceScale) {
        apiBody.orderBodyTraceScale = 1
      }
      if (formData.orderBodyTraceBloodPressure) {
        apiBody.orderBodyTraceBloodPressure = 1
      }

      // Make API request
      props.callCreateMemberApi(
        {
          promise: { resolve, reject },
          promiseTransformer: errors => getMemberProfileFormErrors(errors, memberFields)
        },
        apiBody)
    })

    return submitPromise.catch(data => data)
  }

  const handleFormReset = () => {
    props.resetCreateMemberApi()
  }

  const handleModalClose = () => {
    props.onModalClose()
    props.resetCreateMemberApi()
  }

  /*
   * Rendering
   */
  const renderForm = () => {
    return (
      <MemberProfileForm
        isNewMember
        getInitialValues={getInitialValues}
        careManagers={props.userSession.resources?.care_managers}
        onCancel={props.onModalClose} // eslint-disable-line react/jsx-handler-names
        onSubmit={handleFormSubmit}
      />
    )
  }

  const renderFormSuccess = () => {
    const memberData = props.createMemberApi.data
    const memberName = getSimpleDisplayName(memberData)
    const memberId = memberData.id

    return (
      <div data-testid='add-member__success'>
        <Box data-testid='add-member__confirmation'>
          Successfully added new member <Link href={`/profile/${memberId}`} data-testid='nw-NewMemberProfile'>{memberName}</Link>.
        </Box>

        <DialogActions data-testid='add-member__success-buttons'>
          <Button variant='contained' onClick={handleFormReset}>
            Add another member
          </Button>
          <Button variant='outlined' onClick={handleModalClose}>
            Back to members list
          </Button>
        </DialogActions>
      </div>
    )
  }

  const renderModal = () => {
    const isMemberAdded = !!props.createMemberApi.data.id

    return (
      <Modal
        name='addMember'
        maxWidth='md'
        isOpen={props.isOpen}
        onModalClose={handleModalClose}
        modalTitle='Add member'
      >
        {!isMemberAdded && renderForm()}
        {isMemberAdded && renderFormSuccess()}
      </Modal>
    )
  }

  return renderModal()
}

function getPropsFromStore (state) {
  return {
    userSession: state.userSession,
    clinicianUser: state.userSession.user,
    createMemberApi: state.api.createMember
  }
}

function getPropsFromActions (dispatch) {
  return {
    callCreateMemberApi: (pathVars, apiFields) => {
      dispatch(restApi.actions.createMember(pathVars, { body: JSON.stringify(apiFields) }))
    },
    resetCreateMemberApi: () => {
      dispatch(restApi.actions.createMember.reset())
    }
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(AddMemberModal)
