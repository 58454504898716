import { Circle } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'

import {
  MEMBER_STATUS_FILTERS,
  ALERT_STATUS_FILTERS,
  ALERT_NOTES_FILTERS,
  ALERT_SORTS
} from './baseAlertUtils'
import { productConfig } from '../../../ejenta/js/config/config-service'
import { humanizeAlertLevel, humanizeAlertType } from '../../../ejenta/js/constants/strings'
import { LIFESTYLE_COACH, PREGNANCY_STATUS } from '../constants/memberFilterGroups'
const alertConfig = productConfig().alerts.refineSidebar

export const ALERTS_VIEW = {
  PAGE_SIZE: 20,
  FILTER_GROUPS: {
    PREGNANCY_STATUS: alertConfig.hasPregnancyStatus ? PREGNANCY_STATUS : {},

    MEMBER_STATUS: MEMBER_STATUS_FILTERS,

    ALERT_LEVEL: alertConfig.hasLevelSort
      ? {
          displayTitle: 'Alert Level',
          filters: alertConfig.levelFilters?.map(levelFilter => {
            return {
              id: `FILTER/${levelFilter.toUpperCase()}`,
              displayTitle: humanizeAlertLevel(levelFilter)
            }
          })
        }
      : {},

    ALERT_STATUS: ALERT_STATUS_FILTERS,

    LIFESTYLE_COACH: alertConfig.hasLifestyleCoach ? LIFESTYLE_COACH : {},

    ALERT_TYPE: {
      displayTitle: 'Alert type',
      filters: alertConfig.typeFilters.map(typeFilter => {
        return {
          id: `FILTER/${typeFilter.toUpperCase()}`,
          displayTitle: humanizeAlertType(typeFilter)
        }
      })
    },

    NOTES: ALERT_NOTES_FILTERS
  }
}

export const DEFAULT_ALERT_SORT = ALERT_SORTS.SORT_STATUS

export function renderLevel (level, hideText) {
  if (level) {
    let colorText = ''
    let colorClass = ''

    if (level === 3.0) {
      colorText = 'Red'
      colorClass = 'error'
    } else if (level === 2.0) {
      colorText = 'Orange'
      colorClass = 'primary'
    } else if (level === 1.0) {
      colorText = 'Yellow'
      colorClass = 'warning'
    }

    return (
      <Box component='span' sx={{ display: 'inline-flex', alignItems: 'center' }}>
        <Circle color={colorClass} sx={{ fontSize: 16, mr: 0.5 }} />
        {!hideText && <Typography component='span' variant='body2' fontSize={15}> {colorText}</Typography>}
      </Box>

    )
  }

  return <div />
}
