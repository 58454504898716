import React, { useState } from 'react'
import { productConfig } from '../../../config/config-service'
import { Grid } from '@mui/material'
import { getProfileMemberFields } from '../../../utils/member/baseProfileUtils'

export default function MemberDevicesFormFields () {
  const [deviceConfig] = useState(productConfig().profile.devices)
  const memberFields = getProfileMemberFields()

  return (
    <Grid container spacing={1} sx={{ mt: 1 }}>
      {deviceConfig.hasBodyTraceScale &&
        <Grid item xs={12} md={6} data-testid='member-devices__weight'>
          {memberFields.bodyTraceScaleImei.formInput()}
        </Grid>}

      {deviceConfig.hasBodyTraceBP &&
        <Grid item xs={12} md={6} data-testid='member-devices__blood-pressure'>
          {memberFields.bodyTraceBpImei.formInput()}
        </Grid>}

      {deviceConfig.hasIGlucose &&
        <Grid item xs={12} md={6} data-testid='member-devices__blood-glucose'>
          {memberFields.iGlucoseId.formInput()}
        </Grid>}
    </Grid>
  )
}
