import React, { useState } from 'react'
import MemberPregnancyForm from '../../../controllers/forms/MemberPregnancyForm'
import { getMemberProfileFormErrors, getProfileMemberFields } from '../../../../utils/member/baseProfileUtils'
import { getMemberApiBody, getProfileFormInitialValues } from '../../../../constants/constants'
import { Alert, Box, Snackbar } from '@mui/material'

export default function PregnancyInformationPane (props) {
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const memberFields = getProfileMemberFields()

  const getInitialValues = () => {
    return getProfileFormInitialValues(memberFields, props.memberObj)
  }

  const handleFormSubmit = (formData) => {
    const submitPromise = new Promise((resolve, reject) => {
      const apiBody = getMemberApiBody(memberFields, formData, props.memberObj.user.group)

      props.apiActions.updateUser(
        {
          id: props.memberObj.user.id,
          promise: { resolve, reject },
          promiseTransformer: errors => getMemberProfileFormErrors(errors, memberFields)
        },
        apiBody
      )
    })

    return submitPromise.then(handleFormSubmitSuccess).catch(data => data)
  }

  const handleFormSubmitSuccess = () => {
    setSnackbarMessage('Successfully updated pregnancy details.')
    setSnackbarSeverity('success')
    setSnackbarOpen(true)
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  const renderForm = () => {
    return (
      <MemberPregnancyForm
        hideBorder
        memberObj={props.memberObj}
        onSubmit={handleFormSubmit}
        onCancel={() => props.profileViewActions.handleClearModal()}
        getInitialValues={getInitialValues}
      />
    )
  }

  const renderFeedback = () => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        key='edit-pregnancy-pane__success__snack-bar'
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    )
  }

  const renderProfileInformationPage = () => {
    return (
      <Box data-testid='edit-pregnancy-pane'>
        {renderForm()}

        {renderFeedback()}
      </Box>
    )
  }

  return renderProfileInformationPage()
}
