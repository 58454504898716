import React from 'react'
import restApi from '../../../api'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  ContentMain,
  ContentMainIntro,
  ContentMainIntroHeader,
  ContentMainBody
} from '../../layout/ContentMain'
import SettingsSidebar from '../../layout/SettingsSidebar'
import AccountInformation from '../AccountSettingsPage/AccountInformation'
import MessagePreferences from '../AccountSettingsPage/MessagePreferences'
import ChangePasswordForm from '../AccountSettingsPage/ChangePassword'
import Grid from '@mui/material/Grid'

/* *****************************************************************************
 * Account settings page
 * *****************************************************************************/
function AccountSettingsPage (props) {
  const renderDesktop = () => {
    return (
      <Grid container spacing={2}>
        <Grid item sm={12} md={6}>
          <AccountInformation />
        </Grid>

        <Grid item sm={12} md={6}>
          <ChangePasswordForm />
        </Grid>

        <Grid item sm={12} md={6}>
          <MessagePreferences />
        </Grid>
      </Grid>
    )
  }

  const renderAccountSettingsPage = () => {
    if (!props.user) {
      return null
    }

    return (
      <>
        <SettingsSidebar />

        <ContentMain drawerWidth={220}>
          <ContentMainIntro>
            <ContentMainIntroHeader title='Account' />
          </ContentMainIntro>

          <ContentMainBody>
            <div className='account-settings u-top-page-margin'>
              {renderDesktop()}
            </div>
          </ContentMainBody>
        </ContentMain>
      </>
    )
  }

  return renderAccountSettingsPage()
}

function getPropsFromStore (state) {
  return {
    user: state.userSession.user
  }
}

function getPropsFromActions (dispatch) {
  return {
    apiActions: bindActionCreators(restApi.actions, dispatch)
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(AccountSettingsPage)
