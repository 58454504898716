import { Box } from '@mui/material'
import React from 'react'

export default function DeviceUsageData (props) {
  const memberUserObj = props.memberObj.user

  const getUsageData = () => {
    return [
      {
        deviceType: 'Welch Allyn weight',
        hasMeasurements: memberUserObj.has_wa_weight
      },
      {
        deviceType: 'Welch Allyn BP',
        hasMeasurements: memberUserObj.has_wa_bp
      },
      {
        deviceType: 'Apple Health weight',
        hasMeasurements: memberUserObj.has_healthkit_weight
      },
      {
        deviceType: 'Apple Health BP',
        hasMeasurements: memberUserObj.has_healthkit_bp
      },
      {
        deviceType: 'Manually entered weight',
        hasMeasurements: memberUserObj.has_manual_weight
      },
      {
        deviceType: 'Manually entered BP',
        hasMeasurements: memberUserObj.has_manual_bp
      },
      {
        deviceType: 'Manually entered BG',
        hasMeasurements: memberUserObj.has_manual_bg
      }
    ]
  }

  const fields = getUsageData().map((f, fIndex) =>
    <React.Fragment key={fIndex}>
      {f.hasMeasurements !== undefined &&
        <Box className='device__section' sx={{ color: !f.hasMeasurements ? 'lightgray' : 'black' }}>
          <span>{f.deviceType}: &nbsp; </span>
          <div className='device__section-data'>{f.hasMeasurements ? 'Yes' : 'No'}</div>
        </Box>}
    </React.Fragment>
  )

  const renderDeviceStatusRows = () => {
    const devicesSection = fields.length > 0 && (
      <Box sx={{ p: 1 }}>
        {fields}
      </Box>
    )

    return (
      <div className='devices--condensed'>
        {devicesSection}
      </div>
    )
  }

  return renderDeviceStatusRows()
}
