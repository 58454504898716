import _ from 'lodash'
import { getBaseChartOptions, addPausePlotBands } from './baseChartUtils'
import { baseSleepChartOptions, getSleepDurationSeries } from './baseSleepChart'
import { getXAxisForRange } from './chartUtils'

/* ********************************************************************************
 * Chart types
 * ********************************************************************************/
function getFullChartOptions (memberObj, dashboardViewStore) {
  const xAxisOptions = { xAxis: getXAxisForRange(memberObj, dashboardViewStore) }

  let options = _.merge(
    getBaseChartOptions(),
    baseSleepChartOptions,
    xAxisOptions
  )

  options = getSleepDurationSeries(options, memberObj, dashboardViewStore)

  options = addPausePlotBands(memberObj.user.pauses, options, true)

  return options
}

/* ********************************************************************************
 * Parent method
 * ********************************************************************************/

function getSleepChartOptions ({ memberObj, dashboardViewStore }) {
  return getFullChartOptions(memberObj, dashboardViewStore)
}

export default getSleepChartOptions
