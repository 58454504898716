import React from 'react'
import restApi from '../../js/api'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { analyticsSlice } from '../../js/store/analytics'
import Tooltip from '../../js/components/elements/Tooltip'
import AnalyticsChart from '../layout/AnalyticsChart'
import getWeightFluctuationChartOptions from '../charts/weightFluctuationChart'
import getActivityChartOptions from '../charts/activityChart'
import getSleepChartOptions from '../charts/sleepChart'

/* ****************************************************************************
 * Analytics => Trends => Alerts
 * ****************************************************************************/
function AnalyticsMeasurementsSection (props) {
  const renderWeightFluctuationModule = () => {
    const tooltipText = "Explore how a patient's weight is correlated with their median weight change between measurements."

    return (
      <div className='ax-module' data-testid='ax-module__weight-fluctuation'>
        <div className='ax-module-header'>
          Weight fluctuation
          <Tooltip tooltipId='ax-module__weight-fluctuation__tooltip' content={tooltipText} />
        </div>
        <AnalyticsChart
          containerId='ax-weight-fluctuations-chart'
          chartOptions={getWeightFluctuationChartOptions(props.analyticsStore.onboardedUsers)}
        />
      </div>
    )
  }

  const renderActivityModule = () => {
    const activityTooltip = 'Every patient averages a different number of steps depending on the day of the week. This chart represents the distribution of those averages across the entire population.'

    return (
      <div className='ax-module' data-testid='ax-module__activity-by-dow'>
        <div className='ax-module-header'>
          Activity by day of week
          <Tooltip tooltipId='ax-module__activity-by-dow__tooltip' content={activityTooltip} />
        </div>
        <AnalyticsChart
          containerId='ax-activity-chart'
          chartOptions={getActivityChartOptions(props.analyticsStore.onboardedUsers)}
        />
      </div>
    )
  }

  const renderSleepModule = () => {
    const sleepTooltip = 'Use this chart to investigate how long patients sleep, how many sleep disruptions they experience in a night, and potential relationships between the two.'

    return (
      <div className='ax-module' data-testid='ax-module__sleep'>
        <div className='ax-module-header'>
          Nightly sleep length and disruptions
          <Tooltip tooltipId='ax-module__sleep__tooltip' content={sleepTooltip} />
        </div>
        <AnalyticsChart
          containerId='ax-sleep-chart'
          chartOptions={getSleepChartOptions(props.analyticsStore.onboardedUsers)}
        />
      </div>
    )
  }

  const renderAxMeasurementsSection = () => {
    return (
      <div className='ax-subsection' data-testid='ax-subsection__measurements'>
        <div className='ax-subsection__header'>Measurements</div>

        <div>
          <div>
            {renderWeightFluctuationModule()}
          </div>
          <div>
            {renderActivityModule()}
          </div>
        </div>

        <div>
          <div>
            {renderSleepModule()}
          </div>
          <div />
        </div>
      </div>
    )
  }

  return renderAxMeasurementsSection()
}

function getPropsFromStore (state) {
  return {
    analyticsStore: state.analytics
  }
}

function getPropsFromActions (dispatch) {
  return {
    apiActions: bindActionCreators(restApi.actions, dispatch),
    analyticsActions: bindActionCreators(analyticsSlice.actions, dispatch)
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(AnalyticsMeasurementsSection)
