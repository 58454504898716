import React from 'react'
import { Box, Link, Typography } from '@mui/material'
import { ejentaAppVersion } from '../../utils/apiUtils'

/* *****************************************************************************
 * Footer components
 * *****************************************************************************/
function renderCopyright (props) {
  const styles = { display: 'flex', justifyContent: 'center', mb: 1, fontWeight: 350 }
  if (props.isFullscreen) {
    styles.color = 'gray'
    styles.fontSize = 12
  }

  return (
    <Box className='footer__copyright'>
      {props.isFullscreen && <Typography variant='caption' sx={{ color: 'gray', fontSize: 10 }}>v{ejentaAppVersion}</Typography>}
      <Box sx={styles}>&copy; 2012–2023 Ejenta, Inc.</Box>
    </Box>
  )
}

/* *****************************************************************************
 * App footer
 * *****************************************************************************/
function AppFooter (props) {
  const renderLinks = () => {
    const styles = {
      mr: props.isFullscreen ? 3 : 0,
      opacity: props.isFullscreen ? 0.7 : 1,
      fontSize: props.isFullscreen ? 13 : 'inherit'
    }
    return (
      <>
        <Link underline='none' color='secondary' href='//ejenta.com' target='_blank' rel='noopener noreferrer' sx={styles}>
          {props.isFullscreen ? 'About us' : 'About'}
        </Link>
        <Link underline='none' color='secondary' href='//ejenta.com/terms.pdf' target='_blank' rel='noopener noreferrer' sx={styles}>
          {props.isFullscreen ? 'Terms of Service' : 'Terms'}
        </Link>
        <Link underline='none' color='secondary' href='//ejenta.com/privacy.pdf' target='_blank' rel='noopener noreferrer' sx={styles}>
          {props.isFullscreen ? 'Privacy policy' : 'Privacy'}
        </Link>
      </>
    )
  }

  const renderDefault = () => {
    return (
      <Box>
        {renderCopyright(props)}

        <Box data-testid='footer__links' sx={{ mb: 1, display: 'flex', justifyContent: 'space-evenly' }}>
          {renderLinks()}
        </Box>
      </Box>
    )
  }

  const renderBasic = () => {
    return (
      <Box data-testid='footer__links' sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'rgba(0,0,0,.85)', alignItems: 'center', p: 1 }}>
        <Box>{renderCopyright(props)}</Box>
        <Box>
          {renderLinks(true)}
        </Box>
      </Box>
    )
  }

  if (props.isFullscreen) return renderBasic()
  else return renderDefault()
}

export { AppFooter }
