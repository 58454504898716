import _ from 'lodash'
import moment from 'moment'

export const BASE_RANGE_VIEWS = {
  ONE_DAY: 'P1D',
  THREE_DAYS: 'P3D',
  WEEK: 'P7D',
  TWO_WEEKS: 'P14D',
  THREE_WEEKS: 'P21D',
  MONTH: 'P1M',
  THREE_MONTHS: 'P3M',
  SIX_MONTHS: 'P6M',
  YEAR: 'P1Y'
}

export const PREG_RANGE_VIEWS = _.merge(BASE_RANGE_VIEWS, {
  WEEK_CONDENSED: 'week-condensed',
  TRIMESTER: 'trimester',
  PREGNANCY: 'pregnancy'
})

// Example formatting (1 week, 2 weeks, 1 month, 3 months, 1 year)
export function formatDuration (duration) {
  return duration.format('y [years], M [months], w [weeks], d [days]', { largest: 1 })
}

export function getDeliveryDate (user) {
  const conceptionDate = moment(user.conception_date)
  const deliveryDate = conceptionDate.add(40, 'weeks')

  return deliveryDate
}

export function getWeekOfPregnancy (member, date) {
  const conceptionDate = moment(member.user.conception_date)
  let pregnancyWeek = moment(date).diff(conceptionDate, 'weeks')

  if (isNaN(pregnancyWeek)) {
    // this can happen when conceptionDate and date are the same day
    pregnancyWeek = 0
  }

  return pregnancyWeek
}

export function getTrimesterOfPregnancy (member, date) {
  if (!date) return member?.pregnancy_info?.current_trimester
  if (moment(date).isBefore(moment(member.pregnancy_info.trimester_2.start_date))) return 1
  if (moment(date).isBefore(moment(member.pregnancy_info.trimester_3.start_date))) return 2
  return 3
}

export function getTrimesterBounds (member, trimester) {
  if (trimester === 1) {
    return {
      startDate: member.pregnancy_info.trimester_1.start_date,
      endDate: moment(member.pregnancy_info.trimester_2.start_date).subtract(1, 'days')
    }
  }

  if (trimester === 2) {
    return {
      startDate: member.pregnancy_info.trimester_2.start_date,
      endDate: moment(member.pregnancy_info.trimester_3.start_date).subtract(1, 'days')
    }
  }

  return {
    startDate: member.pregnancy_info.trimester_3.start_date,
    endDate: moment()
  }
}

export function getPregRangeBounds (member, rangeZoom, rangeStart) {
  if (!member) return {}

  let trimesterBounds
  let startDate
  let endDate

  switch (rangeZoom) {
    // Single week
    case PREG_RANGE_VIEWS.WEEK:
      if (!rangeStart) {
        startDate = moment().subtract(6, 'days')
        endDate = moment()
      } else {
        startDate = rangeStart
        endDate = moment(rangeStart).add(6, 'days')
      }

      break

    // Single trimester
    case PREG_RANGE_VIEWS.TRIMESTER:
      trimesterBounds = getTrimesterBounds(
        member,
        getTrimesterOfPregnancy(member, rangeStart)
      )
      startDate = trimesterBounds.startDate
      endDate = trimesterBounds.endDate
      break

    // Full pregnancy
    case PREG_RANGE_VIEWS.PREGNANCY:
      startDate = moment(member.user.conception_date)
      endDate = moment()
      break

    default:
      break
  }

  const momentFormat = m => moment(m).format('YYYY-MM-DD')
  return { startDate: momentFormat(startDate), endDate: momentFormat(endDate) }
}
