
import React, { useState } from 'react'
import moment from 'moment'
import { momentCalendarDateConfig } from '../../../utils/baseStringUtils'
import { getSurveyResponseTooltipContent } from '../../../utils/member/analyticsUtils'

import EmptyCell from './EmptyCell'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

export default function SurveyResponseCell (props) {
  const [memberObj] = useState(props.memberObj)

  if (!memberObj.survey_responses?.latest_measurement) {
    return <EmptyCell message='No scores' />
  }

  const renderTooltipContent = (memberObj, id) => {
    const splitData = getSurveyResponseTooltipContent(memberObj.survey_responses.latest_measurement)?.split('<br/>')?.filter(i => i)
    if (!splitData?.length) return null

    return (
      <>
        {splitData.map((item, id) => <Typography key={id}>{item}</Typography>)}
      </>
    )
  }

  const lastSurveyResponse = moment(memberObj.survey_responses.latest_measurement.date)
  const latestSrTooltipId = `tooltip-latest_score-${memberObj.user.id}`

  const latestCol = (
    <Box sx={{ textAlign: 'center' }}>
      <Tooltip data-testid={latestSrTooltipId} id={latestSrTooltipId} title={renderTooltipContent(memberObj, latestSrTooltipId)}>
        <div>{`${memberObj.survey_responses.latest_measurement.total_score}`}</div>
      </Tooltip>
      <div className='member-row__latest-timestamp'> {lastSurveyResponse.calendar(null, momentCalendarDateConfig)} </div>
    </Box>
  )

  return (
    <Grid container>
      <Grid item xs={12}> {latestCol} </Grid>
    </Grid>
  )
}
