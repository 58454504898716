import _ from 'lodash'
import baseChartOptions from './baseChartOptions'
import { getAgeBuckets, getAgeCounts } from './ageChartConfig'

function getAgeChartOptions (users, analyticsActions) {
  const ageCounts = getAgeCounts(users)

  const ageChartOptions = {
    chart: {
      type: 'column'
    },

    xAxis: {
      title: {
        text: 'Age',
        margin: 24
      },

      categories: _.values(getAgeBuckets()),
      tickLength: 0
    },

    yAxis: {
      title: {
        text: '# of members'
      }
    },

    legend: {
      enabled: false
    },

    series: [{
      name: 'Members',
      className: 'series-0 ax-chart-clickable',

      data: _.map(ageCounts, (a, key) => ({
        y: a.length,
        events: {
          click: () => {
            analyticsActions.setDrilldown({
              title: `Age: ${getAgeBuckets()[key]}`,
              type: 'member',
              items: a
            })
          }
        }
      })),

      tooltip: {
        headerFormat: null,
        pointFormatter: function format () {
          return `
            <span class="ax-text-bold">${this.category}</span><br/>
            Members: <em>${this.y}</em>
          `
        }
      }
    }]
  }

  return _.merge(_.cloneDeep(baseChartOptions), ageChartOptions)
}

export default getAgeChartOptions
