import React from 'react'
import { connect } from 'react-redux'
import restApi from '../../../api'
import ClinicianNav from 'controllers/UserNav/ClinicianNav'

/* *****************************************************************************
 * User navigation menu
 * *****************************************************************************/

function UserNav (props) {
  if (!props.userSession?.user || !props.userSession?.isClinician) {
    return null
  } else {
    return (
      <ClinicianNav forDesktop={props.forDesktop} filterCounts={props.filterCounts} />
    )
  }
}

function getPropsFromStore (state) {
  return {
    userSession: state.userSession,
    filterCounts: state.alertsView.filterCounts
  }
}

function getPropsFromActions (dispatch) {
  return {
    handleLogout () {
      dispatch(restApi.actions.logout())
    }
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(UserNav)
