import React from 'react'
import Tooltip from '../../js/components/elements/Tooltip'
import AnalyticsChart from '../layout/AnalyticsChart'
import getAdherenceChartOptions from '../charts/adherenceChart'

export default function Gender (props) {
  const adherenceTooltip = 'Click on the legend to drill into adherence levels for a specific sensor.'

  return (
    <div className='ax-module'>
      <div className='ax-module-header'>
        Adherence
        <Tooltip tooltipId='ax-module__adherence__tooltip' content={adherenceTooltip} />
      </div>
      <AnalyticsChart
        containerId='ax-adherence-chart'
        chartOptions={getAdherenceChartOptions(props.users, props.range, props.analyticsActions)}
      />
    </div>
  )
}
