import React, { useState } from 'react'
import restApi from '../../../api'
import Snackbar from '../../elements/Snackbar'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { EjentaForm, renderField, renderInput } from '../../elements/EjentaForm'
import { Typography, Card, CardContent, Button, DialogActions, Alert } from '@mui/material'
import { Form } from 'react-final-form'

function BaseChangePasswordForm (props) {
  const validateChangePasswordForm = (fields) => {
    const errors = {}
    if ((fields.newPassword || fields.newPasswordConfirm) && !fields.currentPassword) errors.currentPassword = 'Must enter current password.'

    if (fields.currentPassword && !fields.newPassword) errors.newPassword = 'Must enter a new password.'
    else if (fields.currentPassword && fields.currentPassword === fields.newPassword) errors.newPassword = 'Current and new passwords must be different.'

    if (fields.newPassword !== fields.newPasswordConfirm) errors.newPasswordConfirm = 'New password fields must match.'

    return errors
  }

  const renderSubmitButton = ({ pristine, submitting, errors }) => {
    const hasErrors = Object.keys(errors)?.length > 0
    if (submitting) return (<Button variant='contained' disabled> Saving... </Button>)
    return (<Button variant='contained' type='submit' disabled={pristine || hasErrors}> Change password </Button>)
  }

  const renderBaseChangePasswordForm = () => {
    const currentPasswordField = {
      name: 'currentPassword',
      type: 'password',
      label: 'Current password',
      component: renderInput
    }

    const newPasswordField = {
      name: 'newPassword',
      type: 'password',
      label: 'New password',
      component: renderInput
    }

    const newPasswordConfirmField = {
      name: 'newPasswordConfirm',
      type: 'password',
      label: 'Confirm new password',
      component: renderInput
    }

    return (
      <Form
        onSubmit={props.onSubmit}
        initialValues={() => {
          return {
            currentPassword: '',
            newPassword: '',
            newPasswordConfirm: ''
          }
        }}
        validate={values => validateChangePasswordForm(values)}
      >
        {({ handleSubmit, pristine, submitting, submitError, errors }) => (
          <EjentaForm onSubmit={handleSubmit}>
            {submitError && <Alert severity='error'>{submitError}</Alert>}
            {renderField({}, currentPasswordField)}
            {renderField({}, newPasswordField)}
            {renderField({}, newPasswordConfirmField)}
            <DialogActions>
              {renderSubmitButton({ pristine, submitting, errors })}
            </DialogActions>
          </EjentaForm>
        )}
      </Form>
    )
  }

  return renderBaseChangePasswordForm()
}

function ChangePasswordForm (props) {
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const handleSubmit = (fields) => {
    const submitPromise = new Promise((resolve, reject) => {
      const apiFields = {
        oldpassword: fields.currentPassword,
        newpassword: fields.newPassword
      }

      props.apiActions.changePassword(
        {
          id: props.user.id,
          promise: { resolve, reject }
        },
        { body: JSON.stringify(apiFields) }
      )
    })

    return submitPromise.then(handleSubmitSuccess).catch(data => {
      return data
    })
  }

  const handleSubmitSuccess = () => {
    setSnackbarOpen(true)
  }

  const renderChangePasswordForm = () => {
    return (
      <Card variant='outlined'>
        <CardContent>
          <Typography variant='h6' fontSize='1.1rem' sx={{ mb: 3 }}>Change Password</Typography>
          <Snackbar
            isOpen={snackbarOpen}
            dataKey='change-password__success__snackbar'
            handleClose={() => setSnackbarOpen(false)}
            severity='success'
            message='Password updated successfully'
          />

          <BaseChangePasswordForm user={props.user} onSubmit={handleSubmit} />
        </CardContent>
      </Card>
    )
  }

  return renderChangePasswordForm()
}

function getPropsFromStore (state) {
  return {
    user: state.userSession.user
  }
}

function getPropsFromActions (dispatch) {
  return {
    apiActions: bindActionCreators(restApi.actions, dispatch)
  }
}

export default connect(getPropsFromStore, getPropsFromActions)(ChangePasswordForm)
