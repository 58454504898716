import _ from 'lodash'
import baseChartOptions from './baseChartOptions'
import { getAlertTypeCounts } from './alertChartConfig'

function getAlertTypeChartOptions (alerts) {
  const alertTypeCounts = getAlertTypeCounts(alerts)

  const alertTypeChartOptions = {
    chart: {
      type: 'pie'
    },

    plotOptions: {
      pie: {
        states: {
          hover: {
            brightness: 0,
            halo: {
              opacity: 0.1
            }
          }
        }
      }
    },

    series: [{
      name: 'Alerts',
      data: _.map(alertTypeCounts, c => ({
        name: c.name,
        y: c.value,
        className: c.className
      })),
      tooltip: {
        headerFormat: null,
        pointFormatter: function format () {
          return `
            <span class="ax-text-bold">${this.name}</span><br/>
            Alerts: <em>${this.y}</em>
          `
        }
      }
    }]
  }

  return _.merge(_.cloneDeep(baseChartOptions), alertTypeChartOptions)
}

export default getAlertTypeChartOptions
