import moment from 'moment'
import thunk from 'redux-thunk'
import React from 'react'
import ReactGA from 'react-ga'
import restApi from './api'
import csrfReducer from 'redux-csrf'
import { createRoot } from 'react-dom/client'
import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { composeWithDevTools } from '@redux-devtools/extension'
import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { SESSION_CHECK_INTERVAL, SESSION_WARNING_LENGTH } from './constants/constants'
import { rootSlice } from './store/rootReducer'
import { membersCacheSlice } from './store/membersCache'
import { membersViewSlice } from './store/membersView'
import { alertsViewSlice } from './store/alertsView'
import { clinicianCarePlanSlice } from './store/clinicianCarePlan'
import { profileViewSlice } from './store/profileView'
import { dashboardViewSlice } from './store/dashboardView'
import { analyticsSlice } from './store/analytics'
import { sidebarSlice } from './store/sidebarView'
import { sessionExpire, warnSessionExpire, setSessionExpireTime, userSessionSlice } from './store/userSession'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import EjentaRouter from './routes/router'

require('file-loader?name=index.html!index.html') // eslint-disable-line import/no-webpack-loader-syntax
require('scss/main.scss')
require('scss/vendors/_fonts.scss')

const container = document.getElementById('root')
const root = createRoot(container)
const muiTheme = createTheme({
  typography: {
    fontWeightRegular: 300
  },
  palette: {
    text: {
      primary: 'rgba(0, 0, 0, 0.87)'
    },
    primary: {
      main: '#EF6C00', // orange
      light: 'rgba(240, 108, 0, 0.28)'
    },
    warning: {
      main: '#e2de03' // yellow
    },
    secondary: {
      main: '#999', // grayish
      light: '#7f7f7f',
      contrastText: '#fff' // white
    }
  }
})

function renderApp (store) {
  const state = store.getState()

  root.render(
    <Provider store={store}>
      <ThemeProvider theme={muiTheme}>
        <BrowserRouter>
          <EjentaRouter state={state} dispatch={store.dispatch} />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  )
}

// these paths are functions passed to redux actions. Functions are non-serializable and would therefore throw an error
const ignoredNonSerializablePaths = [
  'api.login.request.pathvars.promise.resolve',
  'api.login.request.pathvars.promise.reject',

  'api.loginAmazon.request.pathvars.promise.resolve',
  'api.loginAmazon.request.pathvars.promise.reject',

  'api.updateUser.request.pathvars.promise.resolve',
  'api.updateUser.request.pathvars.promise.reject',
  'api.updateUser.request.pathvars.promiseTransformer',

  'api.sendPhoneLoginCode.request.pathvars.promise.resolve',

  'api.memberSummary.request.pathvars.promise.resolve',
  'api.alertCounts.request.pathvars.promise.resolve',

  'api.createMember.request.pathvars.promise.resolve',
  'api.createMember.request.pathvars.promise.reject',
  'api.createMember.request.pathvars.promiseTransformer',

  'api.updateAccount.request.pathvars.promise.resolve',
  'api.updateAccount.request.pathvars.promise.reject',
  'api.updateAccount.request.pathvars.promiseTransformer',

  'api.submitSignupCode.request.pathvars.promise.resolve',
  'api.submitSignupCode.request.pathvars.promise.reject',

  'api.submitAddress.request.pathvars.promise.resolve',
  'api.submitAddress.request.pathvars.promise.reject',

  'api.firstTimePassword.request.pathvars.promise.resolve',
  'api.firstTimePassword.request.pathvars.promise.reject',

  'api.changePassword.request.pathvars.promise.resolve',
  'api.changePassword.request.pathvars.promise.reject',

  'api.resetPassword.request.pathvars.promise.resolve',
  'api.resetPassword.request.pathvars.promise.reject',

  'api.sendResetPasswordEmail.request.pathvars.promise.resolve',
  'api.sendResetPasswordEmail.request.pathvars.promise.reject',
  'api.sendResetPasswordEmail.error',

  'api.sendPhoneLoginCode.request.pathvars.promise.reject',

  'api.csrf.error',
  'api.logout.error',
  'api.clinicianAlerts.error',
  'api.resources.error',
  'api.sendAnalytics.error',
  'api.setActiveGroup.error',
  'api.session.error',
  'api.alertCounts.error',
  'api.getClinicianDefaultCarePlan.error',

  'request.pathvars.promise.resolve',
  'request.pathvars.promise.reject',
  'request.pathvars.promiseTransformer',

  'meta.arg.dispatch',
  'meta.arg.getState',
  'error'
]

const collectedReducers = combineReducers({
  csrf: csrfReducer,
  api: combineReducers(restApi.reducers),
  routing: routerReducer,

  sidebar: sidebarSlice.reducer,
  userSession: userSessionSlice.reducer,
  membersCache: membersCacheSlice.reducer,
  membersView: membersViewSlice.reducer,
  profileView: profileViewSlice.reducer,
  dashboardView: dashboardViewSlice.reducer,
  clinicianCarePlan: clinicianCarePlanSlice.reducer,
  alertsView: alertsViewSlice.reducer,
  analytics: analyticsSlice.reducer,
  rootReducer: rootSlice.reducer
})

const baseReducer = (state, action) => {
  if (action.type === 'root/clear') { // check for action type
    state = undefined
  }

  return collectedReducers(state, action)
}

/*
 * Create Redux store
 */
const store = configureStore({
  reducer: baseReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredPaths: ignoredNonSerializablePaths,
      ignoredActionPaths: ignoredNonSerializablePaths
    }
  }).concat(composeWithDevTools(
    thunk
  ))
})

// Continuously checks the current session to make sure that it is still valid
setInterval(() => {
  const userSession = store.getState().userSession
  const sessionExpireTime = moment(userSession.sessionExpireTime)
  const now = moment()

  if (!userSession.isLoggedIn || !sessionExpireTime) return

  // Check if session has expired
  if (now.isAfter(sessionExpireTime)) {
    store.dispatch(sessionExpire())
    store.dispatch(restApi.actions.logout())
  } else if (sessionExpireTime.diff(now) < SESSION_WARNING_LENGTH) { // Check if session is expiring
    store.dispatch(warnSessionExpire())
  }
}, SESSION_CHECK_INTERVAL)

/*
 * Add error-handling fetch middleware to REST API
 */

function fetchWrapper (fetch) {
  return (url, opts) =>
    fetch(url, opts).then((resp) => {
      const contentType = resp.headers.get('content-type')

      // Respond to 403s with a refresh
      if (resp.status === 403) {
        window.location = '/'
      }
      // Set a timeout for "are you still there?" dialog and session expiration
      if (resp.headers.has('x-expiration-time')) {
        const userSession = store.getState().userSession
        if (userSession.isLoggedIn) {
          // Sets expiration time a minute earlier to make sure that the user has enough time
          // to respond to the modal.
          const expireTime = moment(Number(resp.headers.get('x-expiration-time'))).subtract(1, 'm')
          store.dispatch(setSessionExpireTime(expireTime.valueOf()))
        }
      }

      // 400 responses that contain json aren't rejected here
      // but are handled by baseUtils.resolvePromise
      if (contentType && contentType.includes('json')) {
        return resp.text().then((data) => {
          try {
            return JSON.parse(data)
          } catch (err) {
            // Reject the promise if the json isn't actually json.
            return Promise.reject(data)
          }
        })
      }

      if (resp.status >= 400) {
        return Promise.reject({ status: resp.status, statusText: resp.statusText }) // eslint-disable-line prefer-promise-reject-errors
      }

      return resp.text().then((data) => {
        return data
      })
    })
}

restApi.use('fetch', fetchWrapper(fetch))

/*
 * Initialize Google Analytics
 */

ReactGA.initialize('UA-64162912-5')

/*
 * Initialize routes
 */
// const router = <Router store={store} /> // createRouter(store)

/*
 * Send auth request to server
 */
store.dispatch(restApi.actions.csrf())

/*
 * Wait for auth responses from server before rendering app
 */
store.subscribe(() => {
  const userSession = store.getState().userSession
  const authComplete = userSession?.isLoggedIn != null
  const groupComplete = userSession?.activeGroup !== undefined // session endpoint finished
  if (authComplete && groupComplete) {
    // Do not unsubscribe from store updates, so that app sees navigation events
    renderApp(store)
  }
})

export default store
