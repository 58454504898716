import _ from 'lodash'
import moment from 'moment'
import classnames from 'classnames'

import { getBaseSeriesOptions, getPointDateString } from './baseChartUtils'
import { getMarkerRadius } from './chartUtils'
import { isAlertOpen } from '../../constants/constants'

/* ********************************************************************************
 * Base chart options
 * ********************************************************************************/

export const baseWeightChartOptions = {
  chart: { className: 'weight-chart' },

  legend: {
    symbolRadius: 0,
    itemMarginTop: 12
  },

  tooltip: {
    headerFormat: null,
    pointFormatter: function format () {
      return `
        <strong>${this.name}</strong><br/>
        ${this.dataString}
      `
    },
    useHTML: true,
    shared: true,
    stickOnContact: true,
    borderWidth: 0
  },

  plotOptions: {
    line: {
      marker: { enabled: true }
    }
  },

  yAxis: [{
    title: { text: '' },
    labels: { format: '{value} lb' }
  }],

  series: []
}

/* ********************************************************************************
 * Weight measurements series
 * ********************************************************************************/

export function getWeightMeasurementsSeries (options, memberObj, dashboardViewStore, handleDeleteWeight) {
  if (memberObj.weight.data.length) {
    const newOptions = _.cloneDeep(options)

    // give this function global scope so we can embed it in the HTML Delete button onClick in the tooltip
    window.handleDeleteWeight = handleDeleteWeight

    // Set marker radius
    _.set(newOptions, ['plotOptions', 'line', 'marker', 'radius'], getMarkerRadius(dashboardViewStore))

    const weightMeasurements = _.reverse(_.cloneDeep(memberObj.weight.data)).map((m) => {
      const point = {
        name: getPointDateString(moment(m.timestamp), true),
        x: +moment(m.timestamp),
        y: m.weight.value,
        useHTML: true,
        dataString: m.timestamp,
        dataSource: m.source,
        events: {
          click: () => {
            if (handleDeleteWeight) {
              handleDeleteWeight(m.timestamp)
            }
          }
        }
      }
      return point
    })

    const weightMeasurementsSeries = _.extend({
      name: 'Weight',
      data: weightMeasurements,
      zIndex: 1,
      marker: { symbol: 'circle', lineWidth: 0 },
      type: 'line',
      className: 'series-weightMeasurements',
      tooltip: {
        useHtml: true,
        headerFormat: null,
        pointFormatter: function format () {
          return `
            <strong>${this.name}</strong><br/>
            <span>Weight: ${this.y} ${memberObj.weight.information.units.weight}</span><br />
            <small>Source: ${this.dataSource ?? 'Unknown'}</small>
            <small style='justify-content: right; width: 100%; display: flex'>Click to delete</small>
          `
        }
      }
    }, getBaseSeriesOptions())

    newOptions.series.push(weightMeasurementsSeries)
    return newOptions
  }
  return options
}

export function getDeletedWeightMeasurementsSeries (options, memberObj, dashboardViewStore, handleRestoreWeight) {
  const deletedWeightData = memberObj.deleted_weight.data ?? []
  const newOptions = _.cloneDeep(options)

  // give this function global scope so we can embed it in the HTML Restore button onClick in the tooltip
  window.handleRestoreWeight = handleRestoreWeight

  const deletedMeasurements = _.reverse(_.cloneDeep(deletedWeightData)).map((m) => {
    const point = {
      name: getPointDateString(moment(m.timestamp), true),
      x: +moment(m.timestamp),
      y: m.weight.value,
      useHtml: true,
      dataString: m.timestamp,
      dataSource: m.source,
      events: {
        click: () => {
          if (handleRestoreWeight) {
            handleRestoreWeight(m.timestamp)
          }
        }
      }
    }
    return point
  })

  let markerRadius = getMarkerRadius(dashboardViewStore)
  if (!markerRadius || markerRadius < 2) markerRadius = 2

  const deletedWeightsSeries = _.extend({
    name: 'Deleted Weights',
    data: deletedMeasurements,
    zIndex: 1,
    color: '#a1a1a1',
    marker: { symbol: 'circle', radius: markerRadius, lineWidth: 0 },
    type: 'scatter',
    className: 'series-deletedWeights',
    tooltip: {
      useHtml: true,
      headerFormat: null,
      pointFormatter: function format () {
        return `
            <strong>${this.name}</strong><br/>
            <span>Weight: ${this.y} ${memberObj.deleted_weight.information.units.weight}</span><br />
            <small>Source: ${this.dataSource ?? 'Unknown'}</small>
            <small style='justify-content: right; width: 100%; display: flex'>Click to restore</small>
          `
      }
    }
  }, getBaseSeriesOptions())

  newOptions.tooltip.headerFormat = null

  newOptions.series.push(deletedWeightsSeries)
  return newOptions
}

/* ********************************************************************************
 * Weight range series
 * ********************************************************************************/

export function get2LbRangeSeries (memberObj, options) {
  if (memberObj.weight.data.length) {
    const newOptions = _.cloneDeep(options)

    const sortedWeightData = _.sortBy(memberObj.weight.data, w => w.timestamp)

    const rangeData = []

    // Use the previous period's average weight to get the "first" weight for calculating this range
    // (non-ideal, but we don't have easy access to the previous period's measurements T___T).
    if (memberObj.weight.information.periods[1]) {
      rangeData.push([
        +moment(sortedWeightData[0].timestamp).subtract(1, 'day'),
        memberObj.weight.information.periods[1].avg_weight.value - 2,
        memberObj.weight.information.periods[1].avg_weight.value + 2
      ])
    }

    _.each(sortedWeightData, (w, wIndex) => {
      const nextW = sortedWeightData[wIndex + 1]
      if (nextW && moment(nextW.timestamp).isBefore(moment(w.timestamp).add(1, 'day'))) {
        rangeData.push([+moment(nextW.timestamp) - 1, w.weight.value - 2, w.weight.value + 2])
      } else {
        rangeData.push([+(moment(w.timestamp).add(1, 'day')) - 1, w.weight.value - 2, w.weight.value + 2])
      }
    })

    if (rangeData.length >= 2) {
      const rangeSeries = _.extend({
        name: 'Within 2 lbs/day',
        data: rangeData,
        yAxis: 1,
        color: '#95D542',

        type: 'arearange',
        marker: { enabled: false },
        className: 'series-weight2Lb',
        zIndex: 0,

        enableMouseTracking: false
      }, getBaseSeriesOptions())

      newOptions.series.push(rangeSeries)
      newOptions.yAxis.push({ title: { text: null }, labels: { enabled: false }, linkedTo: 0 })
    }
    return newOptions
  }
  return options
}

export function get5LbLastWeekSeries (memberObj, options) {
  if (memberObj.weight.data.length) {
    const newOptions = _.cloneDeep(options)
    const lastWeekAvg = _.get(memberObj.weight, ['information', 'periods', '1', 'avg_weight', 'value'])
    newOptions.series.push(_.extend({
      className: 'series-weight5lbLastWeek',
      name: '5 lbs from last week',
      type: 'line',
      marker: { enabled: false },
      data: lastWeekAvg ? [[0, lastWeekAvg + 5], [+moment().add(1, 'week'), lastWeekAvg + 5]] : []
    }, getBaseSeriesOptions()))

    return newOptions
  }
  return options
}

/* ********************************************************************************
 * Weight alerts series
 * ********************************************************************************/

export function getWeightAlertsSeries (memberObj, options, handleAlertClick) {
  if (!(memberObj.alerts && memberObj.alerts.length)) return options
  const weightAlerts = _.filter(memberObj.alerts, a => a.metric_type === 'weight' || (a.metric_type === 'multi' && a.member_content.indexOf('weight') !== -1))
  if (!weightAlerts.length) return options

  const alertsData = weightAlerts.map((alertObj) => {
    /*
     * Get the Y-value of the marker
     */
    let pointY = 0
    const weightMeasurement = memberObj.weight.data.find(
      d => d.timestamp === alertObj.measurement_timestamp
    )

    // If there is a corresponding measurement, display the alert marker above the actual weight
    if (weightMeasurement) {
      pointY = weightMeasurement.weight.value * 1.02
    } else if (memberObj.weight.data[0]) { // Otherwise just use the first weight (or deleted weight) value we have, but be more generous about padding
      pointY = memberObj.weight.data[0].weight.value * 1.05
    } else if (memberObj.deleted_weight.data[0]) {
      pointY = memberObj.deleted_weight.data[0].weight.value * 1.05
    } else {
      pointY = 100 // we have no weights or deleted weights in this range; shouldn't happen, but in case it does, just use a Y-axis value of 100
    }

    /*
     * Get marker styling
     */
    const marker = {}
    if (alertObj.type === 'weight_tracking' || alertObj.type === 'multi_tracking') {
      marker.symbol = 'circle'
    }
    const isOpen = isAlertOpen(alertObj)

    const classNames = classnames({
      'is-open': isOpen,
      'is-closed': !isOpen,
      'is-tracking': alertObj.type === 'weight_tracking' || alertObj.type === 'multi_tracking'
    })

    /*
     * Define Highcharts point
     */
    const point = {
      name: 'Weight alert',
      description: alertObj.provider_content,
      x: moment(alertObj.measurement_timestamp),
      y: pointY,
      marker,

      className: classNames,
      events: {
        click: () => {
          if (handleAlertClick) {
            handleAlertClick(alertObj)
          }
        }
      }
    }

    return point
  })

  const alertsSeries = _.extend({
    name: 'Weight alerts',

    type: 'scatter',
    className: 'series-weightAlerts',
    lineWidth: 0,
    color: '#EF6C00',

    marker: {
      symbol: 'triangle-down',
      radius: 7,
      lineWidth: 0
    },

    tooltip: {
      useHTML: true,
      // do not use pointFormat. Despite documentation, it does not allow access to all point object properties.
      headerFormat: null,
      pointFormatter: function format () {
        return `
          <strong>${this.name}</strong><br/>
          ${this.description}
        `
      }
    },

    data: alertsData
  }, getBaseSeriesOptions())

  const newOptions = _.cloneDeep(options)
  newOptions.series.push(alertsSeries)
  return newOptions
}
