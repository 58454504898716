import { createSlice } from '@reduxjs/toolkit'

/* ********************************************************************************
 *
 * Main reducer for EjentaApp.
 *
 * ********************************************************************************/
const rootSlice = createSlice({
  initialState: {},
  name: 'root',
  reducers: {
    clear: (state) => {
      state = null
    }
  }
})

/*
 * Exports
 */
export const { clear } = rootSlice.actions
export { rootSlice }
