
import _ from 'lodash'
import React from 'react'
import { RANGE_VIEWS, getRangeBounds } from '../member/dateUtils'
import moment from 'moment'
import { getWeekOfPregnancy, getTrimesterOfPregnancy, getTrimesterBounds } from '../baseDateUtils'
import { getColumnWidth as getColumnWidthBase, getXAxisForRange as getXAxisForRangeBase } from './baseChartUtils'
import { productConfig } from '../../config/config-service'
import { Box } from '@mui/material'
import WeightChart from '../../components/elements/Charts/WeightChart'
import BloodPressureChart from '../../components/elements/Charts/BloodPressureChart'
import BloodGlucoseChart from '../../components/elements/Charts/BloodGlucoseChart'
import BloodOxygenChart from '../../components/elements/Charts/BloodOxygenChart'
import PregnancyWeightChart from '../../components/elements/Charts/PregnancyWeightChart'
import ActivityChart from '../../components/elements/Charts/ActivityChart'
import SleepChart from '../../components/elements/Charts/SleepChart'
import FoodChart from '../../components/elements/Charts/FoodChart'
import ChartModule from '../../components/elements/Charts/ChartModule'
import getHeartRateChartOptions from './heartRateChart'
import getRespiratoryRateChartOptions from './respiratoryRateChart'
import getTemperatureChartOptions from './temperatureChart'

export default function renderProfileCharts (memberObj, dashboardViewStore, handleAlertOpen, handleClickDeleteWeight, handleClickRestoreWeight) {
  const profileChartList = productConfig().profile.charts

  const getChartByName = (name) => {
    switch (name) {
      case 'weight':
        return (
          <WeightChart
            key={name}
            dashboardViewStore={dashboardViewStore}
            memberObj={memberObj}
            showMemberAlerts
            handleDeleteWeight={handleClickDeleteWeight}
            handleRestoreWeight={handleClickRestoreWeight}
            handleAlertClick={handleAlertOpen}
          />
        )
      case 'blood_pressure':
        return (
          <BloodPressureChart
            key={name}
            dashboardViewStore={dashboardViewStore}
            memberObj={memberObj}
            showMemberAlerts
            handleAlertClick={handleAlertOpen}
          />
        )
      case 'blood_glucose':
        return (
          <BloodGlucoseChart
            key={name}
            dashboardViewStore={dashboardViewStore}
            memberObj={memberObj}
            handleAlertClick={handleAlertOpen}
          />
        )
      case 'pulse_ox':
        return (
          <BloodOxygenChart
            key={name}
            dashboardViewStore={dashboardViewStore}
            memberObj={memberObj}
            handleAlertClick={handleAlertOpen}
            showMemberAlerts
          />
        )
      case 'pregnancyWeight':
        return (
          <PregnancyWeightChart
            key={name}
            dashboardViewStore={dashboardViewStore}
            memberObj={memberObj}
            showMemberAlerts
            handleDeleteWeight={handleClickDeleteWeight}
            handleRestoreWeight={handleClickRestoreWeight}
            handleAlertClick={handleAlertOpen}
          />
        )
      case 'activity':
        return (
          <ActivityChart
            key={name}
            dashboardViewStore={dashboardViewStore}
            memberObj={memberObj}
            showMemberAlerts
            handleAlertClick={handleAlertOpen}
          />
        )
      case 'sleep':
        return (
          <SleepChart
            key={name}
            dashboardViewStore={dashboardViewStore}
            memberObj={memberObj}
            showMemberAlerts
            handleAlertClick={handleAlertOpen}
          />
        )
      case 'food':
        return (
          <FoodChart
            key={name}
            dashboardViewStore={dashboardViewStore}
            memberObj={memberObj}
          />
        )
      case 'temperature':
        return (
          <ChartModule
            key={name}
            dashboardViewStore={dashboardViewStore}
            memberObj={memberObj}
            metricType={name}
            getChartOptions={getTemperatureChartOptions}
            handleAlertClick={handleAlertOpen}
            showMemberAlerts
          />
        )
      case 'heart_rate':
        return (
          <ChartModule
            key={name}
            dashboardViewStore={dashboardViewStore}
            memberObj={memberObj}
            metricType={name}
            getChartOptions={getHeartRateChartOptions}
            handleAlertClick={handleAlertOpen}
            showMemberAlerts
          />
        )
      case 'respiratory_rate':
        return (
          <ChartModule
            key={name}
            dashboardViewStore={dashboardViewStore}
            memberObj={memberObj}
            metricType={name}
            getChartOptions={getRespiratoryRateChartOptions}
            handleAlertClick={handleAlertOpen}
            showMemberAlerts
          />
        )

      default:
        return null
    }
  }

  return (
    <Box>
      {profileChartList.map(profileChart => {
        return getChartByName(profileChart)
      })}
    </Box>
  )
}

export function getColumnWidth (dashboardViewStore, isMobile) {
  if (productConfig().profile.hasPregnancyCharts) {
    if (dashboardViewStore.rangeZoom === RANGE_VIEWS.WEEK) return 40
    else if (dashboardViewStore.rangeZoom === RANGE_VIEWS.TRIMESTER) return 4
    else if (dashboardViewStore.rangeZoom === RANGE_VIEWS.WEEK_CONDENSED) return 20

    return 1
  } else {
    return getColumnWidthBase(dashboardViewStore, isMobile)
  }
}

export function getMarkerRadius (dashboardViewStore) {
  if (productConfig().profile.hasPregnancyCharts) {
    if (dashboardViewStore.rangeZoom === RANGE_VIEWS.WEEK) return 4
    else if (dashboardViewStore.rangeZoom === RANGE_VIEWS.TRIMESTER) return 2
    return false
  } else {
    if (dashboardViewStore.rangeZoom === RANGE_VIEWS.YEAR) return false
    return 4
  }
}

export function getXAxisForRange (memberObj, dashboardViewStore, isMobile) {
  if (productConfig().profile.hasPregnancyCharts) {
    return getPregnancyXAxisForRange(memberObj, dashboardViewStore)
  } else {
    return getXAxisForRangeBase(memberObj, dashboardViewStore, isMobile)
  }
}

/* ********************************************************************************
 * X-axis bounds and formatting
 * ********************************************************************************/

function getXAxisForPregnancy (memberObj) {
  const xAxis = {
    labels: {}
  }

  const startDate = +moment(memberObj.user.conception_date)
  const endDate = +moment.max(moment(memberObj.user.conception_date).add(40, 'weeks'), moment())

  xAxis.min = startDate
  xAxis.max = endDate
  xAxis.minPadding = 0
  xAxis.maxPadding = 0

  xAxis.plotLines = _.map(_.range(3), (d) => {
    const plotLine = {
      value: +moment(getTrimesterBounds(memberObj, d).startDate),
      width: 2,
      className: 'trimester-plot-line'
    }

    return plotLine
  })

  xAxis.tickLength = 0
  xAxis.tickPositioner = () => _.map(
    _.range(3), d => +moment(getTrimesterBounds(memberObj, d).startDate).add(6.65, 'weeks')
  )
  xAxis.labels.formatter = function format () {
    return `Trimester ${getTrimesterOfPregnancy(memberObj, this.value)}`
  }

  return xAxis
}

function getPregnancyXAxisForRange (memberObj, dashboardViewStore) {
  // Base x-axis properties
  const xAxis = {
    type: 'category',
    labels: {
      y: 32
    },
    plotLines: [],
    plotBands: []
  }

  // Get start/end dates
  const { rangeZoom, rangeStart } = dashboardViewStore
  const { startDate, endDate } = getRangeBounds(memberObj, rangeZoom, rangeStart)

  const chartStartDate = +moment(startDate).subtract(1, 'days').startOf('day')
  const chartEndDate = +moment(endDate).endOf('day')

  xAxis.min = +chartStartDate
  xAxis.max = +chartEndDate
  xAxis.minPadding = 0
  xAxis.maxPadding = 0

  switch (rangeZoom) {
    case RANGE_VIEWS.WEEK:
      xAxis.tickPositioner = () => _.map(_.range(7), d => +moment(startDate).add(d, 'days'))
      xAxis.labels.formatter = function format () {
        return moment(this.value).format('dd<br/>M/D')
      }
      break

    case RANGE_VIEWS.TRIMESTER: {
      const { endDate: trimesterEndDate } = getTrimesterBounds(
        memberObj,
        getTrimesterOfPregnancy(memberObj, startDate)
      )
      // valueOf gets unix ms timestamp as a number
      xAxis.max = trimesterEndDate.valueOf()
      xAxis.tickPositioner = () => _.map(
        _.range(trimesterEndDate.diff(startDate, 'weeks')),
        w => +moment(startDate).add(w, 'weeks')
      )
      xAxis.labels.formatter = function format () {
        return `Week ${getWeekOfPregnancy(memberObj, this.value)}`
      }
      break
    }

    case RANGE_VIEWS.PREGNANCY:
    default:
      _.merge(xAxis, getXAxisForPregnancy(memberObj))
      break
  }

  // Draw a line for the join date (presumably the first time we have data)
  const joinedDate = moment(memberObj.user.created)
  xAxis.plotLines.push({
    value: +joinedDate,
    className: 'joined-plot-line'
  })

  xAxis.labels.useHTML = true

  return xAxis
}
