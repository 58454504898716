
import React, { useState } from 'react'
import moment from 'moment'
import { momentCalendarDateConfig } from '../../../utils/baseStringUtils'
import Grid from '@mui/material/Grid'

export default function ProgramStepCell (props) {
  const [memberObj] = useState(props.memberObj)

  return (
    <Grid container>
      <Grid item xs={12}> {memberObj.pregnancy_info.program_step} </Grid>
      <Grid item xs={12}> <aside>{moment(memberObj.pregnancy_info.program_step_date).calendar(null, momentCalendarDateConfig)}</aside> </Grid>
    </Grid>
  )
}
