import _ from 'lodash'
import React, { useState } from 'react'
import moment from 'moment'
import {
  getDisplayDelta,
  getDeltaArrow,
  momentCalendarDateConfig
} from '../../../utils/baseStringUtils'
import {
  getBloodGlucoseDeltaInRange
} from '../../../utils/member/analyticsUtils'
import EmptyCell from './EmptyCell'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

export default function BloodGlucoseCell (props) {
  const [memberObj] = useState(props.memberObj)

  if (!memberObj.user.has_bg || !memberObj.blood_glucose) {
    return <EmptyCell message='Device not set up' />
  }

  const latestBgData = _.get(memberObj, ['blood_glucose', 'latest_measurement'])
  const lastBgMeasurement = moment(latestBgData.timestamp)
  const latestCol = (
    <Box>
      <div className='member-row__latest'> {latestBgData.blood_glucose} </div>
      <div className='member-row__latest-timestamp'> {lastBgMeasurement.calendar(null, momentCalendarDateConfig)} </div>
    </Box>
  )

  const bgDelta7d = getBloodGlucoseDeltaInRange(memberObj)
  const deltaArrow = bgDelta7d !== 0 ? getDeltaArrow(bgDelta7d, false) : undefined
  const deltaCol = (<span className='member-row__delta'> {getDisplayDelta(bgDelta7d)} {deltaArrow} </span>)

  return (
    <Grid container>
      <Grid item xs={6}>{latestCol}</Grid>
      <Grid item xs={6}>{deltaCol}</Grid>
    </Grid>
  )
}
