import _ from 'lodash'
import React, { useState } from 'react'
import moment from 'moment'
import EmptyCell from './EmptyCell'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { productConfig } from '../../../config/config-service'
import { getDisplayDelta, getDeltaArrow, momentCalendarDateConfig } from '../../../utils/baseStringUtils'
import { getSystolicDeltaInRange, getDiastolicDeltaInRange, getNoDataString } from '../../../utils/member/analyticsUtils'

export default function BloodPressureCell (props) {
  const [memberObj] = useState(props.memberObj)

  if (!memberObj.user.has_bp || !memberObj.blood_pressure) {
    let message = !memberObj.user.bt_bp_imei ? 'Device not set up' : 'No BP data'
    if (productConfig().members.mainPage.hasShippingData) {
      message = getNoDataString(memberObj.user, !!memberObj.user.bt_scale_imei, 'BodyTrace scale')
    }

    return <EmptyCell message={message} />
  }

  const latestBpData = _.get(memberObj, ['blood_pressure', 'latest_measurement'])
  const systolicDelta = getSystolicDeltaInRange(memberObj)
  const diastolicDelta = getDiastolicDeltaInRange(memberObj)
  const lastBpMeasurement = moment(latestBpData.timestamp)

  const latestCol = (
    <Box>
      <div className='member-row__latest'>{`${Math.round(latestBpData.systolic)}/${Math.round(latestBpData.diastolic)}`}</div>
      <div className='member-row__latest-timestamp'>{lastBpMeasurement.calendar(null, momentCalendarDateConfig)}</div>
    </Box>
  )

  // by default, determine arrow direction by change in systolic; else change in diastolic
  const deltaArrow = systolicDelta !== 0 ? getDeltaArrow(systolicDelta, false) : getDeltaArrow(diastolicDelta, false)
  const deltaCol = (<span className='member-row__delta'> {getDisplayDelta(systolicDelta)}/{getDisplayDelta(diastolicDelta)} {deltaArrow} </span>)

  return (
    <Grid container>
      <Grid item xs={6}>{latestCol}</Grid>
      <Grid item xs={6}>{deltaCol}</Grid>
    </Grid>
  )
}
