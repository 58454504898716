import { Box, IconButton, Typography, TablePagination } from '@mui/material'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import React from 'react'

/* *****************************************************************************
 * Pagination element.
 * *****************************************************************************/

export default function Pagination (props) {
  const handlePaging = (parentHandler) => {
    window.scrollTo(0, 0)
    parentHandler()
  }

  const handleChange = (event, value) => {
    props.setPage(value + 1)
    window.scrollTo(0, 0)
  }

  const renderExperimentalPaginator = () => {
    return (
      <TablePagination
        component='div'
        count={props.itemCount}
        page={props.page - 1}
        onPageChange={handleChange}
        rowsPerPage={20}
        rowsPerPageOptions={[20]}
      />
    )
  }

  const renderPageRange = (variant) => {
    let pageRangeStr
    const variantType = variant ?? 'subtitle2'
    const pageEnd = Math.min(props.itemCount, props.pageEnd)

    if (props.pageStart === props.pageEnd) {
      pageRangeStr = props.pageStart
    } else {
      pageRangeStr = `${props.pageStart} - ${pageEnd}`
    }

    return (<Typography variant={variantType}>{pageRangeStr}</Typography>)
  }

  const renderPaginator = () => {
    const prevDisabled = (props.pageStart === 1)
    const nextDisabled = (props.pageEnd >= props.itemCount)

    return (
      <div className='c-pagination'>
        <IconButton
          data-testid='paginate__left-button'
          disabled={prevDisabled}
          onClick={() => handlePaging(props.handlePagePrev)}
        >
          <ChevronLeft />
        </IconButton>

        <Box sx={{ display: 'inline-flex', alignItems: 'center', ml: 1, mr: 1 }}>
          {renderPageRange()}
          <Typography variant='subtitle2' component='span' fontWeight={300} pl={1} pr={1}> of </Typography>
          <Typography variant='subtitle2'>{props.itemCount}</Typography>
        </Box>

        <IconButton
          data-testid='paginate__right-button'
          disabled={nextDisabled}
          onClick={() => handlePaging(props.handlePageNext)}
        >
          <ChevronRight />
        </IconButton>
      </div>
    )
  }

  return props.custom ? renderExperimentalPaginator() : renderPaginator()
}
