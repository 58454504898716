import _ from 'lodash'
import baseChartOptions from './baseChartOptions'
import { DAYS_OF_WEEK } from '../../js/constants/constants'
import { getAlertDayCounts } from './alertChartConfig'

function getAlertDayChartOptions (alerts) {
  const alertDayCounts = getAlertDayCounts(alerts)

  const alertDayChartOptions = {
    chart: {
      type: 'column'
    },

    xAxis: {
      categories: DAYS_OF_WEEK, // _.map(DAYS_OF_WEEK, d => d.slice(0, 2)),
      labels: {
        formatter: function format () {
          return this.value.slice(0, 2)
        }
      },
      tickLength: 0
    },

    yAxis: {
      title: {
        text: '# of alerts',
        margin: 24
      }
    },

    plotOptions: {
      column: {
        stacking: 'normal'
      }
    },

    tooltip: {
      headerFormat: null,
      pointFormatter: function format () {
        return `
          <span class="ax-text-bold">${this.category} - ${this.series.name}</span><br/>
          Alerts: <em>${this.y}</em>
        `
      }
    },

    series: _.map(alertDayCounts, c => ({
      name: c.name,
      data: c.value,
      className: c.className
    }))
  }

  return _.merge(_.cloneDeep(baseChartOptions), alertDayChartOptions)
}

export default getAlertDayChartOptions
