import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { productConfig } from '../../../config/config-service'

export default function CarePlanHeader (props) {
  const carePlan = productConfig().carePlan
  let alertColumnSize = props.isDefault ? 5 : 4
  if (!carePlan.canBeDisabled) alertColumnSize++
  if (!carePlan.hasLevel && !carePlan.hasGoal) alertColumnSize += 4

  return (
    <Box data-testid='care-plan__header' sx={{ flexGrow: 1, marginLeft: 2, color: (theme) => theme.palette.primary.main, fontWeight: 500 }}>
      <Grid container columnSpacing={3}>
        {!props.isDefault &&
          <Grid item xs={1}> <Box data-testid='care-plan__header__spacer' /> </Grid>}
        {carePlan.canBeDisabled &&
          <Grid item xs={1}>
            <Box data-testid='care-plan__header__enabled'>Enabled</Box>
          </Grid>}
        {(carePlan.hasLevel || carePlan.hasGoal) &&
          <Grid item xs={3}>
            <Box data-testid='care-plan__header__item'> {carePlan.headerItemLabel} </Box>
          </Grid>}
        <Grid item xs={alertColumnSize}>
          <Box data-testid='care-plan__header__alert'>Generate alert...</Box>
        </Grid>
        <Grid item xs={3}>
          <Box data-testid='care-plan__header__notify'>Notify...</Box>
        </Grid>
      </Grid>
    </Box>
  )
}
