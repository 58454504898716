import _ from 'lodash'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import Tooltip from '../../../elements/Tooltip'
import { DateRangePicker } from 'react-dates'
import { getPauseDescription } from '../../../../utils/member/baseProfileUtils'
import {
  Snackbar,
  Alert,
  Button,
  DialogActions,
  Box,
  Select,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Checkbox
} from '@mui/material'

const suspendAlertOptions = [
  { value: 'hospital', label: 'Hospitalization' },
  { value: 'vacation', label: 'Vacation' },
  { value: 'bed_rest', label: 'Bed rest' },
  { value: 'other', label: 'Other' }
]

const successMessages = {
  delete: 'Successfully canceled suspension.',
  update: 'Successfully updated suspension.',
  create: 'Successfully created suspension.',
  resume: 'Successfully resumed alerts.'
}

function SchedulePause (props) {
  const [saveInProgress, setSaveInProgress] = useState(false)
  const [cancelInProgress, setCancelInProgress] = useState(false)
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const [snackbarMessage, setSnackbarMessage] = useState(null)
  const [pristine, setPristine] = useState(true)
  const [unsavedStartDate, setUnsavedStartDate] = useState(null)
  const [unsavedEndDate, setUnsavedEndDate] = useState(null)
  const [focusedInput, setFocusedInput] = useState(null)
  const [isIndefiniteChecked, setIsIndefiniteChecked] = useState(false)
  const [unsavedReason, setUnsavedReason] = useState('')

  useEffect(() => {
    if (props.pause) {
      const startDate = _.get(props.pause, ['start']) ? moment(props.pause.start) : null
      const endDate = _.get(props.pause, ['resume']) ? transformResumeToEnd(moment(props.pause.resume)) : null
      const reason = props.pause.reason
      const newIsIndefiniteChecked = props.pause ? props.pause.resume == null : false

      setUnsavedStartDate(unsavedStartDate ?? startDate)
      setUnsavedEndDate(unsavedEndDate ?? endDate)
      setUnsavedReason(reason ?? unsavedReason)
      setIsIndefiniteChecked(isIndefiniteChecked ?? newIsIndefiniteChecked)
    }
  }, [])

  const handleDatesChange = ({ startDate, endDate }) => {
    setPristine(false)
    setUnsavedStartDate(startDate)
    setUnsavedEndDate(endDate)
    if (focusedInput === 'endDate') {
      // Ensure that the indefinite end date checkbox is unchecked
      setIsIndefiniteChecked(false)
    }
  }

  const handleCloseSnackbar = () => {
    setIsSnackbarOpen(false)
  }

  const handleDropdownChange = (event) => {
    setPristine(false)
    setUnsavedReason(event.target.value)
  }

  const handleToggleIndefiniteCheckbox = () => {
    const updatedCheckedValue = !isIndefiniteChecked
    setIsIndefiniteChecked(updatedCheckedValue)
    setPristine(false)

    if (updatedCheckedValue) {
      setUnsavedEndDate(null)
    }
  }

  const handleFocusChange = (newFocusedInput) => {
    // If indefinite, prevent auto focusing on end date after start date is selected.
    if (focusedInput === 'startDate' && newFocusedInput === 'endDate' && isIndefiniteChecked) {
      setFocusedInput(null)
    } else {
      setFocusedInput(newFocusedInput)
    }
  }

  const handleSave = () => {
    const apiBody = buildApiRequestBody(unsavedStartDate,
      unsavedEndDate, unsavedReason)
    setSaveInProgress(true)

    if (props.pause) {
      props.apiActions.updatePause(
        {
          id: props.memberObj.user.id,
          pause_id: props.pause.id
        }, {
          body: JSON.stringify(apiBody)
        }
      ).then(() => handleSuccess(successMessages.update))
    } else {
      props.apiActions.createPause(
        {
          id: props.memberObj.user.id
        }, {
          body: JSON.stringify(apiBody)
        }
      ).then(() => handleSuccess(successMessages.create))
    }
  }

  const handleCancelSuspension = () => {
    // Clear any local unsaved changes
    setCancelInProgress(true)
    setUnsavedEndDate(null)
    setUnsavedReason('')
    setUnsavedStartDate(null)
    setIsIndefiniteChecked(false)
    setFocusedInput(null)
    setPristine(true)

    // Either resume alerts or delete suspension altogether
    if (isPauseInFuture()) {
      handleDeletePause()
    } else if (isPauseActive) {
      handleResumeAlerts()
    }
  }

  const handleResumeAlerts = () => {
    if (props.pause) {
      // Give an end date of yesterday, which is a resume date of today
      const endDate = moment().subtract(1, 'days')

      const apiBody = buildApiRequestBody(moment(props.pause.start),
        endDate, props.pause.reason)

      props.apiActions.updatePause(
        {
          id: props.memberObj.user.id,
          pause_id: props.pause.id
        }, {
          body: JSON.stringify(apiBody)
        }
      ).then(() => handleSuccess(successMessages.resume))
    }
  }

  const handleDeletePause = () => {
    if (props.pause) {
      props.apiActions.deletePause(
        {
          id: props.memberObj.user.id,
          pause_id: props.pause.id
        }
      ).then(() => handleSuccess(successMessages.delete))
    }
  }

  const transformResumeToEnd = (resumeDate) => {
    return resumeDate.clone().subtract(1, 'days')
  }

  const transformEndToResume = (endDate) => {
    return endDate.clone().add(1, 'days')
  }

  const isPauseInFuture = () => {
    return props.pause && moment().isBefore(props.pause.start)
  }

  const isPauseActive = () => {
    return props.pause && moment().isSameOrAfter(props.pause.start)
  }

  const buildApiRequestBody = (start, end, reason) => {
    return {
      start: start ? start.format('YYYY-MM-DD') : null,
      resume: end ? transformEndToResume(end).format('YYYY-MM-DD') : null,
      reason: reason || null
    }
  }

  // Called when an API request was successful
  const handleSuccess = (updatedMessage) => {
    setPristine(true)
    setCancelInProgress(false)
    setSaveInProgress(false)
    setSnackbarMessage(updatedMessage)
    setIsSnackbarOpen(true)
    setSnackbarSeverity('success')
  }

  const renderSentence = () => {
    const pause = props.pause
    if (!pause) return null
    return (
      <Alert severity='info'>
        {getPauseDescription(pause)}
      </Alert>
    )
  }

  const renderSaveButton = () => {
    const enable = !saveInProgress && !pristine &&
      unsavedReason && unsavedStartDate != null &&
      (unsavedEndDate != null || isIndefiniteChecked)

    let saveText = 'Save changes'
    if (saveInProgress && !props.pause) {
      saveText = 'Creating...'
    } else if (saveInProgress && props.pause) {
      saveText = 'Updating...'
    }

    return (
      <Button variant='contained' data-testid='schedule-pause__save-button' onClick={() => handleSave()} disabled={!enable}>{saveText}</Button>
    )
  }

  const renderCancelSuspensionButton = () => {
    const enable = !cancelInProgress && props.pause
    const cancelText = cancelInProgress ? 'Canceling...' : 'Cancel suspension'

    return (
      <Button variant='outlined' data-testid='schedule-pause__cancel-button' onClick={() => handleCancelSuspension()} disabled={!enable}> {cancelText} </Button>
    )
  }

  const renderFeedback = () => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        key='edit-profile-pane__success__snack-bar'
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    )
  }

  const renderSchedulePause = () => {
    const helpText = 'Temporarily suspend alerts and reminders about this member. Data will still be collected during this time.'
    return (
      <div>
        {renderFeedback()}

        {renderSentence()}

        <Box className='u-strong u-bottom-pad--small' sx={{ mt: 2, mb: 1 }}>
          Suspend Alerts
          <Tooltip tooltipId='schedule-pause__suspend-alerts__tooltip' content={helpText} />
        </Box>

        <div>
          <div className='u-top-pad--small u-bottom-pad--small profile-pause-edit'>
            <FormControl sx={{ width: '15rem', marginRight: '1rem' }} size='small'>
              <InputLabel id='profile-pause__select-label'>Reason</InputLabel>
              <Select
                labelId='profile-pause__select-label'
                id='profile-pause__select'
                value={unsavedReason}
                label='Reason'
                onChange={handleDropdownChange}
              >
                {suspendAlertOptions.map(x => <MenuItem key={x.value} value={x.value}>{x.label}</MenuItem>)}
              </Select>
            </FormControl>
            <div className='DateRangePicker--small'>
              <DateRangePicker
                startDate={unsavedStartDate}
                startDateId='pause_start_date_input'
                endDate={unsavedEndDate}
                endDateId='pause_end_date_input'
                onDatesChange={handleDatesChange}
                focusedInput={focusedInput}
                onFocusChange={handleFocusChange}
                numberOfMonths={1}
                minimumNights={0}
                hideKeyboardShortcutsPanel
                startDatePlaceholderText='Start Date'
                endDatePlaceholderText={isIndefiniteChecked ? 'Indefinite' : 'End Date'}
                displayFormat='MM/DD/YY'
              />
            </div>
            <FormControlLabel
              label='Indefinitely'
              control={<Checkbox checked={isIndefiniteChecked} onChange={handleToggleIndefiniteCheckbox} />}
            />
          </div>
          <DialogActions data-testid='profile-pause__buttons'>
            {renderSaveButton()}
            {renderCancelSuspensionButton()}
          </DialogActions>
        </div>
      </div>
    )
  }

  return renderSchedulePause()
}

export default SchedulePause
