import React from 'react'
import CarePlanItem from '../CarePlanItem'

export default class ProgramStepChangeItem extends CarePlanItem {
  constructor (props) {
    super(props)
    this.type = 'program_step_change'
  }

  renderGoal () {
    return (<div> Program Step </div>)
  }

  renderClinicianTrigger () {
    return (<div>If program step changes.</div>)
  }

  renderCondensed () {
    return <div>Program step changes</div>
  }
}
