import React, { useState, useEffect } from 'react'
import { generateRandomId } from '../../utils/baseStringUtils'
import { styled } from '@mui/material/styles'
import MUITooltip, { tooltipClasses } from '@mui/material/Tooltip'
import Help from '@mui/icons-material/Help'

const BootstrapTooltip = styled(({ className, ...props }) => (
  <MUITooltip {...props} arrow classes={{ popper: className }} />
))(({ _theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '.9rem'
  }
}))

function Tooltip (props) {
  const [tooltipId, setTooltipId] = useState(`tooltip-${generateRandomId()}`)
  const [direction, setDirection] = useState('right')

  useEffect(() => {
    if (!props.tooltipId) {
      setTooltipId(`tooltip-${generateRandomId()}`)
    } else setTooltipId(props.tooltipId)
    if (props.direction) setDirection(props.direction)
  }, [])

  const renderTooltip = () => {
    const tooltipIcon = props.icon || <Help color='secondary' fontSize='1rem' />

    return (
      <BootstrapTooltip placement={direction} id={tooltipId} data-testid={tooltipId} title={props.content} arrow>
        <span>{tooltipIcon}</span>
      </BootstrapTooltip>
    )
  }

  return renderTooltip()
}

export default Tooltip
