import _ from 'lodash'
import { productConfig } from '../../js/config/config-service'

/* ****************************************************************************
 * Configuration for ageChart.js
 * ****************************************************************************/

const defaultAgeBuckets = {
  age_low: 'Under 40 years',
  age_40s: '40-49 years',
  age_50s: '50-59 years',
  age_60s: '60-69 years',
  age_70s: '70-79 years',
  age_high: '80+ years'
}

const pregnancyAgeBuckets = {
  age_low: 'Under 20 years',
  age_20: '20-25 years',
  age_25: '25-30 years',
  age_30: '30-35 years',
  age_35: '35-40 years',
  age_40: '40+ years'
}

const getDefaultAgeCounts = (users) => {
  return {
    age_low: _.filter(users, u => u.info.age < 40),
    age_40s: _.filter(users, u => u.info.age >= 40 && u.info.age < 50),
    age_50s: _.filter(users, u => u.info.age >= 50 && u.info.age < 60),
    age_60s: _.filter(users, u => u.info.age >= 60 && u.info.age < 70),
    age_70s: _.filter(users, u => u.info.age >= 70 && u.info.age < 80),
    age_high: _.filter(users, u => u.info.age >= 80)
  }
}

const getPregnancyAgeCounts = (users) => {
  return {
    age_low: _.filter(users, u => u.info.age < 20),
    age_20: _.filter(users, u => u.info.age >= 20 && u.info.age < 25),
    age_25: _.filter(users, u => u.info.age >= 25 && u.info.age < 30),
    age_30: _.filter(users, u => u.info.age >= 30 && u.info.age < 35),
    age_35: _.filter(users, u => u.info.age >= 35 && u.info.age < 40),
    age_40: _.filter(users, u => u.info.age >= 40)
  }
}

export const getAgeBuckets = () => {
  if (productConfig().profile.showPregnancyInfo) return pregnancyAgeBuckets
  else return defaultAgeBuckets
}

export const getAgeCounts = (users) => {
  if (productConfig().profile.showPregnancyInfo) return getPregnancyAgeCounts(users)
  else return getDefaultAgeCounts(users)
}
