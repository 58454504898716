
import React from 'react'
import { MS_IN_A_DAY } from '../../../../constants/constants'
import { renderLevel } from '../../../../utils/alertUtils'
import { productConfig } from '../../../../config/config-service'
import CarePlanItem from '../CarePlanItem'
import { CAREPLAN_VALUE_TYPES } from '../../../../constants/carePlanValueTypes'

export default class WeightRangeItem extends CarePlanItem {
  constructor (props) {
    super(props)
    this.type = 'weight_delta'
    this.config = productConfig()
  }

  getValueInput () {
    return this.getInputElement(
      1, 10, 1,
      CAREPLAN_VALUE_TYPES.VALUE.get(),
      CAREPLAN_VALUE_TYPES.VALUE.set()
    )
  }

  getTimeInput () {
    return this.getInputElement(
      1, 14, 1,
      CAREPLAN_VALUE_TYPES.TIME.get(val => val / MS_IN_A_DAY),
      CAREPLAN_VALUE_TYPES.TIME.set(val => val * MS_IN_A_DAY)
    )
  }

  renderGoal () {
    return <div>Maintain weight</div>
  }

  renderClinicianTrigger () {
    const numDays = this.getValue(CAREPLAN_VALUE_TYPES.TIME.get(val => val / MS_IN_A_DAY))
    return (
      <div>
        If weight increases {this.getValueInput()} lb. or more over {this.getTimeInput()} day{numDays === 1 ? '' : 's'}.
      </div>
    )
  }

  renderCondensed () {
    const lbs = this.getValueElement(CAREPLAN_VALUE_TYPES.VALUE.get())
    const getNumDays = CAREPLAN_VALUE_TYPES.TIME.get(val => val / MS_IN_A_DAY)
    const numDays = this.getValue(getNumDays)
    const days = this.getValueElement(getNumDays)
    const level = this.state.itemData.level ? renderLevel(this.state.itemData.level, true) : ''
    return <div>{level} +{lbs} lb. over {days} day{numDays === 1 ? '' : 's'}</div>
  }
}
