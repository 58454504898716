
import React from 'react'
import { CAREPLAN_VALUE_TYPES } from '../../../../constants/carePlanValueTypes'
import { productConfig } from '../../../../config/config-service'
import CarePlanItem from '../CarePlanItem'
import { renderLevel } from '../../../../utils/alertUtils'

export default class SurveyRangeItem extends CarePlanItem {
  constructor (props) {
    super(props)
    this.type = 'survey_range'
    this.config = productConfig()
  }

  yellowMinInputElement () {
    return this.getInputElement(
      1, 9, 1,
      CAREPLAN_VALUE_TYPES.YELLOW_SURVEY_MIN.get(val => val),
      CAREPLAN_VALUE_TYPES.YELLOW_SURVEY_MIN.set(val => val)
    )
  }

  orangeMinInputElement () {
    return this.getInputElement(
      1, 9, 1,
      CAREPLAN_VALUE_TYPES.ORANGE_SURVEY_MIN.get(val => val),
      CAREPLAN_VALUE_TYPES.ORANGE_SURVEY_MIN.set(val => val)
    )
  }

  renderGoal () {
    return <div>Keep symptoms in range</div> // Yeah this doesn't really make sense, but it's not currently used.
  }

  renderLevel () {
    return (
      <span>
        <span className='alert-level-icon alert-level-icon__yellow'>●</span><span className='alert-level-text'> Yellow</span>
        <br />
        <span className='alert-level-icon alert-level-icon__orange'>●</span><span className='alert-level-text'> Orange</span>
        <br />
        <span className='alert-level-icon alert-level-icon__orange'>●</span><span className='alert-level-text'> Orange</span>
        <br />
        <span className='alert-level-icon alert-level-icon__red'>●</span><span className='alert-level-text'> Red</span>
      </span>
    )
  }

  renderClinicianTrigger () {
    return (
      <div>
        <div className='survey-careplan-row'>If total survey score is at least {this.yellowMinInputElement()} out of a possible 24.</div>
        <div className='survey-careplan-row'>If total survey score is at least {this.orangeMinInputElement()} out of a possible 24.</div>
        <div className='survey-careplan-row'>If <i>chest pain</i> response is <i>slight</i> (2) or <i>moderate</i> (3).</div>
        <div className='survey-careplan-row'>If <i>chest pain</i> response is <i>severe</i> (4).</div>
      </div>
    )
  }

  renderCondensed () {
    return <div>{renderLevel(this.state.itemData.level, true)} Survey out of range</div>
  }
}
